import React from 'react';
import styles from "../UnlockableContent/styles.module.scss";
import fireImg from "../../../assets/images/symbols/fire.svg";
import Switch from "../../../components/Switch/Switch";
import cn from "classnames";

type Props = {
    setCreateData: any,
    createData: any,
    noBorder?: boolean
}

const NsfWcontent = ({createData, setCreateData , noBorder}: Props) => {
    const onChange = () => setCreateData({
        ...createData,
        isNsfWcontent: !createData?.isNsfWcontent
    })

    return (
        <div className={cn(styles.propertiesBlock, noBorder && styles.noBorder)}>

            <div className={styles.wrapper}>
                <img src={fireImg} alt={''}/>
                <div>
                    <p className={styles.title}>NSFW (18+) content<span>(Optional)</span></p>
                    <p className={styles.text}>Set this item as explicit and sensitive content</p>
                </div>
            </div>

            <Switch isChecked={createData?.isNsfWcontent} onChange={onChange}/>

        </div>
    );
};

export default NsfWcontent;

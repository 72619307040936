import {
    SORTING_BEST_OFFER,
    SORTING_FLOOR_DECREASE, SORTING_FLOOR_INCREASE,
    SORTING_MOST_FAVORITED,
    SORTING_MOST_VIEWED, SORTING_PRICE_DECREASE, SORTING_PRICE_INCREASE, SORTING_RECENTLY_ADDED,
} from "../constants/profile";
import {
    STATS_TIME_1H,
    STATS_TIME_24H,
    STATS_TIME_30D,
    STATS_TIME_7D,
    STATS_TIME_ALL_TIME
} from "../constants/statsPage";
import moment from "moment";

export const sortNFTList = (array: any, sortingID: number) => {
    let newArray = array

    if (sortingID === SORTING_RECENTLY_ADDED) {
        newArray = array?.sort((a: any, b: any) => moment(b?.createdAt).unix() - moment(a?.createdAt).unix())
    } else if (sortingID === SORTING_PRICE_INCREASE) {
        const listedArr = array?.filter((item: any) =>
            item?.listing?.price > 0 && item?.listing?.endDate > moment().unix() && item?.listing?.startDate <= moment().unix())
        const notListedArr = array?.filter((item: any) =>
            !item?.listing?.price
            || item?.listing?.price <= 0
            || (item?.listing?.price > 0 && item?.listing?.endDate <= moment().unix())
            || (item?.listing?.price > 0 && item?.listing?.startDate > moment().unix()))

        newArray = [...listedArr?.sort((a: any, b: any) => (+a?.listing?.price || 0) - (+b?.listing?.price || 0)), ...notListedArr]
    } else if (sortingID === SORTING_PRICE_DECREASE) {
        const listedArr = array?.filter((item: any) =>
            item?.listing?.price > 0 && item?.listing?.endDate > moment().unix() && item?.listing?.startDate <= moment().unix())
        const notListedArr = array?.filter((item: any) =>
            !item?.listing?.price
            || item?.listing?.price <= 0
            || (item?.listing?.price > 0 && item?.listing?.endDate <= moment().unix())
            || (item?.listing?.price > 0 && item?.listing?.startDate > moment().unix()))

        newArray = [...listedArr?.sort((a: any, b: any) => (+b?.listing?.price || 0) - (+a?.listing?.price || 0)), ...notListedArr]
    } else if (sortingID === SORTING_FLOOR_DECREASE) {
        const hasFloor = array?.filter((item: any) => item?.floorPrice > 0)
        const noFloor = array?.filter((item: any) => !item?.floorPrice || item?.floorPrice <= 0)

        newArray = [...hasFloor?.sort((a: any, b: any) => b?.floorPrice - a?.floorPrice), ...noFloor]
    } else if (sortingID === SORTING_FLOOR_INCREASE) {
        const hasFloor = array?.filter((item: any) => item?.floorPrice > 0)
        const noFloor = array?.filter((item: any) => !item?.floorPrice || item?.floorPrice <= 0)

        newArray = [...hasFloor?.sort((a: any, b: any) => a?.floorPrice - b?.floorPrice), ...noFloor]
    } else if (sortingID === SORTING_BEST_OFFER) {
        newArray = array?.sort((aItem: any, bItem: any) => {
            const bestOfferA = aItem?.offers
                ?.filter((offer: any) => offer?.price > 0 && offer?.startDate <= moment().unix())
                ?.sort((a: any, b: any) => b?.price - a?.price)[0]

            const bestOfferB = bItem?.offers
                ?.filter((offer: any) => offer?.price > 0 && offer?.startDate <= moment().unix())
                ?.sort((a: any, b: any) => b?.price - a?.price)[0]

            return (+bestOfferB?.price || 0) - (+bestOfferA?.price || 0)
        })

    } else if (sortingID === SORTING_MOST_VIEWED) {
        newArray = array?.sort((a: any, b: any) => (b?.views || 0) - (a?.views || 0))
    } else if (sortingID === SORTING_MOST_FAVORITED) {
        newArray = array?.sort((a: any, b: any) => (b?.likes || 0) - (a?.likes || 0))
    }

    return newArray
}


export const SORT_BY_NAME_INCREASE = 'SORT_BY_NAME_INCREASE'
export const SORT_BY_NAME_DECREASE = 'SORT_BY_NAME_DECREASE'

export const SORT_BY_VOLUME_INCREASE = 'SORT_BY_VOLUME_INCREASE'
export const SORT_BY_VOLUME_DECREASE = 'SORT_BY_VOLUME_DECREASE'

export const SORT_BY_FLOOR_INCREASE = 'SORT_BY_FLOOR_INCREASE'
export const SORT_BY_FLOOR_DECREASE = 'SORT_BY_FLOOR_DECREASE'

export const SORT_BY_SALES_INCREASE = 'SORT_BY_SALES_INCREASE'
export const SORT_BY_SALES_DECREASE = 'SORT_BY_SALES_DECREASE'

export const SORT_BY_OWNERS_INCREASE = 'SORT_BY_OWNERS_INCREASE'
export const SORT_BY_OWNERS_DECREASE = 'SORT_BY_OWNERS_DECREASE'

export const SORT_BY_LISTED_INCREASE = 'SORT_BY_LISTED_INCREASE'
export const SORT_BY_LISTED_DECREASE = 'SORT_BY_LISTED_DECREASE'


export const sortCollections = (collections: any, param: string, activeSubTab: number) => {
    if (!collections) return []

    if (param === SORT_BY_NAME_INCREASE)
        return collections?.sort((a: any, b: any) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })
    else if (param === SORT_BY_NAME_DECREASE)
        return collections?.sort((a: any, b: any) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })?.reverse()

    else if (param === SORT_BY_OWNERS_INCREASE)
        return collections?.sort((a: any, b: any) => a?.uniqueOwners - b?.uniqueOwners)

    else if (param === SORT_BY_OWNERS_DECREASE)
        return collections?.sort((a: any, b: any) => b?.uniqueOwners - a?.uniqueOwners)

    else if (param === SORT_BY_LISTED_INCREASE)
        return collections?.sort((a: any, b: any) => (+a?.itemsListed || 0) - (+b?.itemsListed || 0))

    else if (param === SORT_BY_LISTED_DECREASE)
        return collections?.sort((a: any, b: any) => (+b?.itemsListed || 0) - (+a?.itemsListed || 0))


    else if (param === SORT_BY_VOLUME_INCREASE) {
        if (activeSubTab === STATS_TIME_1H)
            return collections?.sort((a: any, b: any) => (+a?.volume1H || 0) - (+b?.volume1H || 0))
        else if (activeSubTab === STATS_TIME_24H)
            return collections?.sort((a: any, b: any) => (+a?.volume24H || 0) - (+b?.volume24H || 0))
        else if (activeSubTab === STATS_TIME_7D)
            return collections?.sort((a: any, b: any) => (+a?.volume7D || 0) - (+b?.volume7D || 0))
        else if (activeSubTab === STATS_TIME_30D)
            return collections?.sort((a: any, b: any) => (+a?.volume30D || 0) - (+b?.volume30D || 0))
        else if (activeSubTab === STATS_TIME_ALL_TIME)
            return collections?.sort((a: any, b: any) => (+a?.volumeAllTime || 0) - (+b?.volumeAllTime || 0))
    } else if (param === SORT_BY_VOLUME_DECREASE) {
        if (activeSubTab === STATS_TIME_1H)
            return collections?.sort((a: any, b: any) => (+b?.volume1H || 0) - (+a?.volume1H || 0))
        else if (activeSubTab === STATS_TIME_24H)
            return collections?.sort((a: any, b: any) => (+b?.volume24H || 0) - (+a?.volume24H || 0))
        else if (activeSubTab === STATS_TIME_7D)
            return collections?.sort((a: any, b: any) => (+b?.volume7D || 0) - (+a?.volume7D || 0))
        else if (activeSubTab === STATS_TIME_30D)
            return collections?.sort((a: any, b: any) => (+b?.volume30D || 0) - (+a?.volume30D || 0))
        else if (activeSubTab === STATS_TIME_ALL_TIME)
            return collections?.sort((a: any, b: any) => (+b?.volumeAllTime || 0) - (+a?.volumeAllTime || 0))
    } else if (param === SORT_BY_FLOOR_INCREASE) {
        return collections?.sort((a: any, b: any) => (+a?.currentListingsFloorPrice || 0) - (+b?.currentListingsFloorPrice || 0))
    } else if (param === SORT_BY_FLOOR_DECREASE) {
        collections?.sort((a: any, b: any) => (+b?.currentListingsFloorPrice || 0) - (+a?.currentListingsFloorPrice || 0))
    } else if (param === SORT_BY_SALES_DECREASE) {
        if (activeSubTab === STATS_TIME_1H)
            return collections?.sort((a: any, b: any) => (+b?.sales1H || 0) - (+a?.sales1H || 0))
        else if (activeSubTab === STATS_TIME_24H)
            return collections?.sort((a: any, b: any) => (+b?.sales24H || 0) - (+a?.sales24H || 0))
        else if (activeSubTab === STATS_TIME_7D)
            return collections?.sort((a: any, b: any) => (+b?.sales7D || 0) - (+a?.sales7D || 0))
        else if (activeSubTab === STATS_TIME_30D)
            return collections?.sort((a: any, b: any) => (+b?.sales30D || 0) - (+a?.sales30D || 0))
        else if (activeSubTab === STATS_TIME_ALL_TIME)
            return collections?.sort((a: any, b: any) => (+b?.salesAllTime || 0) - (+a?.salesAllTime || 0))
    } else if (param === SORT_BY_SALES_INCREASE) {
        if (activeSubTab === STATS_TIME_1H)
            return collections?.sort((a: any, b: any) => (+a?.sales1H || 0) - (+b?.sales1H || 0))
        else if (activeSubTab === STATS_TIME_24H)
            return collections?.sort((a: any, b: any) => (+a?.sales24H || 0) - (+b?.sales24H || 0))
        else if (activeSubTab === STATS_TIME_7D)
            return collections?.sort((a: any, b: any) => (+a?.sales7D || 0) - (+b?.sales7D || 0))
        else if (activeSubTab === STATS_TIME_30D)
            return collections?.sort((a: any, b: any) => (+a?.sales30D || 0) - (+b?.sales30D || 0))
        else if (activeSubTab === STATS_TIME_ALL_TIME)
            return collections?.sort((a: any, b: any) => (+a?.salesAllTime || 0) - (+b?.salesAllTime || 0))
    }


    return collections

}

export const sortInAlphabeticalOrder = (arr: any) => {
    return arr.sort(function (a: any, b: any) {
        if (a?.toLowerCase() < b?.toLowerCase())
            return -1;
        if (a?.toLowerCase() > b?.toLowerCase())
            return 1;
        return 0;
    });
}

export const sortBuTitleInAlphabeticalOrder = (arr: any) => {
    return arr.sort(function (a: any, b: any) {
        if (a?.title?.toString()?.toLowerCase() < b?.title?.toString()?.toLowerCase())
            return -1;
        if (a?.title?.toString()?.toLowerCase() > b?.title?.toString()?.toLowerCase())
            return 1;
        return 0;
    });
}

export const sortByTraitInAlphabeticalOrder = (arr: any) => {
    return arr.sort(function (a: any, b: any) {
        if (a?.trait_type?.toString()?.toLowerCase() < b?.trait_type?.toString()?.toLowerCase())
            return -1;
        if (a?.trait_type?.toString()?.toLowerCase() > b?.trait_type?.toString()?.toLowerCase())
            return 1;
        return 0;
    });
}


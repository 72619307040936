// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_collection__LoWab {
  margin-bottom: 8px;
  position: relative;
  max-width: 512px;
}
.styles_collection__LoWab input {
  cursor: pointer;
}

.styles_dropdown__7ue\\+r {
  position: absolute;
  top: 57px;
  right: 0;
  width: 100%;
  background: #242424;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 18px 22px;
  box-sizing: border-box;
  gap: 24px;
  z-index: 2;
}

.styles_dropdown_item__q-oa2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/templates/CreateCollectionPage/PaymentTokensDropdown/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AAAF","sourcesContent":[".collection {\n  margin-bottom: 8px;\n  position: relative;\n  max-width: 512px;\n\n  input {\n    cursor: pointer\n  }\n}\n\n.dropdown {\n  position: absolute;\n  top: 57px;\n  right: 0;\n  width: 100%;\n  background: #242424;\n  border-radius: 8px;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-direction: column;\n  padding: 18px 22px;\n  box-sizing: border-box;\n  gap: 24px;\n  z-index: 2;\n}\n\n.dropdown_item {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 16px;\n  width: 100%;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collection": `styles_collection__LoWab`,
	"dropdown": `styles_dropdown__7ue+r`,
	"dropdown_item": `styles_dropdown_item__q-oa2`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__SzHHf {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
}

.styles_title__FM3tV {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.35px;
  color: #FFFFFF;
  margin: 0;
  text-align: center;
}

.styles_actions__uPdYT {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
@media (max-width: 550px) {
  .styles_actions__uPdYT {
    flex-direction: column;
    width: 100%;
  }
  .styles_actions__uPdYT div {
    width: 100%;
  }
}

.styles_text__SRyBa {
  text-align: center;
  margin: 20px 0 40px;
  line-height: 25px;
}
.styles_text__SRyBa span {
  cursor: pointer;
  color: #F69C00;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/SignUpModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,sBAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,uBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACF;AAAE;EALF;IAMI,sBAAA;IACA,WAAA;EAGF;EAFE;IACE,WAAA;EAIJ;AACF;;AAAA;EACE,kBAAA;EACA,mBAAA;EACA,iBAAA;AAGF;AADE;EACE,eAAA;EACA,cAAA;AAGJ","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  box-sizing: border-box;\n  flex-direction: column;\n}\n\n.title {\n  font-weight: 700;\n  font-size: 20px;\n  letter-spacing: -0.35px;\n  color: #FFFFFF;\n  margin: 0;\n  text-align: center;\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  @media (max-width: 550px) {\n    flex-direction: column;\n    width: 100%;\n    div {\n      width: 100%;\n    }\n  }\n}\n\n.text {\n  text-align: center;\n  margin: 20px 0 40px;\n  line-height: 25px;\n\n  span {\n    cursor: pointer;\n    color: #F69C00;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__SzHHf`,
	"title": `styles_title__FM3tV`,
	"actions": `styles_actions__uPdYT`,
	"text": `styles_text__SRyBa`
};
export default ___CSS_LOADER_EXPORT___;

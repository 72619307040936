import React, {useEffect} from 'react';
import styles from "../PropertiesBlock/styles.module.scss";
import levelsImg from "../../../assets/images/symbols/levels.svg";
import plusImg from "../../../assets/images/symbols/plus.svg";
import deleteImg from "../../../assets/images/symbols/delete.svg";
import AddLevelsModal from "../../../components/Modals/AddLevelsModal/AddLevelsModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";

type Props = {
    setCreateData: any,
    createData: any
}

const LevelsBlock = ({createData, setCreateData}: Props) => {
    const handleAddLevelsModal = useHandleModal()

    const onOpenModal = () => handleAddLevelsModal.open()
    const onChangeLevel = (e: any, id: number) => setCreateData(
        {
            ...createData,
            levels: createData?.levels?.map((item: any) => item?.id === id
                ? {...item, level: e?.target?.value}
                : item)
        }
    )

    const onDelete = (id: number) => setCreateData({
        ...createData,
        levels: createData?.levels?.filter((item: any) => item?.id !== id)
    })

    const levelWidth: any = (level: any) => {
        return level?.level / (+level?.maxLevel || 1000) * 100 + '%'
    }

    return (
        <div className={styles.propertiesBlock}>

            <div className={styles.block_top}>
                <div className={styles.wrapper}>
                    <img src={levelsImg} alt={''}/>
                    <div>
                        <p className={styles.title}>Levels<span>(Optional)</span></p>
                        <p className={styles.text}>Numerical traits that show as a progress bar</p>
                    </div>
                </div>


                <div className={styles.plus} onClick={onOpenModal}>
                    <img src={plusImg} alt={''}/>
                </div>
            </div>

            {createData?.levels?.some((item: any) => +item?.level && item?.type?.length)
            && <div className={styles.list}>
                {createData?.levels?.map((item: any, key: number) =>
                    <div className={styles.block_bottom} key={key}>
                        <div className={styles.power}>
                            <p>{item?.type}</p>
                            <div className={styles.range_wrapper}>
                                <input
                                    type={"range"}
                                    min="1"
                                    max={item?.maxLevel?.toString() || '1000'}
                                    step={'1'}
                                    value={item?.level}
                                    onChange={(e) => onChangeLevel(e, item?.id)}
                                    style={{backgroundSize: `${levelWidth(item)} 100%`}}
                                />
                                <span>{item?.level} of {item?.maxLevel || '1000'}</span>
                            </div>
                        </div>
                        <div className={styles.deleteImg} onClick={() => onDelete(item?.id)}>
                            <img src={deleteImg} alt={''}/>
                        </div>
                    </div>
                )}
            </div>}

            {handleAddLevelsModal.isActive && <AddLevelsModal
                isOpen={handleAddLevelsModal.isActive}
                onClose={handleAddLevelsModal.close}
                setCreateData={setCreateData}
                createData={createData}
            />}
        </div>
    );
};

export default LevelsBlock;

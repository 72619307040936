import React, {useRef, useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import {HEADER_NAV, HEADER_NAV_WHITELIST} from "../../constants/header";
import profileImg from "../../assets/images/symbols/user.svg";
import walletImg from "../../assets/images/symbols/wallet.svg";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../routes/path";
import {useSelector} from "react-redux";
import useHandleAuth from "../../hooks/auth/useHandleAuth";
import collectionsImg from "../../assets/images/symbols/my-collections.svg";
import settingsImg from "../../assets/images/symbols/settings.svg";
import logoutImg from "../../assets/images/symbols/log-out.svg";
import {toast} from "react-toastify";
import ToastMsg from "../ToastMsg/ToastMsg";
import Button from "../Button/Button";
import SignUpModal from "../Modals/SignUpModal/SignUpModal";
import useHandleModal from "../../hooks/dom/useHandleModal";
import WalletDropdown from "../WalletDropdown/WalletDropdown";

type Props = {
    isOpenMenu: boolean,
    isOpenWallet: boolean,
    onCloseWallet: any,
    onOpenWallet: any
}

const MobileMenu = ({isOpenMenu, isOpenWallet, onCloseWallet, onOpenWallet}: Props) => {
    const customer = useSelector((state: any) => state.customer.data)

    const walletRef = useRef<HTMLInputElement>(null)
    const handleSignUpModal = useHandleModal()
    const navigate = useNavigate()
    const handleAuth = useHandleAuth();

    const onClickNavLink = (link: string) => {
        if (link === pathList.create.path && !customer?._id)
            return toast(<ToastMsg text={'Log in to create NFT'} isError/>)

        navigate(link)
    }

    const onConnectWallet = async () => {
        if (!customer?._id) {
            await handleAuth.connectWallet(handleSignUpModal.open)
        }
    }

    return (
        <div className={cn(styles.mobileMenu, isOpenMenu && styles.mobileMenu_isOpenMenu)}>

            <WalletDropdown dropdownRef={walletRef} isOpenWallet={isOpenWallet} onCloseWallet={onCloseWallet}/>

            <div className={styles.wrapper}>
                <div className={styles.nav}>
                    {(customer?.hasAccessToDrops ? HEADER_NAV_WHITELIST : HEADER_NAV)
                        ?.map((item, key) =>
                            <div
                                key={key}
                                className={styles.nav_item}
                                onClick={() => onClickNavLink(item?.link)}
                            >
                                <div><img src={item?.image} alt={''}/></div>
                                {item?.title}
                            </div>)}
                </div>

                <div className={styles.bottom_nav}>
                    <div className={styles.nav_item}
                         onClick={
                             () => !!customer?._id
                                 ? onClickNavLink(`/${customer?.userName || customer?.address}/collected`)
                                 : onConnectWallet()
                         }>
                        <div className={styles.profile}>
                            <img width={40} height={40} src={customer?.avatar || profileImg} alt={''}/>
                        </div>
                        Profile
                    </div>

                    {// @ts-ignore
                        !window?.safari &&
                        <div className={styles.nav_item} onClick={
                            () => !!customer?._id
                                ? onOpenWallet()
                                : onConnectWallet()
                        }>
                            <div><img src={walletImg} alt={''}/></div>
                            Wallet
                        </div>}

                    {!!customer?._id &&
                    <div className={styles.nav_item}
                         onClick={
                             () => onClickNavLink(`/collections/${customer?.userName || customer?.address}`)//done
                         }>
                        <img src={collectionsImg} alt={''}/>
                        My collections
                    </div>}


                    <div className={styles.nav_item}
                         onClick={() => onClickNavLink(pathList.guides.path)}>
                        <img src={settingsImg} alt={''}/>
                        Guides
                    </div>

                    {!!customer?._id && <div className={styles.nav_item} onClick={handleAuth.logout}>
                        <img src={logoutImg} alt={''}/>
                        Log out
                    </div>}
                </div>
            </div>

            {// @ts-ignore
                (!window?.safari && !customer?._id) &&
                <Button
                    isFullWidth
                    title={'Connect Wallet'}
                    isYellow
                    onClick={onConnectWallet}
                />}

            {handleSignUpModal.isActive && <SignUpModal
                isOpen={handleSignUpModal.isActive}
                onClose={handleSignUpModal.close}
            />}
        </div>
    );
};

export default MobileMenu;

import AuthApi from "../../utils/api/AuthApi";
import {localStorageRemove, localStorageSet} from "../../utils/localStorage";
import useHandleCustomer from "../customer/useHandleCustomer";
import * as customerActions from "../../store/actions/customer";
import {useDispatch, useSelector} from "react-redux";
import useHandleWeb3 from "../web3/useHandleWeb3";
import {useLocation, useNavigate} from "react-router-dom";
import {pathList} from "../../routes/path";
import {toast} from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import CustomerApi from "../../utils/api/CustomerApi";
import useHandleLoader from "../loader/useHandleLoader";
import {
    CHAIN_ID,
    CHAIN_NAME,
    CHAIN_RPC_URL,
    BLOCK_EXPLORER_URL,
    NATIVE_CURRENCY
} from "../../constants";

export default function useHandleAuth() {
    const customer = useSelector((state: any) => state.customer.data)
    const dispatch: any = useDispatch();
    const handleCustomer = useHandleCustomer();
    const handleLoader = useHandleLoader();
    const handleWeb3 = useHandleWeb3();
    const navigate = useNavigate()
    const location = useLocation()

    const login = async () => {
        try {
            await handleWeb3.loadWeb3(logout);

            // @ts-ignore
            if (!window?.ethereum) {
                toast(<ToastMsg text={'Install metamask to authorize!'} isError/>)
                return null;
            }
            // @ts-ignore
            const web3 = window?.web3;

            const coinbase = await web3?.eth?.getCoinbase();
            if (!coinbase) return null;

            const address = coinbase?.toString()?.toLowerCase();
            if (!address) return null;

            const nonce = await handleCustomer.getNonce(address).catch(() => null);
            if (!nonce) return null;

            // @ts-ignore
            const signature = await window.web3?.eth.personal
                .sign(`I am signing my one-time nonce: ${nonce}`, address, "")
                .catch(() => null);
            if (!signature) return logout();

            return new AuthApi()
                .loginMetamask({address, signature})
                .then((res) => {
                    if (res?.status) {
                        if (!res?.data?.customer?.isBlocked) {
                            dispatch(customerActions.setCustomer(res?.data?.customer));
                            localStorageSet("token", res?.data?.token);
                        } else toast(<ToastMsg text={'This account is blocked'} isError/>)
                    }
                })
                .catch(() => null)
        } catch (err) {
            return null;
        }
    };

    const secondLogin = async () => {
        await handleWeb3.loadWeb3(logout);

        // @ts-ignore
        if (!window?.ethereum) {
            toast(<ToastMsg text={'Install metamask to authorize!'} isError/>)
            return null;
        }
        // @ts-ignore
        const web3 = window?.web3;

        const coinbase = await web3?.eth?.getCoinbase();
        if (!coinbase) return null;

        const address = coinbase?.toString()?.toLowerCase();
        if (!address) return null;

        const nonce = await handleCustomer.getNonce(address).catch(() => null);
        if (!nonce) return null;

        return new AuthApi()
            .secondLoginMetamask(address)
            .then((res) => {
                if (res?.status) {
                    if (!res?.data?.customer?.isBlocked) {
                        dispatch(customerActions.setCustomer(res?.data?.customer));
                        localStorageSet("token", res?.data?.token);
                    } else toast(<ToastMsg text={'This account is blocked'} isError/>)
                }
            })
            .catch(() => null)
    }

    const logout = () => {
        localStorageRemove("token");
        dispatch(customerActions.setCustomer(null));

        if (location?.pathname === `/${customer?.userName || customer?.address}/collected` //done
            || location?.pathname === `/${customer?.userName || customer?.address}/created` //done
            || location?.pathname === `/${customer?.userName || customer?.address}/activity` //done
            || location?.pathname === `/${customer?.userName || customer?.address}/listings` //done
            || location?.pathname === `/${customer?.userName || customer?.address}/offers` //done
            || location?.pathname === `/${customer?.userName || customer?.address}/favorites` //done
            || location?.pathname === `/collections/${customer?.userName || customer?.address}` //done
        )
            navigate(pathList.mainPage.path)
    };

    const connectWallet = async (onOpenModal: any, successCallback?: any) => {
        // @ts-ignore
        if (!window?.ethereum) {
            toast(<ToastMsg text={'Install metamask to authorize!'} isError/>)
            return null;
        } else {
            // @ts-ignore
            if (window.ethereum?.chainId !== CHAIN_ID) {
                try {
                    // @ts-ignore
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{chainId: CHAIN_ID}],
                    });
                } catch (switchError: any) {
                    if (switchError.code === 4902) {
                        try {
                            // @ts-ignore
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainId: CHAIN_ID,
                                        chainName: CHAIN_NAME,
                                        rpcUrls: [CHAIN_RPC_URL],
                                        blockExplorerUrls: [BLOCK_EXPLORER_URL],
                                        nativeCurrency: {
                                            symbol: NATIVE_CURRENCY,
                                            decimals: 18
                                        }
                                    }]
                            });
                        } catch (addError) {
                            return toast(<ToastMsg
                                text={'Shibarium network is not available in your metamask, please add it'}
                                isError/>)
                        }
                    } else return toast(<ToastMsg
                        text={'Selected network is wrong! Switch network to Shibarium'}
                        isError/>)
                }
            }
            // @ts-ignore
            await window.ethereum
                .request({method: `eth_requestAccounts`})
                .catch((err: any) => {
                    if (err.code === 4001) {
                        console.log('Please connect to MetaMask.');
                    } else {
                        console.error('eth_requestAccounts ERROR ---> ', err);
                    }
                })
        }
        // @ts-ignore
        if (window?.ethereum?.selectedAddress) {
            await new CustomerApi()
                // @ts-ignore
                .getProfile(window?.ethereum?.selectedAddress)
                .then(async (res) => {
                    if (res?.status) {
                        await handleLoader.loaderWrapper(() => secondLogin())
                    } else {
                        onOpenModal()
                    }
                })
            handleLoader.loaderWrapper(() => handleCustomer
                .fetchInfo()
                .then(res => {
                    if (!res) return logout()
                    else successCallback && successCallback()
                }))
                .catch(() => null)
        }
    }

    return {login, logout, secondLogin, connectWallet};
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_createSellNfTs__K0pyO {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  margin-bottom: 176px;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .styles_createSellNfTs__K0pyO {
    margin-bottom: 100px;
  }
}

.styles_list_item__\\+QGw4 {
  max-width: 370px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.styles_title__6qpDg {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.35px;
  text-transform: uppercase;
  margin: 48px 0 20px;
}
@media (max-width: 640px) {
  .styles_title__6qpDg {
    font-size: 17px;
    line-height: 28px;
    margin: 16px 0 4px;
  }
}

.styles_text__hM4sY {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.35px;
  color: #B3B3B3;
}
@media (max-width: 640px) {
  .styles_text__hM4sY {
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
}
.styles_text__hM4sY span {
  color: #FFA201;
}`, "",{"version":3,"sources":["webpack://./src/templates/MainPage/CreateSellNFTs/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,SAAA;EACA,oBAAA;EACA,eAAA;AACF;AAAE;EAPF;IAQI,oBAAA;EAGF;AACF;;AAAA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,sBAAA;AAGF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,yBAAA;EACA,mBAAA;AAGF;AADE;EATF;IAUI,eAAA;IACA,iBAAA;IACA,kBAAA;EAIF;AACF;;AADA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,cAAA;AAIF;AAFE;EARF;IASI,eAAA;IACA,iBAAA;IACA,SAAA;EAKF;AACF;AAHE;EACE,cAAA;AAKJ","sourcesContent":[".createSellNfTs {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  gap: 32px;\n  margin-bottom: 176px;\n  flex-wrap: wrap;\n  @media (max-width: 640px) {\n    margin-bottom: 100px;\n  }\n}\n\n.list_item {\n  max-width: 370px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: column;\n}\n\n.title {\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 32px;\n  text-align: center;\n  letter-spacing: -0.35px;\n  text-transform: uppercase;\n  margin: 48px 0 20px;\n\n  @media (max-width: 640px) {\n    font-size: 17px;\n    line-height: 28px;\n    margin: 16px 0 4px;\n  }\n}\n\n.text {\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 28px;\n  text-align: center;\n  letter-spacing: -0.35px;\n  color: #B3B3B3;\n\n  @media (max-width: 640px) {\n    font-size: 14px;\n    line-height: 24px;\n    margin: 0;\n  }\n\n  span {\n    color: #FFA201;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createSellNfTs": `styles_createSellNfTs__K0pyO`,
	"list_item": `styles_list_item__+QGw4`,
	"title": `styles_title__6qpDg`,
	"text": `styles_text__hM4sY`
};
export default ___CSS_LOADER_EXPORT___;

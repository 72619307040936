// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_mainLayout__TVqqF {
  min-height: 100vh;
  width: 100%;
}

.styles_wrapper__lUIaj {
  max-width: 1660px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 30px;
  margin: 92px auto 0;
  min-height: calc(100vh - 92px);
}
@media (max-width: 1025px) {
  .styles_wrapper__lUIaj {
    padding: 10px 25px;
  }
}

.styles_wrapper_isFullWidth__I64qg {
  max-width: 100%;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/layouts/MainLayout/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EARF;IASI,kBAAA;EAEF;AACF;;AACA;EACE,eAAA;EACA,UAAA;AAEF","sourcesContent":[".mainLayout {\n  min-height: 100vh;\n  width: 100%;\n}\n\n.wrapper {\n  max-width: 1660px;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 10px 30px;\n  margin: 92px auto 0;\n  min-height: calc(100vh - 92px);\n\n  @media (max-width: 1025px) {\n    padding: 10px 25px;\n  }\n}\n\n.wrapper_isFullWidth{\n  max-width: 100%;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainLayout": `styles_mainLayout__TVqqF`,
	"wrapper": `styles_wrapper__lUIaj`,
	"wrapper_isFullWidth": `styles_wrapper_isFullWidth__I64qg`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useState} from 'react';
import styles from "./styles.module.scss";
import lockImg from "../../../assets/images/symbols/lock.svg";
import Switch from "../../../components/Switch/Switch";
import Input from "../../../components/Input/Input";
import {toast} from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import TextArea from "../../../components/TextArea/TextArea";

type Props = {
    setCreateData: any,
    createData: any
}

const UnlockableContent = ({createData, setCreateData}: Props) => {
    const onChangeUnlockableStatus = () => {
        setCreateData({
            ...createData,
            isUnlockableContent: {...createData?.isUnlockableContent, status: !createData?.isUnlockableContent?.status}
        })
    }

    const onChangeUnlockableText = (e: any) => {
        if (e?.target?.value?.length > 500) return toast(<ToastMsg text={'Too long content'} isError/>)
        setCreateData({
            ...createData,
            isUnlockableContent: {...createData?.isUnlockableContent, text: e?.target?.value}
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.propertiesBlock}>
                <div className={styles.wrapper}>
                    <img src={lockImg} alt={''}/>
                    <div>
                        <p className={styles.title}>Unlockable content<span>(Optional)</span></p>
                        <p className={styles.text}>Include unlockable content that can only be revealed by the owner of
                            the
                            item.</p>
                    </div>
                </div>

                <Switch
                    isChecked={createData?.isUnlockableContent?.status}
                    onChange={onChangeUnlockableStatus}
                />
            </div>

            {!!createData?.isUnlockableContent?.status && <div className={styles.input}>
                <TextArea
                    value={createData?.isUnlockableContent?.text}
                    onChange={onChangeUnlockableText}
                    placeholder={'Content'}
                />
            </div>}
        </div>
    );
};

export default UnlockableContent;

import {
    CATEGORY_ALL,
    CATEGORY_ART,
    CATEGORY_COMMUNITY,
    CATEGORY_GAMING,
    CATEGORY_MEMBERSHIPS, CATEGORY_MUSIC, CATEGORY_PHOTO
} from "./myCollectionsPage";

export const EXPLORE_TAB_ALL = 1
export const EXPLORE_TAB_ART = 2
export const EXPLORE_TAB_COMMUNITY = 3
export const EXPLORE_TAB_GAMING = 4
export const EXPLORE_TAB_MEMBERSHIP = 5

export const EXPLORE_PAGE_TABS = [
    {title: 'All', value: CATEGORY_ALL},
    {title: 'Art', value: CATEGORY_ART},
    {title: 'Community', value: CATEGORY_COMMUNITY},
    {title: 'Gaming', value: CATEGORY_GAMING},
    {title: 'Memberships', value: CATEGORY_MEMBERSHIPS},
    {title: 'Music', value: CATEGORY_MUSIC},
    {title: 'Photography', value: CATEGORY_PHOTO},
]

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__s19BC {
  border-bottom: 1px solid #2D2D2D;
  padding: 25px 0;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.styles_propertiesBlock__ixP7f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  box-sizing: border-box;
}

.styles_noBorder__jRi3y {
  border-bottom: none;
  padding: 25px 0 49px;
}

.styles_wrapper__MtutD {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 27px;
  box-sizing: border-box;
  max-width: 440px;
  width: 100%;
}

.styles_title__vFd23 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.35px;
  color: #FFFFFF;
  margin: 0 0 8px;
}
.styles_title__vFd23 span {
  color: gray;
  font-weight: 400;
  margin-left: 5px;
}

.styles_text__iX9po {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.35px;
  color: #B3B3B3;
  margin: 0;
}

.styles_input__PkVKW {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/templates/CreatePage/UnlockableContent/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;EACA,oBAAA;AACF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;AAAF;;AAGA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;AAAF;AACE;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAGA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,SAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":[".container{\n  border-bottom: 1px solid #2D2D2D;\n  padding: 25px 0;\n  box-sizing: border-box;\n  margin-bottom: 30px;\n}\n\n.propertiesBlock {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 16px;\n  box-sizing: border-box;\n}\n\n.noBorder{\n  border-bottom: none;\n  padding: 25px 0 49px;\n}\n\n\n.wrapper{\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 27px;\n  box-sizing: border-box;\n  max-width: 440px;\n  width: 100%;\n}\n\n.title{\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 25px;\n  letter-spacing: -0.35px;\n  color: #FFFFFF;\n  margin: 0 0 8px;\n  span{\n    color: gray;\n    font-weight: 400;\n    margin-left: 5px;\n  }\n}\n\n.text{\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 28px;\n  letter-spacing: -0.35px;\n  color: #B3B3B3;\n  margin: 0;\n}\n\n.input{\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__s19BC`,
	"propertiesBlock": `styles_propertiesBlock__ixP7f`,
	"noBorder": `styles_noBorder__jRi3y`,
	"wrapper": `styles_wrapper__MtutD`,
	"title": `styles_title__vFd23`,
	"text": `styles_text__iX9po`,
	"input": `styles_input__PkVKW`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_offersBlock__IsCud {
  width: 100%;
  background: #242424;
  border-radius: 12px;
}

.styles_table_wrapper__ArlK3 {
  max-height: 537px;
  overflow-y: scroll;
}

.styles_title__E4gwk {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.35px;
  color: #D9D9D9;
  padding: 24px 32px;
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid #2D2D2D;
}
@media (max-width: 640px) {
  .styles_title__E4gwk {
    font-size: 15px;
    line-height: 20px;
  }
}

.styles_noData__4eFEl {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #D9D9D9;
  margin: 0;
  padding: 21px 32px;
  box-sizing: border-box;
}

.styles_oldOffer__TgjDs {
  max-width: 300px;
  margin: 20px auto 0;
  padding: 0 10px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/templates/SingleNFTPage/OffersBlock/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,sBAAA;EACA,SAAA;EACA,gCAAA;AACF;AAAE;EAXF;IAYI,eAAA;IACA,iBAAA;EAGF;AACF;;AAAA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;EACA,sBAAA;AAGF;;AAAA;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;AAGF","sourcesContent":[".offersBlock {\n  width: 100%;\n  background: #242424;\n  border-radius: 12px;\n}\n\n.table_wrapper {\n  max-height: 537px;\n  overflow-y: scroll;\n}\n\n.title {\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 25px;\n  letter-spacing: -0.35px;\n  color: #D9D9D9;\n  padding: 24px 32px;\n  box-sizing: border-box;\n  margin: 0;\n  border-bottom: 1px solid #2D2D2D;\n  @media (max-width: 640px) {\n    font-size: 15px;\n    line-height: 20px;\n  }\n}\n\n.noData {\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 22px;\n  color: #D9D9D9;\n  margin: 0;\n  padding: 21px 32px;\n  box-sizing: border-box;\n}\n\n.oldOffer {\n  max-width: 300px;\n  margin: 20px auto 0;\n  padding: 0 10px;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offersBlock": `styles_offersBlock__IsCud`,
	"table_wrapper": `styles_table_wrapper__ArlK3`,
	"title": `styles_title__E4gwk`,
	"noData": `styles_noData__4eFEl`,
	"oldOffer": `styles_oldOffer__TgjDs`
};
export default ___CSS_LOADER_EXPORT___;

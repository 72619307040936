import React from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import Button from "../../Button/Button";
import warning from '../../../assets/images/symbols/warning.svg'
import formatNumber, {playAudioCallback, playVideoCallback} from "../../../utils";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../../constants";
import {formatIPFSUrl} from "../../../utils/blockchain";

type Props = {
    nftData: any,
    title?: string,
    text?: string,
    onClose: any,
    isOpen: boolean,
    price: any,
    gasFee: number,
    transactionID: string
}

const TransactionFailedModal = ({transactionID, nftData, onClose, isOpen, title, text, price, gasFee}: Props) => {
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width < MOBILE

    return (
        <ModalLayout
            onClose={onClose}
            maxWidth={isMobile ? windowDimensions?.width - 40 + 'px' : '572px'}
            isOpen={isOpen}
            maxHeight={isMobile ? '90vh' : '693px'}
            withCrossIcon
        >
            <div>
                <div className={styles.img}>
                    {(nftData?.imageDOLink && !nftData?.imageDOLink?.includes('undefined'))
                    && <img
                        src={formatIPFSUrl(nftData?.imageDOLink)}
                        alt={''}/>}

                    {(nftData?.videoDOLink && !nftData?.videoDOLink?.includes('undefined'))
                    && <video //done
                        onPlay={playVideoCallback}
                        id={`modal-${nftData?.videoDOLink}`}
                        className={'nft_video'}
                        controls
                        src={nftData?.videoDOLink}
                    />}

                    {(nftData?.audioDOLink && !nftData?.audioDOLink?.includes('undefined'))
                    && <audio //done
                        id={`modal-${nftData?.audioDOLink}`}
                        onPlay={playAudioCallback}
                        className={'nft_audio'}
                        controls
                        src={nftData?.audioDOLink}
                    />}

                </div>

                <p className={styles.title}>{title}</p>

                <div className={styles.view}>
                    <a target="_blank" rel="noopener noreferrer"
                       href={`https://www.shibariumscan.io/tx/${transactionID}`}>
                        View on Shibariumscan • Show details
                    </a>
                </div>

                <div className={styles.warning}>
                    <img src={warning} alt={''}/>
                    <p>{text}</p>
                </div>

                <div className={styles.block}>
                    <span>Subtotal</span>
                    <span>{formatNumber(price?.value || price)} BONE</span>
                </div>

                <div className={styles.block}>
                    <span>Gas fees</span>
                    <span>{gasFee} BONE</span>
                </div>

                <div className={styles.block2}>
                    <span>Total price</span>
                    <span>{formatNumber(+gasFee + (+price?.value || +price))} BONE</span>
                </div>

                <div className={styles.button}>
                    <Button title={'Done'} isYellow onClick={onClose}/>
                </div>
            </div>
        </ModalLayout>
    );
};

export default TransactionFailedModal;

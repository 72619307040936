import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import FiltersBlock from "../FiltersBlock/FiltersBlock";
import TabHeader from "../TabHeader/TabHeader";
import NftCard from "../../../components/NFTCard/NFTCard";
import {
    FILTER_BUY_NOW, FILTER_HAS_OFFER,
    NFT_TABLE_HEADERS, SORTING_DATA,
} from "../../../constants/profile";
import cn from "classnames";
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import crossIcon from "../../../assets/images/symbols/cross.svg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../../constants";
import FiltersMobile from "../../../components/FiltersMobile/FiltersMobile";
import SortingMobile from "../../../components/SortingMobile/SortingMobile";
import useHandleCustomer from "../../../hooks/customer/useHandleCustomer";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";
import CryptoApi from "../../../utils/api/CryptoApi";
import {sortNFTList} from "../../../utils/sortingNFT";
import {useSelector} from "react-redux";

const CollectedTab = ({
                          currentUser,
                          isNsfwON,
                          showHidden,
                          isOwner,
                          isOpenedFilters,
                          setIsOpenedFilters,
                          isCardView,
                          setIsCardView,
                          isSmallView,
                          setIsSmallView
                      }: {
    isCardView: boolean,
    setIsCardView: any,
    isSmallView: boolean,
    setIsSmallView: any, currentUser: any, isOpenedFilters: boolean, setIsOpenedFilters: any, isNsfwON: boolean, showHidden: boolean, isOwner: boolean
}) => {
    const customer = useSelector((state: any) => state.customer.data)
    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false)
    const [isOpenedMobileSorting, setIsOpenedMobileSorting] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<any>([])
    const [filtersAmount, setFiltersAmount] = useState(0)
    const [selectedSorting, setSelectedSorting] = useState<any>(SORTING_DATA[0])
    const [nftsList, setNftsList] = useState([])
    const [filteredNftsList, setFilteredNftsList] = useState([])
    const [nftsToShow, setNftsToShow] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [forcePage, setForcePage] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedCollectionAddress, setSelectedCollectionAddress] = useState(null)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)

    const [filters, setFilters] = useState([
        {title: 'Buy Now', status: false, id: FILTER_BUY_NOW, amount: 0},
        {title: 'Has Offer', status: false, id: FILTER_HAS_OFFER, amount: 0},
    ])

    const wrapperRef = useRef<HTMLInputElement>(null)
    const handleCustomer = useHandleCustomer()
    const handleLoader = useHandleLoader()
    const navigate = useNavigate()

    const itemsPerPage = 15
    const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0;

    const onDeleteFilter = (id: number) => setFilters && setFilters([...filters?.map((item: any) => item?.id === id ? {
        ...item,
        status: false
    } : item)])

    const onClearFilters = () => setFilters && setFilters(filters?.map((item: any) => ({...item, status: false})))
    const onClickDots = (nft: any) => navigate(`/collection/${nft?.contractAddress}/${nft?.tokenID}`)

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % totalCount;
        setForcePage(event.selected)
        setNftsToShow(filteredNftsList?.slice(event.selected * itemsPerPage, (event.selected + 1) * itemsPerPage))
    }

    useEffect(() => {
        setSelectedFilters(filters?.filter((item: any) => !!item?.status))
        if (selectedCollectionAddress) {
            setFiltersAmount(filters?.filter((item: any) => item?.status)?.length + 1)
        } else setFiltersAmount(filters?.filter((item: any) => item?.status)?.length)
    }, [filters, selectedCollectionAddress])

    useEffect(() => {
        if (!!currentUser) {
            handleLoader.loaderWrapper(async () => await handleCustomer
                .getUserNFTsCollectedTab(
                    currentUser?.address,
                    isNsfwON ? 1 : 0,
                    (!showHidden || customer?.address?.toLowerCase() !== currentUser?.address?.toLowerCase()) ? 0 : 1)
                .then(res => {
                    const today = moment().unix()

                    setNftsList(res?.data)
                    setFilteredNftsList(res?.data)
                    setTotalCount(res?.data?.length)
                    setNftsToShow(res?.data?.slice(0, itemsPerPage))
                    setFilters([
                        {
                            title: 'Buy Now',
                            status: false,
                            id: FILTER_BUY_NOW,
                            amount: res?.data?.filter((item: any) =>
                                item?.listing?.price > 0 && item?.listing?.endDate > today && item?.listing?.startDate <= today
                            )?.length
                        },
                        {
                            title: 'Has Offer',
                            status: false,
                            id: FILTER_HAS_OFFER,
                            amount: res?.data?.filter((item: any) =>
                                !!item?.offers?.filter((offer: any) =>
                                    offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                                )?.length)?.length
                        },
                    ])
                }))
        }

        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
    }, [currentUser?.address, isNsfwON, showHidden, customer?.address])

    useEffect(() => {
        const today = moment().unix()

        let newNftsList = nftsList

        if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_BUY_NOW)?.length && !!selectedFilters?.filter((item: any) => item?.id === FILTER_HAS_OFFER)?.length) {
            newNftsList = nftsList
                ?.filter((item: any) =>
                    !!item?.offers?.filter((offer: any) =>
                        offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                    )?.length)
                ?.filter((item: any) =>
                    item?.listing?.price > 0 && item?.listing?.endDate > today && item?.listing?.startDate <= today
                )
        } else if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_BUY_NOW)?.length) {
            newNftsList = nftsList?.filter((item: any) =>
                item?.listing?.price > 0 && item?.listing?.endDate > today && item?.listing?.startDate <= today
            )
        } else if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_HAS_OFFER)?.length) {
            newNftsList = nftsList?.filter((item: any) =>
                !!item?.offers?.filter((offer: any) =>
                    offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                )?.length)
        }

        if (!!searchQuery?.length) {
            newNftsList = newNftsList?.filter((item: any) => item?.metadata?.name?.toLowerCase()?.startsWith(searchQuery?.toLowerCase()?.trim()))
        }

        if (!!selectedCollectionAddress) {
            newNftsList = newNftsList?.filter((item: any) => item?.contractAddress?.toLowerCase() === selectedCollectionAddress)
        }

        newNftsList = sortNFTList(newNftsList, selectedSorting?.id)

        setFilteredNftsList(newNftsList)
        setTotalCount(newNftsList?.length)
        setNftsToShow(newNftsList?.slice(0, itemsPerPage))
    }, [selectedFilters?.length, nftsList, searchQuery, selectedCollectionAddress, selectedSorting])

    return (
        <div className={styles.collectedTab}>
            <TabHeader
                isOpenedFilters={isOpenedFilters}
                setIsOpenedFilters={setIsOpenedFilters}
                wrapperRef={wrapperRef}
                isCardView={isCardView}
                setIsCardView={setIsCardView}
                filters={filters}
                setFilters={setFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                isOpenedMobileFilters={isOpenedMobileFilters}
                filtersAmount={filtersAmount}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                isOpenedMobileSorting={isOpenedMobileSorting}
                totalCount={filteredNftsList?.length}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />
            <div className={styles.wrapper} ref={wrapperRef}>
                <FiltersBlock
                    nftsList={nftsList}
                    isOpenedFilters={isOpenedFilters}
                    filters={filters}
                    setFilters={setFilters}
                    setSelectedCollection={setSelectedCollectionAddress}
                    selectedCollection={selectedCollectionAddress}
                />

                <div className={cn(styles.nftList_wrapper, isOpenedFilters && styles.nftList_wrapper_filters)}>
                    {(!!selectedFilters?.length && !isTablet) && <div className={styles.selectedFilters}>
                        {selectedFilters?.map((item: any, key: number) => <div
                            key={key}
                            className={styles.selectedFilters_item}
                            onClick={() => onDeleteFilter(item?.id)}
                        >
                            {item?.title}
                            <img src={crossIcon} alt={''}/>
                        </div>)}
                        <div className={styles.selectedFilters_clear} onClick={onClearFilters}>Clear filters</div>
                    </div>}

                    {!!filteredNftsList?.length
                        ? <>{isCardView
                            ? <div className={styles.nftList}>
                                {nftsToShow?.map((item, key) => <NftCard key={key} nft={item}
                                                                         isSmall={isSmallView}/>)}
                            </div>
                            : <div className={styles.NfTsTable}>
                                <NfTsTable
                                    data={nftsToShow}
                                    headers={NFT_TABLE_HEADERS}
                                    withDots
                                    withNFTCheckbox
                                    onClickDots={onClickDots}
                                    maticToUsd={maticToUsd}
                                />
                            </div>
                        }</>
                        : <p className={styles.noData}>No collected NFTs yet</p>}

                    {!!filteredNftsList?.length && <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        pageCount={pageCount}
                        forcePage={forcePage}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pagination_page}
                        activeClassName={styles.pagination_active}
                        previousClassName={styles.pagination_previous}
                        nextClassName={styles.pagination_next}
                        disabledClassName={styles.pagination_disabled}
                    />}


                </div>
            </div>

            {isTablet && <FiltersMobile
                isOpenedMobileFilters={isOpenedMobileFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                filters={filters}
                setFilters={setFilters}
                setSelectedCollection={setSelectedCollectionAddress}
                selectedCollection={selectedCollectionAddress}
                filtersAmount={filtersAmount}
                setIsSmallView={setIsSmallView}
                isSmallView={isSmallView}
                selectedFilters={selectedFilters}
                onDeleteFilter={onDeleteFilter}
                nftsList={nftsList}
            />}

            {isTablet && <SortingMobile
                isOpenedMobileSorting={isOpenedMobileSorting}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />}
        </div>
    );
};

export default CollectedTab;

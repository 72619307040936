import React from 'react';
import styles from "../../../components/NFTsTable/styles.module.scss";
import StatsTableRow from "../StatsTableRow/StatsTableRow";
import cn from "classnames";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {TABLET} from "../../../constants";
import {
    SORT_BY_FLOOR_DECREASE,
    SORT_BY_FLOOR_INCREASE,
    SORT_BY_LISTED_DECREASE,
    SORT_BY_LISTED_INCREASE,
    SORT_BY_OWNERS_DECREASE,
    SORT_BY_OWNERS_INCREASE,
    SORT_BY_SALES_DECREASE,
    SORT_BY_SALES_INCREASE,
    SORT_BY_VOLUME_DECREASE,
    SORT_BY_VOLUME_INCREASE
} from "../../../utils/sortingNFT";
import sortingImgDecreaseWhite from '../../../assets/images/symbols/sortingDecrease_white.svg'
import sortingImgDecreaseGray from '../../../assets/images/symbols/sortingDecrease_gray.svg'
import sortingImgIncreaseWhite from '../../../assets/images/symbols/sortingIncrease_white.svg'
import sortingImgIncreaseGray from '../../../assets/images/symbols/sortingIncrease_gray.svg'
import SignUpModal from "../../../components/Modals/SignUpModal/SignUpModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";

type Props = {
    collectionsData: any,
    activeSubTab: any,
    setSortingParam: any,
    sortingParam: any,
    handleSignUpModal: any
}

const StatsTable = ({collectionsData, activeSubTab, sortingParam, setSortingParam, handleSignUpModal}: Props) => {
    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const onChangeSortingVolume = () => sortingParam === SORT_BY_VOLUME_DECREASE
        ? setSortingParam(SORT_BY_VOLUME_INCREASE)
        : setSortingParam(SORT_BY_VOLUME_DECREASE)

    const onChangeSortingFloor = () => sortingParam === SORT_BY_FLOOR_DECREASE
        ? setSortingParam(SORT_BY_FLOOR_INCREASE)
        : setSortingParam(SORT_BY_FLOOR_DECREASE)

    const onChangeSortingSales = () => sortingParam === SORT_BY_SALES_DECREASE
        ? setSortingParam(SORT_BY_SALES_INCREASE)
        : setSortingParam(SORT_BY_SALES_DECREASE)

    const onChangeSortingOwners = () => sortingParam === SORT_BY_OWNERS_DECREASE
        ? setSortingParam(SORT_BY_OWNERS_INCREASE)
        : setSortingParam(SORT_BY_OWNERS_DECREASE)

    const onChangeSortingListed = () => sortingParam === SORT_BY_LISTED_DECREASE
        ? setSortingParam(SORT_BY_LISTED_INCREASE)
        : setSortingParam(SORT_BY_LISTED_DECREASE)

    return (
        <table cellSpacing={0} className={styles.table}>
            <thead>
            <tr>
                <th className={cn(styles.table_header)}>
                    <div>Rank</div>
                </th>

                <th className={cn(styles.table_header)}>
                    <div>Collection</div>
                </th>
                <th className={cn(styles.table_header, (sortingParam === SORT_BY_VOLUME_DECREASE || sortingParam === SORT_BY_VOLUME_INCREASE) && styles.header_active)}>
                    <div>
                        Volume
                        <div className={styles.arrows} onClick={onChangeSortingVolume}>
                            <img
                                src={sortingParam === SORT_BY_VOLUME_DECREASE ? sortingImgDecreaseWhite : sortingImgDecreaseGray}
                                alt={''} width={20} height={20}/>
                            <img
                                src={sortingParam === SORT_BY_VOLUME_INCREASE ? sortingImgIncreaseWhite : sortingImgIncreaseGray}
                                alt={''} width={20} height={20}/>
                        </div>
                    </div>
                </th>
                {!isTablet &&
                <th className={cn(styles.table_header, (sortingParam === SORT_BY_FLOOR_INCREASE || sortingParam === SORT_BY_FLOOR_DECREASE) && styles.header_active)}>
                    <div>
                        Floor
                        <div className={styles.arrows} onClick={onChangeSortingFloor}>
                            <img
                                src={sortingParam === SORT_BY_FLOOR_DECREASE ? sortingImgDecreaseWhite : sortingImgDecreaseGray}
                                alt={''} width={20} height={20}/>
                            <img
                                src={sortingParam === SORT_BY_FLOOR_INCREASE ? sortingImgIncreaseWhite : sortingImgIncreaseGray}
                                alt={''} width={20} height={20}/>
                        </div>
                    </div>
                </th>}
                {!isTablet &&
                <th className={cn(styles.table_header, (sortingParam === SORT_BY_SALES_INCREASE || sortingParam === SORT_BY_SALES_DECREASE) && styles.header_active)}>
                    <div>
                        Sales
                        <div className={styles.arrows} onClick={onChangeSortingSales}>
                            <img
                                src={sortingParam === SORT_BY_SALES_DECREASE ? sortingImgDecreaseWhite : sortingImgDecreaseGray}
                                alt={''} width={20} height={20}/>
                            <img
                                src={sortingParam === SORT_BY_SALES_INCREASE ? sortingImgIncreaseWhite : sortingImgIncreaseGray}
                                alt={''} width={20} height={20}/>
                        </div>
                    </div>
                </th>}
                {!isTablet &&
                <th className={cn(styles.table_header, styles.header_right, (sortingParam === SORT_BY_OWNERS_DECREASE || sortingParam === SORT_BY_OWNERS_INCREASE) && styles.header_active)}>
                    <div>
                        Unique owners
                        <div className={styles.arrows} onClick={onChangeSortingOwners}>
                            <img
                                src={sortingParam === SORT_BY_OWNERS_DECREASE ? sortingImgDecreaseWhite : sortingImgDecreaseGray}
                                alt={''} width={20} height={20}/>
                            <img
                                src={sortingParam === SORT_BY_OWNERS_INCREASE ? sortingImgIncreaseWhite : sortingImgIncreaseGray}
                                alt={''} width={20} height={20}/>
                        </div>
                    </div>
                </th>}
                {!isTablet &&
                <th className={cn(styles.table_header, styles.header_right, (sortingParam === SORT_BY_LISTED_INCREASE || sortingParam === SORT_BY_LISTED_DECREASE) && styles.header_active)}>
                    <div>
                        Items listed
                        <div className={styles.arrows} onClick={onChangeSortingListed}>
                            <img
                                src={sortingParam === SORT_BY_LISTED_DECREASE ? sortingImgDecreaseWhite : sortingImgDecreaseGray}
                                alt={''} width={20} height={20}/>
                            <img
                                src={sortingParam === SORT_BY_LISTED_INCREASE ? sortingImgIncreaseWhite : sortingImgIncreaseGray}
                                alt={''} width={20} height={20}/>
                        </div>
                    </div>
                </th>}
                <th className={cn(styles.table_header, styles.header_right)}>
                </th>
            </tr>
            </thead>

            <tbody>
            {collectionsData?.map((item: any, key: number) =>
                <StatsTableRow handleSignUpModal={handleSignUpModal} activeSubTab={activeSubTab} key={key}
                               rank={key + 1} collection={item}/>)}
            </tbody>
        </table>
    );
};

export default StatsTable;

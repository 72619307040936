// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__0HGOb {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}
@media (min-width: 1025px) {
  .styles_wrapper__0HGOb:hover .styles_tooltip__9AeXo {
    opacity: 1;
    transition: all 0.3s ease;
    z-index: 1;
  }
}

.styles_tooltip__9AeXo {
  position: absolute;
  top: -50px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  background: #505050;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 20px;
  min-width: 110px;
  width: -moz-fit-content;
  width: fit-content;
  opacity: 0;
  transition: all 0.3s ease;
  height: 40px;
  box-sizing: border-box;
  z-index: -1;
}
.styles_tooltip__9AeXo:hover {
  z-index: -1 !important;
  opacity: 0 !important;
  transition: all 0.3s ease;
}

.styles_tooltip_start__EBvhB {
  left: 0;
  right: auto;
  transform: translateX(0);
}

.styles_tooltip_end__2zIkr {
  left: auto;
  right: 0;
  transform: translateX(0);
}

.styles_text__lR3xd {
  padding-top: 4px;
}

@media (max-width: 1025px) {
  .styles_isVisibleOnMobile__7\\+uBx:hover .styles_tooltip__9AeXo {
    opacity: 1;
    transition: all 0.3s ease;
    z-index: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,uBAAA;EAAA,kBAAA;AACF;AAAE;EAEI;IACE,UAAA;IACA,yBAAA;IACA,UAAA;EACN;AACF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,2BAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,UAAA;EACA,yBAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;AADF;AAGE;EACE,sBAAA;EACA,qBAAA;EACA,yBAAA;AADJ;;AAKA;EACE,OAAA;EACA,WAAA;EACA,wBAAA;AAFF;;AAMA;EACE,UAAA;EACA,QAAA;EACA,wBAAA;AAHF;;AAMA;EACE,gBAAA;AAHF;;AAOE;EAEI;IACE,UAAA;IACA,yBAAA;IACA,UAAA;EALN;AACF","sourcesContent":[".wrapper {\n  position: relative;\n  width: fit-content;\n  @media (min-width: 1025px) {\n    &:hover {\n      .tooltip {\n        opacity: 1;\n        transition: all .3s ease;\n        z-index: 1;\n      }\n    }\n  }\n}\n\n.tooltip {\n  position: absolute;\n  top: -50px;\n  left: 50%;\n  right: 50%;\n  transform: translateX(-50%);\n  background: #505050;\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 5px;\n  padding: 10px 20px;\n  min-width: 110px;\n  width: fit-content;\n  opacity: 0;\n  transition: all .3s ease;\n  height: 40px;\n  box-sizing: border-box;\n  z-index: -1;\n\n  &:hover {\n    z-index: -1 !important;\n    opacity: 0 !important;\n    transition: all .3s ease;\n  }\n}\n\n.tooltip_start {\n  left: 0;\n  right: auto;\n  transform: translateX(0);\n}\n\n\n.tooltip_end {\n  left: auto;\n  right: 0;\n  transform: translateX(0);\n}\n\n.text {\n  padding-top: 4px;\n}\n\n.isVisibleOnMobile {\n  @media (max-width: 1025px) {\n    &:hover {\n      .tooltip {\n        opacity: 1;\n        transition: all .3s ease;\n        z-index: 1;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__0HGOb`,
	"tooltip": `styles_tooltip__9AeXo`,
	"tooltip_start": `styles_tooltip_start__EBvhB`,
	"tooltip_end": `styles_tooltip_end__2zIkr`,
	"text": `styles_text__lR3xd`,
	"isVisibleOnMobile": `styles_isVisibleOnMobile__7+uBx`
};
export default ___CSS_LOADER_EXPORT___;

import Base from "./Base";

export default class AuthApi extends Base {
    async loginMetamask(data: any) {
        return super.post("auth/login-metamask", data);
    }

    async secondLoginMetamask(address: any) {
        return super.post("auth/login-second-metamask", {address});
    }
}

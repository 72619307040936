import Base from "./Base";

export default class ActivitiesApi extends Base {
    async createActivity(data: any) {
        return super.post('activity/create', {...data})
    }

    async getActivitiesByUser(walletAddress: string, limit = 100, page = 1) {
        return super.get(`activity/byUser/${walletAddress}?limit=${limit}&page=${page}`)
    }

    async getActivitiesByUserForWallet(walletAddress: string, limit = 100, page = 1) {
        return super.get(`activity/byUser/forWallet/${walletAddress}?limit=${limit}&page=${page}`)
    }

    async getActivitiesByToken(contractAddress: string, tokenID: number, limit = 100, page = 1) {
        return super.get(`activity/byToken/${contractAddress}/${tokenID}?limit=${limit}&page=${page}`)
    }

    async getActivitiesByContract(contractAddress: string, time: number) {
        return super.get(`activity/byContract/${contractAddress}/${time}`)
    }

    async getAveragePriceByToken(contractAddress: string, tokenID: number) {
        return super.get(`activity/byToken/averagePrice/${contractAddress}/${tokenID}`)
    }

    async getAveragePriceByCollection(contractAddress: string, activeTime: number) {
        return super.get(`activity/byCollection/averagePrice/${contractAddress}/${activeTime}`)
    }

    async getCollectionVolume(contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS, activeTime: number) {
        return super.get(`activity/volume/${contractAddress}/${activeTime}`)
    }

    async getOffersReceived(walletAddress: string, limit = 100, page = 1) {
        return super.get(`activity/offers/received/${walletAddress}?limit=${limit}&page=${page}`)
    }

    async getOffersMade(walletAddress: string, limit = 100, page = 1) {
        return super.get(`activity/offers/made/${walletAddress}?limit=${limit}&page=${page}`)
    }

    async editOfferEvent(offerID: number, eventType: number) {
        return super.put(`activity/offer/edit/${offerID}`, {eventType})
    }

    async getListingsList(walletAddress: string, limit = 100, page = 1) {
        return super.get(`activity/listings/${walletAddress}?limit=${limit}&page=${page}`)
    }

    async getSalesAmount(contractAddress: string, time: number) {
        return super.get(`activity/sales/count/${contractAddress}/${time}`)
    }
}

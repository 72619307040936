import * as USER from '../types/customer'

export function setCustomer (data: any) {
    return function (dispatch: any) {
        dispatch({
            type: USER.SET_CUSTOMER,
            data
        })
    }
}

import React from 'react';
import styles from './styles.module.scss'
import badge from '../../../assets/images/symbols/verified-badge_orange.svg'//done
import shiba from '../../../assets/images/symbols/shiba.svg'
import Button from "../../../components/Button/Button";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../../constants";
import check from '../../../assets/images/symbols/checked.svg'
import counter from '../../../assets/images/symbols/counter.svg'
import moment from "moment";
import {useNavigate} from "react-router-dom";
import formatNumber from "../../../utils";
import cn from "classnames";
import {formatIPFSUrl} from "../../../utils/blockchain";

type Props = {
    item: any,
    isOffersReceived: boolean,
    onCancelOffer: any,
    onEditOffer: any,
    onAcceptOffer: any,
    isOwner: boolean
}

const OffersTabItem = ({item, isOffersReceived, onCancelOffer, onEditOffer, onAcceptOffer, isOwner}: Props) => {
    const navigate = useNavigate()
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width <= MOBILE

    const onClickOwner = (address: string) => address ? navigate(`/${address}/collected`) : null
    const onClickNFT = () => navigate(`/collection/${item?.contractAddress}/${item?.tokenID}`)

    return (
        <div className={styles.listingsTabItem}>

            <div className={styles.leftBlock} onClick={onClickNFT}>
                {item?.imageDOLink && !item?.imageDOLink?.includes('undefined')
                && <img
                    src={formatIPFSUrl(item?.imageDOLink)}
                    alt={''}
                    className={styles.image}
                />}

                {item?.videoDOLink && !item?.videoDOLink?.includes('undefined')
                && <video
                    src={item?.videoDOLink}
                    controls={false}
                    autoPlay={false}
                />}

                <div>
                    <p className={styles.id}>
                        {(isMobile && item?.nftName?.length > 10)
                            ? item?.nftName?.slice(0, 10) + '...'
                            : (item?.nftName || '#' + item?.tokenID)
                        }
                    </p>
                    <p className={styles.name}>
                        {(isMobile && item?.name?.length > 10)
                            ? item?.name?.slice(0, 10) + '...'
                            : item?.name}
                        {item?.isCollectionVerified && <img src={badge} alt={''}/>}
                    </p>
                </div>
            </div>

            <div className={styles.rightBlock}>
                <div>
                    <p className={cn(styles.expires, styles.pointer)} onClick={() => onClickOwner(item?.from)}>
                        From <span>{item?.from?.length > 10 ? item?.from?.slice(0, 10) + '...' : item?.from}</span>
                    </p>
                    <p className={styles.price}>
                        {formatNumber(+(+item?.price)?.toFixed(2)) || '-'}
                        <img src={shiba} alt={''}/>
                        {/*{item?.currency}*/}
                    </p>
                    <p className={styles.expires}>Expires {moment.unix(item?.endDate).format('hh:mm a, D MMM YYYY')}</p>
                </div>
                {isOwner && <>{isOffersReceived
                    ? <div className={styles.buttons}>
                        <Button title={isMobile ? '' : 'Accept'} isBlackWhite image={check}
                                onClick={() => onAcceptOffer(item)}/>
                    </div>
                    : <div className={styles.buttons}>
                        <Button title={isMobile ? '' : 'Edit offer'} isBlackWhite image={check}
                                onClick={() => onEditOffer(item)}/>
                        <Button title={isMobile ? '' : 'Cancel'} isBlackWhite image={counter}
                                onClick={() => onCancelOffer(item)}/>
                    </div>
                }</>}
            </div>

        </div>
    );
};

export default OffersTabItem;

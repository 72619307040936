import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import filtersImg from '../../../assets/images/symbols/filters.svg'
import filtersArrowImg from '../../../assets/images/symbols/filters_collapse.svg'
import searchImg from "../../../assets/images/symbols/search.svg";
import arrow from "../../../assets/images/symbols/arrow.svg";
import plus from "../../../assets/images/symbols/plus_white.svg";
import cardView from "../../../assets/images/symbols/view_cards.svg";
import cardViewGray from "../../../assets/images/symbols/view_cards_gray.svg";
import listViewGray from "../../../assets/images/symbols/view_list_gray.svg";
import listView from "../../../assets/images/symbols/view_list.svg";
import sortingImg from "../../../assets/images/symbols/sorting.svg";
import cross from "../../../assets/images/symbols/cross.svg";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import cn from "classnames";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../../constants";
import {useLocation, useNavigate} from "react-router-dom";
import {pathList} from "../../../routes/path";
import {SORTING_DATA, SORTING_DATA_COLLECTION_PAGE} from "../../../constants/profile";
import {ACTIVITY_TIME_TABS} from "../../../constants/singleCollection";

type Props = {
    isOpenedFilters: boolean,
    setIsOpenedFilters: (a: boolean) => void,
    setIsCardView?: (a: boolean) => void,
    isCardView?: boolean,
    wrapperRef: any,
    withoutFilters?: boolean,
    isActivityTab?: boolean,
    filters?: any,
    setFilters?: (a: any) => void,
    setIsOffersReceived?: (a: any) => void,
    withoutCardView?: boolean,
    isOffersTab?: boolean,
    isOffersReceived?: boolean,
    isOpenedMobileFilters?: boolean,
    setIsOpenedMobileFilters?: (a: any) => void,
    filtersAmount?: number,
    setIsOpenedMobileSorting?: (a: any) => void,
    isOpenedMobileSorting?: boolean,
    totalCount?: any,
    setSearchQuery?: any,
    searchQuery?: any,
    isOwnerOfCollection?: boolean,
    sliderData?: any,
    setSelectedSorting?: any,
    selectedSorting?: any,
    isActivityCollectionTab?: boolean,
    activeTime?: any,
    setActiveTime?: any,
    isMoreOpen?: boolean
}

const TabHeader = ({
                       totalCount,
                       isOpenedFilters,
                       setIsOpenedFilters,
                       wrapperRef,
                       isCardView,
                       setIsCardView,
                       withoutFilters,
                       isActivityTab,
                       isActivityCollectionTab,
                       filters,
                       withoutCardView,
                       isOffersTab,
                       isOffersReceived,
                       setIsOffersReceived,
                       isOpenedMobileFilters,
                       setIsOpenedMobileFilters,
                       filtersAmount,
                       setIsOpenedMobileSorting,
                       isOpenedMobileSorting,
                       setSearchQuery,
                       searchQuery,
                       isOwnerOfCollection,
                       sliderData,
                       setSelectedSorting,
                       selectedSorting,
                       activeTime,
                       setActiveTime,
                       isMoreOpen
                   }: Props) => {
    const [isOpenDropdown, setIsOpenDropdown] = useState(false)
    const [isOpenSearch, setIsOpenSearch] = useState(false)
    const [headerOffsetTop, setHeaderOffsetTop] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState([])
    const [isCollectionPage, setIsCollectionPage] = useState(false)

    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET && windowDimensions?.width > MOBILE
    const isMobile = windowDimensions?.width <= MOBILE

    const dropdownRef = useRef<HTMLInputElement>(null)
    const orderRef = useRef<HTMLInputElement>(null)
    const headerRef = useRef<HTMLInputElement>(null)
    const location = useLocation()
    const navigate = useNavigate()

    const onToggleDropdown = () => setIsOpenDropdown(!isOpenDropdown)
    const onCloseDropdown = () => setIsOpenDropdown(false)
    const onClickCardView = () => setIsCardView && setIsCardView(true)
    const onClickListView = () => setIsCardView && setIsCardView(false)
    const onToggleFilters = () => {
        (isTablet || isMobile)
            ? setIsOpenedMobileFilters && setIsOpenedMobileFilters(!isOpenedMobileFilters)
            : setIsOpenedFilters(!isOpenedFilters)
    }
    const onClickOffersReceived = () => setIsOffersReceived && setIsOffersReceived(true)
    const onClickOffersMade = () => setIsOffersReceived && setIsOffersReceived(false)
    const onToggleSorting = () => setIsOpenedMobileSorting && setIsOpenedMobileSorting(!isOpenedMobileSorting)
    const onToggleSearch = () => setIsOpenSearch(!isOpenSearch)
    const onChangeSearch = (e: any) => setSearchQuery && setSearchQuery(e?.target?.value)
    const onClickAdd = () => navigate(pathList.create.path)
    const onSelectSorting = (sorting: any) => setSelectedSorting(sorting)

    const setStickyHeader = () => {
        if (!headerRef?.current?.classList) return;

        // @ts-ignore
        if (window?.pageYOffset - 2 >= headerOffsetTop) {
            headerRef?.current?.classList?.add(`${styles.sticky}`);
            wrapperRef?.current?.classList?.add(`${styles.sticky_wrapper}`);
        } else {
            headerRef?.current?.classList?.remove(`${styles.sticky}`);
            wrapperRef?.current?.classList?.remove(`${styles.sticky_wrapper}`);
        }
    }

    useEffect(() => {
        // @ts-ignore
        setHeaderOffsetTop(headerRef?.current?.offsetTop);
    }, [sliderData, totalCount, isMoreOpen]);

    useEffect(() => {
        window?.addEventListener('scroll', () => setStickyHeader())

        return window?.removeEventListener('scroll', () => setStickyHeader())
    }, [headerOffsetTop]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedFilters(filters?.filter((item: any) => !!item?.status))
    }, [filters])

    useEffect(() => {
        setIsCollectionPage(location?.pathname?.includes('/collection/'))
    }, [location?.pathname])

    useOutsideClick(dropdownRef, onCloseDropdown, orderRef)

    return (
        <div ref={headerRef} className={styles.tabHeader}>
            <div className={styles.tabHeader_wrapper}>
                {!withoutFilters &&
                <div className={cn(styles.filters, !isOpenedFilters && styles.filters_closed)}
                     onClick={onToggleFilters}>
                    {((isTablet || isMobile) && !!filtersAmount) &&
                    <div className={styles.filters_amount}>{filtersAmount}</div>}

                    {isOpenedFilters
                        ? <>
                            <div className={styles.filters_title}><img src={filtersImg} alt={''}/>Filters</div>
                            <img src={filtersArrowImg} alt={''}/>
                        </>
                        : <div onClick={onToggleFilters}>
                            <img src={filtersImg} alt={''}/>
                        </div>}
                </div>}

                {isOffersTab && <div className={styles.offersTabs}>
                    <span
                        className={cn(styles.offersTabs_item, isOffersReceived && styles.offersTabs_item_active)}
                        onClick={onClickOffersReceived}>
                        Offers received
                    </span>
                    <span
                        className={cn(styles.offersTabs_item, !isOffersReceived && styles.offersTabs_item_active)}
                        onClick={onClickOffersMade}>
                        Offers made
                    </span>
                </div>}

                {isActivityCollectionTab && !isTablet && !isMobile && <div className={styles.subtabs}>
                    {ACTIVITY_TIME_TABS?.map((item, key) => <div
                        key={key}
                        onClick={() => setActiveTime(item?.value)}
                        className={cn(styles.subtabs_item, activeTime === item?.value && styles.subtabs_item_active)}
                    >
                        {item?.title}
                    </div>)}
                </div>}

                {(!isActivityTab && !isOffersTab) && <>
                    <div
                        className={cn(styles.row_wrapper, !isOpenedFilters && styles.row_wrapper_full, selectedFilters?.length && styles.row_wrapper_selectedFilters)}>
                        {!isOpenSearch &&
                        <p className={cn(styles.amount, withoutFilters && styles.amount_padding)}>{totalCount} items</p>}

                        <div className={styles.row}>
                            {!isMobile && <div className={styles.search}>
                                <img src={searchImg} alt={''} width={16} height={16}/>
                                <input placeholder={'Search by name'} onChange={onChangeSearch} value={searchQuery}/>
                            </div>}

                            {!isTablet && !isMobile && <div
                                className={cn(styles.order, isOpenDropdown && styles.order_open)}
                                onClick={onToggleDropdown}
                                ref={orderRef}
                            >
                                {selectedSorting?.title}
                                <img src={arrow} alt={''}/>

                                {isOpenDropdown && <div ref={dropdownRef} className={styles.order_dropdown}>
                                    {(location?.pathname?.includes('/collection/') ? SORTING_DATA_COLLECTION_PAGE : SORTING_DATA)
                                        ?.map((item, key) =>
                                            <div key={key} onClick={() => onSelectSorting(item)}>{item?.title}</div>)}
                                </div>}
                            </div>}

                            {(!isTablet && !isMobile && isCollectionPage && isOwnerOfCollection) &&
                            <div className={styles.addItem} onClick={onClickAdd}>
                                <img src={plus} alt={''}/>
                                Add item
                            </div>}

                            {!withoutCardView && !isTablet && !isMobile && <div className={styles.view}>
                                <div className={cn(styles.view_card, isCardView && styles.view_card_active)}
                                     onClick={onClickCardView}>
                                    <img src={isCardView ? cardView : cardViewGray} alt={''}/>
                                </div>
                                <div className={cn(styles.view_list, !isCardView && styles.view_list_active)}
                                     onClick={onClickListView}>
                                    <img src={!isCardView ? listView : listViewGray} alt={''}/>
                                </div>
                            </div>}

                            {isMobile && isOpenSearch && <div className={styles.search}>
                                <img src={searchImg} alt={''} width={16} height={16}/>
                                <input placeholder={'Search by name'} onChange={onChangeSearch} value={searchQuery}/>
                            </div>}

                            {isMobile && <div onClick={onToggleSearch}>
                                <img src={isOpenSearch ? cross : searchImg} className={styles.searchImg} alt={''}
                                     width={16} height={16}/>
                            </div>}

                            {(isTablet || isMobile) && <div className={styles.sorting} onClick={onToggleSorting}>
                                <img src={sortingImg} alt={''}/>
                            </div>}

                            {((isTablet || isMobile) && isCollectionPage && isOwnerOfCollection) &&
                            <div className={styles.addItem_mobile} onClick={onClickAdd}>
                                <img src={plus} alt={''}/>
                            </div>}
                        </div>
                    </div>
                </>}
            </div>
        </div>
    );
};

export default TabHeader;

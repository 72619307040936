import {useEffect} from "react";
import {useSelector} from "react-redux";
import useHandleCustomer from "./customer/useHandleCustomer";
import useHandleAuth from "./auth/useHandleAuth";
import useHandleWeb3 from "./web3/useHandleWeb3";
import {CHAIN_ID} from "../constants";
import {localStorageGet} from "../utils/localStorage";
import {pathList} from "../routes/path";
import {useLocation, useNavigate} from "react-router-dom";

export default function useFirstLoadEffect() {
    const customer = useSelector((state: any) => state.customer.data)

    const handleCustomer = useHandleCustomer()
    const handleAuth = useHandleAuth();
    const handleWeb3 = useHandleWeb3()
    // const navigate = useNavigate()
    // const location = useLocation()

    // useEffect(() => {
    //     const user = localStorageGet('user', '')
    //     if (user === '1') {
    //     // if (user === '2') {
    //         location.pathname === pathList.login.path && navigate(pathList.mainPage.path)
    //     } else {
    //         navigate(pathList.login.path)
    //     }
    // }, [])

    useEffect(() => {
        handleWeb3.loadWeb3(handleAuth.logout).catch(() => null)

        if (!customer?._id) {
            handleCustomer
                .fetchInfo()
                .then(res => {
                    if (!res) handleAuth.logout()
                    if (res?.isBlocked) handleAuth.logout()
                })
                .catch(() => null)
        }
    }, [customer])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // @ts-ignore
        if (customer?.address && window?.ethereum?.chainId && window.ethereum?.networkVersion) {
            // @ts-ignore
            if (window.ethereum.chainId !== CHAIN_ID) handleAuth.logout()
            // @ts-ignore
            if (window.ethereum.selectedAddress?.toLowerCase() !== customer?.address?.toLowerCase()) handleAuth.logout()
        }
        // @ts-ignore
    }, [customer?.address, window?.ethereum])
}

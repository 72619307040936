// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_singleNftPage__3bWJO {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  flex-direction: column;
  padding-bottom: 72px;
}

.styles_removed__g\\+AIj {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 100px;
}
.styles_removed__g\\+AIj p {
  margin: 0;
  font-size: 20px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/templates/SingleNFTPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;EACA,sBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;AACF;AACE;EACE,SAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".singleNftPage {\n  width: 100%;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 24px;\n  flex-direction: column;\n  padding-bottom: 72px;\n}\n\n.removed {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 30px;\n  margin-top: 100px;\n\n  p{\n    margin: 0;\n    font-size: 20px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"singleNftPage": `styles_singleNftPage__3bWJO`,
	"removed": `styles_removed__g+AIj`
};
export default ___CSS_LOADER_EXPORT___;

import Base from "./Base";

export default class ViewApi extends Base {
    async setView(userAddress: string, contractAddress: string, tokenID: number) {
        return super.post('view', {userAddress, contractAddress, tokenID});
    }

    async getViews(contractAddress: string, tokenID: number) {
        return super.get(`view/getByToken/${contractAddress}/${tokenID}`)
    }
}

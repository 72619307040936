import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import MainLayout from "../../layouts/MainLayout/MainLayout";
import arrow from '../../assets/images/symbols/arrow.svg'
import badge from '../../assets/images/symbols/verified-badge_orange.svg'//done
import PriceInput from "./PriceInput/PriceInput";
import DateInput from "./DateInput/DateInput";
import Button from "../../components/Button/Button";
import {addDays} from "date-fns";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {EVENT_TYPE_LISTING, EVENT_TYPE_LISTING_EDITED, SHIB_ADDRESS, TABLET} from "../../constants";
import useHandleLoader from "../../hooks/loader/useHandleLoader";
import {AVAILABLE_CURRENCY} from "../../constants/listingPage";
import formatNumber, {convertDateToUnix} from "../../utils";
import useHandleMarketplace from "../../hooks/blockchain/useHandleMarketplace";
import {toast} from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import {useCountdown} from "../../hooks/useCountdown";
import useHandleToken from "../../hooks/blockchain/useHandleToken";
import useHandleActivity from "../../hooks/blockchain/useHandleActivity";
import {useDispatch, useSelector} from "react-redux";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import CollectionApi from "../../utils/api/CollectionApi";
import Input from "../../components/Input/Input";
import TransactionPendingModal from "../../components/Modals/TransactionPendingModal/TransactionPendingModal";
import useHandleModal from "../../hooks/dom/useHandleModal";
import * as listingActions from "../../store/actions/listingSuccessModal";
import TransactionSuccessModal from "../../components/Modals/TransactionSuccessModal/TransactionSuccessModal";
import TransactionFailedModal from "../../components/Modals/TransactionFailedModal/TransactionFailedModal";
import {formatIPFSUrl} from "../../utils/blockchain";
import useHandleWeb3 from "../../hooks/web3/useHandleWeb3";

const ListNftPage = () => {
        const customer = useSelector((state: any) => state.customer.data)

        const windowDimensions = useWindowDimensions()
        const isTablet = windowDimensions?.width <= TABLET

        const today = new Date();
        const serviceFee = 1.8

        const [listingData, setListingData] = useState<any>({
            from: today,
            to: addDays(today, 30),
            startTime: moment(today).format('HH:mm'),
            endTime: moment(addDays(today, 30)).format('HH:mm'),
            price: {currency: AVAILABLE_CURRENCY[0], value: ''},
            burnAmount: 0
        })
        const [nftData, setNftData] = useState<any>(null)
        const [disableSubmit, setDisabledSubmit] = useState(true)
        const [creatorEarnings, setCreatorEarnings] = useState(0)
        const [royaltyAmount, setRoyaltyAmount] = useState(0)
        const [floorPrice, setFloorPrice] = useState(0)
        const [txApproveID, setTxApproveID] = useState('')
        const [txListingID, setTxListingID] = useState('')

        const isCreator = nftData?.creator?.toLowerCase() === customer?.address?.toLowerCase()

        const navigate = useNavigate()
        const params = useParams()
        const handleLoader = useHandleLoader()
        const handleMarketplace = useHandleMarketplace()
        const handleToken = useHandleToken()
        const handleActivity = useHandleActivity()
        const handleApproveModal = useHandleModal()
        const handleListingModal = useHandleModal()
        const handleWeb3 = useHandleWeb3()

        const [, , , hoursValue, minutesValue, secondsValue] = useCountdown(moment.unix(nftData?.listing?.endDate).format())

        const onClickBack = () => navigate(-1)
        const onChangeBurnAmount = (e: any) => {
            if (+e?.target?.value > 100) return toast(<ToastMsg text={'Burn amount can not be greater than 100%'} isError/>)
            setListingData({...listingData, burnAmount: e?.target?.value})
        }

        const onApproveToken = async () => {
            if (!nftData || !listingData || disableSubmit) return

            const balanceBONE = await handleWeb3
                .getBalance(customer?.address)
                .then((res: any) => res / 10 ** 18)
            if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to create listing'} isError/>)

            handleMarketplace
                .approveListing(nftData?.tokenID, nftData?.contractAddress)
                .then(async (res: any) => {
                    setTxApproveID(res?.hash)
                    handleApproveModal.open()
                    const transaction = await res?.wait();

                    if (transaction?.status) {
                        toast(<ToastMsg text={'Token is successfully approved! Listing is starting...'}/>)
                        handleApproveModal.close()
                        await onStartListing()
                    } else {
                        handleApproveModal.close()
                        toast(<ToastMsg text={'Approve failed. Try again'} isError/>)
                    }
                })
                .catch((err: any) => {
                    if (err?.code !== 'ACTION_REJECTED') {
                        toast(<ToastMsg text={'Approve failed. Try again'} isError/>)
                    }
                    return handleApproveModal.close()
                })
                .finally(() => {
                    handleApproveModal.close()
                    handleListingModal.close()
                })
        }

        const onStartListing = async () => {
            /* global BigInt */
            const value = BigInt(+listingData?.price?.value * 10 ** 18).toString();
            // const burnAmount = BigInt(+listingData?.burnAmount * 10 ** 18).toString();
            const startDateUnix = convertDateToUnix(listingData?.from, listingData?.startTime)
            const endDateUnix = convertDateToUnix(listingData?.to, listingData?.endTime)

            await handleMarketplace
                .createListing(startDateUnix,
                    endDateUnix,
                    value,
                    nftData?.tokenID,
                    true,
                    SHIB_ADDRESS,
                    nftData?.contractAddress,
                    +listingData?.burnAmount,
                    royaltyAmount)
                .then(async (res: any) => {
                    setTxListingID(res?.hash)
                    handleListingModal.open()
                    const transaction = await res?.wait();

                    if (transaction?.status) {

                        await handleActivity.createActivity({
                            eventType: EVENT_TYPE_LISTING,
                            tokenID: nftData?.tokenID,
                            contractAddress: nftData?.contractAddress?.toLowerCase(),
                            name: nftData?.name,
                            nftName: nftData?.metadata?.name,
                            isCollectionVerified: nftData?.isCollectionVerified,
                            imageDOLink: nftData?.imageDOLink,//done
                            audioDOLink: nftData?.audioDOLink,
                            videoDOLink: nftData?.videoDOLink,
                            price: +listingData?.price?.value,
                            currency: listingData?.price?.currency?.label,
                            from: customer?.address?.toLowerCase(),
                            startDate: startDateUnix,
                            endDate: endDateUnix,
                            transactionTime: moment().unix(),
                            transactionHash: transaction?.transactionHash
                        })

                        await handleToken.checkToken(nftData?.contractAddress, nftData?.tokenID)
                        await new CollectionApi().checkCollection(nftData?.contractAddress?.toLowerCase())

                        handleListingModal.close()
                        toast(<ToastMsg text={'NFT is successfully listed!'}/>)
                        navigate(`/collection/${nftData?.contractAddress}/${nftData?.tokenID}`)
                    } else {
                        handleListingModal.close()
                        toast(<ToastMsg text={'Listing failed. Try again'} isError/>)
                    }
                })
                .catch((err: any) => {
                    if (err?.code !== 'ACTION_REJECTED') {
                        toast(<ToastMsg text={'Listing failed. Try again'} isError/>)
                    }
                    return handleListingModal.close()
                })
        }

        const onEditListing = async () => {
            if (disableSubmit) return

            const balanceBONE = await handleWeb3
                .getBalance(customer?.address)
                .then((res: any) => res / 10 ** 18)
            if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to edit listing'} isError/>)

            /* global BigInt */
            const value = BigInt(+listingData?.price?.value * 10 ** 18).toString();
            // const burnAmount = BigInt(+listingData?.burnAmount * 10 ** 18).toString();
            const startDateUnix = convertDateToUnix(listingData?.from, listingData?.startTime)
            const endDateUnix = convertDateToUnix(listingData?.to, listingData?.endTime)

            handleMarketplace
                .changeListing(
                    value,
                    nftData?.tokenID,
                    nftData?.contractAddress,
                    startDateUnix,
                    endDateUnix,
                    true,
                    SHIB_ADDRESS,
                    +listingData?.burnAmount,
                    royaltyAmount)
                .then(async (res: any) => {
                    setTxListingID(res?.hash)
                    handleListingModal.open()
                    const transaction = await res?.wait();

                    if (transaction?.status) {

                        await handleActivity.createActivity({
                            eventType: EVENT_TYPE_LISTING_EDITED,
                            tokenID: nftData?.tokenID,
                            contractAddress: nftData?.contractAddress?.toLowerCase(),
                            name: nftData?.name,
                            nftName: nftData?.metadata?.name,
                            isCollectionVerified: nftData?.isCollectionVerified,
                            imageDOLink: nftData?.imageDOLink,//done
                            audioDOLink: nftData?.audioDOLink,
                            videoDOLink: nftData?.videoDOLink,
                            price: +listingData?.price?.value,
                            currency: listingData?.price?.currency?.label,
                            from: customer?.address?.toLowerCase(),
                            startDate: startDateUnix,
                            endDate: endDateUnix,
                            transactionTime: moment().unix(),
                            transactionHash: transaction?.transactionHash
                        })

                        await handleToken.checkToken(nftData?.contractAddress, nftData?.tokenID)
                        await new CollectionApi().checkCollection(nftData?.contractAddress?.toLowerCase())

                        handleListingModal.close()
                        toast(<ToastMsg text={'Listing is successfully changed'}/>)
                        navigate(`/collection/${nftData?.contractAddress}/${nftData?.tokenID}`)
                    } else {
                        handleListingModal.close()
                        toast(<ToastMsg text={'Failed to change listing. Try again'} isError/>)
                    }
                })
                .catch((err: any) => {
                    if (err?.code !== 'ACTION_REJECTED') {
                        toast(<ToastMsg text={'Failed to change listing. Try again'} isError/>)
                    }
                    return handleListingModal.close()
                })
        }

        useEffect(() => {
            if (+listingData?.price?.value > 0) {
                setDisabledSubmit(false)
            } else setDisabledSubmit(true)
        }, [listingData?.price])

        useEffect(() => {
            new CollectionApi()
                .getCollectionFees(params?.contractAddress)
                .then((res) => {
                    setRoyaltyAmount((res?.data?.isExternalCollection || res?.data?.forDrops) ? +res?.data?.royaltyPercentage : 0)
                    setCreatorEarnings(+res?.data?.royaltyPercentage || 0)
                    setFloorPrice(+res?.data?.currentListingsFloorPrice || 0)
                })

            handleLoader.loaderWrapper(() => handleToken
                .checkToken(params?.contractAddress, params?.tokenId)
                .then((res: any) => {
                    setNftData(res)

                    if (!!res?.listing?.price && res?.listing?.endDate > moment().unix()) {
                        setListingData({
                            from: new Date(moment.unix(res?.listing?.startDate).format()),
                            to: new Date(moment.unix(res?.listing?.endDate).format()),
                            startTime: moment.unix(res?.listing?.startDate).format('HH:mm'),
                            endTime: moment.unix(res?.listing?.endDate).format('HH:mm'),
                            price: {
                                currency: AVAILABLE_CURRENCY?.find(item => item?.address?.toLowerCase() === res?.listing?.tokenAddress?.toLowerCase()),
                                value: res?.listing?.price
                            },
                            burnAmount: res?.listing?.burnPercent
                        })
                    }
                })
            )
        }, [params?.contractAddress, params?.tokenId])

        useEffect(() => {
            if (nftData?.listing?.price > 0 && hoursValue === 0 && minutesValue === 0 && secondsValue === 0) {
                setListingData({
                    from: today,
                    to: addDays(today, 30),
                    startTime: moment(today).format('HH:mm'),
                    endTime: moment(addDays(today, 30)).format('HH:mm'),
                    price: {currency: AVAILABLE_CURRENCY[0], value: ''},
                    burnAmount: 0
                })
                toast(<ToastMsg text={'This listing has expired. Create a new listing'} isError/>)
            }
        }, [hoursValue, minutesValue, secondsValue])

        return (
            <MainLayout isFullWidth>

                {!nftData?.tokenID
                    ? <FullPageLoader isLoaderActive={!nftData?.tokenID}/>
                    : <div className={styles.listNftPage}>
                        {!isTablet && <div className={styles.back} onClick={onClickBack}><img src={arrow} alt={''}/></div>}

                        {isTablet && <div className={styles.image_wrapper}>
                            {(nftData?.imageDOLink && !nftData?.imageDOLink?.includes('undefined'))
                            && <img
                                src={ formatIPFSUrl(nftData?.imageDOLink)}
                                alt={''} className={styles.image}/>}

                            {(nftData?.videoDOLink && !nftData?.videoDOLink?.includes('undefined'))
                            && <video
                                className={'nft_video'}
                                src={nftData?.videoDOLink}
                            />}

                            {(nftData?.audioDOLink && !nftData?.audioDOLink?.includes('undefined'))
                            && <audio
                                className={'nft_audio'}
                                controls
                                src={nftData?.audioDOLink}
                            />}

                            <div className={styles.image_desc}>
                                <p className={styles.nft_id}>{nftData?.metadata?.name || ('#' + nftData?.tokenID)}</p>
                                <p className={styles.nft_collection}>
                                    {nftData?.name} {' '}
                                    {nftData?.isCollectionVerified && <img src={badge} alt={''}/>}
                                </p>
                            </div>
                            <p className={styles.price}>{listingData?.price?.value || 0} {listingData?.price?.currency?.label}</p>
                        </div>}

                        <div className={styles.wrapper}>

                            <div className={styles.form_wrapper}>
                                <p className={styles.title}>List item for sale</p>

                                <p className={styles.subtitle}>Price</p>
                                <PriceInput
                                    listingData={listingData}
                                    setListingData={setListingData}
                                    floorPrice={floorPrice}
                                />

                                <p className={styles.subtitle}>Duration</p>
                                <DateInput setListingData={setListingData} listingData={listingData}/>

                                <p className={styles.subtitle}>Burn Amount: <span>Send a portion of your sale to the SHIB burn address</span>
                                </p>
                                <Input
                                    value={listingData?.burnAmount}
                                    onChange={onChangeBurnAmount}
                                    text={'%'}
                                    type={'number'}
                                    withRightBlock
                                    placeholder={'Enter burn amount, %'}
                                />
                                <p className={styles.burnAmount}>
                                    {+listingData?.price?.value * +listingData?.burnAmount / 100} SHIB total
                                </p>

                                <p className={styles.subtitle}>Overview</p>
                                <div className={styles.overview}>
                                    <div className={styles.overview_row}>
                                        <span className={styles.overview_title}>Listing price</span>
                                        <span className={styles.overview_value}>{
                                            !!listingData?.price?.value
                                                ? formatNumber(listingData?.price?.value)
                                                : '--'} {listingData?.price?.currency?.label}</span>
                                    </div>
                                    <div className={styles.overview_row}>
                                        <span className={styles.overview_title}>Service fee</span>
                                        <span className={styles.overview_value}>{serviceFee}%</span>
                                    </div>
                                    <div className={styles.overview_row}>
                                        <span className={styles.overview_title}>Creator earnings</span>
                                        <span className={styles.overview_value}>{creatorEarnings}%</span>
                                    </div>
                                    <div className={styles.overview_row}>
                                        <span className={styles.overview_title}>Burn amount</span>
                                        <span className={styles.overview_value}>{listingData?.burnAmount}%</span>
                                    </div>
                                </div>

                                <div className={styles.overview_row}>
                                    <span className={styles.overview_gains}>Potential gains</span>
                                    <span className={styles.overview_gains_value}>
                                    {isCreator
                                        ? formatNumber((100 - serviceFee - +listingData?.burnAmount) * +listingData?.price?.value / 100)
                                        : formatNumber((100 - creatorEarnings - serviceFee - +listingData?.burnAmount) * +listingData?.price?.value / 100)} SHIB
                                </span>
                                </div>


                                <Button
                                    title={'List item'}
                                    isYellow
                                    disabled={disableSubmit}
                                    onClick={(nftData?.listing?.price && nftData?.listing?.endDate > moment().unix()) ? onEditListing : onApproveToken}
                                />

                            </div>

                            {!isTablet && <div className={styles.image_wrapper}>
                                {(nftData?.imageDOLink && !nftData?.imageDOLink?.includes('undefined'))
                                && <img
                                    src={ formatIPFSUrl(nftData?.imageDOLink)}
                                    alt={''} className={styles.image}/>}

                                {(nftData?.videoDOLink && !nftData?.videoDOLink?.includes('undefined'))
                                && <video
                                    className={'nft_video'}
                                    controls
                                    src={nftData?.videoDOLink}
                                />}

                                {(nftData?.audioDOLink && !nftData?.audioDOLink?.includes('undefined'))
                                && <audio
                                    className={'nft_audio'}
                                    controls
                                    src={nftData?.audioDOLink}
                                />}
                                <div className={styles.image_desc}>
                                    <p className={styles.nft_id}>{nftData?.metadata?.name || ('#' + nftData?.tokenID)}</p>
                                    <p className={styles.nft_collection}>
                                        {nftData?.name} {' '}
                                        {nftData?.isCollectionVerified && <img src={badge} alt={''}/>}
                                    </p>
                                </div>
                            </div>}
                        </div>

                        {handleApproveModal.isActive && <TransactionPendingModal
                            isOpen={handleApproveModal.isActive}
                            title={'Your transaction is processing...'}
                            text={`Your approval of`}
                            transactionID={txApproveID}
                            yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
                        />}

                        {handleListingModal.isActive && <TransactionPendingModal
                            isOpen={handleListingModal.isActive}
                            title={'Your transaction is processing...'}
                            transactionID={txListingID}
                            text={`Your listing of`}
                            yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
                        />}
                    </div>}
            </MainLayout>
        );
    }
;

export default ListNftPage;

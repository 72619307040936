import React, {useRef, useState} from 'react';
import Input from "../../../components/Input/Input";
import styles from './styles.module.scss'
import arrow from '../../../assets/images/symbols/arrow.svg'
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import {AVAILABLE_CURRENCY} from "../../../constants/listingPage";
import {toast} from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";

type Props = {
    setListingData: any,
    listingData: any,
    floorPrice: number
}

const PriceInput = ({setListingData, listingData, floorPrice}: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    const dropdownRef = useRef<HTMLInputElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const onToggle = () => setIsOpen(!isOpen)
    const onClose = () => setIsOpen(false)

    const onChangePriceValue = (e: any) => {
        if (+e?.target?.value > 100000000000)
            return toast(<ToastMsg text={'Price can not be over 100,000,000,000 SHIB'} isError/>)
        if (e?.target?.value?.split('.')[1]?.length > 2) return toast(<ToastMsg text={'Only hundredth are allowed in price'} isError/>)
        if (e?.target?.value < 0) return

        setListingData({...listingData, price: {...listingData.price, value: e?.target?.value}})
    }

    const onChangePriceCurrency = (currency: any) => {
        setListingData({...listingData, price: {...listingData.price, currency}})
        onClose()
    }

    useOutsideClick(dropdownRef, onClose, inputRef)

    return (
        <div className={styles.priceInput}>
            <Input
                inputRef={inputRef}
                value={listingData?.price?.value}
                onChange={onChangePriceValue}
                placeholder={'Listing price'}
                text={listingData?.price?.currency?.label}
                image={arrow}
                withRightBlock
                onClickText={onToggle}
                onClickInput={onClose}
                type={'number'}
                min={0}
                isOpen={isOpen}
            />

            {isOpen && <div className={styles.dropdown} ref={dropdownRef}>
                {AVAILABLE_CURRENCY?.map((item: any, key: number) =>
                    <div key={key} className={styles.dropdown_item} onClick={() => onChangePriceCurrency(item)}>
                        {item?.label}
                    </div>)}
            </div>}

            <p className={styles.text}>Floor <span>{floorPrice?.toLocaleString("en-US", {maximumFractionDigits: 2})} SHIB</span></p>
        </div>
    );
};

export default PriceInput;

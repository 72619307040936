// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_propertyRow__rIBG4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.styles_property__Abejo {
  max-width: 424px;
  width: 100%;
  background: rgba(255, 162, 1, 0.25);
  border: 1px solid #F79C00;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px;
}
@media (max-width: 1025px) {
  .styles_property__Abejo {
    max-width: 90%;
  }
}

.styles_property_type__U5uBR {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.49px;
  text-transform: uppercase;
  color: #F79C00;
  text-align: center;
  margin: 0 0 12px;
}

.styles_property_name__3Wtk0 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.35px;
  color: #FFFFFF;
  text-align: center;
  margin: 0;
}

.styles_delete__oAHhs {
  width: 52px;
  height: 52px;
  border: 1px solid #3E3E3E;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/templates/CreatePage/PropertyRow/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,mCAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;AACF;AACE;EATF;IAUI,cAAA;EAEF;AACF;;AACA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AAEF;;AACA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAEF","sourcesContent":[".propertyRow{\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 30px;\n}\n\n.property{\n  max-width: 424px;\n  width: 100%;\n  background: rgba(255, 162, 1, 0.25);\n  border: 1px solid #F79C00;\n  border-radius: 8px;\n  box-sizing: border-box;\n  padding: 16px;\n\n  @media (max-width: 1025px) {\n    max-width: 90%;\n  }\n}\n\n.property_type{\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 700;\n  font-size: 12px;\n  line-height: 16px;\n  letter-spacing: 0.49px;\n  text-transform: uppercase;\n  color: #F79C00;\n  text-align: center;\n  margin: 0 0 12px;\n}\n\n.property_name{\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 25px;\n  letter-spacing: -0.35px;\n  color: #FFFFFF;\n  text-align: center;\n  margin: 0;\n}\n\n.delete{\n  width: 52px;\n  height: 52px;\n  border: 1px solid #3E3E3E;\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"propertyRow": `styles_propertyRow__rIBG4`,
	"property": `styles_property__Abejo`,
	"property_type": `styles_property_type__U5uBR`,
	"property_name": `styles_property_name__3Wtk0`,
	"delete": `styles_delete__oAHhs`
};
export default ___CSS_LOADER_EXPORT___;

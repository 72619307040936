import React, {useEffect, useRef, useState} from 'react';
import styles from "../styles.module.scss";
import TabHeader from "../../ProfilePage/TabHeader/TabHeader";
import cn from "classnames";
import NftCard from "../../../components/NFTCard/NFTCard";
import FiltersBlock from "../FiltersBlock/FiltersBlock";
import crossIcon from "../../../assets/images/symbols/cross.svg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {TABLET} from "../../../constants";
import SortingMobile from "../../../components/SortingMobile/SortingMobile";
import SingleCollectionFiltersMobile
    from "../../../components/SingleCollectionFiltersMobile/SingleCollectionFiltersMobile";
import ReactPaginate from "react-paginate";
import CollectionApi from "../../../utils/api/CollectionApi";
import {
    NFT_TABLE_HEADERS,
    SORTING_DATA_COLLECTION_PAGE
} from "../../../constants/profile";
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import {useNavigate} from "react-router-dom";
import CryptoApi from "../../../utils/api/CryptoApi";
import {useSelector} from "react-redux";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";

const NfTsTab = ({
                     collectionData,
                     isOpenedFilters,
                     setIsOpenedFilters,
                     isCardView,
                     setIsCardView,
                     isSmallView,
                     setIsSmallView,
                     isMoreOpen
                 }: {
    collectionData: any, isOpenedFilters: boolean,
    setIsOpenedFilters: any, isCardView: any,
    setIsCardView: any,
    isSmallView: any,
    setIsSmallView: any,
    isMoreOpen?: boolean
}) => {
    const customer = useSelector((state: any) => state.customer.data)
    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const [filters, setFilters] = useState<any>([])
    const [selectedFilters, setSelectedFilters] = useState<any>([])
    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false)
    const [isOpenedMobileSorting, setIsOpenedMobileSorting] = useState(false)
    const [selectedCollections, setSelectedCollections] = useState<any>([])
    const [filtersAmount, setFiltersAmount] = useState(0)
    const [selectedSorting, setSelectedSorting] = useState<any>(SORTING_DATA_COLLECTION_PAGE[0])
    const [nftsToShow, setNftsToShow] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(0)
    const [searchQuery, setSearchQuery] = useState<any>('')
    const [maticToUsd, setMaticToUsd] = useState<any>(0)

    const wrapperRef = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const handleLoader = useHandleLoader()

    const onDeleteFilter = (title: number, parent: string) =>
        setFilters && setFilters([...filters?.map((item: any) =>
            (item?.title === title && item?.parent === parent)
                ? {...item, status: false}
                : item)])

    const onClearFilters = () => setFilters && setFilters(filters?.map((item: any) => ({...item, status: false})))

    const handlePageClick = (event: any) => setPage(event.selected)

    const onClickDots = (nft: any) => navigate(`/collection/${nft?.contractAddress}/${nft?.tokenID}`)

    const fetchList = async (page = 0) => {
        const filterAttrList = selectedFilters
            ?.filter((item: any) => !item?.type)
            ?.map((item: any) => ({parent: item?.parent, title: item?.title}));
        const filterStatusList = selectedFilters
            ?.filter((item: any) => item?.type)
            ?.map((item: any) => item?.type);

        await new CollectionApi()
            .getTokenListWithFilters(page, {
                sortType: selectedSorting?.id,
                contractAddress: collectionData?.contractAddress,
                filterAttrList,
                filterStatusList,
                searchName: searchQuery,
            })
            .then(res => {
                if (res?.status) {
                    setNftsToShow(res?.data?.list);
                    setTotalCount(res?.data?.count);
                }
            });
    }

    useEffect(() => {
        setSelectedFilters(filters?.filter((item: any) => !!item?.status))
        setFiltersAmount(filters?.filter((item: any) => item?.status)?.length + selectedCollections?.length)
    }, [filters, selectedCollections])

    useEffect(() => {
        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
            .catch(() => null)

        new CollectionApi()
            .getTokenListCounter(collectionData?.contractAddress)
            .then(res => res?.status && setFilters(res?.data))
    }, [collectionData?.contractAddress])

    useEffect(() => {
        if (collectionData?.contractAddress) {
            handleLoader.loaderWrapper(() => fetchList());
            setPage(0);
        }
    }, [collectionData?.contractAddress, selectedFilters?.length, selectedSorting, searchQuery])

    useEffect(() => {
        if (page > 0) handleLoader.loaderWrapper(() => fetchList(page));
    }, [page])

    return (
        <div className={styles.tab_wrapper}>
            <TabHeader
                totalCount={totalCount}
                isOpenedFilters={isOpenedFilters}
                setIsOpenedFilters={setIsOpenedFilters}
                wrapperRef={wrapperRef}
                filters={filters}
                setFilters={setFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                isOpenedMobileFilters={isOpenedMobileFilters}
                filtersAmount={filtersAmount}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                isOpenedMobileSorting={isOpenedMobileSorting}
                isOwnerOfCollection={collectionData?.creator === customer?._id && !collectionData?.isExternalCollection}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                isCardView={isCardView}
                setIsCardView={setIsCardView}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
                isMoreOpen={isMoreOpen}
            />

            <div className={styles.block_wrapper} ref={wrapperRef}>

                {!isTablet && <FiltersBlock
                    isOpenedFilters={isOpenedFilters}
                    filters={filters}
                    setFilters={setFilters}
                    collectionData={collectionData}
                />}


                <div className={cn(styles.nftList_wrapper, isOpenedFilters && styles.nftList_wrapper_filters)}>
                    {(!!selectedFilters?.length && !isTablet) && <div className={styles.selectedFilters}>
                        {selectedFilters?.map((item: any, key: number) => <div
                            key={key}
                            className={styles.selectedFilters_item}
                            onClick={() => onDeleteFilter(item?.title, item?.parent)}
                        >
                            {item?.parent}: {item?.title}
                            <img src={crossIcon} alt={''}/>
                        </div>)}
                        <div className={styles.selectedFilters_clear} onClick={onClearFilters}>Clear filters</div>
                    </div>}

                    {isCardView
                        ? <div className={cn(styles.nftList, isOpenedFilters && styles.nftList_filters)}>
                            {nftsToShow?.map((item: any, key: number) =>
                                <NftCard key={key} nft={item} isSmall={isSmallView}/>)}
                        </div>
                        : <div className={styles.NfTsTable}>
                            <NfTsTable
                                data={nftsToShow}
                                headers={NFT_TABLE_HEADERS}
                                withDots
                                withNFTCheckbox
                                onClickDots={onClickDots}
                                maticToUsd={maticToUsd}
                            />
                        </div>
                    }

                    {totalCount >= 20 && <ReactPaginate
                        breakLabel="..."
                        forcePage={page}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        pageCount={totalCount ? Math.ceil(totalCount / 48) : 0}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pagination_page}
                        activeClassName={styles.pagination_active}
                        previousClassName={styles.pagination_previous}
                        nextClassName={styles.pagination_next}
                        disabledClassName={styles.pagination_disabled}
                    />}
                </div>
            </div>

            {isTablet && <SingleCollectionFiltersMobile
                isOpenedMobileFilters={isOpenedMobileFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                filters={filters}
                setFilters={setFilters}
                setSelectedCollections={setSelectedCollections}
                selectedCollections={selectedCollections}
                filtersAmount={filtersAmount}
                setIsSmallView={setIsSmallView}
                isSmallView={isSmallView}
                selectedFilters={selectedFilters}
                onDeleteFilter={onDeleteFilter}
                collectionData={collectionData}
            />}

            {isTablet && <SortingMobile
                filters={SORTING_DATA_COLLECTION_PAGE}
                isOpenedMobileSorting={isOpenedMobileSorting}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />}
        </div>
    );
};

export default NfTsTab;

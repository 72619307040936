import React, {useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import cross from '../../assets/images/symbols/cross.svg'
import arrow from '../../assets/images/symbols/arrow.svg'
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import FiltersRow from "../../templates/SingleCollectionPage/FiltersRow/FiltersRow";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../constants";
import listView from "../../assets/images/symbols/small_view_white.svg";
import listViewGray from "../../assets/images/symbols/small_view.svg";
import cardView from "../../assets/images/symbols/view_cards.svg";
import cardViewGray from "../../assets/images/symbols/view_cards_gray.svg";
import crossIcon from "../../assets/images/symbols/cross.svg";
import {ACTIVITY_TIME_TABS} from "../../constants/singleCollection";

type Props = {
    isOpenedMobileFilters: boolean,
    setIsOpenedMobileFilters: (a: any) => void,
    setFilters?: (a: any) => void,
    filters?: any,
    selectedCollections: any,
    setSelectedCollections: (a: any) => void,
    filtersAmount: number,
    isActivityTab?: boolean,
    setIsSmallView?: any,
    isSmallView?: any,
    selectedFilters?: any,
    onDeleteFilter?: any,
    withoutView?: boolean,
    collectionData?: any,
    activeTime?: any,
    setActiveTime?: any
}

const SingleCollectionFiltersMobile = ({
                                           isOpenedMobileFilters,
                                           setIsOpenedMobileFilters,
                                           filters,
                                           setFilters,
                                           setSelectedCollections,
                                           filtersAmount,
                                           isActivityTab,
                                           setIsSmallView,
                                           isSmallView,
                                           selectedFilters,
                                           onDeleteFilter,
                                           withoutView,
                                           collectionData,
                                           activeTime,
                                           setActiveTime
                                       }: Props) => {
    const [isViewOpen, setIsViewOpen] = useState(true)

    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width <= MOBILE

    const onToggleView = () => setIsViewOpen(!isViewOpen)

    const onToggleActivityFilters = (title: number, parent: string) => setFilters && setFilters(
        [...filters?.map((item: any) =>
            (item?.title === title && item?.parent === parent) ? {
                ...item,
                status: !item?.status
            } : item)])

    const onCloseFilters = () => setIsOpenedMobileFilters(false)

    const onClearFilters = () => {
        setSelectedCollections([])
        setFilters && setFilters(filters?.map((item: any) => ({...item, status: false})))
    }

    const onApplyFilters = () => {
        setIsOpenedMobileFilters(false)
    }

    const onClickSmallView = () => setIsSmallView && setIsSmallView(true)
    const onClickBigView = () => setIsSmallView && setIsSmallView(false)

    return (
        <div className={cn(styles.filtersMobile, isOpenedMobileFilters && styles.filtersMobile_isOpenedMobileFilters)}>

            <div className={styles.title}>
                Filters
                <div className={styles.cross} onClick={onCloseFilters}>
                    <img src={cross} alt={''} width={18.67} height={18.67}/>
                </div>
            </div>

            <div className={styles.content}>

                {(!!selectedFilters?.length && isMobile) &&
                <div className={styles.selectedFilters}>
                    {selectedFilters?.map((item: any, key: number) => <div
                        key={key}
                        className={styles.selectedFilters_item}
                        onClick={() => onDeleteFilter(item?.title)}
                    >
                        {item?.title}
                        <img src={crossIcon} alt={''}/>
                    </div>)}
                </div>}

                {isMobile && !withoutView && <div className={styles.view_block}>
                    <div className={styles.status_header} onClick={onToggleView}>
                        View
                        <img src={arrow} alt={''} className={cn(styles.arrow, isViewOpen && styles.arrow_isOpen)}/>
                    </div>
                    {isViewOpen && <div className={styles.view}>
                        <div className={cn(styles.view_card, isSmallView && styles.view_card_active)}
                             onClick={onClickSmallView}>
                            <img src={isSmallView ? listView : listViewGray} alt={''}/>
                        </div>
                        <div className={cn(styles.view_list, !isSmallView && styles.view_list_active)}
                             onClick={onClickBigView}>
                            <img src={!isSmallView ? cardView : cardViewGray} alt={''}/>
                        </div>
                    </div>}
                </div>}

                {isActivityTab && <FiltersRow
                    filterParent={'Event'}
                    onToggleActivityFilters={onToggleActivityFilters}
                    filters={filters}
                    isActivityTab
                />}

                {isActivityTab && <>
                    <div className={styles.subtitle}>
                        Sort by time
                    </div>

                    <div className={styles.time}>
                        {ACTIVITY_TIME_TABS?.map((item, key) => <div
                            key={key}
                            onClick={() => setActiveTime(item?.value)}
                            className={styles.sorting_item}
                        >
                            <Checkbox
                                setIsChecked={() => null}
                                isChecked={activeTime === item?.value}
                                title={item?.title}
                                onClickCallback={() => setActiveTime(item?.value)}
                                isFullWidth
                            />
                        </div>)}
                    </div>
                </>}


                {!isActivityTab && <FiltersRow
                    filterParent={'Status'}
                    onToggleActivityFilters={onToggleActivityFilters}
                    filters={filters?.filter((filter: any) => filter?.parent === 'Status')}
                    isActivityTab
                />}

                {!isActivityTab && collectionData?.attributes
                && Object.keys(collectionData?.attributes)
                    ?.filter((item: string) => !!item?.trim()?.length)
                    ?.map((item, key) =>
                        <FiltersRow
                            key={key}
                            filterParent={item}
                            onToggleActivityFilters={onToggleActivityFilters}
                            filters={filters?.filter((filter: any) => filter?.parent === item)}
                        />)
                }
            </div>

            <div className={styles.buttons}>
                <Button
                    title={'Clear filters'}
                    isBlackWhite
                    onClick={onClearFilters}
                />
                <Button
                    title={`Apply ${!!filtersAmount ? filtersAmount : ''} filters`}
                    isYellow
                    onClick={onApplyFilters}
                />
            </div>
        </div>
    );
};

export default SingleCollectionFiltersMobile;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import MainLayout from "../../layouts/MainLayout/MainLayout";
import Button from "../../components/Button/Button";
import plus from '../../assets/images/symbols/plus_white.svg'
import plusYellow from '../../assets/images/symbols/plus.svg'
import badge from '../../assets/images/symbols/verified-badge_orange.svg'
import {useNavigate, useParams} from "react-router-dom";
import {pathList} from "../../routes/path";
import CustomerApi from "../../utils/api/CustomerApi";
import useHandleLoader from "../../hooks/loader/useHandleLoader";
import {useSelector} from "react-redux";

const MyCollectionsPage = () => {
    const customer = useSelector((state: any) => state.customer.data)
    const [collections, setCollections] = useState([])
    const [isOwner, setIsOwner] = useState(false)

    const navigate = useNavigate()
    const handleLoader = useHandleLoader()
    const params: any = useParams()

    const onClickAdd = () => navigate(pathList.createCollection.path)
    const onClickAddDrops = () => navigate(pathList.createDropsCollection.path)
    const onClickCollection = (contractAddress: string) => navigate(`/collection/${contractAddress}`)//done

    useEffect(() => {
        handleLoader.loaderWrapper(() => new CustomerApi()
            .getUserCollections(params?.address)
            .then((res: any) => setCollections(res?.data)))
    }, [params?.address])

    useEffect(() => {
        setIsOwner(params?.address?.toLowerCase() === customer?.address?.toLowerCase()
            || params?.address?.toLowerCase() === customer?.userName?.toLowerCase())
    }, [params?.address, customer?.address, customer?.userName])

    return (
        <MainLayout>
            <div className={styles.myCollectionsPage}>

                <div>
                    <p className={styles.title}>{isOwner ? 'My' : params?.address} Collections</p>
                    {isOwner && <div className={styles.addNew}>
                        <Button title={'Add new collection'} image={plus} isYellow onClick={onClickAdd}/>
                        {customer?.hasAccessToDrops &&
                        <Button title={'Add new collection for drops'} image={plusYellow} onClick={onClickAddDrops}/>}
                    </div>}

                    {!!collections?.length
                        ? <div className={styles.list}>
                            {collections?.map((item: any, key) =>
                                <div
                                    key={key}
                                    className={styles.list_item}
                                    onClick={() => onClickCollection(item?.externalLink
                                        ? item?.externalLink?.includes('/')
                                            ? item?.contractAddress
                                            : item?.externalLink
                                        : item?.contractAddress)}
                                >
                                    <img src={item?.featuredImage} alt={''}/>
                                    <span>
                                        {item?.name?.length > 20 ? item?.name?.slice(0, 20) + '...' : item?.name}
                                        {item?.isVerified && <img width={20} height={20} src={badge} alt={''}/>}
                                    </span>
                                </div>)}
                        </div>
                        :
                        <p className={styles.noData}>{isOwner ? 'You' : params?.address?.slice(0, 5) + '...' + params?.address?.slice(-5)} don’t
                            have any collections at the moment</p>}


                </div>


            </div>
        </MainLayout>
    );
};

export default MyCollectionsPage;

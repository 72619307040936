import React from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import logo from '../../../assets/images/logos/Pawzaar Orange Night.svg'
import Button from "../../Button/Button";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import useHandleAuth from "../../../hooks/auth/useHandleAuth";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../../routes/path";

const SignUpModal = ({isOpen, onClose}: { isOpen: boolean, onClose: any }) => {
    const handleLoader = useHandleLoader()
    const handleAuth = useHandleAuth();
    const windowDimensions = useWindowDimensions()
    const navigate = useNavigate()

    const onLogin = () => {
        onClose()
        handleLoader.loaderWrapper(handleAuth.login, 3)
    }

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={windowDimensions?.width < 540 ? windowDimensions?.width - 40 + 'px' : '500px'}
            maxHeight={'fit-content'}
        >
            <div className={styles.wrapper}>
                <img width={250} src={logo} alt={''}/>
                <p className={styles.title}>Welcome to Pawzaar</p>
                <p className={styles.text}>By connecting your wallet and using Pawzaar, you agree to
                    our <span onClick={() => {
                        onClose()
                        navigate(pathList.terms.path)
                    }
                    }>Terms of Service</span> and <span onClick={() => {
                        onClose()
                        navigate(pathList.privacyPolicy.path)
                    }}>Privacy Policy</span>.</p>
            </div>

            <div className={styles.actions}>
                <Button title={'Cancel'} onClick={onClose}/>
                <Button title={'Accept and sign'} isYellow onClick={onLogin}/>
            </div>

        </ModalLayout>
    );
};

export default SignUpModal;

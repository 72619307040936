export const localStorageSet = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const localStorageGet = (key: string, defaultValue?: any) => {
    let data = defaultValue;
    try {
        const storageData = localStorage.getItem(key)
        if (storageData) data = JSON.parse(storageData);

    } catch (e) {
        console.error(e);
    }

    return data || defaultValue;
};

export const localStorageRemove = (key: string) => localStorage.removeItem(key);

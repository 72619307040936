// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_toastMsg__8cOWG {
  background: linear-gradient(90deg, #46C96B 0%, #BEC946 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #FFFFFF;
  z-index: 1111110;
}

.styles_toastMsg_isError__rIjJD {
  background: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ToastMsg/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,4DAAA;EACA,0CAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,4DAAA;EACA,YAAA;AACF","sourcesContent":[".toastMsg {\n  background: linear-gradient(90deg, #46C96B 0%, #BEC946 100%);\n  border: 1px solid rgba(255, 255, 255, 0.1);\n  border-radius: 12px;\n  padding: 16px 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 20px;\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 24px;\n  color: #FFFFFF;\n  z-index: 1111110;\n}\n\n.toastMsg_isError {\n  background: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastMsg": `styles_toastMsg__8cOWG`,
	"toastMsg_isError": `styles_toastMsg_isError__rIjJD`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/CerebriSansPro-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Cerebri Sans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
  font-weight: 700;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,2DAAA;EACA,gBAAA;EACA,kBAAA;AACF","sourcesContent":["@font-face {\n  font-family: 'Cerebri Sans';\n  src: url('../assets/fonts/CerebriSansPro-Bold.woff') format('woff');\n  font-weight: 700;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import NfTsTableRow from "./NFTsTableRow/NFTsTableRow";

type Props = {
    headers: { title: string, withCheckBox: boolean }[],
    data: any,
    withDots?: boolean,
    withNFTCheckbox?: boolean,
    withListingButtons?: boolean,
    withOffersButtons?: boolean,
    isOffersReceived?: boolean,
    withLightBorder?: boolean,
    onClickDots?: any,
    onCancelOffer?: any,
    onEditOffer?: any,
    onAcceptOffer?: any,
    onCancelListing?: any,
    onEditListing?: any,
    maticToUsd: any
}

const NfTsTable = ({
                       headers,
                       data,
                       withDots,
                       withListingButtons,
                       withOffersButtons,
                       isOffersReceived,
                       withLightBorder,
                       onClickDots,
                       onCancelOffer,
                       onEditOffer,
                       onAcceptOffer,
                       onCancelListing,
                       onEditListing,
                       maticToUsd
                   }: Props) => {

    return (
        <table cellSpacing={0} className={styles.table}>
            <thead>
            <tr className={withLightBorder ? styles.header : ''}>
                {headers?.map((item, key) =>
                    <th key={key}
                        className={cn(styles.table_header, withLightBorder && styles.table_header_withLightBorder)}>
                        {item?.title}
                    </th>)}
            </tr>
            </thead>

            <tbody>
            {data?.map((item: any, key: number) =>
                <NfTsTableRow
                    headers={headers}
                    withDots={withDots}
                    withListingButtons={withListingButtons}
                    withOffersButtons={withOffersButtons}
                    isOffersReceived={isOffersReceived}
                    withLightBorder={isOffersReceived}
                    onClickDots={onClickDots}
                    onCancelOffer={onCancelOffer}
                    onEditOffer={onEditOffer}
                    onAcceptOffer={onAcceptOffer}
                    onCancelListing={onCancelListing}
                    onEditListing={onEditListing}
                    maticToUsd={maticToUsd}
                    key={key}
                    item={item}
                />)}
            </tbody>
        </table>
    );
};

export default NfTsTable;

import React, {useEffect, useState} from 'react';
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import Input from "../../Input/Input";
import styles from './styles.module.scss'
import Button from "../../Button/Button";
import deleteImg from '../../../assets/images/symbols/delete.svg'
import cn from "classnames";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";

type Props = {
    isOpen: boolean,
    onClose: any,
    setCreateData: any,
    createData: any
}

const AddPropertiesModal = ({isOpen, onClose, createData, setCreateData}: Props) => {
    const [properties, setProperties] = useState([{name: '', type: '', id: new Date().getTime()}])
    const [isSaveDisabled, setIsSaveDisabled] = useState(true)
    const windowDimensions = useWindowDimensions()

    const onChangeType = (e: any, id: number) => setProperties(
        properties?.map((item: any) => item?.id === id
            ? {...item, type: e?.target?.value}
            : item))

    const onChangeName = (e: any, id: number) => setProperties(
        properties?.map((item: any) => item?.id === id
            ? {...item, name: e?.target?.value}
            : item))

    const onAddProperty = () => {
        if (isSaveDisabled) return
        if (properties?.length > 8) return toast(<ToastMsg text={'You can add only 9 properties'} isError/>)
        setProperties([...properties, {name: '', type: '', id: new Date().getTime()}])
    }
    const onDelete = (id: number) => {
        if (properties?.length === 1) setProperties([{name: '', type: '', id: new Date().getTime()}])
        else setProperties(properties?.filter((item: any) => item?.id !== id))
    }

    const onSave = () => {
        if (isSaveDisabled) return
        onClose()
        setCreateData({
            ...createData,
            properties: properties.filter((item) => item?.name?.length && item?.type?.length)
        })
    }

    const onCloseModal = () => {
        onClose()
        setProperties([{name: '', type: '', id: new Date().getTime()}])
    }

    useEffect(() => {
        setIsSaveDisabled(!!properties?.filter((item: any) => !item?.name?.length || !item?.type?.length)?.length)
    }, [properties])

    useEffect(() => {
        setProperties(createData?.properties?.length
            ? createData?.properties
            : [{name: '', type: '', id: new Date().getTime()}])
    }, [createData?.properties, isOpen])

    return (
        <ModalLayout
            onClose={onCloseModal}
            isOpen={isOpen}
            maxWidth={windowDimensions?.width < 820 ? windowDimensions?.width - 40 + 'px' : '784px'}
            withCrossIcon
            maxHeight={'90vh'}
        >
            <>
                <p className={styles.title}>Add properties</p>

                <div className={styles.list}>
                    {properties?.map((property: any, key: number) => <div className={styles.property} key={key}>
                        <div className={styles.block}>
                            <p>Type</p>
                            <Input
                                value={property?.type}
                                onChange={(e: any) => onChangeType(e, property?.id)}
                                placeholder={'Background'}
                            />
                        </div>

                        <div className={styles.block}>
                            <p>Name</p>
                            <Input
                                value={property?.name}
                                onChange={(e: any) => onChangeName(e, property?.id)}
                                placeholder={'Blue'}
                            />
                        </div>

                        <div
                            className={cn(styles.delete, styles.delete_visible)}
                            onClick={() => onDelete(property?.id)}>
                            <img src={deleteImg} alt={''}/>
                        </div>
                    </div>)}
                </div>


                <div className={styles.add}>
                    <Button
                        title={'Add property'}
                        disabled={isSaveDisabled || properties?.length === 9}
                        isBlackWhite
                        onClick={onAddProperty}
                    />
                </div>

                <Button title={'Save'} disabled={isSaveDisabled} isYellow onClick={onSave}/>

            </>
        </ModalLayout>
    );
};

export default AddPropertiesModal;

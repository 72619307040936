import React, {useEffect, useState} from 'react';
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import {AVAILABLE_CURRENCY} from "../../../constants/listingPage";
import Button from "../../Button/Button";
import useHandleMarketplace from "../../../hooks/blockchain/useHandleMarketplace";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useHandleToken from "../../../hooks/blockchain/useHandleToken";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import {EVENT_TYPE_OFFER_ACCEPTED} from "../../../constants";
import moment from "moment";
import {useSelector} from "react-redux";
import CollectionApi from "../../../utils/api/CollectionApi";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {formatIPFSUrl} from "../../../utils/blockchain";
import useHandleWeb3 from "../../../hooks/web3/useHandleWeb3";
import cn from "classnames";
import {playAudioCallback, playVideoCallback} from "../../../utils";


type Props = {
    isOpen: boolean,
    onClose: any,
    setIsNewOfferPlaced?: any,
    nftData?: any,
    currentOffer: any,
    setNftData?: any,
    isNewOfferPlaced?: boolean,
    isFromProfile?: boolean,
    updateDataCallback?: any,
    handlePendingAcceptModal?: any,
    handlePendingApproveModal?: any,
    setAcceptTxID: any,
    setApproveTxID: any
}

const AcceptOfferModal = ({
                              isOpen,
                              onClose,
                              setIsNewOfferPlaced,
                              nftData,
                              currentOffer,
                              setNftData,
                              isNewOfferPlaced,
                              isFromProfile,
                              updateDataCallback,
                              handlePendingAcceptModal,
                              handlePendingApproveModal,
                              setAcceptTxID,
                              setApproveTxID
                          }: Props) => {
    const customer = useSelector((state: any) => state.customer.data)
    // const [burnAmount, setBurnAmount] = useState(0)
    const [creatorEarnings, setCreatorEarnings] = useState(0)
    const [isExternalCollection, setIsExternalCollection] = useState(false)
    const [isForDrops, setIsForDrops] = useState(false)
    const [royaltyAmount, setRoyaltyAmount] = useState(0)
    const [royaltyAddress, setRoyaltyAddress] = useState('')

    const serviceFee = 1.8
    const isCreator = nftData?.creator?.toLowerCase() === customer?.address?.toLowerCase()

    const handleMarketplace = useHandleMarketplace()
    const handleToken = useHandleToken()
    const handleActivity = useHandleActivity()
    const windowDimensions = useWindowDimensions()
    const handleWeb3 = useHandleWeb3()

    const onAcceptOffer = async () => {
        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to accept offer'} isError/>)

        if (nftData?.listing?.price > 0 && nftData?.listing?.endDate > moment().unix()) {
            return toast(<ToastMsg text={'You should cancel listing before accepting offer'} isError/>)
        }

        handleMarketplace
            .approveOffer(
                isFromProfile ? currentOffer?.tokenID : nftData?.tokenID,
                isFromProfile ? currentOffer?.contractAddress : nftData?.contractAddress)
            .then(async (res: any) => {
                setApproveTxID(res?.hash)
                onClose()
                handlePendingApproveModal.open()
                const transaction = await res?.wait();

                if (transaction?.status) {
                    toast(<ToastMsg text={'Approve is successful. Accepting offer...'}/>)

                    handlePendingApproveModal.close()

                    await handleMarketplace
                        .onAcceptOffer(
                            isFromProfile ? currentOffer?.tokenID : nftData?.tokenID,
                            isFromProfile ? currentOffer?.offerID : currentOffer?.id,
                            isFromProfile ? currentOffer?.contractAddress : nftData?.contractAddress,
                            0,
                            (!isExternalCollection && !isForDrops))
                        .then(async (res: any) => {
                            setAcceptTxID(res?.hash)
                            handlePendingAcceptModal.open()
                            const transaction = await res?.wait();
                            if (transaction?.status) {
                                const royaltyPrice = BigInt((+currentOffer?.price * royaltyAmount / 100) * 10 ** 18).toString()

                                await handleMarketplace
                                    .approveShibRoyalty(process.env.REACT_APP_OFFERS_ADDRESS, royaltyPrice)
                                    .catch(() => null)

                                await handleMarketplace
                                    .transferRoyaltyOffers(royaltyPrice, royaltyAddress)
                                    .then((tx: any) => console.log('tx --> ', tx))
                                    .catch(() => null)

                                await handleActivity.createActivity({
                                    eventType: EVENT_TYPE_OFFER_ACCEPTED,
                                    tokenID: isFromProfile ? currentOffer?.tokenID : nftData?.tokenID,
                                    contractAddress: isFromProfile ? currentOffer?.contractAddress?.toLowerCase() : nftData?.contractAddress?.toLowerCase(),
                                    name: isFromProfile ? currentOffer?.name : nftData?.name,
                                    nftName: isFromProfile ? currentOffer?.nftName : nftData?.metadata?.name,
                                    isCollectionVerified: isFromProfile ? currentOffer?.isCollectionVerified : nftData?.isCollectionVerified,
                                    imageDOLink: isFromProfile ? currentOffer?.imageDOLink : nftData?.imageDOLink,//done
                                    audioDOLink: isFromProfile ? currentOffer?.audioDOLink : nftData?.audioDOLink,
                                    videoDOLink: isFromProfile ? currentOffer?.videoDOLink : nftData?.videoDOLink,
                                    price: currentOffer?.price,
                                    currency: isFromProfile ? currentOffer?.currency : AVAILABLE_CURRENCY?.find((item: any) =>
                                        item?.address?.toLowerCase() === currentOffer?.tokenAddress?.toLowerCase())?.label,
                                    from: currentOffer?.from?.toLowerCase() || currentOffer?.buyer?.toLowerCase(),
                                    to: isFromProfile ? currentOffer?.to?.toLowerCase() : currentOffer?.owner?.toLowerCase(),
                                    startDate: currentOffer?.startDate,
                                    endDate: currentOffer?.endDate,
                                    transactionTime: moment().unix(),
                                    transactionHash: transaction?.transactionHash,
                                    offerID: isFromProfile ? currentOffer?.offerID : currentOffer?.id
                                })

                                setIsNewOfferPlaced && setIsNewOfferPlaced(!isNewOfferPlaced)

                                !isFromProfile && await handleToken
                                    .checkToken(nftData?.contractAddress, nftData?.tokenID)
                                    .then(res => setNftData(res))

                                await new CollectionApi()
                                    .checkCollection(currentOffer?.contractAddress?.toLowerCase() || nftData?.contractAddress?.toLowerCase())

                                updateDataCallback && updateDataCallback()
                                handlePendingAcceptModal.close()
                                toast(<ToastMsg text={'Offer is successfully accepted!'}/>)
                                onClose()
                            } else {
                                handlePendingAcceptModal.close()
                                onClose()
                                toast(<ToastMsg text={'Failed to accept offer. Try again'} isError/>)
                            }
                        })
                        .catch((err: any) => {
                            console.log('1 --> ', err);
                            if (err?.code !== 'ACTION_REJECTED') {
                                toast(<ToastMsg text={'Failed to accept offer. Try again'} isError/>)
                            }
                            handlePendingAcceptModal.close()
                            onClose()
                            return null
                        })
                        .finally(onClose)
                } else {
                    handlePendingApproveModal.close()
                    toast(<ToastMsg text={'Approve failed. Try again'} isError/>)
                }
            })
            .catch((err: any) => {
                console.log('2 --> ', err);
                if (err?.code !== 'ACTION_REJECTED') {
                    toast(<ToastMsg text={'Approve failed. Try again'} isError/>)
                }
                handlePendingApproveModal.close()
                return null
            })
    }

    useEffect(() => {
        new CollectionApi()
            .getCollectionFees(nftData?.contractAddress)
            .then((res) => {
                setCreatorEarnings(res?.data?.royaltyPercentage ? +res?.data?.royaltyPercentage : 0)
                setIsExternalCollection(res?.data?.isExternalCollection)
                setIsForDrops(res?.data?.forDrops)
                setRoyaltyAmount((res?.data?.isExternalCollection || res?.data?.forDrops) ? +res?.data?.royaltyPercentage : 0)
                setRoyaltyAddress(res?.data?.royaltyAddress)
            })
    }, [])

    return (
        <ModalLayout
            maxWidth={windowDimensions?.width < 840 ? windowDimensions?.width - 40 + 'px' : '800px'}
            isOpen={isOpen}
            onClose={onClose}
            maxHeight={'fit-content'}
            withCrossIcon
        >
            <p className={styles.title}>Accept offer</p>

            <div className={styles.wrapper}>

                <div className={styles.image}>
                    {((nftData?.imageDOLink && !nftData?.imageDOLink?.includes('undefined'))
                        || (currentOffer?.imageDOLink && !currentOffer?.imageDOLink?.includes('undefined')))
                    && <img
                        src={isFromProfile ? formatIPFSUrl(currentOffer?.imageDOLink) : formatIPFSUrl(nftData?.imageDOLink)}
                        alt={''}/>}

                    {((nftData?.videoDOLink && !nftData?.videoDOLink?.includes('undefined'))
                        || (currentOffer?.videoDOLink && !currentOffer?.videoDOLink?.includes('undefined')))
                    && <video //done
                        onPlay={playVideoCallback}
                        id={`modal-${isFromProfile ? currentOffer?.videoDOLink : nftData?.videoDOLink}`}
                        className={'nft_video'}
                        controls
                        src={isFromProfile ? currentOffer?.videoDOLink : nftData?.videoDOLink}
                    />}

                    {((nftData?.audioDOLink && !nftData?.audioDOLink?.includes('undefined'))
                        || (currentOffer?.audioDOLink && !currentOffer?.audioDOLink?.includes('undefined')))
                    && <audio //done
                        id={`modal-${isFromProfile ? currentOffer?.audioDOLink : nftData?.audioDOLink}`}
                        onPlay={playAudioCallback}
                        className={'nft_audio'}
                        controls
                        src={isFromProfile ? currentOffer?.audioDOLink : nftData?.audioDOLink}
                    />}
                </div>

                <div>
                    <p className={styles.id}>{
                        isFromProfile
                            ? (currentOffer?.nftName || 'NFT #' + currentOffer?.tokenID)
                            : (nftData?.metadata?.name || 'NFT #' + nftData?.tokenID)
                    }</p>
                    <p className={styles.collection}>{isFromProfile ? currentOffer?.name : nftData?.name}</p>
                    <p className={styles.price}>
                        Offered
                        price: {currentOffer?.price} {isFromProfile ? currentOffer?.currency : AVAILABLE_CURRENCY?.find(item => item?.address?.toLowerCase() === currentOffer?.tokenAddress?.toLowerCase())?.label}
                    </p>
                    <p className={styles.serviceFee}>Service fee: {serviceFee}%</p>
                    <p className={styles.creatorFee}>Creator earnings: {creatorEarnings}%</p>
                    <p className={styles.earnings}>Your total
                        earnings: <span>
                            {isCreator
                                ? +(currentOffer?.price - (+currentOffer?.price * (serviceFee) / 100))?.toFixed(2)
                                : +(currentOffer?.price - (+currentOffer?.price * (creatorEarnings + serviceFee) / 100))?.toFixed(2)} {isFromProfile ? currentOffer?.currency : AVAILABLE_CURRENCY?.find(item => item?.address?.toLowerCase() === currentOffer?.tokenAddress?.toLowerCase())?.label}
                        </span>
                    </p>
                </div>

            </div>

            <div className={styles.actions}>
                <Button title={'Accept offer'} isYellow onClick={onAcceptOffer}/>
            </div>

        </ModalLayout>
    );
};

export default AcceptOfferModal;

import React from 'react';
import styles from './styles.module.scss'
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import {ACTIVITY_TABLE_HEADERS} from "../../../constants/singleNftPage";

const ActivityBlock = ({nftData, maticToUsd}: { nftData: any, maticToUsd: number}) => {

    return (
        <div className={styles.activityBlock}>
            <p className={styles.title}>Item activity</p>
            {!!nftData?.activity?.length
                ? <div className={styles.table}>
                    <NfTsTable maticToUsd={maticToUsd} data={nftData?.activity} headers={ACTIVITY_TABLE_HEADERS} withLightBorder/>
                </div>
                : <p className={styles.noData}>No item activity yet</p>
            }
        </div>
    );
};

export default ActivityBlock;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_activityBlock__U\\+Gl5 {
  background: #242424;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
}

.styles_title__hL24e {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.35px;
  color: #D9D9D9;
  padding: 24px 32px;
  margin: 0;
  border-bottom: 1px solid #2D2D2D;
}

.styles_table__ELMSl {
  width: 100%;
  overflow-x: scroll;
}
.styles_table__ELMSl td {
  padding-left: 32px;
}
.styles_table__ELMSl::-webkit-scrollbar {
  width: 6px;
  padding: 3px;
  height: 2px;
}
.styles_table__ELMSl::-webkit-scrollbar-track {
  background: transparent;
}
.styles_table__ELMSl::-webkit-scrollbar-thumb {
  background: rgba(121, 125, 133, 0.5);
  border-radius: 120px;
}
.styles_table__ELMSl::-webkit-scrollbar-thumb:hover {
  background: rgba(121, 125, 133, 0.5);
  opacity: 0.5;
}

.styles_noData__a1Phe {
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  letter-spacing: -0.35px;
  color: #FFFFFF;
  text-align: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/templates/SingleNFTPage/ActivityBlock/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,gCAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,UAAA;EACA,YAAA;EACA,WAAA;AAAJ;AAGE;EACE,uBAAA;AADJ;AAIE;EACE,oCAAA;EACA,oBAAA;AAFJ;AAKE;EACE,oCAAA;EACA,YAAA;AAHJ;;AAOA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;AAJF","sourcesContent":[".activityBlock {\n  background: #242424;\n  border-radius: 12px;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.title {\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 25px;\n  letter-spacing: -0.35px;\n  color: #D9D9D9;\n  padding: 24px 32px;\n  margin: 0;\n  border-bottom: 1px solid #2D2D2D;\n}\n\n.table {\n  width: 100%;\n  overflow-x: scroll;\n\n  td{\n    padding-left: 32px;\n  }\n\n  &::-webkit-scrollbar {\n    width: 6px;\n    padding: 3px;\n    height: 2px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: rgba(121, 125, 133, .5);\n    border-radius: 120px;\n  }\n\n  &::-webkit-scrollbar-thumb:hover {\n    background: rgba(121, 125, 133, .5);\n    opacity: 0.5;\n  }\n}\n\n.noData{\n  font-weight: 700;\n  font-size: 21px;\n  line-height: 27px;\n  letter-spacing: -0.35px;\n  color: #FFFFFF;\n  text-align: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activityBlock": `styles_activityBlock__U+Gl5`,
	"title": `styles_title__hL24e`,
	"table": `styles_table__ELMSl`,
	"noData": `styles_noData__a1Phe`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import transferImg from '../../../assets/images/symbols/transfer.svg'
import useHandleMarketplace from "../../../hooks/blockchain/useHandleMarketplace";
import useHandleToken from "../../../hooks/blockchain/useHandleToken";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import {EVENT_TYPE_TRANSFER} from "../../../constants";
import moment from "moment";
import CollectionApi from "../../../utils/api/CollectionApi";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import useHandleWeb3 from "../../../hooks/web3/useHandleWeb3";
import {useSelector} from "react-redux";

type Props = {
    isOpen: boolean,
    onClose: any,
    nftData: any,
    setNftData: any,
    callback?: any,
    handlePendingTransferModal?: any,
    setTransferTxID: any
}

const TransferModal = ({
                           isOpen, onClose, nftData, setNftData, callback = null,
                           handlePendingTransferModal,
                           setTransferTxID
                       }: Props) => {
    const customer = useSelector((state: any) => state.customer.data)

    const [address, setAddress] = useState('')

    const handleMarketplace = useHandleMarketplace()
    const handleToken = useHandleToken()
    const handleActivity = useHandleActivity()
    const windowDimensions = useWindowDimensions()
    const handleWeb3 = useHandleWeb3()

    const onChangeAddress = (e: any) => {
        setAddress(e?.target?.value)
    }

    const onTransfer = async () => {
        if (!address || !nftData?.tokenID) return

        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to transfer NFT'} isError/>)

        handleMarketplace
            .transfer(address, nftData?.tokenID, nftData?.contractAddress)
            .then(async (res: any) => {
                setTransferTxID(res?.hash)
                onClose()
                handlePendingTransferModal.open()

                const transaction = await res?.wait();

                if (transaction?.status) {
                    await handleActivity.createActivity({
                        eventType: EVENT_TYPE_TRANSFER,
                        tokenID: nftData?.tokenID,
                        contractAddress: nftData?.contractAddress?.toLowerCase(),
                        name: nftData?.name,
                        nftName: nftData?.metadata?.name,
                        isCollectionVerified: nftData?.isCollectionVerified,
                        imageDOLink: nftData?.imageDOLink,//done
                        audioDOLink: nftData?.audioDOLink,
                        videoDOLink: nftData?.videoDOLink,
                        from: transaction?.from?.toLowerCase(),
                        to: address?.toLowerCase(),
                        transactionTime: moment().unix(),
                        transactionHash: transaction?.transactionHash,
                    })

                    callback && callback()

                    await handleToken
                        .checkToken(nftData?.contractAddress, nftData?.tokenID)
                        .then(res => setNftData(res))

                    await new CollectionApi().checkCollection(nftData?.contractAddress?.toLowerCase())

                    onClose()
                    handlePendingTransferModal.close()
                    toast(<ToastMsg text={'You have successfully transferred the NFT!'}/>)
                } else {
                    onClose()
                    handlePendingTransferModal.close()
                    toast(<ToastMsg text={'Failed to transfer NFT. Try again'} isError/>)
                }
            })
            .catch((err: any) => {
                onClose()
                handlePendingTransferModal.close()
                if (err?.code !== 'ACTION_REJECTED') {
                    toast(<ToastMsg text={'Failed to transfer NFT. Try again'} isError/>)
                }
            })
            .finally(() => {
                handlePendingTransferModal.close()
                onClose()
                setAddress('')
            })
    }

    return (
        <ModalLayout
            maxWidth={windowDimensions?.width < 640 ? windowDimensions?.width - 40 + 'px' : '600px'}
            isOpen={isOpen}
            onClose={onClose}
            maxHeight={''}
            withCrossIcon
        >
            <p className={styles.title}>Transfer NFT</p>
            <p>Enter the address you would like transfer to</p>
            <Input
                value={address}
                onChange={onChangeAddress}
                image={transferImg}
                withRightBlock
                placeholder={'Enter address'}
            />

            <div className={styles.button}>
                <Button
                    title={'Transfer'}
                    onClick={onTransfer}
                    disabled={!address?.length}
                    isYellow
                />
            </div>

        </ModalLayout>
    );
};

export default TransferModal;

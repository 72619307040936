// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_textarea__ZDllx {
  background: #3E3E3E;
  border-radius: 8px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  min-height: 204px;
  resize: none;
  border: none;
  outline: none;
  padding: 14px 18px;
  box-sizing: border-box;
}
.styles_textarea__ZDllx::placeholder {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #949494;
}`, "",{"version":3,"sources":["webpack://./src/components/TextArea/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;AACF;AACE;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".textarea{\n  background: #3E3E3E;\n  border-radius: 8px;\n  width: 100%;\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 24px;\n  color: #FFFFFF;\n  min-height: 204px;\n  resize: none;\n  border: none;\n  outline: none;\n  padding: 14px 18px;\n  box-sizing: border-box;\n\n  &::placeholder{\n    font-family: 'Open Sans', sans-serif;\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 24px;\n    color: #949494;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `styles_textarea__ZDllx`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useState} from 'react';
import './styles.css'
import Slider from "react-slick";
import NextArrow from "../../../components/SliderArrows/NexArrow/NextArrow";
import PrevArrow from "../../../components/SliderArrows/PrevArrow/PrevArrow";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../../constants";
import badge from '../../../assets/images/symbols/verified-badge_orange.svg'
import shiba from '../../../assets/images/symbols/shiba.svg'
import styles from '../styles.module.scss'
import cn from "classnames";

const SpotlightCollections = ({collectionsData}: { collectionsData: any }) => {
    const [mouseMoved, setMouseMoved] = useState(false);

    const navigate = useNavigate()
    const windowDimensions = useWindowDimensions()

    const isTablet = windowDimensions?.width <= TABLET
    const isMobile = windowDimensions?.width <= MOBILE

    const onClickCollection = (contractAddress: string | undefined) => {
        if (!mouseMoved) {
            navigate(`/collection/${contractAddress}`)
        }
    }

    const settings = {
        dots: true,
        infinite: collectionsData?.length > 3,
        arrows: !isTablet,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        nextArrow: <NextArrow right={'13px'}/>,
        prevArrow: <PrevArrow left={'0px'}/>,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: TABLET,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    cssEase: "linear",
                    centerMode: true,
                    centerPadding: '40px',
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                    speed: 700,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    cssEase: "linear",
                    centerMode: true,
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                    speed: 700,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                    cssEase: "linear",
                    centerMode: true,
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                    speed: 700,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    cssEase: "linear",
                    centerPadding: '20px',
                    centerMode: true,
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                    speed: 700,
                }
            },
        ]
    };

    // @ts-ignore
    return (
        <div className={'SpotlightCollections_slider'}>
            <Slider {...settings}>
                {collectionsData
                    ?.sort((a: any, b: any) => b?.volumeAllTime - a?.volumeAllTime)
                    ?.map((item: any, key: number) =>
                        <div key={key}
                             className={'SpotlightCollections_slider_item'}
                             onMouseMove={() => setMouseMoved(true)}
                             onMouseDown={() => setMouseMoved(false)}
                             onMouseUp={() => onClickCollection(item?.externalLink
                                 ? item?.externalLink?.includes('/')
                                     ? item?.contractAddress
                                     : item?.externalLink
                                 : item?.contractAddress)}>
                            <div className={'SpotlightCollections_slider_item_image'}>
                                <img src={item?.featuredImage} alt={''}/>
                            </div>
                            <div className={'SpotlightCollections_slider_item_desc'}>
                                {isMobile
                                    ? <p className={'SpotlightCollections_slider_item_title'}>
                                        {item?.name?.length > 13
                                            ? item?.name?.slice(0, 13) + '...'
                                            : item?.name}
                                        {item?.isVerified && <img src={badge} alt={''}/>}
                                    </p>
                                    : <p className={'SpotlightCollections_slider_item_title'}>
                                        {item?.name}
                                        {item?.isVerified &&
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12"
                                                 viewBox="0 0 33 33" fill="none">
                                            <path
                                                d="M14.232 4.00546C15.2967 2.36016 17.704 2.36016 18.7688 4.00546C19.4636 5.07922 20.8162 5.51868 22.0094 5.05843C23.8379 4.35318 25.7855 5.76815 25.6798 7.72506C25.6108 9.00218 26.4467 10.1527 27.6826 10.4818C29.5764 10.986 30.3203 13.2754 29.0846 14.7965C28.2781 15.7891 28.2781 17.2113 29.0846 18.2039C30.3203 19.725 29.5764 22.0145 27.6826 22.5186C26.4467 22.8477 25.6108 23.9982 25.6798 25.2753C25.7855 27.2323 23.8379 28.6472 22.0094 27.942C20.8162 27.4817 19.4636 27.9212 18.7688 28.9949C17.704 30.6403 15.2967 30.6403 14.232 28.9949C13.5371 27.9212 12.1846 27.4817 10.9913 27.942C9.16283 28.6472 7.2153 27.2323 7.321 25.2753C7.38998 23.9982 6.55407 22.8477 5.31814 22.5186C3.42434 22.0145 2.68045 19.725 3.9162 18.2039C4.72268 17.2113 4.72268 15.7891 3.9162 14.7965C2.68045 13.2754 3.42434 10.986 5.31814 10.4818C6.55407 10.1527 7.38998 9.00218 7.321 7.72506C7.2153 5.76815 9.16283 4.35318 10.9913 5.05843C12.1846 5.51868 13.5371 5.07922 14.232 4.00546Z"
                                                fill="#F79C00"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M22.5404 12.7929C22.931 13.1834 22.931 13.8166 22.5404 14.2071L15.8738 20.8738C15.4832 21.2643 14.8501 21.2643 14.4596 20.8738L11.7929 18.2071C11.4024 17.8166 11.4024 17.1834 11.7929 16.7929C12.1834 16.4024 12.8166 16.4024 13.2071 16.7929L15.1667 18.7525L21.1262 12.7929C21.5168 12.4024 22.1499 12.4024 22.5404 12.7929Z"
                                                  fill="white"/>
                                            </svg>
                                        </span>
                                        }
                                    </p>}

                                {!!item?.currentListingsFloorPrice && <p className={styles.floor}>
                                    <span>Floor</span>
                                    <span>{(item?.currentListingsFloorPrice)?.toLocaleString("en-US", {maximumFractionDigits: 2})} SHIB</span>
                                </p>}
                                {/*{isMobile*/}
                                {/*    ? <p className={'SpotlightCollections_slider_item_text'}>{*/}
                                {/*        item?.description?.length > 30*/}
                                {/*            ? item?.description?.slice(0, 30) + '...'*/}
                                {/*            : item?.description*/}
                                {/*    }</p>*/}
                                {/*    : <p className={'SpotlightCollections_slider_item_text'}>{*/}
                                {/*        item?.description?.length > 80*/}
                                {/*            ? item?.description?.slice(0, 80) + '...'*/}
                                {/*            : item?.description*/}
                                {/*    }</p>}*/}
                            </div>
                        </div>)}
            </Slider>
        </div>
    );
};

export default SpotlightCollections;

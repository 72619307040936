import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";

type Props = {
    placeholder?: string,
    value: any,
    onChange: any,
    text?: string,
    image?: string,
    withRightBlock?: boolean,
    onlyWithArrow?: boolean,
    onFocus?: any,
    onBlur?: any,
    inputRef?: any,
    type?: string,
    onClickWrapper?: any,
    disabled?: boolean,
    min?: any,
    max?: any,
    isOpen?: boolean,
    onClickText?: any,
    onClickInput?: any,
    withoutCaret?: boolean,
    withoutDecimals?: boolean
}

const Input = ({
                   placeholder,
                   value,
                   onChange,
                   text,
                   image,
                   withRightBlock,
                   onlyWithArrow,
                   onFocus,
                   onBlur,
                   inputRef,
                   type = 'text',
                   onClickWrapper = null,
                   onClickText = null,
                   onClickInput = null,
                   disabled,
                   min = null,
                   max = null,
                   isOpen = false,
                   withoutCaret = false,
                   withoutDecimals = false
               }: Props) => {
    const isWindows = window.navigator.userAgent.search('Windows') > -1

    return (
        <div className={cn(styles.input_wrapper, withoutCaret && styles.caret)} ref={inputRef} onClick={onClickWrapper}>
            <input
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                type={type}
                disabled={disabled}
                min={min}
                max={max}
                onClick={onClickInput}
                inputMode={type === 'number'
                    ? withoutDecimals
                        ? 'numeric'
                        : 'decimal'
                    : 'text'}
            />
            {withRightBlock && <div className={styles.rightBlock} onClick={onClickText}>
                <span className={isWindows ? styles.text : ''}>{!!text && text}</span>
                {!!image && <img className={cn(styles.arrow, isOpen && styles.arrow_open)} src={image} alt={''}/>}
            </div>}
            {onlyWithArrow && <div className={styles.onlyWithArrow}>{!!image &&
            <img className={cn(styles.arrow, isOpen && styles.arrow_open)} src={image} alt={''}/>}</div>}
        </div>
    );
};

export default Input;

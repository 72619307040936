import React, {MouseEventHandler} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";

type Props = {
    title: string,
    onClick?: MouseEventHandler<HTMLDivElement> | undefined,
    isYellow?: boolean,
    isBlackWhite?: boolean,
    image?: string | null,
    disabled?: boolean,
    isFullWidth?: boolean
};

const Button = ({
                    title,
                    onClick,
                    isYellow,
                    isBlackWhite,
                    image = null,
                    disabled,
                    isFullWidth = false
                }: Props) => {
    return (
        <div
            className={cn(styles.button,
                isYellow && styles.button_yellow,
                isBlackWhite && styles.button_isBlackWhite,
                disabled && styles.button_disabled,
                isFullWidth && styles.button_isFullWidth
            )}
            onClick={onClick}>
            {image && <img src={image} alt={''}/>}
            {title}
        </div>
    );
};

export default Button;

import React, {useEffect, useRef, useState} from 'react';
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./styles.module.scss";
import logo from '../../assets/images/logoPlaceholder.svg'
import deleteImg from '../../assets/images/symbols/delete.svg'
import featured from '../../assets/images/featuredImgPlaceholder.svg'
import banner from '../../assets/images/bannerPlaceholder.svg'
import Input from "../../components/Input/Input";
import TextArea from "../../components/TextArea/TextArea";
import cn from "classnames";
import CategoryDropdown from "./CategoryDropdown/CategoryDropdown";
import Button from "../../components/Button/Button";
import NsfWcontent from "../CreatePage/NSFWcontent/NSFWcontent";
import PaymentTokensDropdown from "./PaymentTokensDropdown/PaymentTokensDropdown";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../constants";
import {toast} from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import useHandleWeb3 from "../../hooks/web3/useHandleWeb3";
import useHandleMarketplace from "../../hooks/blockchain/useHandleMarketplace";
import FileApi from "../../utils/api/FileApi";
import CollectionApi from "../../utils/api/CollectionApi";
import {useNavigate} from "react-router-dom";
import useHandleLoader from "../../hooks/loader/useHandleLoader";
import {useSelector} from "react-redux";

const CreateCollectionPage = () => {
    const [createData, setCreateData] = useState<any>({
        socials: [{link: '', id: 0}],
        paymentTokens: [],
        symbol: 'PAWZAAR'
    })
    const [isSubmitDisable, setIsSubmitDisable] = useState(true)
    const [isValidURL, setIsValidURL] = useState(false)
    const [timerID, setTimerID] = useState<any>(0)
    const customer = useSelector((state: any) => state.customer.data)

    const logoRef = useRef<HTMLInputElement>(null)
    const featuredRef = useRef<HTMLInputElement>(null)
    const bannerRef = useRef<HTMLInputElement>(null)
    const handleMarketplace = useHandleMarketplace()
    const handleWeb3 = useHandleWeb3()
    const windowDimensions = useWindowDimensions()
    const navigate = useNavigate()
    const handleLoader = useHandleLoader()

    const isMobile = windowDimensions?.width <= MOBILE

    const onUploadLogo = (event: any) => {
        if (!event?.target?.files[0]) return
        if (event?.target?.files[0]?.size > 100000000) return toast(<ToastMsg
            text={'You can not upload file over 100 MB'} isError/>)
        const reader = new FileReader();
        reader?.readAsDataURL(event?.target?.files[0]);
        reader.onload = function () {
            setCreateData({...createData, logo: reader.result, logoFile: event?.target?.files[0]})
        };
    }

    const onUploadFeaturedImg = (event: any) => {
        if (!event?.target?.files[0]) return
        if (event?.target?.files[0]?.size > 100000000) return toast(<ToastMsg
            text={'You can not upload file over 100 MB'} isError/>)
        const reader = new FileReader();
        reader?.readAsDataURL(event?.target?.files[0]);
        reader.onload = function () {
            setCreateData({...createData, featuredImg: reader.result, featuredImgFile: event?.target?.files[0]})
        };
    }

    const onUploadBanner = (event: any) => {
        if (!event?.target?.files[0]) return
        if (event?.target?.files[0]?.size > 100000000) return toast(<ToastMsg
            text={'You can not upload file over 100 MB'} isError/>)
        const reader = new FileReader();
        reader?.readAsDataURL(event?.target?.files[0]);
        reader.onload = function () {
            setCreateData({...createData, banner: reader.result, bannerFile: event?.target?.files[0]})
        };
    }

    const onChangeSocials = (e: any, id: number) => setCreateData({
        ...createData,
        socials: createData?.socials?.map((item: any) => item?.id === id
            ? {...item, link: e?.target?.value}
            : item)
    })

    const onDeleteSocialLink = (id: number) => {
        setCreateData({
            ...createData,
            socials: createData?.socials?.filter((item: any) => item?.id !== id)
        })
    }

    const onAddLink = () => {
        if (createData?.socials?.length === 4) return toast(<ToastMsg text={'You can add only 4 links'} isError/>)

        if (!!createData?.socials?.filter((item: any) => !item?.link?.trim()?.length)?.length) return
        setCreateData({
            ...createData,
            socials: [...createData?.socials, {link: '', id: new Date()?.getTime()}]
        })
    }

    const onDeletePaymentToken = (token: any) => {
        const data = createData?.paymentTokens?.filter((item: any) => item?.id !== token?.id)
        setCreateData({...createData, paymentTokens: data})
    }

    const onChangeName = (e: any) => {
        if (e?.target?.value?.length > 32) return toast(<ToastMsg text={'Name is too long'} isError/>)
        setCreateData({...createData, name: e?.target?.value})
    }

    const onChangeDesc = (e: any) => {
        if (e?.target?.value?.length > 500) return toast(<ToastMsg text={'Description is too long'} isError/>)
        setCreateData({...createData, description: e?.target?.value})
    }
    const onChangeUrl = (e: any) => {
        if (e?.target?.value?.length > 32) return toast(<ToastMsg text={'URL is too long'} isError/>)
        if (e?.target?.value?.includes('/')
            || e?.target?.value?.includes(':')
            || e?.target?.value?.includes('&')
            || e?.target?.value?.includes('$')
            || e?.target?.value?.includes('+')
            || e?.target?.value?.includes(' ')
            || e?.target?.value?.includes('=')) return toast(<ToastMsg
            text={'URL can not contain spaces or symbols / : & = $ +'} isError/>)

        setCreateData({...createData, externalLink: e?.target?.value})
    }
    const onChangeRoyaltyPercentage = (e: any) => {
        if (+e?.target?.value > 10) return toast(<ToastMsg text={'Royalty can not be greater than 10%'} isError/>)

        e?.target?.value >= 0 && setCreateData({
            ...createData,
            royaltyPercentage: e?.target?.value
        })
    }

    // const onChangeMaxLevel = (e: any) => setCreateData({...createData, maxLevel: e?.target?.value})
    // const onChangeMaxStats = (e: any) => setCreateData({...createData, maxStats: e?.target?.value})

    const validateData = () => {
        if (!createData?.logoFile) return 'Collection should have logo image'
        if (!createData?.featuredImgFile) return 'Collection should have featured image'
        if (!createData?.bannerFile) return 'Collection should have banner image'
        if (!createData?.name?.length) return 'Collection should have name'
        if (!createData?.externalLink?.length) return 'Collection should have URL'
        if (!createData?.category?.length) return 'Collection should have category'
        if (!createData?.royaltyPercentage) return 'Collection should have defined royalty fee'
        if (!createData?.paymentTokens?.length) return 'Collection should have defined payment tokens'
        if (!isValidURL) return 'This URL already exists.'

        // if (!createData?.maxLevel) return 'Enter the max possible level for NFTs in this collection'
        // if (!createData?.maxStats) return 'Enter the max possible stats value for NFTs in this collection'

        if (createData?.royaltyPercentage > 10) return 'Royalty fee cannot exceed 10%'

        return null
    }

    const onCreateCollection = async () => {
        const error = validateData()
        if (!!error?.length) return toast(<ToastMsg text={error} isError/>)
        if (!customer?._id) return toast(<ToastMsg text={'You should log in to create collection'} isError/>)

        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to create collection'} isError/>)

        await handleLoader.loaderWrapper(async () => {
            await new CollectionApi()
                .checkUniqueUrl(createData?.externalLink)
                .then(async (res) => {
                    if (res?.data?.length)
                        return toast(<ToastMsg text={'This collection URL already exists'} isError/>)
                    else {
                        const deployContractData: any = await handleMarketplace
                            .deployContract(createData?.name,
                                createData?.symbol,
                                createData?.royaltyPercentage,
                                0)
                        if (!deployContractData) return toast(<ToastMsg text={'Failed to deploy contract'} isError/>)

                        const logoFormData = new FormData();
                        logoFormData.append("file", createData?.logoFile);
                        const logoLink = await new FileApi().saveFileDO(logoFormData)
                        if (!logoLink?.status) return toast(<ToastMsg text={'Failed to upload logo'} isError/>)

                        const featuredFormData = new FormData();
                        featuredFormData.append("file", createData?.featuredImgFile);
                        const featuredLink = await new FileApi().saveFileDO(featuredFormData)
                        if (!featuredLink?.status) return toast(<ToastMsg text={'Failed to upload featured image'}
                                                                          isError/>)

                        const bannerFormData = new FormData();
                        bannerFormData.append("file", createData?.bannerFile);
                        const bannerLink = await new FileApi().saveFileDO(bannerFormData)
                        if (!bannerLink?.status) return toast(<ToastMsg text={'Failed to upload banner'} isError/>)

                        const network = await handleWeb3.getNetwork()

                        await new CollectionApi()
                            .createContract({
                                ...createData,
                                socials: createData?.socials?.filter((item: any) => !!item?.link?.length),
                                bannerImage: bannerLink?.data,
                                featuredImage: featuredLink?.data,
                                logoImage: logoLink?.data,
                                provider: network,
                                transactionHash: deployContractData.transactionHash,
                                nonce: deployContractData.nonce,
                                contractAddress: deployContractData.contractAddress?.toLowerCase(),
                                externalLink: createData?.externalLink?.toLowerCase()
                            })
                            .then((res: any) => {
                                if (res?.status) {
                                    toast(<ToastMsg text={'Collection is successfully created'}/>)
                                    navigate(`/collections/${customer?.userName || customer?.address}`)//done
                                    setCreateData(null)
                                } else toast(<ToastMsg text={'Failed to create collection. Try again'} isError/>)
                            })
                            .catch(() => null);

                    }
                })
        }, 3, true)
    }

    const checkCollectionURL = () => {
        if (createData?.externalLink?.length) {
            new CollectionApi()
                .checkUniqueUrl(createData?.externalLink?.trim()?.toLowerCase())
                .then((res) => setIsValidURL(!res?.data?.length))
        }
    }

    useEffect(() => {
        const error = validateData()
        setIsSubmitDisable(!!error?.length)
    }, [createData])

    useEffect(() => {
        clearTimeout(timerID)
        const id = setTimeout(checkCollectionURL, 1000)
        setTimerID(id)
    }, [createData?.externalLink])

    return (
        <MainLayout>
            <div className={styles.createPage}>
                <p className={styles.title}>Create collection</p>

                <p className={styles.block_title}>Logo image</p>
                <p className={styles.subtitle}>Face of the collection. Recommended dimensions are 350x350 px</p>
                <div className={styles.logo} onClick={() => logoRef?.current?.click()}>
                    <input
                        type={'file'}
                        hidden
                        ref={logoRef}
                        onChange={onUploadLogo}
                        accept="image/*"
                    />
                    <img src={createData.logo || logo} alt={''}/>
                    <div className={styles.hoverEffectLogo}/>
                </div>

                <p className={styles.block_title}>Featured image</p>
                <p className={styles.subtitle}>Displayed image on explore page. Recommended dimensions are 512x272
                    px</p>
                <div className={cn(styles.featured, createData.featuredImg && styles.featured_full)}
                     onClick={() => featuredRef?.current?.click()}>
                    <input
                        type={'file'}
                        hidden
                        ref={featuredRef}
                        onChange={onUploadFeaturedImg}
                        accept="image/*"
                    />
                    <img src={createData.featuredImg || featured} alt={''}/>
                    <div className={styles.hoverEffectFeatured}/>
                </div>

                <p className={styles.block_title}>Banner image</p>
                <p className={styles.subtitle}>Enhances visual appeal and personalization. Recommended dimensions are
                    1920x392 px</p>
                <div className={cn(styles.banner, createData.banner && styles.banner_full)}
                     onClick={() => bannerRef?.current?.click()}>
                    <input
                        type={'file'}
                        hidden
                        ref={bannerRef}
                        onChange={onUploadBanner}
                        accept="image/*"
                    />
                    <img src={createData.banner || banner} alt={''}/>
                    <div className={styles.hoverEffectBanner}/>
                </div>

                <p className={cn(styles.block_title, styles.margin)}>Name</p>
                <Input value={createData?.name} onChange={onChangeName} placeholder={'Collection name'}/>

                <p className={cn(styles.block_title, styles.margin)}>URL</p>
                <Input value={createData?.externalLink} onChange={onChangeUrl}
                       placeholder={'your-unique-link'}/>
                {!!createData?.externalLink?.length
                && <p className={cn(styles.validation, isValidURL && styles.validation_isValid)}>
                    {isValidURL
                        ? 'This URL is available'
                        : 'This URL already exists'}
                </p>}

                <p className={cn(styles.block_title, styles.margin)}>Description<span>(Optional)</span></p>
                <TextArea value={createData?.description} onChange={onChangeDesc} placeholder={'Item description'}/>

                <p className={cn(styles.block_title, styles.margin)}>Category</p>
                <p className={styles.subtitle}>Define your collection</p>
                <CategoryDropdown createData={createData} setCreateData={setCreateData}/>

                <p className={cn(styles.block_title, styles.margin)}>Links<span>(Optional)</span></p>
                <div className={styles.socials}>
                    {createData?.socials?.map((item: any, key: number) => <div className={styles.socials_item}>
                            <Input
                                key={key}
                                value={item?.link}
                                onChange={(e: any) => onChangeSocials(e, item?.id)}
                                placeholder={'Website/Twitter/Discord/Telegram'}
                            />
                            {createData?.socials?.length > 1 && <div
                                className={styles.delete}
                                onClick={() => onDeleteSocialLink(item?.id)}>
                                <img src={deleteImg} alt={''}/>
                            </div>}
                        </div>
                    )}
                    <div className={styles.socials_button}>
                        <Button
                            isFullWidth
                            title={'Add link'}
                            disabled={!!createData?.socials?.filter((item: any) => !item?.link?.trim()?.length)?.length || createData?.socials?.length === 4}
                            onClick={onAddLink}
                            isBlackWhite
                        />
                    </div>
                </div>

                {/*<p className={cn(styles.block_title, styles.margin)}>Max level</p>*/}
                {/*<div className={styles.fee}>*/}
                {/*    <p className={styles.subtitle}>Max level that NFT of this collection will have</p>*/}
                {/*    <Input value={createData?.maxLevel} type={'number'} onChange={onChangeMaxLevel}*/}
                {/*           placeholder={'Max Level'}/>*/}
                {/*</div>*/}

                {/*<p className={cn(styles.block_title, styles.margin)}>Max stats value</p>*/}
                {/*<div className={styles.fee}>*/}
                {/*    <p className={styles.subtitle}>Max stats value that NFT of this collection will have</p>*/}
                {/*    <Input value={createData?.maxStats} type={'number'} onChange={onChangeMaxStats}*/}
                {/*           placeholder={'Max Stats'}/>*/}
                {/*</div>*/}

                <p className={cn(styles.block_title, styles.margin)}>Royalties</p>
                <div className={styles.fee}>
                    <p className={styles.subtitle}>Fee percentage (not more than 10%)</p>
                    <Input value={createData?.royaltyPercentage} type={'number'} onChange={onChangeRoyaltyPercentage}
                           placeholder={'Fee percentage'}/>
                </div>

                {/*<p className={cn(styles.block_title, styles.margin)}>Burn amount (not more than 100%)</p>*/}
                {/*<div className={styles.fee}>*/}
                {/*    <p className={styles.subtitle}>% of sale sent to SHIB burn address (not more than 100%)</p>*/}
                {/*    <Input value={createData?.burnPercentage} type={'number'} onChange={onChangeBurn}*/}
                {/*           placeholder={'Burn percentage'}/>*/}
                {/*</div>*/}

                <p className={cn(styles.block_title, styles.margin)}>Payment tokens</p>
                <p className={styles.subtitle}>Currency of choice</p>
                <PaymentTokensDropdown createData={createData} setCreateData={setCreateData}/>
                {!!createData?.paymentTokens?.length && <div className={styles.paymentTokens}>
                    {isMobile
                        ? createData?.paymentTokens
                            ?.map((item: any, key: number) => <div key={key}
                                                                   className={styles.paymentTokens_item_wrapper}>
                                <div className={styles.paymentTokens_item}>
                                    <span>{item?.id}</span>
                                    <span>{item?.chain}</span>
                                </div>
                                <div className={styles.delete} onClick={() => onDeletePaymentToken(item)}>
                                    <img src={deleteImg} alt={''}/>
                                </div>
                            </div>)
                        : createData?.paymentTokens
                            ?.map((item: any, key: number) => <div key={key} className={styles.paymentTokens_item}>
                                <span>{item?.id}</span>
                                <span>{item?.chain}</span>
                            </div>)
                    }
                </div>}

                <NsfWcontent setCreateData={setCreateData} createData={createData} noBorder/>

                <Button title={'Create collection'} disabled={isSubmitDisable} isYellow onClick={onCreateCollection}/>

            </div>
        </MainLayout>
    );
};

export default CreateCollectionPage;

import React, {useEffect, useState} from 'react';
import arrow from '../../../assets/images/symbols/arrow_right.svg'
import badge from '../../../assets/images/symbols/verified-badge_orange.svg'//done
import styles from './styles.module.scss'
import cn from "classnames";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {
    STATS_TIME_1H,
    STATS_TIME_24H,
    STATS_TIME_30D,
    STATS_TIME_7D,
    STATS_TIME_ALL_TIME
} from "../../../constants/statsPage";
import starOrange from "../../../assets/images/symbols/star_orange.svg";
import starGray from "../../../assets/images/symbols/star_gray.svg";
import {useSelector} from "react-redux";
import CollectionApi from "../../../utils/api/CollectionApi";
import useHandleAuth from "../../../hooks/auth/useHandleAuth";
import formatNumber from "../../../utils";

type Props = {
    collection: any,
    activeSubTab: any,
    rank: number,
    handleSignUpModal: any
}

const StatsMobileItem = ({collection, activeSubTab, rank, handleSignUpModal}: Props) => {
    const customer = useSelector((state: any) => state.customer.data)

    const [isOpen, setIsOpen] = useState(false)
    const [isFavourite, setIsFavourite] = useState(false)
    const [sales, setSales] = useState(0)
    const [volume, setVolume] = useState(0)

    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width <= MOBILE
    const navigate = useNavigate()
    const handleAuth = useHandleAuth()

    const onClickStar = async () => {
        if (!customer?._id) return handleAuth.connectWallet(handleSignUpModal.open)

        setIsFavourite(!isFavourite)
        await new CollectionApi().setLikes(collection?.contractAddress)
    }
    const onClickRow = () => navigate(`/collection/${collection?.externalLink
        ? collection?.externalLink?.includes('/')
            ? collection?.contractAddress
            : collection?.externalLink
        : collection?.contractAddress}`)//done
    const onToggleOpen = () => setIsOpen(!isOpen)

    useEffect(() => {
        if (activeSubTab === STATS_TIME_1H) {
            setSales(collection?.sales1H)
            setVolume(collection?.volume1H)
        } else if (activeSubTab === STATS_TIME_24H) {
            setSales(collection?.sales24H)
            setVolume(collection?.volume24H)
        } else if (activeSubTab === STATS_TIME_7D) {
            setSales(collection?.sales7D)
            setVolume(collection?.volume7D)
        } else if (activeSubTab === STATS_TIME_30D) {
            setSales(collection?.sales30D)
            setVolume(collection?.volume30D)
        } else if (activeSubTab === STATS_TIME_ALL_TIME) {
            setSales(collection?.salesAllTime)
            setVolume(collection?.volumeAllTime)
        }

        setIsFavourite(collection?.likes?.includes(customer?._id))
    }, [activeSubTab, collection?.contractAddress, customer?._id])

    return (
        <div className={styles.activityTabItem}>

            <div className={styles.main_row}>
                <div className={styles.activityTabItem_left}>

                    <div className={styles.rank}>{rank}</div>

                    <div className={styles.activityTabItem_info}>
                        <img src={collection?.logoImage} onClick={onClickRow} alt={''}
                             className={styles.collection_image}/>
                        <div>
                            <p className={styles.collection_name} onClick={onClickRow}>{collection?.name}
                                {collection?.isVerified && <img src={badge} alt={''}/>}
                            </p>
                            {isMobile &&
                            <p className={cn(styles.more, isOpen && styles.more_isOpen)} onClick={onToggleOpen}>
                                {isOpen ? 'Less' : 'More'}
                                <img src={arrow} alt={''} width={4}/>
                            </p>}
                        </div>
                    </div>
                </div>

                <div className={styles.activityTabItem_right}>
                    <div>
                        <p className={styles.price}>Volume: {formatNumber(volume)} SHIB</p>
                        <p className={styles.percent}>Floor: {formatNumber(collection?.currentListingsFloorPrice)} SHIB</p>
                    </div>

                    <div onClick={onClickStar} className={styles.star}>
                        <img src={isFavourite ? starOrange : starGray} alt={''}/>
                    </div>
                </div>
            </div>

            {isOpen && <div className={styles.bottom_row}>
                <div className={styles.bottom_row_item}>
                    <span className={styles.bottom_row_item_header}>Sales</span>
                    <span className={styles.bottom_row_item_text}>{sales}</span>
                </div>

                <div className={styles.bottom_row_item}>
                    <span className={styles.bottom_row_item_header}>Unique owners</span>
                    <span
                        className={styles.bottom_row_item_from}>{collection?.uniqueOwners || 0}</span>
                </div>

                <div className={styles.bottom_row_item}>
                    <span className={styles.bottom_row_item_header}>Items listed</span>
                    <span
                        className={styles.bottom_row_item_from}>{collection?.itemsListed} of {collection?.totalTokensCount} ({+((collection?.itemsListed * 100 / collection?.totalTokensCount) || 0)?.toFixed(2)?.toString() || 0}%)</span>
                </div>

            </div>}

        </div>
    );
};

export default StatsMobileItem;

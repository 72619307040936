import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import MainLayout from "../../layouts/MainLayout/MainLayout";
import banner from '../../assets/images/cover-image-collection.png'
import {
    COLLECTION_PAGE_TABS,
    COLLECTION_TAB_ACTIVITY,
    COLLECTION_TAB_NFTS
} from "../../constants/singleCollection";
import badge from '../../assets/images/symbols/verified-badge_orange.svg'
import cn from "classnames";
import NfTsTab from "./NFTsTab/NFTsTab";
import ActivityTab from "./ActivityTab/ActivityTab";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../constants";
import CollectionApi from "../../utils/api/CollectionApi";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {AVAILABLE_CATEGORIES} from "../../constants/myCollectionsPage";
import shiba from "../../assets/images/symbols/shiba.svg";
import uploadImg from "../../assets/images/symbols/link.svg";
import CustomerApi from "../../utils/api/CustomerApi";
import useHandleContracts from "../../hooks/blockchain/useHandleContracts";
import {setSocialsImage} from "../../utils/socials";
import ethescanImg from '../../assets/images/symbols/etherscan.svg'
import formatNumber from "../../utils";
import Tooltip from "../../components/Tooltip/Tooltip";
import {toast} from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import {useSelector} from "react-redux";
import FileApi from "../../utils/api/FileApi";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import FullPageLoader from "../../components/Loaders/FullPageLoader";


const addressesForPunks = ['0xa77c8bcc7b683440447ee59cc8d73d0cf4e8ef50', '0xe103768ff7315b5313a6334643eb6399871a197c'];

const SingleCollectionPage = () => {
        const customer = useSelector((state: any) => state.customer.data)

        const [isOpenedFilters, setIsOpenedFilters] = useState(false)
        const [isCardView, setIsCardView] = useState(true)
        const [isSmallView, setIsSmallView] = useState(false)
        const [activeTab, setActiveTab] = useState(COLLECTION_TAB_NFTS)
        const [collectionData, setCollectionData] = useState<any>(null)
        const [collectionStats, setCollectionStats] = useState<any>([])
        const [creatorAddress, setCreatorAddress] = useState('')
        const [createTime, setCreateTime] = useState('')
        const [isCopied, setIsCopied] = useState(false)
        const [isEditMode, setIsEditMode] = useState(false)
        const [logoImage, setLogoImage] = useState('')
        const [bannerImage, setBannerImage] = useState('')
        const [externalLink, setExternalLink] = useState('')
        const [isValidURL, setIsValidURL] = useState(false)
        const [timerID, setTimerID] = useState<any>(0)
        const [isMoreOpen, setIsMoreOpen] = useState(false)

        const windowDimensions = useWindowDimensions()
        const params: any = useParams()
        const navigate = useNavigate()
        const location = useLocation()
        const handleContracts = useHandleContracts()
        const copyRef: any = useRef<HTMLInputElement>(null)
        const bannerRef = useRef<HTMLInputElement>(null)
        const logoRef = useRef<HTMLInputElement>(null)
        const externalLinkRef = useRef<HTMLInputElement>(null)

        const isTablet = windowDimensions?.width <= 1300 && windowDimensions?.width > MOBILE
        const isMobile = windowDimensions?.width <= MOBILE

        const onClickTab = (value: number) => setActiveTab(value)
        const onClickCreator = () => navigate(`/${creatorAddress}/collected`)
        // const onToggleNSFW = () => setIsNsfwON(!isNsfwON)
        const onReadMore = () => setIsMoreOpen(!isMoreOpen)

        const onClickShare = () => {
            if (isCopied) return

            navigator.clipboard.writeText(copyRef?.current?.value).then(() => {
                setIsCopied(true)
                toast(<ToastMsg text={'Copied to clipboard!'}/>)
                setTimeout(() => setIsCopied(false), 3000)
            })
        }

        const uploadBannerImage = (event: any) => {
            if (!event?.target?.files[0]) return
            if (event?.target?.files[0]?.size > 100000000) return toast(<ToastMsg
                text={'You can not upload file over 100 MB'} isError/>)
            const reader: any = new FileReader();
            reader?.readAsDataURL(event?.target?.files[0]);
            reader.onload = function () {
                if (event?.target?.files[0]?.type?.includes('image')) {
                    setBannerImage(reader.result)

                    const formData = new FormData();
                    formData.append("file", event?.target?.files[0]);

                    return new FileApi().saveFileDO(formData).then(async (res: any) => {
                        await new CollectionApi().editCollection({
                            contractAddress: collectionData?.contractAddress?.toLowerCase(),
                            bannerImage: res?.data
                        }).then((res) => {
                            if (res) toast(<ToastMsg text={'Banner image is successfully edited'}/>)
                            else toast(<ToastMsg text={'Failed to edit banner image'} isError/>)
                        })
                    })
                } else toast(<ToastMsg text={'You can upload only image'} isError/>)
            };
        }

        const uploadLogoImage = (event: any) => {
            if (!event?.target?.files[0]) return
            if (event?.target?.files[0]?.size > 100000000) return toast(<ToastMsg
                text={'You can not upload file over 100 MB'} isError/>)
            const reader: any = new FileReader();
            reader?.readAsDataURL(event?.target?.files[0]);
            reader.onload = function () {
                if (event?.target?.files[0]?.type?.includes('image')) {
                    setLogoImage(reader.result)

                    const formData = new FormData();
                    formData.append("file", event?.target?.files[0]);

                    return new FileApi().saveFileDO(formData).then(async (res: any) => {
                        await new CollectionApi().editCollection({
                            contractAddress: collectionData?.contractAddress?.toLowerCase(),
                            logoImage: res?.data
                        }).then((res) => {
                            if (res) toast(<ToastMsg text={'Logo image is successfully edited'}/>)
                            else toast(<ToastMsg text={'Failed to edit logo image'} isError/>)
                        })
                    })
                } else toast(<ToastMsg text={'You can upload only image'} isError/>)
            };
        }

        const onChangeExternalLink = (e: any) => {
            if (e?.target?.value?.includes('/')
                || e?.target?.value?.includes(':')
                || e?.target?.value?.includes('&')
                || e?.target?.value?.includes('$')
                || e?.target?.value?.includes('+')
                || e?.target?.value?.includes(' ')
                || e?.target?.value?.includes('=')) return toast(<ToastMsg
                text={'Collection URL can not contain spaces or symbols / : & = $ +'} isError/>)
            setExternalLink(e?.target?.value)
        }

        const onSaveExternalLink = async () => {
            if (isEditMode && !!collectionData && externalLink !== collectionData?.externalLink) {
                await new CollectionApi()
                    .checkUniqueUrl(externalLink)
                    .then(async (res) => {
                        if (res?.data?.length)
                            return toast(<ToastMsg text={'This collection URL already exists'} isError/>)
                        else {
                            await new CollectionApi().editCollection({
                                contractAddress: collectionData?.contractAddress?.toLowerCase(),
                                externalLink: externalLink?.toLowerCase()
                            }).then((res) => {
                                if (res) {
                                    toast(<ToastMsg text={'Collection URL is successfully edited'}/>)
                                    const newLink = location?.pathname?.split('/')[0]
                                        + '/' + location?.pathname?.split('/')[1]
                                        + '/' + externalLink?.toLowerCase()
                                    navigate(newLink)
                                } else toast(<ToastMsg text={'Failed to edit Collection URL'} isError/>)
                            })
                        }
                    })
            }
        }

        const checkURL = () => {
            if (externalLink?.length) {
                new CollectionApi()
                    .checkUniqueUrl(externalLink?.trim()?.toLowerCase())
                    .then((res) => setIsValidURL(!res?.data?.length || externalLink?.trim() === collectionData?.externalLink?.toLowerCase()))
            }
        }

        const descLength = () => {
            if (windowDimensions?.width > 1025) return 150
            else if (windowDimensions?.width <= 1025 && windowDimensions?.width > 820) return 130
            else if (windowDimensions?.width <= 820) return 70
            else return 150
        }

        useEffect(() => {
            clearTimeout(timerID)
            const id = setTimeout(checkURL, 1000)
            setTimerID(id)
        }, [externalLink])

        useEffect(() => {
            new CollectionApi()
                .getCollectionByAddress(params?.contractAddress)
                .then(async (res1: any) => {
                    // await new CollectionApi()
                    //     .checkCollection(res1?.data?.contractAddress?.toLowerCase())
                    //     .then(async (res: any) => {
                    setCollectionData(res1?.data)
                    setCollectionStats(
                        [
                            {value: res1?.data?.volumeAllTime || 0, title: 'Total Volume', image: shiba},
                            {value: res1?.data?.currentListingsFloorPrice || 0, title: 'Floor', image: shiba},
                            {value: res1?.data?.totalTokensCount || 0, title: 'Items'},
                            {value: res1?.data?.uniqueOwners || 0, title: 'Owners'},
                        ]
                    )
                    setBannerImage(res1?.data?.bannerImage)
                    setLogoImage(res1?.data?.logoImage)
                    setExternalLink(res1?.data?.externalLink)
                    setIsEditMode(!res1?.data?.activitiesCount && res1?.data?.creator?.toLowerCase() === customer?._id?.toLowerCase())

                    if (res1?.data?.isExternalCollection && res1?.data?.deployedBlockNumber) {
                        const time = await handleContracts.getBlockTimestamp(res1?.data?.deployedBlockNumber)
                        setCreateTime(time
                            ? moment.unix(time).format('D MMM YYYY')
                            : moment(res1?.data?.createdAt).format('D MMM YYYY')
                        )
                    } else setCreateTime(moment(res1?.data?.createdAt).format('D MMM YYYY'))

                    if (res1?.data?.creator) {
                        await new CustomerApi().getName(res1?.data?.creator).then((getNameRes: any) => {
                            setCreatorAddress(getNameRes?.data?.userName || getNameRes?.data?.address)
                        })
                    }
                    // })
                })
                .catch(() => null)

        }, [params?.contractAddress, customer?._id])

        useEffect(() => {
            setIsOpenedFilters(windowDimensions?.width > TABLET)
            setIsSmallView(isMobile)
            setIsCardView(true)
        }, [windowDimensions?.width, isTablet, isMobile])

        useOutsideClick(externalLinkRef, onSaveExternalLink)

        return (
            <MainLayout isFullWidth>
                {!collectionData
                    ? <FullPageLoader isLoaderActive={true}/>
                    : <>
                        <div
                            className={cn(styles.banner, isEditMode && styles.banner_edit)}
                            onClick={() => isEditMode ? bannerRef?.current?.click() : null}
                        >
                            <img src={bannerImage || banner} alt={''}/>
                            {isEditMode && <input
                                type={'file'}
                                hidden
                                ref={bannerRef}
                                onChange={uploadBannerImage}
                                accept="image/*"
                            />}
                        </div>

                        <div className={styles.wrapper}>

                            {(isTablet || isMobile) && <div className={styles.collection_actions}>
                                <div className={styles.socials_item} onClick={onClickShare}>
                                    <img src={uploadImg} alt={''}/>
                                    <input
                                        value={`${process.env.REACT_APP_LINK}/collection/${collectionData?.externalLink
                                            ? collectionData?.externalLink?.includes('/')
                                                ? collectionData?.contractAddress
                                                : collectionData?.externalLink
                                            : collectionData?.contractAddress}`}//done
                                        ref={copyRef}
                                        hidden
                                        onChange={() => {
                                        }}/>
                                </div>
                                {/*<div className={styles.socials_item}><img src={dots} alt={''}/></div>*/}
                            </div>}

                            {!isTablet && !isMobile && <div className={styles.socials}>
                                {collectionData?.socials?.map((item: any, key: number) => <a
                                    key={key}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item?.link?.includes('https://') ? item?.link : 'https://' + item?.link}
                                    className={styles.socials_item}
                                >
                                    <img src={setSocialsImage(item?.link)} alt={''}/>
                                </a>)}

                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://www.shibariumscan.io/address/${collectionData?.contractAddress}`}
                                    className={styles.socials_item}
                                >
                                    <img src={ethescanImg} alt={''}/>
                                </a>

                                <div className={styles.socials_separator}/>
                                <div className={styles.socials_item} onClick={onClickShare}>
                                    <img src={uploadImg} alt={''}/>
                                    <input
                                        value={`${process.env.REACT_APP_LINK}/collection/${collectionData?.externalLink
                                            ? collectionData?.externalLink?.includes('/')
                                                ? collectionData?.contractAddress
                                                : collectionData?.externalLink
                                            : collectionData?.contractAddress}`}//done
                                        ref={copyRef}
                                        hidden
                                        onChange={() => {
                                        }}/>
                                </div>
                                {/*<div className={styles.socials_item}><img src={dots} alt={''}/></div>*/}
                            </div>}

                            <div className={styles.collection}>
                                <div
                                    className={cn(styles.collection_img, isEditMode && styles.banner_edit)}
                                    onClick={() => isEditMode ? logoRef?.current?.click() : null}
                                >
                                    <img src={logoImage} alt={''}/>
                                    {isEditMode && <input
                                        type={'file'}
                                        hidden
                                        ref={logoRef}
                                        onChange={uploadLogoImage}
                                        accept="image/*"
                                    />}
                                </div>

                                <p className={styles.collection_name}>
                                    {collectionData?.name}
                                    {collectionData?.isVerified && <img src={badge} alt={''} width={27} height={27}/>}
                                </p>

                                {isEditMode && <input
                                    ref={externalLinkRef}
                                    value={externalLink}
                                    className={styles.externalLink}
                                    placeholder={'Add unique collection URL...'}
                                    onChange={onChangeExternalLink}
                                />}

                                {(isEditMode
                                    && externalLink?.trim()?.toLowerCase() !== collectionData?.externalLink?.toLowerCase()
                                    && !!externalLink?.trim()?.length)
                                && <p className={cn(styles.validation, isValidURL && styles.validation_isValid)}>
                                    {isValidURL
                                        ? 'This URL is available'
                                        : 'This URL already exists'}
                                </p>}

                                {isMobile &&
                                <p className={styles.collection_characteristics_item} onClick={onClickCreator}>
                                    <span>By</span> {creatorAddress?.length > 20 ? creatorAddress?.slice(0, 10) + '...' : creatorAddress}
                                </p>}
                                {/*<p className={styles.collection_more}>Read more</p>*/}

                                {(isTablet || isMobile) && <>
                                    <div className={styles.collection_characteristics}>
                                        {!isMobile && <p onClick={onClickCreator} className={styles.creator}>
                                            <span>By</span> {creatorAddress?.length > 20 ? creatorAddress?.slice(0, 10) + '...' : creatorAddress}
                                        </p>}
                                        <p><span>Created</span> {createTime}</p>
                                        <p><span>Creator royalty</span> {collectionData?.royaltyPercentage}%</p>
                                        <p>
                                            <span>Category</span> {AVAILABLE_CATEGORIES?.find(item => item?.value === collectionData?.category)?.name || '-'}
                                        </p>
                                    </div>

                                    <p className={styles.collection_desc}>{
                                        (collectionData?.description?.length > descLength() && !isMoreOpen)
                                            ? collectionData?.description?.slice(0, descLength()) + '...'
                                            : collectionData?.description
                                    }</p>
                                    {collectionData?.description?.length > descLength() &&
                                    <p onClick={onReadMore} className={styles.collection_more}>
                                        {isMoreOpen ? 'Read less' : 'Read more'}
                                    </p>}
                                </>}

                                {(isTablet || isMobile) && <div className={styles.stats}>
                                    {collectionStats?.slice(0, 2)?.map((item: any, key: number) =>
                                        <Tooltip key={key} isVisibleOnMobile
                                                 text={(item?.value)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                                            <div key={key}>
                                                <p className={styles.stats_value}>
                                                    {formatNumber(+item?.value?.toFixed(2))}
                                                    {!!item?.image && <img src={item?.image} alt={''}/>}
                                                </p>
                                                <p className={styles.stats_title}>{item?.title}</p>
                                            </div>
                                        </Tooltip>
                                    )}
                                    {collectionStats?.slice(2, 4)?.map((item: any, key: number) => <div key={key}>
                                            <p className={styles.stats_value}>
                                                {formatNumber(+item?.value)}
                                                {!!item?.image && <img src={item?.image} alt={''}/>}
                                            </p>
                                            <p className={styles.stats_title}>{item?.title}</p>
                                        </div>
                                    )}
                                </div>}
                            </div>


                            {!isTablet && !isMobile && <div className={styles.stats}>
                                {collectionStats?.slice(0, 2)?.map((item: any, key: number) =>
                                    <Tooltip key={key}
                                             text={(item?.value)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                                        <div>
                                            <p className={styles.stats_value}>
                                                {formatNumber(+item?.value?.toFixed(2))}
                                                {!!item?.image && <img src={item?.image} alt={''}/>}
                                            </p>
                                            <p className={styles.stats_title}>{item?.title}</p>
                                        </div>
                                    </Tooltip>)}
                                {collectionStats?.slice(2, 4)?.map((item: any, key: number) => <div key={key}>
                                        <p className={styles.stats_value}>
                                            {formatNumber(+item?.value)}
                                            {!!item?.image && <img src={item?.image} alt={''}/>}
                                        </p>
                                        <p className={styles.stats_title}>{item?.title}</p>
                                    </div>
                                )}
                            </div>}

                        </div>

                        {!isTablet && !isMobile && <>
                            <div className={styles.collection_characteristics}>
                                <p onClick={onClickCreator} className={styles.creator}>
                                    <span>By</span> {creatorAddress?.length > 20 ? creatorAddress?.slice(0, 10) + '...' : creatorAddress}
                                </p>
                                <p><span>Created</span> {createTime}</p>
                                <p><span>Creator royalty</span> {collectionData?.royaltyPercentage}%</p>
                                <p>
                                    <span>Category</span> {AVAILABLE_CATEGORIES?.find(item => item?.value === collectionData?.category)?.name || '-'}
                                </p>
                            </div>

                            <p className={styles.collection_desc}>{
                                (collectionData?.description?.length > descLength() && !isMoreOpen)
                                    ? collectionData?.description?.slice(0, descLength()) + '...'
                                    : collectionData?.description
                            }</p>
                            {collectionData?.description?.length > descLength() &&
                            <p onClick={onReadMore} className={styles.collection_more}>
                                {isMoreOpen ? 'Read less' : 'Read more'}
                            </p>}
                        </>}


                        <div className={styles.tabs}>
                            {COLLECTION_PAGE_TABS?.map((item, key) => <div
                                key={key}
                                onClick={() => onClickTab(item?.value)}
                                className={cn(styles.tabs_item, activeTab === item?.value && styles.tabs_item_active)}
                            >
                                {item?.title}
                            </div>)}

                            {collectionData?.isNsfWcontent &&
                            <div className={cn(styles.NSFW, styles.NSFW_active)}>NSFW</div>}
                        </div>

                        {activeTab === COLLECTION_TAB_NFTS &&
                        <NfTsTab
                            collectionData={collectionData}
                            isOpenedFilters={isOpenedFilters}
                            setIsOpenedFilters={setIsOpenedFilters}
                            isCardView={isCardView}
                            setIsCardView={setIsCardView}
                            isSmallView={isSmallView}
                            setIsSmallView={setIsSmallView}
                            isMoreOpen={isMoreOpen}
                        />}
                        {activeTab === COLLECTION_TAB_ACTIVITY &&
                        <ActivityTab
                            collectionData={collectionData}
                            contractAddress={collectionData?.contractAddress}
                            isOpenedFilters={isOpenedFilters}
                            setIsOpenedFilters={setIsOpenedFilters}
                            isMoreOpen={isMoreOpen}
                        />}
                    </>}
            </MainLayout>
        );
    }
;

export default SingleCollectionPage;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import Checkbox from "../../../components/Checkbox/Checkbox";
import arrow from '../../../assets/images/symbols/arrow.svg'
import searchImg from '../../../assets/images/symbols/search.svg'
import cn from "classnames";
import CollectionApi from "../../../utils/api/CollectionApi";

type Props = {
    isOpenedFilters: boolean,
    isActivityTab?: boolean,
    isListingsTab?: boolean,
    isOffersTab?: boolean,
    setFilters?: (a: any) => void,
    filters?: any,
    nftsList?: any,
    selectedCollection?: any,
    setSelectedCollection?: any
}

const FiltersBlock = ({
                          isOpenedFilters,
                          isActivityTab,
                          isListingsTab,
                          isOffersTab,
                          setFilters,
                          filters,
                          nftsList,
                          selectedCollection,
                          setSelectedCollection
                      }: Props) => {
    const [isStatusOpen, setIsStatusOpen] = useState(true)
    const [isCollectionOpen, setIsCollectionOpen] = useState(true)
    const [collectionsData, setCollectionsData] = useState([])
    const [collectionsQuery, setCollectionsQuery] = useState<any>('')
    const [filteredCollections, setFilteredCollections] = useState([])

    const onToggleStatus = () => setIsStatusOpen(!isStatusOpen)
    const onToggleCollection = () => setIsCollectionOpen(!isCollectionOpen)
    const onChangeQuery = (e: any) => setCollectionsQuery(e?.target?.value)
    const onClickCollection = (collection: string) => setSelectedCollection(collection)
    const resetCollections = () => setSelectedCollection(null)
    const onToggleActivityFilters = (id: number) => setFilters && setFilters([...filters?.map((item: any) => item?.id === id ? {
        ...item,
        status: !item?.status
    } : item)])

    useEffect(() => {
        if (nftsList?.length) {
            const uniqueAddresses: any = Array.from(new Set(nftsList?.map((item: any) => item?.contractAddress?.toLowerCase())))

            new CollectionApi()
                .getCollectionsByAddresses(uniqueAddresses)
                .then((res) => {
                    setCollectionsData(res)
                    setFilteredCollections(res)
                })
        }
    }, [nftsList])

    useEffect(() => {
        if (collectionsQuery?.length) {
            setFilteredCollections(collectionsData
                ?.filter((item: any) =>
                    item?.name?.toLowerCase()?.startsWith(collectionsQuery?.trim()?.toLowerCase())
                )
            )
        } else setFilteredCollections(collectionsData)
    }, [collectionsQuery])

    return (
        <div className={cn(styles.filtersBlock, isOpenedFilters && styles.filtersBlock_open)}>

            {!isOffersTab && <div className={cn(styles.status_block,
                isStatusOpen && styles.status_block__opened,
                (isStatusOpen && isActivityTab) && styles.status_block__opened_isActivityTab,
                (isStatusOpen && isListingsTab) && styles.status_block__opened_isListingsTab,)}
            >
                <div className={styles.title} onClick={onToggleStatus}>Status
                    <img src={arrow} alt={''}
                         className={cn(styles.title_arrow, isStatusOpen && styles.title_arrow_opened)}/>
                </div>
                <div className={cn(styles.status_list, isStatusOpen && styles.status_list_opened)}>
                    {!!filters?.length && <>{filters?.map((item: any, key: number) =>
                        <div className={styles.filter_row} key={key}>
                            <Checkbox
                                key={key}
                                setIsChecked={() => null}
                                isChecked={item?.status}
                                title={item?.title}
                                onClickCallback={() => onToggleActivityFilters(item?.id)}
                            />
                            <span className={styles.amount}>{item?.amount}</span>
                        </div>
                    )}
                    </>}
                </div>
            </div>}

            <div className={cn(styles.collection_block,
                !isStatusOpen && styles.collection_block_statusClosed,
                (isStatusOpen && isActivityTab) && styles.collection_block_isActivityTab,
                (isStatusOpen && isListingsTab) && styles.collection_block_isListingsTab,
                (isStatusOpen && isOffersTab) && styles.collection_block_isOffersTab,)}
            >
                <div className={cn(styles.title, styles.collection_title)} onClick={onToggleCollection}>Collection
                    <img src={arrow} alt={''}
                         className={cn(styles.title_arrow, isCollectionOpen && styles.title_arrow_opened)}/>
                </div>

                {isCollectionOpen && <div className={styles.search}>
                    <img src={searchImg} alt={''}/>
                    <input placeholder={'Search'} value={collectionsQuery} onChange={onChangeQuery}/>
                </div>}

                <div className={cn(styles.collection_list, isCollectionOpen && styles.collection_list_opened)}>

                    {!!selectedCollection &&
                    <div className={cn(styles.collection_list_item, styles.reset)} onClick={resetCollections}>
                        Reset
                    </div>}

                    {filteredCollections?.map((item: any, key) =>
                        <div
                            key={key}
                            className={cn(styles.collection_list_item, selectedCollection === item?.contractAddress?.toLowerCase() && styles.collection_list_item_selected)}
                            onClick={() => onClickCollection(item?.contractAddress?.toLowerCase())}
                        >
                            <div className={styles.collection_list_item_name}>
                                <img src={item?.logoImage} alt={''}/>
                                {item?.name?.length > 15
                                    ? item?.name?.slice(0, 15) + '...'
                                    : item?.name}
                            </div>
                            <span className={styles.collection_list_item_amount}>{item?.totalTokensCount}</span>
                        </div>)}
                </div>
            </div>

        </div>
    );
};

export default FiltersBlock;

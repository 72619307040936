import axios from "axios";
import {localStorageGet} from "../localStorage";

export default class Base {
    private readonly url: string;

    constructor() {
        const BASE_URL = process.env.REACT_APP_API || window.location.origin;
        this.url = BASE_URL + "/api";
    }

    getToken() {
        return localStorageGet("token", "");
    }

    getAuthHeader() {
        return {headers: {Authorization: `Bearer ${this.getToken()}`}};
    }

    getUrl(path: string) {
        return `${this.url}/${path}`;
    }

    errorCatch(error: any) {
        return {status: false, message: error?.response?.data?.message};
    }

    get(url: string, config?: any) {
        return axios
            .get(this.getUrl(url), {...this.getAuthHeader(), ...config})
            .then((res) => res.data)
            .catch(this.errorCatch);
    }

    post(url: string, data?: any, config?: any) {
        return axios
            .post(this.getUrl(url), data, {...this.getAuthHeader(), ...config})
            .then((res) => res.data)
            .catch((error) => ({status: false, message: error}));
    }

    put(url: string, data: any, config?: any) {
        return axios
            .put(this.getUrl(url), data, {...this.getAuthHeader(), ...config})
            .then((res) => res.data)
            .catch((error) => ({status: false, message: error}));
    }

    delete(url: string, data: any, config?: any) {
        return axios
            .delete(this.getUrl(url), {...this.getAuthHeader(), ...config})
            .then((res) => res.data)
            .catch((error) => ({status: false, message: error}));
    }

    postThirdParty(url: string, data: any, config: any) {
        return axios
            .post(url, data, config)
            .then((res) => res.data)
            .catch((error) => ({status: false, message: error}));
    }

    getThirdParty(url: string, data: any, config: any) {
        return axios
            .get(url, data)
            .then((res) => res.data)
            .catch((error) => ({status: false, message: error}));
    }
}

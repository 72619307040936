// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_dateInput__KtHl2 {
  width: 100%;
  position: relative;
}

.styles_inputWrapper__sq3Oz {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.styles_inputWrapper__sq3Oz input {
  cursor: pointer;
}

.styles_date_note__Cbel9 {
  position: absolute;
  top: 14px;
  left: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #949494;
}
.styles_date_note__Cbel9 span {
  color: white;
}

.styles_calendar__74dZ1 {
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 2;
  background: #3E3E3E;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
}

.styles_time_wrapper__FjviU {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin: 16px 0;
}`, "",{"version":3,"sources":["webpack://./src/templates/ListNFTPage/DateInput/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;AAEE;EACE,YAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,cAAA;AADF","sourcesContent":[".dateInput {\n  width: 100%;\n  position: relative;\n}\n\n.inputWrapper {\n  width: 100%;\n  position: relative;\n  cursor: pointer;\n\n  input {\n    cursor: pointer;\n  }\n}\n\n.date_note {\n  position: absolute;\n  top: 14px;\n  left: 18px;\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 24px;\n  color: #949494;\n\n  span {\n    color: white;\n  }\n}\n\n.calendar {\n  position: absolute;\n  top: 60px;\n  right: 0;\n  z-index: 2;\n  background: #3E3E3E;\n  border-radius: 8px;\n  padding: 16px;\n  box-sizing: border-box;\n}\n\n.time_wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 16px;\n  margin: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateInput": `styles_dateInput__KtHl2`,
	"inputWrapper": `styles_inputWrapper__sq3Oz`,
	"date_note": `styles_date_note__Cbel9`,
	"calendar": `styles_calendar__74dZ1`,
	"time_wrapper": `styles_time_wrapper__FjviU`
};
export default ___CSS_LOADER_EXPORT___;

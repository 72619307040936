import {METADATA_CATEGORY_LEVEL, METADATA_CATEGORY_PROPERTY, METADATA_CATEGORY_STATS} from "../constants";

export const hexToNumber = (hexString: any) => parseInt(hexString, 16);
export const hexDateToUnixNumber = (hexString: any) => parseInt(hexString, 18);
export const numberToHex = (num: number) => num.toString(16);
export const convertBigIntToNumber = (value: any) => parseInt(value) / Math.pow(10, 18);
export const formatIPFSUrl = (tokenURI: string) => tokenURI?.includes("https://")
    ? tokenURI
    : "https://ipfs.io/ipfs/" + tokenURI?.substring(7);

export const getItemMetadata = (data: any, mainFile: string, audioImg: any) => {
    return {
        name: data.name,
        description: data.description,
        image: data?.image
            ? mainFile
            : data?.audio
                ? audioImg
                : null,
        audio: data?.audio ? mainFile : null,
        video: data?.video ? mainFile : null,
        attributes: [
            ...data.properties.map((item: any) => ({
                trait_type: item.type?.trim(),
                value: item.name?.trim(),
                category: METADATA_CATEGORY_PROPERTY
            })),
            ...data.levels.map((item: any) => ({
                display_type: "number",
                trait_type: item.type?.trim(),
                value: +item.level,
                max_value: +item?.maxLevel || 1000,
                category: METADATA_CATEGORY_LEVEL
            })),
            ...data.stats.map((item: any) => ({
                display_type: "number",
                trait_type: item.type?.trim(),
                value: +item.name,
                max_value: +item?.maxStats || 1000,
                category: METADATA_CATEGORY_STATS
            })),
        ],
        unlockableContent: data?.isUnlockableContent?.status ? data?.isUnlockableContent?.text : null,
        isNsfWcontent: data?.isNsfWcontent,
        socials: data?.socials
    };
};

export const summarizeNFTAttributes = (nftArr: any) => {
    const totalAttributes = nftArr.reduce((acc: any, currentValue: any) => [...acc, ...currentValue?.attributes], []);

    let result: any = {};

    totalAttributes.forEach((item: any) => {
        if (!result[item?.trait_type]) {
            result = {...result, [item?.trait_type]: {}};
        }

        if (!result[item?.trait_type][item?.value]) {
            result[item?.trait_type] = {...result[item?.trait_type], [item?.value]: 1};
        } else {
            result[item?.trait_type][item?.value] = result[item?.trait_type][item?.value] + 1;
        }
    })

    return result

}

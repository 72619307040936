import React, {useEffect} from 'react';
import styles from './styles.module.scss'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import cn from "classnames";
import useFirstLoadEffect from "../../hooks/useFirstLoadEffect";
import {useSelector} from "react-redux";
import FullPageLoader from "../../components/Loaders/FullPageLoader";

type Props = {
    children: JSX.Element,
    isFullWidth?: boolean
};

const MainLayout = ({children, isFullWidth}: Props) => {
    const loader = useSelector((state: any) => state.loader)

    useFirstLoadEffect()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.mainLayout}>
            <FullPageLoader isLoaderActive={loader?.isActive} opacityLevel={loader?.opacityLevel} withImage={loader?.withImage}/>

            <Header/>
            <div className={cn(styles.wrapper, isFullWidth && styles.wrapper_isFullWidth)}>
                {children}
            </div>
            <Footer/>
        </div>
    );
};

export default MainLayout;

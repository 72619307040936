import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";

type Props = {
    title?: string,
    setIsChecked: (a: boolean) => void,
    isChecked: boolean,
    onClickCallback?: () => void | undefined,
    withoutTitle?: boolean,
    notCentered?: boolean,
    isFullWidth?: boolean
};

const Checkbox = ({
                      title,
                      setIsChecked,
                      isChecked,
                      onClickCallback = undefined,
                      withoutTitle = false,
                      notCentered = false,
                      isFullWidth= false
                  }: Props) => {

    const onToggleChecked = () => {
        if (onClickCallback) {
            onClickCallback()
            if (isChecked) return
        }
        setIsChecked(!isChecked)
    }

    return (
        <div className={cn(styles.checkbox, notCentered && styles.checkbox_notCentered, isFullWidth && styles.checkbox_isFullWidth)} onClick={onToggleChecked}>
            <div className={cn(styles.checkbox_wrapper, isChecked && styles.checkbox_wrapper_checked)}>
                <input type="checkbox" checked={isChecked} onChange={onToggleChecked}/>
            </div>
            {!withoutTitle && <span>{title}</span>}
        </div>
    );
};

export default Checkbox;

import discordImg from '../assets/images/symbols/discord.svg'
import discordImgGray from '../assets/images/symbols/discord_gray.svg'
import twitterImg from '../assets/images/symbols/twitter.svg'
import twitterImgGray from '../assets/images/symbols/twitter_gray.svg'
import globusImg from '../assets/images/symbols/globus.svg'
import globusImgGray from '../assets/images/symbols/globus_gray.svg'
import instaImg from '../assets/images/symbols/insta.svg'
import instaImgGray from '../assets/images/symbols/insta_gray.svg'
import telegramImg from "../assets/images/symbols/telegram.svg";
import telegramImgGray from "../assets/images/symbols/telegram_gray.svg";
import youtubeImg from "../assets/images/symbols/youtube.svg";
import youtubeImgGray from "../assets/images/symbols/youtube_gray.svg";
import tiktokImg from "../assets/images/symbols/tiktok.svg";
import tiktokImgGray from "../assets/images/symbols/tiktok_gray.svg";
import redditImg from "../assets/images/symbols/reddit.svg";
import redditImgGray from "../assets/images/symbols/reddit_gray.svg";
import threadsImg from "../assets/images/symbols/threads.svg";
import threadsImgGray from "../assets/images/symbols/threads_gray.svg";

export const setSocialsImage = (link: string, isGray = false) => {
    if (link?.includes('twitter')) return isGray ? twitterImgGray : twitterImg
    else if (link?.includes('discord')) return isGray ? discordImgGray : discordImg
    else if (link?.includes('threads')) return isGray ? threadsImgGray : threadsImg
    else if (link?.includes('instagram')) return isGray ? instaImgGray : instaImg
    else if (link?.includes('t.me')) return isGray ? telegramImgGray : telegramImg
    else if (link?.includes('youtube.com')) return isGray ? youtubeImgGray : youtubeImg
    else if (link?.includes('tiktok.com')) return isGray ? tiktokImgGray : tiktokImg
    else if (link?.includes('reddit.com')) return isGray ? redditImgGray : redditImg
    else return isGray ? globusImgGray : globusImg
}

import React, {useEffect} from 'react';
import styles from './styles.module.scss'
import {customStyles} from './custumModalStyles'
import Modal from "react-modal";
import cross from '../../assets/images/symbols/cross.svg'
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../constants";

type Props = {
    isOpen: boolean,
    onClose: any,
    maxWidth: string,
    maxHeight?: string,
    children: any,
    withCrossIcon?: boolean,
    isLaunchpad?: boolean
}

const ModalLayout = ({
                         isOpen,
                         onClose,
                         maxWidth,
                         maxHeight,
                         children,
                         withCrossIcon,
                         isLaunchpad = false
                     }: Props) => {
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width < MOBILE

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={(e: any) => {
                e?.stopPropagation()
                onClose()
            }}
            style={{
                // @ts-ignore
                ...customStyles, content: {
                    ...customStyles?.content,
                    maxWidth: maxWidth,
                    maxHeight: maxHeight,
                    padding: isLaunchpad
                        ? '0'
                        : isMobile
                            ? '24px 16px'
                            : '24px 32px',
                }
            }}>
            <div className={styles.wrapper} onClick={(e) => e?.stopPropagation()}>
                {withCrossIcon &&
                <div onClick={onClose} className={styles.cross}>
                    <img src={cross} alt={''}/>
                </div>}
                {children}
            </div>
        </Modal>
    );
};

export default ModalLayout;


import Web3 from "web3";
import TransactionApi from "../../utils/api/TransactionApi";
import useHandleContracts from "../blockchain/useHandleContracts";
import {LEASH_ADDRESS, SHIB_ADDRESS, WBONE_ADDRESS} from "../../constants";
import {useSelector} from "react-redux";

export default function useHandleWeb3() {
    const handleContracts = useHandleContracts()
    const customer = useSelector((state: any) => state.customer.data)

    const add = async (data: any) => {
        const network = await getNetwork()

        return new TransactionApi()
            .add({...data, provider: network})
            .then((res) => res?.status)
            .catch(() => null);
    };

    const checkNonce = async (nonce: any) => {
        const network = await getNetwork()

        return new TransactionApi()
            .checkNonce(nonce, network)
            .then((res: any) => res?.data)
            .catch(() => null)
    };

    const loadWeb3 = async (accountsChangedCallback: any) => {
        try {
            // @ts-ignore
            if (window.ethereum) {
                // @ts-ignore
                window.web3 = new Web3(window.ethereum);
                // @ts-ignore
                window.ethereum.on("accountsChanged", () => accountsChangedCallback());
                // @ts-ignore
                window.ethereum.on("chainChanged", () => accountsChangedCallback());
                // @ts-ignore
            } else if (window.web3) {
                // @ts-ignore
                window.web3 = new Web3(window.web3.currentProvider);
            } else {
                console.warn("Non ethereum!");
            }
        } catch (err: any) {
            console.warn(`Ethereum err: ${err?.message}`);
        }
    };

    const isAddress = (address: string) => {
        // @ts-ignore
        const web3 = window?.web3;
        return web3.utils.isAddress(address);
    };

    const getAccount = async () => {
        // @ts-ignore
        const web3 = window?.web3;
        const accounts = await web3?.eth?.getAccounts().catch(() => null);
        if (!accounts?.length) return;

        return accounts[0];
    };

    const getNonce = async (account: string) => {
        // @ts-ignore
        const web3 = window?.web3;

        const nonce = await web3?.eth?.getTransactionCount(account, "latest");
        const nonceChecked = await checkNonce(nonce);
        return nonceChecked?.toString();
    };

    const getNetwork = async () => {
        // @ts-ignore
        const web3 = window?.web3;
        return web3?.eth?.getChainId();
    };

    const getGasPrice = async () => {
        // @ts-ignore
        const web3 = window?.web3;
        return web3.eth.getGasPrice();
    };

    const getBalance = (address: string) => {
        // @ts-ignore
        const web3 = window?.web3;
        return web3?.eth?.getBalance(address)
    }

    const getBalanceOfShib = async () => {
        return await handleContracts.contractERC20WithSigner(SHIB_ADDRESS).balanceOf(customer?.address)
    }

    const getBalanceOfLeash = async () => {
        return await handleContracts.contractERC20WithSigner(LEASH_ADDRESS).balanceOf(customer?.address)
    }

    const getBalanceOfWbone = async () => {
        return await handleContracts.contractERC20WithSigner(WBONE_ADDRESS).balanceOf(customer?.address)
    }

    return {
        loadWeb3,
        isAddress,
        getAccount,
        getNonce,
        getNetwork,
        getGasPrice,
        add,
        checkNonce,
        getBalance,
        getBalanceOfShib,
        getBalanceOfLeash,
        getBalanceOfWbone
    };
}

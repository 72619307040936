import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import {OFFERS_TABLE_HEADERS} from "../../../constants/singleNftPage";
import useHandleMarketplace from "../../../hooks/blockchain/useHandleMarketplace";
import moment from "moment";
import OffersTable from "./OffersTable/OffersTable";
import MakeOfferModal from "../../../components/Modals/MakeOfferModal/MakeOfferModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import AcceptOfferModal from "../../../components/Modals/AcceptOfferModal/AcceptOfferModal";
import {useSelector} from "react-redux";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import {EVENT_TYPE_OFFER_CANCELED} from "../../../constants";
import {AVAILABLE_CURRENCY} from "../../../constants/listingPage";
import useHandleToken from "../../../hooks/blockchain/useHandleToken";
import CollectionApi from "../../../utils/api/CollectionApi";
import TransactionPendingModal from "../../../components/Modals/TransactionPendingModal/TransactionPendingModal";
import {toast} from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import Button from "../../../components/Button/Button";
import useHandleWeb3 from "../../../hooks/web3/useHandleWeb3";

const OffersBlock = ({
                         nftData,
                         isNewOfferPlaced,
                         setIsNewOfferPlaced,
                         setNftData,
                         maticToUsd
                     }: { nftData: any, maticToUsd: number, isNewOfferPlaced: boolean, setIsNewOfferPlaced: any, setNftData: any }) => {
    const customer = useSelector((state: any) => state.customer.data)

    const [offersLength, setOffersLength] = useState<any>(0)
    const [offersList, setOffersList] = useState<any>([])

    const [offersLengthOLD, setOffersLengthOLD] = useState<any>(0)
    const [offersListOLD, setOffersListOLD] = useState<any>([])
    const [currentOffer, setCurrentOffer] = useState<any>(null)

    const [offerTxID, setOfferTxID] = useState<any>('')
    const [cancelTxID, setCancelTxID] = useState<any>('')
    const [acceptTxID, setAcceptTxID] = useState<any>('')
    const [approveTxID, setApproveTxID] = useState<any>('')
    const [approveShibOfferTxID, setApproveShibOfferTxID] = useState<any>('')

    const handleMarketplace = useHandleMarketplace()
    const handleEditOfferModal = useHandleModal()
    const handleAcceptOfferModal = useHandleModal()
    const handleActivity = useHandleActivity()
    const handleToken = useHandleToken()
    const handleWeb3 = useHandleWeb3()

    const handlePendingOfferModal = useHandleModal()
    const handlePendingCancelModal = useHandleModal()
    const handlePendingAcceptModal = useHandleModal()
    const handlePendingApproveModal = useHandleModal()
    const handlePendingApproveShibOfferModal = useHandleModal()

    const getOffersData = async () => {
        setOffersList([])

        if (offersLength) {
            let newOffersList: any = []

            // @ts-ignore
            if (window?.ethereum) {
                for (let i = 0; i < offersLength; i++) {

                    const offerInfoResponse = await handleMarketplace
                        .checkOffer(nftData?.tokenID, i, nftData?.contractAddress)

                    newOffersList = [...newOffersList, offerInfoResponse]
                }
            } else newOffersList = nftData?.offers

            setOffersList(newOffersList
                    ?.filter((item: any) => (item?.price > 0 && item?.endDate >= moment().unix()))
                    ?.filter((item: any) => item?.from?.toLowerCase() === customer?.address?.toLowerCase()
                        ? item
                        : item?.startDate <= moment().unix())
                // ?.filter((item: any) => !(item?.from?.toLowerCase() === nftData?.owner?.toLowerCase()
                //     && item?.from?.toLowerCase() === customer?.address?.toLowerCase())
                // )
            )
        }
    }

    const getOffersDataOLD = async () => {
        setOffersListOLD([])

        if (offersLengthOLD) {
            let newOffersList: any = []

            // @ts-ignore
            if (window?.ethereum) {
                for (let i = 0; i <= offersLengthOLD; i++) {

                    const offerInfoResponse = await handleMarketplace
                        .checkOfferOld(nftData?.tokenID, i, nftData?.contractAddress)

                    newOffersList = [...newOffersList, offerInfoResponse]
                }
            }

            setOffersListOLD(newOffersList
                ?.filter((item: any) => (item?.price > 0))
                ?.filter((item: any) => item?.from?.toLowerCase() === customer?.address?.toLowerCase()
                    ? item
                    : item?.startDate <= moment().unix())
                ?.filter((item: any) => !(item?.from?.toLowerCase() === nftData?.owner?.toLowerCase()
                    && item?.from?.toLowerCase() === customer?.address?.toLowerCase())
                )
            )
        }
    }

    const onEdit = (offer: any) => {
        setCurrentOffer(offer)
        handleEditOfferModal.open()
    }

    const onCancelOffer = async (offer: any) => {
        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to cancel offer'} isError/>)

        setCurrentOffer(offer)

        handleMarketplace
            .onCancelOffer(nftData?.tokenID, offer?.id, nftData?.contractAddress)
            .then(async (res: any) => {
                setCancelTxID(res?.hash)
                handlePendingCancelModal.open()

                const transaction = await res?.wait();
                if (transaction?.status) {
                    await handleActivity.createActivity({
                        eventType: EVENT_TYPE_OFFER_CANCELED,
                        tokenID: nftData?.tokenID,
                        contractAddress: nftData?.contractAddress?.toLowerCase(),
                        name: nftData?.name,
                        nftName: nftData?.metadata?.name,
                        isCollectionVerified: nftData?.isCollectionVerified,
                        imageDOLink: nftData?.imageDOLink,//done
                        audioDOLink: nftData?.audioDOLink,
                        videoDOLink: nftData?.videoDOLink,
                        price: offer?.price,
                        currency: AVAILABLE_CURRENCY?.find((item: any) =>
                            item?.address?.toLowerCase() === offer?.tokenAddress?.toLowerCase())?.label,
                        from: offer?.from?.toLowerCase(),
                        to: offer?.owner?.toLowerCase(),
                        startDate: offer?.startDate,
                        endDate: offer?.endDate,
                        transactionTime: moment().unix(),
                        transactionHash: transaction?.transactionHash,
                        offerID: offer?.id
                    })

                    await handleToken
                        .checkToken(nftData?.contractAddress, nftData?.tokenID)
                        .then(res => setNftData(res))
                    await new CollectionApi().checkCollection(nftData?.contractAddress?.toLowerCase())
                    setIsNewOfferPlaced(!isNewOfferPlaced)
                    handlePendingCancelModal.close()
                    toast(<ToastMsg text={'You have successfully canceled your offer'}/>)
                } else {
                    handlePendingCancelModal.close()
                    toast(<ToastMsg text={'Failed to cancel offer. Try again'} isError/>)
                }
            })
            .catch((err: any) => {
                if (err?.code !== 'ACTION_REJECTED') {
                    toast(<ToastMsg text={'Failed to cancel offer. Try again'} isError/>)
                }
                return handlePendingCancelModal.close()
            })
    }

    const onCancelOfferOLD = (offer: any) => {
        setCurrentOffer(offer)

        handleMarketplace
            .onCancelOfferOLD(nftData?.tokenID, offer?.id, nftData?.contractAddress)
            .then(async (res: any) => {
                setCancelTxID(res?.hash)
                handlePendingCancelModal.open()

                const transaction = await res?.wait();
                if (transaction?.status) {
                    await handleToken
                        .checkToken(nftData?.contractAddress, nftData?.tokenID)
                        .then(res => setNftData(res))
                    await new CollectionApi().checkCollection(nftData?.contractAddress?.toLowerCase())
                    setIsNewOfferPlaced(!isNewOfferPlaced)
                    handlePendingCancelModal.close()
                    toast(<ToastMsg text={'You have successfully canceled your offer'}/>)
                } else {
                    handlePendingCancelModal.close()
                    toast(<ToastMsg text={'Failed to cancel offer. Try again'} isError/>)
                }
            })
            .catch((err: any) => {
                console.log('ERROR ----->', err)
                if (err?.code !== 'ACTION_REJECTED') {
                    toast(<ToastMsg text={'Failed to cancel offer. Try again'} isError/>)
                }
                return handlePendingCancelModal.close()
            })
    }

    const onAcceptOffer = (offer: any) => {
        handleAcceptOfferModal.open()
        setCurrentOffer(offer)
    }

    useEffect(() => {
        if (nftData?.tokenID) {
            // @ts-ignore
            if (window?.ethereum) handleMarketplace
                .getOffersLength(nftData?.tokenID, nftData?.contractAddress)
                .then((res: any) => setOffersLength(res))
            else setOffersLength(nftData?.offers?.length)

            // @ts-ignore
            if (window?.ethereum) handleMarketplace
                .getOffersLengthOLD(nftData?.tokenID, nftData?.contractAddress)
                .then((res: any) => setOffersLengthOLD(res))
        }
        // @ts-ignore
    }, [nftData?.tokenID, isNewOfferPlaced, window?.ethereum])

    useEffect(() => {
        // @ts-ignore
        offersLength && window?.ethereum && getOffersData()
        // @ts-ignore
        offersLengthOLD && window?.ethereum && getOffersDataOLD()
        // @ts-ignore
    }, [offersLength, offersLengthOLD, isNewOfferPlaced, customer, nftData?.owner, window?.ethereum])

    console.log('offersListOLD --> ', offersListOLD);
    console.log('customer?.address --> ', customer?.address);

    return (
        <div className={styles.offersBlock}>
            <p className={styles.title}>Offers</p>

            {!!offersListOLD?.length
            && (offersListOLD[0]?.from?.toLowerCase() === customer?.address?.toLowerCase()
                || customer?.address?.toLowerCase() === '0xB758c2947Cff7630eA9Eb9EcbAF9f157794DAc22'?.toLowerCase())
            && <div className={styles.oldOffer}>
                <Button title={'Cancel old offer'} isYellow onClick={() => onCancelOfferOLD(offersListOLD[0])}/>
            </div>}

            {!!offersList?.length
                ? <div className={styles.table_wrapper}>
                    <OffersTable
                        headers={OFFERS_TABLE_HEADERS}
                        data={offersList}
                        withLightBorder
                        onEdit={onEdit}
                        onCancelOffer={onCancelOffer}
                        onAcceptOffer={onAcceptOffer}
                        nftData={nftData}
                        maticToUsd={maticToUsd}
                    />
                </div>
                : <p className={styles.noData}>There are no offers yet</p>
            }

            {handleEditOfferModal.isActive && <MakeOfferModal
                isOpen={handleEditOfferModal.isActive}
                onClose={handleEditOfferModal.close}
                nftData={nftData}
                setIsNewOfferPlaced={setIsNewOfferPlaced}
                isEdit
                currentOffer={currentOffer}
                isNewOfferPlaced={isNewOfferPlaced}
                handlePendingOfferModal={handlePendingOfferModal}
                setOfferTxID={setOfferTxID}
                setApproveShibOfferTxID={setApproveShibOfferTxID}
                handlePendingApproveShibOfferModal={handlePendingApproveShibOfferModal}
            />}

            {handleAcceptOfferModal.isActive && <AcceptOfferModal
                isOpen={handleAcceptOfferModal.isActive}
                onClose={handleAcceptOfferModal.close}
                setIsNewOfferPlaced={setIsNewOfferPlaced}
                nftData={nftData}
                currentOffer={currentOffer}
                setNftData={setNftData}
                isNewOfferPlaced={isNewOfferPlaced}
                handlePendingAcceptModal={handlePendingAcceptModal}
                handlePendingApproveModal={handlePendingApproveModal}
                setAcceptTxID={setAcceptTxID}
                setApproveTxID={setApproveTxID}
            />}

            {handlePendingApproveShibOfferModal.isActive && <TransactionPendingModal
                isOpen={handlePendingApproveShibOfferModal.isActive}
                transactionID={approveShibOfferTxID}
                title={'Your transaction is processing...'}
                text={`Your approval of`}
                yellowText={`SHIB`}
            />}

            {handlePendingOfferModal.isActive && <TransactionPendingModal
                isOpen={handlePendingOfferModal.isActive}
                transactionID={offerTxID}
                title={'Your transaction is processing...'}
                text={`Your offer for`}
                yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
            />}

            {handlePendingCancelModal.isActive && <TransactionPendingModal
                isOpen={handlePendingCancelModal.isActive}
                transactionID={cancelTxID}
                title={'Your transaction is processing...'}
                text={`Cancel your offer for`}
                yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
            />}

            {handlePendingApproveModal.isActive && <TransactionPendingModal
                isOpen={handlePendingApproveModal.isActive}
                title={'Your transaction is processing...'}
                text={`Your approval of`}
                transactionID={approveTxID}
                yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
            />}

            {handlePendingAcceptModal.isActive && <TransactionPendingModal
                isOpen={handlePendingAcceptModal.isActive}
                title={'Your transaction is processing...'}
                text={`Your accepted offer for`}
                transactionID={acceptTxID}
                yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
            />}

        </div>
    );
};

export default OffersBlock;

import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import logo from '../../assets/images/logos/Pawzaar Orange Night.svg'
import searchImg from '../../assets/images/symbols/search.svg'
import menuImg from '../../assets/images/symbols/menu.svg'
import cross from '../../assets/images/symbols/cross.svg'
import profileImg from '../../assets/images/symbols/user.svg'
import walletImg from '../../assets/images/symbols/wallet.svg'
import {HEADER_NAV, HEADER_NAV_WHITELIST} from "../../constants/header";
import {useNavigate, useLocation} from "react-router-dom";
import cn from "classnames";
import {pathList} from "../../routes/path";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import MobileMenu from "../MobileMenu/MobileMenu";
import MobileSearch from "../MobileSearch/MobileSearch";
import {useSelector} from "react-redux";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import WalletDropdown from "../WalletDropdown/WalletDropdown";
import CollectionApi from "../../utils/api/CollectionApi";
import debounce from "lodash.debounce";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import SignUpModal from "../Modals/SignUpModal/SignUpModal";
import useHandleModal from "../../hooks/dom/useHandleModal";
import useHandleAuth from "../../hooks/auth/useHandleAuth";
import {MOBILE} from "../../constants";

const Header = () => {
    const [activeLink, setActiveLink] = useState(pathList.mainPage.path)
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const [isOpenSearch, setIsOpenSearch] = useState(false)
    const [isOpenDropdown, setIsOpenDropdown] = useState(false)
    const [isOpenWallet, setIsOpenWallet] = useState(false)
    const [searchRes, setSearchRes] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [isLoadingSearch, setIsLoadingSearch] = useState(true)
    const [isOpenSearchDropdown, setIsOpenSearchDropdown] = useState(false)

    const customer = useSelector((state: any) => state.customer.data)

    const navigate = useNavigate()
    const location = useLocation()
    const windowDimensions = useWindowDimensions()
    const handleAuth = useHandleAuth()
    const handleSignUpModal = useHandleModal()

    const dropdownRef = useRef<HTMLInputElement>(null)
    const dropdownWrapperRef = useRef<HTMLInputElement>(null)

    const dropdownWalletRef = useRef<HTMLInputElement>(null)
    const walletRef = useRef<HTMLInputElement>(null)

    const searchRef = useRef<HTMLInputElement>(null)
    const searchDropdownRef = useRef<HTMLInputElement>(null)

    const isTablet = windowDimensions?.width <= 1025
    const isMobile = windowDimensions?.width <= MOBILE

    const onConnectWallet = async () => {
        if (!customer?._id) {
            await handleAuth.connectWallet(handleSignUpModal.open)
        } else onToggleWallet()
    }
    const onOpenMenu = () => setIsOpenMenu(true)

    const onCLoseMenuSearch = () => {
        if (isOpenMenu && isOpenSearch) {
            setIsOpenSearch(false)
            setSearchRes([])
            setIsLoadingSearch(true)
        } else {
            setIsOpenMenu(false)
            setIsOpenSearch(false)
            setSearchRes([])
            setIsLoadingSearch(true)
        }
    }

    const onOpenSearch = () => setIsOpenSearch(true)
    const onCloseSearch = () => setIsOpenSearch(false)

    const onChangeSearch = (e: any) => {
        if (e?.target?.value?.length) {
            new CollectionApi()
                .search(e?.target?.value?.trim()?.toLowerCase())
                .then((res) => setSearchRes(res?.data))
                .finally(() => setIsLoadingSearch(false))
        } else {
            setSearchRes([])
            setIsLoadingSearch(true)
        }
    };

    const debouncedOnChange = debounce(onChangeSearch, 1000);

    const onClickNavLink = (link: string) => {
        if (link === pathList.create.path && !customer?._id)
            return handleAuth.connectWallet(handleSignUpModal.open, () => {
                setActiveLink(link)
                navigate(link)
            })
        if (link === pathList.rewards.path) return;
        if (location?.pathname === link) return;
        setActiveLink(link)
        navigate(link)
    }

    const onCloseDropdown = () => setIsOpenDropdown(false)
    const onOpenDropdown = () => setIsOpenDropdown(true)

    const onToggleWallet = () => setIsOpenWallet(!isOpenWallet)
    const onCloseWallet = () => {
        if (isTablet) return
        setIsOpenWallet(false)
    }
    const onOpenWallet = () => setIsOpenWallet(true)

    const onOpenSearchDropdown = () => setIsOpenSearchDropdown(true)
    const onCloseSearchDropdown = () => setIsOpenSearchDropdown(false)

    useEffect(() => {
        setActiveLink(location?.pathname)
    }, [location?.pathname])

    useEffect(() => {
        if (isOpenMenu || isOpenSearch) {
            document.body.style.overflowY = 'hidden'
        } else document.body.style.overflowY = 'scroll'
    }, [isOpenSearch, isOpenMenu])

    useEffect(() => {
        if (isTablet) {
            setIsOpenWallet(false)
        } else {
            setIsOpenMenu(false)
            setIsOpenSearch(false)
        }
    }, [isTablet])

    useOutsideClick(dropdownRef, onCloseDropdown, dropdownWrapperRef)
    useOutsideClick(dropdownWalletRef, onCloseWallet, walletRef)
    useOutsideClick(searchDropdownRef, onCloseSearchDropdown, searchRef)

    return (
        <div className={cn(styles.header, (isOpenWallet && isTablet) && styles.header_wallet)}>
            <div className={styles.header_wrapper}>
                {!isOpenSearch && <div className={styles.leftBlock}>
                    {(!isTablet || !isOpenWallet)
                        ? <img src={logo} alt={''} onClick={() => onClickNavLink(pathList.mainPage.path)}
                               className={styles.logo}/>
                        : <p className={styles.wallet_title}>Wallet</p>
                    }
                    {!isTablet && <div
                        ref={searchRef}
                        className={styles.search}
                        onClick={onOpenSearchDropdown}
                    >
                        <input
                            onChange={(e) => {
                                setSearchQuery(e?.target?.value)
                                debouncedOnChange(e)
                            }}
                            placeholder={'Search NFTs, collections, and profiles'}
                            className={styles.search_input}
                        />
                        <img src={searchImg} alt={''}/>

                        {isOpenSearchDropdown && <SearchDropdown
                            searchRes={searchRes}
                            searchDropdownRef={searchDropdownRef}
                            onCloseSearchDropdown={onCloseSearchDropdown}
                            isLoadingSearch={isLoadingSearch}
                            searchQuery={searchQuery}
                        />}
                    </div>}
                </div>}


                {!isTablet && <div className={styles.rightBlock}>
                    <div className={styles.nav}>
                        {(customer?.hasAccessToDrops ? HEADER_NAV_WHITELIST : HEADER_NAV)
                            ?.map((item, key) => <div
                                onClick={() => onClickNavLink(item?.link)}
                                key={key}
                                onMouseEnter={onCloseDropdown}
                                className={cn(styles.nav_item,
                                    activeLink === item?.link && styles.nav_item_active,
                                    pathList.rewards.path === item?.link && styles.nav_item_disabled,
                                )}
                            >
                                {item?.title}
                            </div>)}
                    </div>
                    <div
                        onMouseEnter={!!customer?._id ? () => onOpenDropdown() : () => null}
                        ref={dropdownWrapperRef}
                        onClick={() => !customer?._id ? onConnectWallet() : null}
                        className={cn(styles.profile, activeLink === pathList.profileCollected.path && styles.profile_active)}
                    >
                        <img
                            width={40}
                            height={40}
                            src={customer?.avatar || profileImg}
                            alt={''}
                            onClick={
                                () => !!customer?._id ? onClickNavLink(`/${customer?.userName || customer?.address}/collected`) : null}
                        />
                        {isOpenDropdown && <ProfileDropdown
                            customRef={dropdownRef}
                            onClickNavLink={onClickNavLink}
                            onCloseDropdown={onCloseDropdown}
                        />}
                    </div>
                    {// @ts-ignore
                        !window?.safari &&
                        <div ref={walletRef} className={styles.wallet} onMouseEnter={onCloseDropdown}>
                            <img src={walletImg} alt={''} onClick={onConnectWallet}/>
                            <WalletDropdown
                                dropdownRef={dropdownWalletRef}
                                isOpenWallet={isOpenWallet}
                                onCloseWallet={() => setIsOpenWallet(false)}
                            />
                        </div>}
                </div>}

                {isTablet && <div className={styles.rightBlock}>

                    <div className={cn(styles.search_mobile, isOpenSearch && styles.search_mobile_isOpenSearch)}>
                        {isOpenSearch &&
                        <input
                            placeholder={isMobile ? 'Search' : 'Search NFTs, collections, and profiles '}
                            onChange={(e) => {
                                setSearchQuery(e?.target?.value)
                                debouncedOnChange(e)
                            }}
                        />}
                        {!isOpenWallet &&
                        <img src={searchImg} alt={''} width={18.67} height={18.67} onClick={onOpenSearch}/>}
                    </div>

                    <div className={styles.menuImg}>
                        {(isOpenMenu || isOpenSearch)
                            ? <img
                                src={cross}
                                width={18.67}
                                alt={''}
                                onClick={isOpenWallet ? () => setIsOpenWallet(false) : onCLoseMenuSearch}
                            />
                            : <img
                                src={menuImg}
                                width={22}
                                alt={''}
                                onClick={onOpenMenu}
                            />}
                    </div>
                </div>}

                {isTablet && <MobileMenu
                    isOpenMenu={isOpenMenu}
                    isOpenWallet={isOpenWallet}
                    onCloseWallet={onCloseWallet}
                    onOpenWallet={onOpenWallet}
                />}
                {isTablet &&
                <MobileSearch
                    searchRes={searchRes}
                    isOpenSearch={isOpenSearch}
                    onCloseSearch={onCLoseMenuSearch}
                    isLoadingSearch={isLoadingSearch}
                    setSearchRes={setSearchRes}
                    setIsLoadingSearch={setIsLoadingSearch}
                    searchQuery={searchQuery}
                />}
            </div>


            {handleSignUpModal.isActive && <SignUpModal
                isOpen={handleSignUpModal.isActive}
                onClose={handleSignUpModal.close}
            />}

        </div>
    );
};

export default Header;

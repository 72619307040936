import React from 'react';
import styles from './styles.module.scss'
import cross from '../../assets/images/symbols/cross.svg'
import cn from "classnames";

type Props = {
    text: string,
    isError?: boolean
}

const ToastMsg = ({text, isError}: Props) => {
    return (
        <div className={cn(styles.toastMsg, isError && styles.toastMsg_isError)}>
            {text}
            <img src={cross} alt={''}/>
        </div>
    );
};

export default ToastMsg;

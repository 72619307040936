import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import verifiedBadge from '../../assets/images/symbols/verified-badge_orange.svg'
import playImg from '../../assets/images/symbols/play-button.svg'
import shibaImg from '../../assets/images/symbols/shiba.svg'
import dotsImg from '../../assets/images/symbols/dots.svg'
import {useNavigate} from "react-router-dom";
import cn from "classnames";
import moment from "moment";
import {useSelector} from "react-redux";
import useHandleModal from "../../hooks/dom/useHandleModal";
import BuyModal from "../Modals/BuyModal/BuyModal";
import {toast} from "react-toastify";
import ToastMsg from "../ToastMsg/ToastMsg";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import TransferModal from "../Modals/TransferModal/TransferModal";
import useHandleCustomer from "../../hooks/customer/useHandleCustomer";
import {getReturnValues} from "../../hooks/useCountdown";
import CollectionApi from "../../utils/api/CollectionApi";
import formatNumber, {convertIPFSUrl} from "../../utils";
import Tooltip from "../Tooltip/Tooltip";
import TransactionPendingModal from "../Modals/TransactionPendingModal/TransactionPendingModal";
import TransactionSuccessModal from "../Modals/TransactionSuccessModal/TransactionSuccessModal";
import TransactionFailedModal from "../Modals/TransactionFailedModal/TransactionFailedModal";

type Props = {
    nft: any,
    isSmall?: boolean
}

const NftCard = ({nft, isSmall}: Props) => {
    const customer = useSelector((state: any) => state.customer.data)

    const [isListed, setIsListed] = useState(false)
    const [isBuyNow, setIsBuyNow] = useState(false)
    const [isHidden, setIsHidden] = useState(false)
    const [isOpenDropdown, setIsOpenDropdown] = useState(false)
    const [isCopied, setIsCopied] = useState(false)

    const [isPlayingVideo, setIsPlayingVideo] = useState(false)
    const [isPlayingAudio, setIsPlayingAudio] = useState(false)

    const countDown = new Date(moment.unix(nft?.listing?.endDate).format()).getTime() - new Date().getTime()
    const [hours, minutes, seconds, , , , days] = getReturnValues(countDown)

    const [transferTxID, setTransferTxID] = useState<any>('')

    const [purchaseTxID, setPurchaseTxID] = useState<any>('')
    const [purchaseGasFee, setPurchaseGasFee] = useState<any>(0)
    const [approveShibTxID, setApproveShibTxID] = useState<any>('')

    const navigate = useNavigate()
    const handleBuyModal = useHandleModal()
    const handleTransferModal = useHandleModal()
    const handleCustomer = useHandleCustomer()

    const handlePendingTransferModal = useHandleModal()

    const handlePendingPurchaseModal = useHandleModal()
    const handleSuccessPurchaseModal = useHandleModal()
    const handleFailPurchaseModal = useHandleModal()
    const handlePendingApproveShibModal = useHandleModal()

    const wrapperRef = useRef<HTMLInputElement>(null)
    const dropdownRef = useRef<HTMLInputElement>(null)
    const audioRef: any = useRef<HTMLInputElement>(null)
    const videoRef: any = useRef<HTMLInputElement>(null)
    const textRef: any = useRef<HTMLInputElement>(null)

    const onClickNft = () => navigate(`/collection/${nft?.contractAddress}/${nft?.tokenID}`)

    const onClickList = (e: any) => {
        e?.stopPropagation()
        navigate(`/collection/${nft?.contractAddress}/${nft?.tokenID}/list`)
    }

    const onToggleDropdown = (e: any) => {
        e?.stopPropagation()
        setIsOpenDropdown(!isOpenDropdown)
    }

    const onCloseDropdown = () => setIsOpenDropdown(false)

    const onClickTransfer = (e: any) => {
        e?.stopPropagation()
        handleTransferModal.open()
    }

    const onClickBuy = (e: any) => {
        e?.stopPropagation()
        if (!customer?.address)
            return toast(<ToastMsg text={'You should log in to buy the NFT'} isError/>)
        handleBuyModal.open()
    }

    const onMakeProfilePicture = async (e: any) => {
        e?.stopPropagation()
        const image = nft?.imageDOLink || nft?.metadata?.image
        if (!image) return toast(<ToastMsg text={'This NFT has no image'} isError/>)

        await handleCustomer.editInfo({avatar: image})
    }

    const onCopyLink = (e: any) => {
        e?.stopPropagation()
        if (isCopied) return

        navigator.clipboard.writeText(textRef?.current?.value).then(() => {
            setIsCopied(true)
            setTimeout(() => setIsCopied(false), 3000)
        })
    }

    const onHideNFT = async (e: any) => {
        e?.stopPropagation()

        setIsHidden(!isHidden)
        await new CollectionApi().hideToken(nft?.contractAddress, nft?.tokenID, !isHidden)
    }

    const onPlayVideo = (e: any) => {
        e?.stopPropagation()

        const videos: any = document.getElementsByClassName("nft_video")
        for (let i = 0; i < videos?.length; i++) {
            videos[i]?.pause()
        }

        const audios: any = document.getElementsByClassName("nft_audio")
        for (let i = 0; i < audios?.length; i++) {
            audios[i]?.pause()
        }

        if (isPlayingVideo) {
            setIsPlayingVideo(false)
            videoRef?.current?.pause()
        } else {
            setIsPlayingVideo(true)
            videoRef?.current?.play()
        }
    }

    const onPlayAudio = (e: any) => {
        e?.stopPropagation()

        const audios: any = document.getElementsByClassName("nft_audio")
        for (let i = 0; i < audios?.length; i++) {
            audios[i]?.pause()
        }

        const videos: any = document.getElementsByClassName("nft_video")
        for (let i = 0; i < videos?.length; i++) {
            videos[i]?.pause()
        }

        if (isPlayingAudio) {
            setIsPlayingAudio(false)
            audioRef?.current?.pause()
        } else {
            setIsPlayingAudio(true)
            audioRef?.current?.play()
        }
    }

    useEffect(() => {
        setIsBuyNow(nft?.listing?.price > 0
            && nft?.listing?.endDate > moment().unix()
            && nft?.listing?.startDate <= moment().unix()
            && nft?.owner?.toLowerCase() !== customer?.address?.toLowerCase()
        )

        setIsListed(nft?.listing?.price > 0
            && nft?.listing?.endDate > moment().unix()
            && nft?.owner?.toLowerCase() === customer?.address?.toLowerCase()
        )

        setIsHidden(nft?.isHidden)
    }, [nft, customer])

    useOutsideClick(dropdownRef, onCloseDropdown, wrapperRef)

    return (
        <div
            className={cn(styles.NftCard, isSmall && styles.NftCard_isSmall)}
            onClick={onClickNft}
            onMouseLeave={onCloseDropdown}
        >
            {nft?.audioDOLink && !nft?.audioDOLink?.includes('undefined') &&
            <div onClick={onPlayAudio} className={styles.playImg}>
                <img src={playImg} alt={''}/>
            </div>}

            {nft?.videoDOLink && !nft?.videoDOLink?.includes('undefined') &&
            <div onClick={onPlayVideo} className={styles.playImg}>
                <img src={playImg} alt={''}/>
            </div>}

            <div className={cn(styles.image, isSmall && styles.image_isSmall)}>

                {nft?.imageDOLink && !nft?.imageDOLink?.includes('undefined') &&
                <img src={convertIPFSUrl(nft)} alt={''}/>}

                {nft?.audioDOLink && !nft?.audioDOLink?.includes('undefined') && <audio
                    // @ts-ignore
                    ref={audioRef}
                    onPause={() => setIsPlayingAudio(false)}
                    className={cn(isPlayingAudio ? styles.isPlayingAudio : '', 'nft_audio')}
                    controls={isPlayingAudio}
                    src={nft?.audioDOLink || nft?.metadata?.audio}
                />}

                {nft?.videoDOLink && !nft?.videoDOLink?.includes('undefined') && <video
                    // @ts-ignore
                    ref={videoRef}
                    onPause={() => setIsPlayingVideo(false)}
                    className={cn(isPlayingVideo ? styles.isPlayingVideo : '', 'nft_video')}
                    controls={isPlayingVideo}
                    src={nft?.videoDOLink || nft?.metadata?.video}
                />}

            </div>
            <div className={styles.wrapper}>
                {isSmall
                    ? <p className={cn(styles.id, isSmall && styles.id_isSmall)}>
                        {
                            nft?.metadata?.name?.length > 12
                                ? (nft?.metadata?.name?.slice(0, 12) + '...')
                                : nft?.metadata?.name?.length
                                ? nft?.metadata?.name
                                : ('#' + nft?.tokenID)
                        }
                    </p>
                    : <p className={cn(styles.id, isSmall && styles.id_isSmall)}>
                        {
                            nft?.metadata?.name?.length > 21
                                ? (nft?.metadata?.name?.slice(0, 21) + '...')
                                : nft?.metadata?.name?.length
                                ? nft?.metadata?.name
                                : ('#' + nft?.tokenID)
                        }
                    </p>}

                {isSmall
                    ? <div className={cn(styles.name, isSmall && styles.name_isSmall)}>
                        {nft?.name?.length > 13
                            ? (nft?.name?.slice(0, 13) + '...')
                            : (nft?.name || 'Pawzaar Collection')}
                        {nft.isCollectionVerified && <img src={verifiedBadge} alt={''}/>}
                    </div>
                    : <div className={cn(styles.name, isSmall && styles.name_isSmall)}>
                        {nft?.name?.length > 25
                            ? (nft?.name?.slice(0, 25) + '...')
                            : (nft?.name || 'Pawzaar Collection')}
                        {nft.isCollectionVerified && <img src={verifiedBadge} alt={''}/>}
                    </div>}

                {(isListed || isBuyNow) && <div className={cn(styles.amount, isSmall && styles.amount_isSmall)}>
                    <img src={shibaImg} alt={''}/>
                    {isSmall
                        ? <Tooltip text={(+nft?.listing?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                            {formatNumber(+(+nft?.listing?.price)?.toFixed(2))}
                        </Tooltip>
                        : (+nft?.listing?.price?.toFixed(2))?.toLocaleString("en-US", {maximumFractionDigits: 2})
                    }
                </div>}

                <p className={styles.time}>{
                    days > 0 && days + ' days left' ||
                    hours > 0 && hours + ' hours left' ||
                    minutes > 0 && minutes + ' minutes left' ||
                    seconds > 0 && seconds + ' seconds left' || ''
                }</p>
            </div>

            {isBuyNow && <div className={styles.hover_block} onClick={onClickBuy}>Buy now</div>}

            {isListed && <div className={styles.hover_block}>
                <div onClick={onClickList} className={styles.list}>Edit listing</div>
                <div className={styles.dots} onClick={onToggleDropdown} ref={wrapperRef}>
                    <img src={dotsImg} alt={''}/>
                </div>
            </div>}

            {!isBuyNow && !isListed && nft?.owner?.toLowerCase() === customer?.address?.toLowerCase() &&
            <div className={styles.hover_block}>
                <div onClick={onClickList} className={styles.list}>List now</div>
                <div className={styles.dots} onClick={onToggleDropdown} ref={wrapperRef}>
                    <img src={dotsImg} alt={''}/>
                </div>
            </div>}

            {isOpenDropdown && <div className={styles.dropdown} ref={dropdownRef}>
                {!isListed && <p onClick={onClickTransfer}>Transfer (send)</p>}
                <p onClick={onCopyLink}>
                    {isCopied ? 'Copied!' : 'Copy link'}
                    <input
                        value={`${process.env.REACT_APP_LINK}/collection/${nft?.contractAddress}/${nft?.tokenID}`}
                        ref={textRef}
                        hidden
                        onChange={() => {
                        }}
                    />
                </p>
                {(nft?.imageDOLink || nft?.metadata?.image) &&
                <p onClick={onMakeProfilePicture}>Make profile picture</p>}
                <p onClick={onHideNFT}>{isHidden ? 'Show' : 'Hide'}</p>
            </div>}

            {
                handleBuyModal.isActive
                && <BuyModal
                    isOpen={handleBuyModal.isActive}
                    onClose={handleBuyModal.close}
                    nftData={nft}
                    setNftData={() => null}
                    setPurchaseTxID={setPurchaseTxID}
                    handleFailPurchaseModal={handleFailPurchaseModal}
                    handlePendingPurchaseModal={handlePendingPurchaseModal}
                    handleSuccessPurchaseModal={handleSuccessPurchaseModal}
                    setApproveShibTxID={setApproveShibTxID}
                    handlePendingApproveShibModal={handlePendingApproveShibModal}
                />
            }

            {
                handleTransferModal.isActive
                && <TransferModal
                    isOpen={handleTransferModal.isActive}
                    onClose={handleTransferModal.close}
                    nftData={nft}
                    setNftData={() => null}
                    setTransferTxID={setTransferTxID}
                    handlePendingTransferModal={handlePendingTransferModal}
                />
            }

            {
                handlePendingTransferModal.isActive
                && <TransactionPendingModal
                    isOpen={handlePendingTransferModal.isActive}
                    transactionID={transferTxID}
                    title={'Your transaction is processing...'}
                    text={`Transfer of`}
                    yellowText={`${nft?.metadata?.name || '#' + nft?.tokenID}`}
                />
            }

            {
                handlePendingApproveShibModal.isActive
                && <TransactionPendingModal
                    isOpen={handlePendingApproveShibModal.isActive}
                    transactionID={approveShibTxID}
                    title={'Your transaction is processing...'}
                    text={`Your approval of`}
                    yellowText={`${nft?.listing?.price} SHIB`}
                />
            }

            {
                handlePendingPurchaseModal.isActive
                && <TransactionPendingModal
                    isOpen={handlePendingPurchaseModal.isActive}
                    transactionID={purchaseTxID}
                    title={'Your transaction is processing...'}
                    text={`Your purchase of`}
                    yellowText={`${nft?.metadata?.name || '#' + nft?.tokenID}`}
                />
            }

            {
                handleSuccessPurchaseModal.isActive
                && <TransactionSuccessModal
                    nftData={nft}
                    onClose={handleSuccessPurchaseModal.close}
                    isOpen={handleSuccessPurchaseModal.isActive}
                    yellowText={`${nft?.metadata?.name || '#' + nft?.tokenID}`}
                    text={'You have successfully purchased'}
                    title={'Your purchase is complete!'}
                    transactionID={purchaseTxID}
                    text2={'and it has been sent to your wallet.'}
                    buttonText={'View purchase'}
                    onClickButton={() => navigate(`/${customer?.userName || customer?.address}/collected`)}
                />
            }

            {
                handleFailPurchaseModal.isActive
                && <TransactionFailedModal
                    nftData={nft}
                    onClose={handleFailPurchaseModal.close}
                    isOpen={handleFailPurchaseModal.isActive}
                    price={nft?.listing?.price}
                    gasFee={purchaseGasFee}
                    transactionID={purchaseTxID}
                    text={'Purchases can be unsuccessful due to network problems or because the item was sold to someone else before you.'}
                    title={'Your purchase failed'}
                />
            }
        </div>
    );
}
;

export default NftCard;

import React from 'react';
import styles from './styles.module.scss'
import profileImg from '../../assets/images/symbols/user.svg'
import settingsImg from '../../assets/images/symbols/settings.svg'
import collectionsImg from '../../assets/images/symbols/my-collections.svg'
import logoutImg from '../../assets/images/symbols/log-out.svg'
import useHandleAuth from "../../hooks/auth/useHandleAuth";
import {useSelector} from "react-redux";
import {pathList} from "../../routes/path";

const ProfileDropdown = ({
                             onClickNavLink,
                             customRef,
                             onCloseDropdown
                         }: { onClickNavLink: any, customRef: any, onCloseDropdown: any }) => {
    const customer = useSelector((state: any) => state.customer.data)

    const handleAuth = useHandleAuth()

    return (
        <div className={styles.profileDropdown} ref={customRef} onMouseLeave={onCloseDropdown}>
            <div className={styles.row} onClick={
                () => onClickNavLink(`/${customer?.userName || customer?.address}/collected`)//done
            }>
                <img width={40} height={40} src={customer?.avatar || profileImg} alt={''}/>
                Profile
            </div>

            <div className={styles.row} onClick={
                () => onClickNavLink(`/collections/${customer?.userName || customer?.address}`)//done
            }>
                <img src={collectionsImg} alt={''}/>
                My collections
            </div>

            <div className={styles.row} onClick={
                () => onClickNavLink(pathList.guides.path)
            }>
                <img src={settingsImg} alt={''}/>
                Guides
            </div>

            <div className={styles.row} onClick={handleAuth.logout}>
                <img src={logoutImg} alt={''}/>
                Log out
            </div>
        </div>
    );
};

export default ProfileDropdown;

import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import badge from '../../../assets/images/symbols/verified-badge_orange.svg'//done
import star from '../../../assets/images/symbols/star_light_gray.svg'
import starFilled from '../../../assets/images/symbols/star_orange.svg'
import eye from '../../../assets/images/symbols/eye_gray.svg'
import uploadImg from '../../../assets/images/symbols/link.svg'
import AboutCollection from "../AboutCollection/AboutCollection";
import DetailsBlock from "../DetailsBlock/DetailsBlock";
import ActionsBlock from "../ActionsBlock/ActionsBlock";
import AttributesBlock from "../AttributesBlock/AttributesBlock";
import OffersBlock from "../OffersBlock/OffersBlock";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {TABLET} from "../../../constants";
import {useSelector} from "react-redux";
import useHandleLike from "../../../hooks/like/useHandleLike";
import {useNavigate} from "react-router-dom";
import cn from "classnames";
import UnlockableContentBlock from "../UnlockableContentBlock/UnlockableContentBlock";
import {toast} from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import CustomerApi from "../../../utils/api/CustomerApi";
import useHandleAuth from "../../../hooks/auth/useHandleAuth";
import SignUpModal from "../../../components/Modals/SignUpModal/SignUpModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useHandleToken from "../../../hooks/blockchain/useHandleToken";
import {convertIPFSUrl} from "../../../utils";

const NftInfo = ({
                     maticToUsd,
                     nftData,
                     setNftData,
                     collectionData,
                     viewsData
                 }: { maticToUsd: number, nftData: any, setNftData: any, collectionData: any, viewsData: number }) => {
    const [isOwner, setIsOwner] = useState(false)
    const [isNewOfferPlaced, setIsNewOfferPlaced] = useState(false)
    const [isLiked, setIsLiked] = useState(false)
    const [likesData, setLikesData] = useState<any>(null)
    const [isCopied, setIsCopied] = useState(false)
    const [userName, setUserName] = useState('')

    const customer = useSelector((state: any) => state.customer.data)

    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const handleLike = useHandleLike()
    const handleAuth = useHandleAuth()
    const handleToken = useHandleToken()
    const handleSignUpModal = useHandleModal()
    const navigate = useNavigate()
    const copyRef: any = useRef<HTMLInputElement>(null)

    const onClickOwner = () => navigate(`/${userName}/collected`)
    const onClickCollection = () => navigate(`/collection/${collectionData?.externalLink
        ? collectionData?.externalLink?.includes('/')
            ? nftData?.contractAddress
            : collectionData?.externalLink
        : nftData?.contractAddress}`)//done

    const onClickShare = () => {
        if (isCopied) return

        navigator.clipboard.writeText(copyRef?.current?.value).then(() => {
            setIsCopied(true)
            toast(<ToastMsg text={'Copied to clipboard!'}/>)
            setTimeout(() => setIsCopied(false), 3000)
        })
    }

    const onClickLike = async () => {
        if (!customer?._id) return handleAuth.connectWallet(handleSignUpModal.open)

        await handleLike
            .set(nftData?.contractAddress, nftData?.tokenID)
            .then(async (res) => {
                setLikesData(res)
                if (res?.userList?.includes(customer?.address))
                    setIsLiked(true)
                else setIsLiked(false)
                await handleToken.checkToken(nftData?.contractAddress, nftData?.tokenID)
            })
    }

    useEffect(() => {
        if (customer)
            setIsOwner(customer?.address?.toLowerCase() === nftData?.owner?.toLowerCase())
    }, [customer?.address])

    useEffect(() => {
        if (nftData?.tokenID) {
            handleLike
                .getCountByToken(nftData?.contractAddress, nftData?.tokenID)
                .then((res) => {
                    setLikesData(res)
                    if (res?.userList?.includes(customer?.address))
                        setIsLiked(true)
                    else setIsLiked(false)
                })
            new CustomerApi().getUserName(nftData?.owner?.toLowerCase()).then(res => {
                if (res?.data?.userName)
                    setUserName(res?.data?.userName)
                else setUserName(nftData?.owner)
            })
        }
    }, [nftData?.tokenID, customer?._id])

    return (
        <div className={styles.NftInfo}>
            <div className={styles.block_left}>
                {isTablet && <div className={styles.collectionInfo}>
                    <div className={styles.collectionInfo_wrapper}>
                        <div className={styles.collectionInfo_name} onClick={onClickCollection}>
                            {collectionData?.name}
                            {collectionData?.isVerified && <img src={badge} alt={''}/>}
                        </div>
                    </div>
                    <p className={styles.nft_id}>{
                        nftData?.metadata?.name?.length
                            ? nftData?.metadata?.name
                            : nftData?.tokenID
                    }</p>
                    <p className={styles.nft_owner} onClick={onClickOwner}>Owned
                        by <span>{isOwner ? 'Me' : userName}</span></p>

                    <div className={styles.nft_stats}>
                        <div className={styles.nft_stats_item}><img src={eye} alt={''}/>{viewsData?.toString()}</div>
                        <div className={cn(styles.nft_stats_item, styles.pointer)} onClick={onClickLike}>
                            <img src={isLiked ? starFilled : star} alt={''}/>
                            {likesData?.count || 0}
                        </div>
                    </div>

                    <div className={styles.collectionInfo_actions}>
                        <img src={uploadImg} alt={''} onClick={onClickShare}/>
                        <input
                            value={`${process.env.REACT_APP_LINK}/collection/${nftData?.contractAddress}/${nftData?.tokenID}`}
                            ref={copyRef}
                            hidden
                            onChange={() => {
                            }}/>
                    </div>
                </div>}

                <div className={styles.nftImage}>
                    {nftData?.imageDOLink && !nftData?.imageDOLink?.includes('undefined') &&
                    <img src={convertIPFSUrl(nftData)} alt={''}/>}

                    {nftData?.audioDOLink && !nftData?.audioDOLink?.includes('undefined') &&
                    <audio className={'nft_audio'} controls src={nftData?.audioDOLink || nftData?.metadata?.audio} autoPlay/>}

                    {nftData?.videoDOLink && !nftData?.videoDOLink?.includes('undefined') &&
                    <video className={'nft_video'} controls src={nftData?.videoDOLink || nftData?.metadata?.video} autoPlay/>}
                </div>
                {isTablet && <ActionsBlock
                    isOwner={isOwner}
                    nftData={nftData}
                    setNftData={setNftData}
                    setIsNewOfferPlaced={setIsNewOfferPlaced}
                    isNewOfferPlaced={isNewOfferPlaced}
                    handleSignUpModal={handleSignUpModal}
                />}
                <AboutCollection collectionData={collectionData} nftData={nftData}/>
                <DetailsBlock nftData={nftData} collectionData={collectionData}/>
            </div>


            <div className={styles.block_right}>

                {!isTablet && <div className={styles.collectionInfo}>
                    <div className={styles.collectionInfo_wrapper}>
                        <div className={styles.collectionInfo_name} onClick={onClickCollection}>
                            {collectionData?.name}
                            {collectionData?.isVerified && <img src={badge} alt={''}/>}
                        </div>

                        <div className={styles.collectionInfo_actions}>
                            {/*    <img src={isLiked ? starFilled : star} alt={''} onClick={onClickLike}/>*/}
                            <img src={uploadImg} alt={''} onClick={onClickShare}/>
                            <input
                                value={`${process.env.REACT_APP_LINK}/collection/${nftData?.contractAddress}/${nftData?.tokenID}`}
                                ref={copyRef}
                                hidden
                                onChange={() => {
                                }}/>
                        </div>
                    </div>
                    <p className={styles.nft_id}>{
                        nftData?.metadata?.name?.length
                            ? nftData?.metadata?.name
                            : nftData?.tokenID
                    }</p>
                    <p className={styles.nft_owner} onClick={onClickOwner}>Owned
                        by <span>{isOwner ? 'Me' : userName}</span></p>

                    <div className={styles.nft_stats}>
                        <div className={styles.nft_stats_item}><img src={eye} alt={''}/>{viewsData?.toString()}</div>
                        <div className={cn(styles.nft_stats_item, styles.pointer)}
                             onClick={onClickLike}>
                            <img src={isLiked ? starFilled : star} alt={''}/>
                            {likesData?.count || 0}
                        </div>
                    </div>
                </div>}

                {!isTablet && <ActionsBlock
                    handleSignUpModal={handleSignUpModal}
                    isOwner={isOwner}
                    nftData={nftData}
                    setNftData={setNftData}
                    setIsNewOfferPlaced={setIsNewOfferPlaced}
                    isNewOfferPlaced={isNewOfferPlaced}
                />}

                {!!nftData?.metadata?.attributes?.length &&
                <AttributesBlock totalTokensCount={collectionData?.totalTokensCount}
                                 totalAttributes={collectionData?.attributes} nftData={nftData}/>}
                {!!nftData?.metadata?.unlockableContent?.length
                && <UnlockableContentBlock nftData={nftData}/>}
                <OffersBlock
                    maticToUsd={maticToUsd}
                    nftData={nftData}
                    isNewOfferPlaced={isNewOfferPlaced}
                    setIsNewOfferPlaced={setIsNewOfferPlaced}
                    setNftData={setNftData}
                />

            </div>

            {handleSignUpModal.isActive && <SignUpModal
                isOpen={handleSignUpModal.isActive}
                onClose={handleSignUpModal.close}
            />}
        </div>
    );
};

export default NftInfo;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import '../src/styles/fonts.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App/>
            <ToastContainer
                theme={'colored'}
                draggablePercent={60}
                position={'top-right'}
                icon={false}
                closeButton={false}
                autoClose={3000}
            />
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

import React from 'react';
import styles from './styles.module.scss'

type Props = {
    placeholder?: string,
    value: string,
    onChange: any
}

const TextArea = ({placeholder, value, onChange}: Props) => {
    return (
        <textarea className={styles.textarea} placeholder={placeholder} value={value} onChange={onChange}/>
    );
};

export default TextArea;

import * as CUSTOMER from '../types/customer'

const initialState = {
    data: null,
}

export default function customer (state = initialState, action: any) {
    switch (action.type) {
        case CUSTOMER.SET_CUSTOMER: {
            return {
                ...state,
                data: action.data
            }
        }
        default:
            return state
    }
}

import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import Input from "../../../components/Input/Input";
import arrow from '../../../assets/images/symbols/arrow.svg'
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import CollectionApi from "../../../utils/api/CollectionApi";
import {useSelector} from "react-redux";

type Props = {
    setCreateData: any,
    createData: any
}

const CollectionDropdown = ({createData, setCreateData}: Props) => {
    const customer = useSelector((state: any) => state.customer.data)

    const [isOpen, setIsOpen] = useState(false)
    const [collections, setCollections] = useState<any>([])

    const dropdownRef = useRef<HTMLInputElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const onToggle = () => setIsOpen(!isOpen)
    const onClose = () => setIsOpen(false)

    const onSetCollection = (selectedCol: any) => {
        setCreateData({...createData, collection: selectedCol})
        onClose()
    }

    useEffect(() => {
        if (customer?._id)
            new CollectionApi().getCollectionsDropdown().then((res: any) => {
                const filteredData = res?.data
                    ?.filter((item: any) => !item?.isExternalCollection)
                    ?.filter((item: any) => item?.creator === customer?._id)

                if (!filteredData?.length) {
                    setCollections([])
                    setCreateData({...createData, collection: null})
                } else {
                    setCollections(filteredData)

                    if (!createData?.collection?.length) {
                        setCreateData({...createData, collection: filteredData[0]})
                    }
                }
            })
    }, [customer])

    useOutsideClick(dropdownRef, onClose, inputRef)

    return (
        <div className={styles.collection}>
            <Input
                inputRef={inputRef}
                value={collections?.find((item: any) => item?.contractAddress === createData?.collection?.contractAddress)?.name || ''}
                onChange={() => null}
                placeholder={'Choose collection'}
                onlyWithArrow
                image={arrow}
                withoutCaret
                onClickWrapper={onToggle}
                isOpen={isOpen}
            />

            {isOpen && <div className={styles.dropdown} ref={dropdownRef}>
                {!!collections?.length
                    ? collections?.map((item: any, key: number) =>
                        <div key={key} className={styles.dropdown_item}
                             onClick={() => onSetCollection(item)}>
                            {item?.name}
                        </div>)
                    : <div className={styles.dropdown_item}>No collections yet</div>
                }
            </div>}
        </div>
    );
};

export default CollectionDropdown;

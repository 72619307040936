import React, {useEffect, useRef, useState} from 'react';
import TabHeader from "../TabHeader/TabHeader";
import FiltersBlock from "../FiltersBlock/FiltersBlock";
import cn from "classnames";
import {
    FILTER_BUY_NOW,
    FILTER_HAS_OFFER,
    NFT_TABLE_HEADERS, SORTING_DATA
} from "../../../constants/profile";
import NftCard from "../../../components/NFTCard/NFTCard";
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import styles from './styles.module.scss'
import Slider from "react-slick";
import verifiedBadge from '../../../assets/images/symbols/verified-badge_orange.svg'//done
import NextArrow from "../../../components/SliderArrows/NexArrow/NextArrow";
import PrevArrow from "../../../components/SliderArrows/PrevArrow/PrevArrow";
import crossIcon from "../../../assets/images/symbols/cross.svg";
import {useNavigate} from "react-router-dom";
import FiltersMobile from "../../../components/FiltersMobile/FiltersMobile";
import SortingMobile from "../../../components/SortingMobile/SortingMobile";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../../constants";
import useHandleCustomer from "../../../hooks/customer/useHandleCustomer";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import CustomerApi from "../../../utils/api/CustomerApi";
import CryptoApi from "../../../utils/api/CryptoApi";
import {sortNFTList} from "../../../utils/sortingNFT";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import {FreeMode, Mousewheel} from 'swiper/modules';
import './styles.css'
import {useSelector} from "react-redux";

const CreatedTab = ({
                        currentUser,
                        isOwner,
                        isNsfwON,
                        showHidden,
                        isOpenedFilters,
                        setIsOpenedFilters,
                        isCardView,
                        setIsCardView,
                        isSmallView,
                        setIsSmallView
                    }: {
    isCardView: boolean,
    setIsCardView: any,
    isSmallView: boolean,
    setIsSmallView: any, currentUser: any, isOpenedFilters: boolean, setIsOpenedFilters: any, isOwner: boolean, isNsfwON: boolean, showHidden: boolean
}) => {
    const customer = useSelector((state: any) => state.customer.data)
    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET
    const isMobile = windowDimensions?.width <= MOBILE

    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false)
    const [isOpenedMobileSorting, setIsOpenedMobileSorting] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<any>([])
    const [filtersAmount, setFiltersAmount] = useState(0)
    const [selectedSorting, setSelectedSorting] = useState<any>(SORTING_DATA[0])
    const [nftsList, setNftsList] = useState([])
    const [sliderData, setSliderData] = useState<any>([])
    const [filteredNftsList, setFilteredNftsList] = useState([])
    const [nftsToShow, setNftsToShow] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [forcePage, setForcePage] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')
    const [mouseMoved, setMouseMoved] = useState(false);
    const [selectedCollectionAddress, setSelectedCollectionAddress] = useState(null)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)

    const [filters, setFilters] = useState([
        {title: 'Buy Now', status: false, id: FILTER_BUY_NOW, amount: 0},
        {title: 'Has Offer', status: false, id: FILTER_HAS_OFFER, amount: 0},
    ])

    const itemsPerPage = 15
    const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0;

    const wrapperRef = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const handleCustomer = useHandleCustomer()
    const handleLoader = useHandleLoader()

    const onClickCollection = (contractAddress: string) => {
        if (!mouseMoved || isTablet)
            navigate(`/collection/${contractAddress}`)//done
    }
    const onClickViewAll = () => navigate(`/collections/${currentUser?.userName || currentUser?.address}`) //done
    const onClickDots = (nft: any) => navigate(`/collection/${nft?.contractAddress}/${nft?.tokenID}`)

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % totalCount;
        setForcePage(event.selected)
        setNftsToShow(filteredNftsList?.slice(event.selected * itemsPerPage, (event.selected + 1) * itemsPerPage))
    }

    const settings = {
        dots: false,
        infinite: sliderData?.length > 3,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <NextArrow right={'-7px'}/>,
        prevArrow: <PrevArrow left={'-5px'}/>,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 2.8,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 1390,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: TABLET,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    speed: 3000,
                    autoplaySpeed: 3000,
                    cssEase: "linear",
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    autoplay: true,
                    speed: 3000,
                    autoplaySpeed: 3000,
                    cssEase: "linear",
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                    centerMode: true,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 670,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    speed: 3000,
                    autoplaySpeed: 3000,
                    cssEase: "linear",
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                    centerMode: true,
                    centerPadding: '20px'
                }
            },
        ]
    };

    const onDeleteFilter = (id: number) => setFilters && setFilters([...filters?.map((item: any) => item?.id === id ? {
        ...item,
        status: false
    } : item)])

    const onClearFilters = () => setFilters && setFilters(filters?.map((item: any) => ({...item, status: false})))

    useEffect(() => {
        setSelectedFilters(filters?.filter((item: any) => !!item?.status))
        if (selectedCollectionAddress) {
            setFiltersAmount(filters?.filter((item: any) => item?.status)?.length + 1)
        } else setFiltersAmount(filters?.filter((item: any) => item?.status)?.length)
    }, [filters, selectedCollectionAddress])

    useEffect(() => {
        handleLoader.loaderWrapper(() => handleCustomer
            .getUserNFTsCreated(
                currentUser?.address,
                isNsfwON ? 1 : 0,
                (!showHidden || customer?.address?.toLowerCase() !== currentUser?.address?.toLowerCase()) ? 0 : 1)
            .then(res => {
                const today = moment().unix()

                setNftsList(res?.data)
                setFilteredNftsList(res?.data)
                setTotalCount(res?.data?.length)
                setNftsToShow(res?.data?.slice(0, itemsPerPage))
                setFilters([
                    {
                        title: 'Buy Now',
                        status: false,
                        id: FILTER_BUY_NOW,
                        amount: res?.data?.filter((item: any) =>
                            item?.listing?.price > 0 && item?.listing?.endDate > today && item?.listing?.startDate <= today
                        )?.length
                    },
                    {
                        title: 'Has Offer',
                        status: false,
                        id: FILTER_HAS_OFFER,
                        amount: res?.data?.filter((item: any) =>
                            !!item?.offers?.filter((offer: any) =>
                                offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                            )?.length)?.length
                    },
                ])
            }))

        new CustomerApi()
            .getUserCollections(currentUser?.address)
            .then((res: any) => setSliderData(res?.data))

        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
    }, [currentUser?.address, isNsfwON, customer?.address, showHidden])


    useEffect(() => {
        const today = moment().unix()

        let newNftsList = nftsList

        if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_BUY_NOW)?.length && !!selectedFilters?.filter((item: any) => item?.id === FILTER_HAS_OFFER)?.length) {
            newNftsList = nftsList
                ?.filter((item: any) =>
                    !!item?.offers?.filter((offer: any) =>
                        offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                    )?.length)
                ?.filter((item: any) =>
                    item?.listing?.price > 0 && item?.listing?.endDate > today && item?.listing?.startDate <= today
                )
        } else if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_BUY_NOW)?.length) {
            newNftsList = nftsList?.filter((item: any) =>
                item?.listing?.price > 0 && item?.listing?.endDate > today && item?.listing?.startDate <= today
            )
        } else if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_HAS_OFFER)?.length) {
            newNftsList = nftsList?.filter((item: any) =>
                !!item?.offers?.filter((offer: any) =>
                    offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                )?.length)
        }

        if (!!searchQuery?.length) {
            newNftsList = newNftsList?.filter((item: any) => item?.metadata?.name?.toLowerCase()?.startsWith(searchQuery?.toLowerCase()?.trim()))
        }

        if (!!selectedCollectionAddress) {
            newNftsList = newNftsList?.filter((item: any) => item?.contractAddress?.toLowerCase() === selectedCollectionAddress)
        }

        newNftsList = sortNFTList(newNftsList, selectedSorting?.id)

        setFilteredNftsList(newNftsList)
        setTotalCount(newNftsList?.length)
        setNftsToShow(newNftsList?.slice(0, itemsPerPage))
    }, [selectedFilters?.length, nftsList, searchQuery, selectedCollectionAddress, selectedSorting])

    return (
        <div className={styles.createdTab}>

            {!!sliderData?.length && <div className={styles.slider}>
                <div className={styles.collections_nav}>
                    <span className={styles.collections_nav_my}>
                        {isOwner
                            ? 'My'
                            : currentUser?.userName?.length
                                ? currentUser?.userName
                                : currentUser?.address
                        } collections</span>
                    <span className={styles.collections_nav_all} onClick={onClickViewAll}>View all</span>
                </div>

                {isTablet
                    ? <div className={styles.slider_list}>
                        <Swiper
                            // @ts-ignore
                            slidesPerView={'auto'}
                            spaceBetween={80}
                            freeMode={true}
                            centeredSlides={sliderData?.length === 1}
                            modules={[FreeMode, Mousewheel]}
                            mousewheel={true}
                            className="swiperProfile"
                        >
                            {sliderData?.map((item: any, key: number) =>
                                <SwiperSlide>
                                    <div key={key}
                                         className={styles.slider_item}
                                         onMouseMove={() => setMouseMoved(true)}
                                         onMouseDown={() => setMouseMoved(false)}
                                         onMouseUp={() => onClickCollection(item?.externalLink
                                             ? item?.externalLink?.includes('/')
                                                 ? item?.contractAddress
                                                 : item?.externalLink
                                             : item?.contractAddress)}
                                    >
                                        <div className={styles.slider_item_wrapper}
                                             onClick={() => onClickCollection(item?.externalLink
                                                 ? item?.externalLink?.includes('/')
                                                     ? item?.contractAddress
                                                     : item?.externalLink
                                                 : item?.contractAddress)}>
                                            <img src={item?.featuredImage} alt={''}
                                                 className={styles.slider_item_image}/>
                                            <div className={styles.slider_item_name}>
                                                {item?.name?.length > 20 ? item?.name?.slice(0, 20) + '...' : item?.name}
                                                {item?.isVerified && <img src={verifiedBadge} alt={''}/>}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>)}
                        </Swiper>
                    </div>
                    : <Slider {...settings}>
                        {sliderData?.map((item: any, key: number) =>
                            <div key={key}
                                 className={styles.slider_item}
                                 onMouseMove={() => setMouseMoved(true)}
                                 onMouseDown={() => setMouseMoved(false)}
                                 onMouseUp={() => onClickCollection(item?.externalLink
                                     ? item?.externalLink?.includes('/')
                                         ? item?.contractAddress
                                         : item?.externalLink
                                     : item?.contractAddress)}
                            >
                                <img src={item?.featuredImage} alt={''} className={styles.slider_item_image}/>
                                <div className={styles.slider_item_name}>
                                    {item?.name}
                                    {item?.isVerified && <img src={verifiedBadge} alt={''}/>}
                                </div>
                            </div>)}
                    </Slider>}
            </div>}

            <TabHeader
                isOpenedFilters={isOpenedFilters}
                setIsOpenedFilters={setIsOpenedFilters}
                wrapperRef={wrapperRef}
                isCardView={isCardView}
                setIsCardView={setIsCardView}
                filters={filters}
                setFilters={setFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                isOpenedMobileFilters={isOpenedMobileFilters}
                filtersAmount={filtersAmount}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                isOpenedMobileSorting={isOpenedMobileSorting}
                totalCount={filteredNftsList?.length}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                sliderData={sliderData}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />
            <div className={styles.wrapper} ref={wrapperRef}>
                <FiltersBlock
                    nftsList={nftsList}
                    isOpenedFilters={isOpenedFilters}
                    filters={filters}
                    setFilters={setFilters}
                    setSelectedCollection={setSelectedCollectionAddress}
                    selectedCollection={selectedCollectionAddress}
                />

                <div className={cn(styles.nftList_wrapper, isOpenedFilters && styles.nftList_wrapper_filters)}>
                    {(!!selectedFilters?.length && !isTablet) && <div className={styles.selectedFilters}>
                        {selectedFilters?.map((item: any, key: number) => <div
                            key={key}
                            className={styles.selectedFilters_item}
                            onClick={() => onDeleteFilter(item?.id)}
                        >
                            {item?.title}
                            <img src={crossIcon} alt={''}/>
                        </div>)}
                        <div className={styles.selectedFilters_clear} onClick={onClearFilters}>Clear filters</div>
                    </div>}

                    {!!filteredNftsList?.length ?
                        <>{isCardView
                            ? <div className={cn(styles.nftList, isOpenedFilters && styles.nftList_filters)}>
                                {nftsToShow?.map((item, key) => <NftCard key={key} nft={item} isSmall={isSmallView}/>)}
                            </div>
                            : <div className={cn(styles.NfTsTable, isOpenedFilters && styles.NfTsTable_filters)}>
                                <NfTsTable
                                    maticToUsd={maticToUsd}
                                    data={nftsToShow}
                                    headers={NFT_TABLE_HEADERS}
                                    withDots
                                    withNFTCheckbox
                                    onClickDots={onClickDots}
                                />
                            </div>
                        }</>
                        : <p className={styles.noData}>No created NFTs yet</p>}

                    {!!filteredNftsList?.length && <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        pageCount={pageCount}
                        forcePage={forcePage}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pagination_page}
                        activeClassName={styles.pagination_active}
                        previousClassName={styles.pagination_previous}
                        nextClassName={styles.pagination_next}
                        disabledClassName={styles.pagination_disabled}
                    />}
                </div>
            </div>

            {isTablet && <FiltersMobile
                isOpenedMobileFilters={isOpenedMobileFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                filters={filters}
                setFilters={setFilters}
                setSelectedCollection={setSelectedCollectionAddress}
                selectedCollection={selectedCollectionAddress}
                filtersAmount={filtersAmount}
                setIsSmallView={setIsSmallView}
                isSmallView={isSmallView}
                selectedFilters={selectedFilters}
                onDeleteFilter={onDeleteFilter}
                nftsList={nftsList}
            />}

            {isTablet && <SortingMobile
                isOpenedMobileSorting={isOpenedMobileSorting}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />}
        </div>
    );
};

export default CreatedTab;

import Base from "./Base";

export default class CryptoApi extends Base {

   async getETHtoUSD() {
        return super
            .getThirdParty('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
            .then(res => res?.USD)
    }

    async getUSDtoETH() {
        return super
            .getThirdParty('https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH')
            .then(res => res?.ETH)
    }

    async getUSDtoMATIC() {
        return super
            .getThirdParty('https://min-api.cryptocompare.com/data/price?fsym=SHIB&tsyms=USD')
            .then(res => res?.USD)
    }

    async getUSDtoBONE() {
        return super
            .getThirdParty('https://min-api.cryptocompare.com/data/price?fsym=BONE&tsyms=USD')
            .then(res => res?.USD)
    }

    async getUSDtoSHIB() {
        return super
            .getThirdParty('https://min-api.cryptocompare.com/data/price?fsym=SHIB&tsyms=USD')
            .then(res => res?.USD)
    }

    async getUSDtoLEASH() {
        return super
            .getThirdParty('https://min-api.cryptocompare.com/data/price?fsym=LEASH&tsyms=USD')
            .then(res => res?.USD)
    }
}

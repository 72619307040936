import React, {useRef, useState} from 'react';
import arrow from '../../../assets/images/symbols/arrow.svg'
import styles from './styles.module.scss'
import cn from "classnames";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import {AVAILABLE_CATEGORIES, CATEGORY_ALL_DATA} from "../../../constants/myCollectionsPage";

const CategoriesDropdown = ({setActiveCategory, activeCategory} : {setActiveCategory: any, activeCategory: any}) => {
    const [isOpen, setIsOpen] = useState(false)

    const dropdownRef = useRef<HTMLInputElement>(null)
    const wrapperRef = useRef<HTMLInputElement>(null)

    const onToggleDropdown = () => setIsOpen(!isOpen)
    const onCloseDropdown = () => setIsOpen(false)

    const onClickCategory = (category: any) => {
        setActiveCategory(category)
        onCloseDropdown()
    }

    useOutsideClick(dropdownRef, onCloseDropdown, wrapperRef)

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            <div className={styles.categories} onClick={onToggleDropdown}>
                {activeCategory?.name}
                <img
                    src={arrow}
                    alt={''}
                    className={cn(styles.categories_arrow, isOpen && styles.categories_arrow_open)}
                />
            </div>

            {isOpen && <div className={styles.dropdown} ref={dropdownRef}>
                {[CATEGORY_ALL_DATA, ...AVAILABLE_CATEGORIES]?.map((item, key) =>
                    <div key={key} className={styles.dropdown_item} onClick={() => onClickCategory(item)}>
                        {item?.name}
                    </div>)}
            </div>}
        </div>
    );
};

export default CategoriesDropdown;

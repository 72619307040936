import React, {useEffect, useState} from 'react';
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from './styles.module.scss'
import Button from "../../components/Button/Button";
import playImg from '../../assets/images/symbols/play.svg'
import SpotlightCollections from "./SpotlightCollections/SpotlightCollections";
import TopCollections from "./TopCollections/TopCollections";
import CreateSellNfTs from "./CreateSellNFTs/CreateSellNFTs";
import GuideBlock from "./GuideBlock/GuideBlock";
import CategoryBlock from "./CategoryBlock/CategoryBlock";
import IntroductionBlock from "./IntroductionBlock/IntroductionBlock";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../routes/path";
import CollectionApi from "../../utils/api/CollectionApi";
import useHandleLoader from "../../hooks/loader/useHandleLoader";
import useHandleAuth from "../../hooks/auth/useHandleAuth";
import useHandleModal from "../../hooks/dom/useHandleModal";
import {useSelector} from "react-redux";
import SignUpModal from "../../components/Modals/SignUpModal/SignUpModal";
import CustomerApi from "../../utils/api/CustomerApi";

const MainPage = () => {
    const customer = useSelector((state: any) => state.customer.data)

    const [collectionsData, setCollectionsData] = useState([])
    const [spotlightCollections, setSpotlightCollections] = useState([])
    const [promotionalCollection, setPromotionalCollection] = useState<any>(null)
    const [ownerPromotional, setOwnerPromotional] = useState<any>(null)

    const navigate = useNavigate()
    const handleLoader = useHandleLoader()
    const handleAuth = useHandleAuth()
    const handleSignUpModal = useHandleModal()

    const onClickCreate = async () => {
        if (!customer?._id) {
            await handleAuth.connectWallet(handleSignUpModal.open, () => navigate(pathList.create.path))
        } else navigate(pathList.create.path)
    }

    const onClickExplore = () => navigate(pathList.explore.path)
    const onClickPromotional = () => navigate(`/collection/${promotionalCollection?.externalLink || promotionalCollection?.contractAddress}`)

    useEffect(() => {
        handleLoader.loaderWrapper(() => new CollectionApi()
            .getAllCollections()
            .then((res) => {
                setCollectionsData(res?.data)
            })
        )

        new CollectionApi()
            .getSponsoredCollection()
            .then((res) => setPromotionalCollection(res?.data[0]))

        new CollectionApi()
            .getSpotlightCollections()
            .then((res) => setSpotlightCollections(res?.data))
    }, [])

    useEffect(() => {
        if (promotionalCollection?.creator) {
            new CustomerApi()
                .getName(promotionalCollection?.creator)
                .then((res: any) => {
                    setOwnerPromotional(res?.data?.userName?.length ? res?.data?.userName : (res?.data?.address?.slice(0, 5) + '...' + res?.data?.address?.slice(-5)))
                })
        }

        if (promotionalCollection?.contractAddress === "0x9acb9c23334e2b9eda9c301f23ce42e3dc378d35") {
            setOwnerPromotional('0xc7D0445ac2947760b3dD388B8586Adf079972Bf3'?.slice(0, 5) + '...' + '0xc7D0445ac2947760b3dD388B8586Adf079972Bf3'?.slice(-5))
        }
    }, [promotionalCollection?.contractAddress])

    return (
        <MainLayout>
            <>
                <div className={styles.topBlock}>
                    <div className={styles.titleBlock}>
                        <h1 className={styles.mainTitle}>NFTs for everyone, by anyone</h1>
                        <p className={styles.titleBlock_text}>The very first Shibarium NFT marketplace</p>
                        <div className={styles.titleBlock_actions}>
                            <Button title={'Explore'} isYellow onClick={onClickExplore}/>
                            <Button title={'Create'} onClick={onClickCreate}/>
                        </div>
                        <a href={'#learn'} className={styles.learn}>
                            <img src={playImg} alt={''}/>
                            Learn everything about Pawzaar
                        </a>
                    </div>

                    <div className={styles.imageBlock} onClick={onClickPromotional}>
                        <div className={styles.bigImg}
                             style={{backgroundImage: `url(${promotionalCollection?.featuredImage})`}}/>
                        <div className={styles.bigImg_desc}>
                            <img src={promotionalCollection?.logoImage} className={styles.bigImg_desc_logo} alt={''}
                                 width={50} height={50}/>
                            <div>
                                <p className={styles.bigImg_desc_title}>{promotionalCollection?.name}</p>
                                <p className={styles.bigImg_desc_by}>by {ownerPromotional}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <p className={styles.title}>Spotlight Collections</p>
                <SpotlightCollections collectionsData={spotlightCollections}/>

                <p className={styles.title}>Top collections</p>
                <TopCollections collectionsData={collectionsData}/>

                <p className={styles.title} id={'guides'}>Create and sell NFTs</p>
                <CreateSellNfTs/>

                <p className={styles.title}>Guide</p>
                <GuideBlock/>

                <p className={styles.title}>Category</p>
                <CategoryBlock collectionsData={collectionsData}/>

                <IntroductionBlock/>

                {handleSignUpModal.isActive && <SignUpModal
                    isOpen={handleSignUpModal.isActive}
                    onClose={handleSignUpModal.close}
                />}
            </>
        </MainLayout>
    );
};

export default MainPage;

import {
    ACTIVITY_FILTER_LISTINGS,
    ACTIVITY_FILTER_OFFERS,
    ACTIVITY_FILTER_SALES,
    ACTIVITY_FILTER_TRANSFERS, FILTER_BUY_NOW, FILTER_HAS_OFFER
} from "./profile";
import {
    STATS_TIME_1H,
    STATS_TIME_24H,
    STATS_TIME_30D,
    STATS_TIME_7D,
    STATS_TIME_90D,
    STATS_TIME_ALL_TIME
} from "./statsPage";


export const COLLECTION_TAB_NFTS = 1
export const COLLECTION_TAB_ACTIVITY = 2

export const COLLECTION_PAGE_TABS = [
    {title: 'NFTs', value: COLLECTION_TAB_NFTS},
    {title: 'Activity', value: COLLECTION_TAB_ACTIVITY},
]

export const NFTS_EVENT_FILTERS = [
    {title: 'Sales', status: true, parent: 'Event', type: ACTIVITY_FILTER_SALES, amount: 0},
    {title: 'Listings', status: false, parent: 'Event', type: ACTIVITY_FILTER_LISTINGS, amount: 0},
    {title: 'Offers', status: false, parent: 'Event', type: ACTIVITY_FILTER_OFFERS, amount: 0},
    {title: 'Transfers', status: false, parent: 'Event', type: ACTIVITY_FILTER_TRANSFERS, amount: 0},
]

export const ACTIVITY_TIME_TABS = [
    {title: '24h', value: STATS_TIME_24H},
    {title: '7d', value: STATS_TIME_7D},
    {title: '30d', value: STATS_TIME_30D},
    {title: '90d', value: STATS_TIME_90D},
    {title: 'All time', value: STATS_TIME_ALL_TIME},
]

export const SIDEBAR_TERMS = [
    {id: 1, text: 'Introduction'},
    {id: 2, text: 'Accessing the Service'},
    {id: 3, text: 'Ownership'},
    {id: 4, text: 'License to Access and Use Our Service and Content'},
    {id: 5, text: 'Third-Party Content, Agreements, and Services'},
    {id: 6, text: 'User Conduct'},
    {id: 7, text: 'Intellectual Property Rights'},
    {id: 8, text: 'Communication Preferences'},
    {id: 9, text: 'App Terms'},
    {id: 10, text: 'Indemnification'},
    {id: 11, text: 'Disclaimers'},
    {id: 12, text: 'Assumption of Risk'},
    {id: 13, text: 'Limitation of Liability'},
    {id: 14, text: 'Privacy Policy'},
    {id: 15, text: 'Modifications to the Service'},
    {id: 16, text: 'Dispute Resolution; Arbitration'},
    {id: 17, text: 'Governing Law and Venue'},
    {id: 18, text: 'Termination'},
    {id: 19, text: 'Severability'},
    {id: 20, text: 'Injunctive Relief'},
    {id: 21, text: 'California Residents'},
    {id: 22, text: 'Export Laws'},
    {id: 23, text: 'Survival'},
    {id: 24, text: 'Miscellaneous'},
]

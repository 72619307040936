import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import cross from '../../assets/images/symbols/cross.svg'
import arrow from '../../assets/images/symbols/arrow.svg'
import check from '../../assets/images/symbols/check_yellow.svg'
import searchImg from '../../assets/images/symbols/search.svg'
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../constants";
import cardView from "../../assets/images/symbols/view_cards.svg";
import cardViewGray from "../../assets/images/symbols/view_cards_gray.svg";
import listView from "../../assets/images/symbols/small_view_white.svg";
import listViewGray from "../../assets/images/symbols/small_view.svg";
import crossIcon from "../../assets/images/symbols/cross.svg";
import CollectionApi from "../../utils/api/CollectionApi";

type Props = {
    isOpenedMobileFilters: boolean,
    setIsOpenedMobileFilters: (a: any) => void,
    setFilters?: (a: any) => void,
    filters?: any,
    selectedCollection: any,
    setSelectedCollection: (a: any) => void,
    setIsSmallView?: (a: any) => void,
    filtersAmount: number,
    isSmallView?: boolean,
    selectedFilters?: any,
    onDeleteFilter?: any,
    withoutView?: boolean,
    nftsList?: any
}

const FiltersMobile = ({
                           isOpenedMobileFilters,
                           setIsOpenedMobileFilters,
                           filters,
                           setFilters,
                           selectedCollection,
                           setSelectedCollection,
                           filtersAmount,
                           isSmallView,
                           setIsSmallView,
                           selectedFilters,
                           onDeleteFilter,
                           withoutView,
                           nftsList
                       }: Props) => {
    const [isStatusOpen, setIsStatusOpen] = useState(true)
    const [isViewOpen, setIsViewOpen] = useState(true)
    const [isCollectionOpen, setIsCollectionOpen] = useState(true)
    const [collectionsData, setCollectionsData] = useState([])
    const [collectionsQuery, setCollectionsQuery] = useState<any>('')
    const [filteredCollections, setFilteredCollections] = useState([])

    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width <= MOBILE

    const onToggleStatus = () => setIsStatusOpen(!isStatusOpen)
    const onToggleCollection = () => setIsCollectionOpen(!isCollectionOpen)
    const onToggleView = () => setIsViewOpen(!isViewOpen)
    const onCloseFilters = () => setIsOpenedMobileFilters(false)
    const onToggleStatusFilters = (id: number) => setFilters && setFilters([...filters?.map((item: any) => item?.id === id ? {
        ...item,
        status: !item?.status
    } : item)])

    const onSelectCollection = (address: string) => setSelectedCollection(address)
    const onChangeQuery = (e: any) => setCollectionsQuery(e?.target?.value)

    const onClearFilters = () => {
        setSelectedCollection(null)
        setFilters && setFilters(filters?.map((item: any) => ({...item, status: false})))
    }

    const onApplyFilters = () => {
        setIsOpenedMobileFilters(false)
    }

    const onClickSmallView = () => setIsSmallView && setIsSmallView(true)
    const onClickBigView = () => setIsSmallView && setIsSmallView(false)

    useEffect(() => {
        if (nftsList?.length) {
            const uniqueAddresses: any = Array.from(new Set(nftsList?.map((item: any) => item?.contractAddress?.toLowerCase())))

            new CollectionApi()
                .getCollectionsByAddresses(uniqueAddresses)
                .then((res) => {
                    setCollectionsData(res)
                    setFilteredCollections(res)
                })
        }
    }, [nftsList])

    useEffect(() => {
        if (collectionsQuery?.length) {
            setFilteredCollections(collectionsData
                ?.filter((item: any) =>
                    item?.name?.toLowerCase()?.startsWith(collectionsQuery?.trim()?.toLowerCase())
                )
            )
        } else setFilteredCollections(collectionsData)
    }, [collectionsQuery])

    return (
        <div className={cn(styles.filtersMobile, isOpenedMobileFilters && styles.filtersMobile_isOpenedMobileFilters)}>

            <div className={styles.title}>
                Filters
                <div className={styles.cross} onClick={onCloseFilters}>
                    <img src={cross} alt={''} width={18.67} height={18.67}/>
                </div>
            </div>

            <div className={styles.content}>

                {((!!selectedFilters?.length) && isMobile) &&
                <div className={styles.selectedFilters}>
                    {selectedFilters?.map((item: any, key: number) => <div
                        key={key}
                        className={styles.selectedFilters_item}
                        onClick={() => onDeleteFilter(item?.id)}
                    >
                        {item?.title}
                        <img src={crossIcon} alt={''}/>
                    </div>)}
                </div>}

                {isMobile && !withoutView && <div className={styles.view_block}>
                    <div className={styles.status_header} onClick={onToggleView}>
                        View
                        <img src={arrow} alt={''} className={cn(styles.arrow, isViewOpen && styles.arrow_isOpen)}/>
                    </div>
                    {isViewOpen && <div className={styles.view}>
                        <div className={cn(styles.view_card, isSmallView && styles.view_card_active)}
                             onClick={onClickSmallView}>
                            <img src={isSmallView ? listView : listViewGray} alt={''}/>
                        </div>
                        <div className={cn(styles.view_list, !isSmallView && styles.view_list_active)}
                             onClick={onClickBigView}>
                            <img src={!isSmallView ? cardView : cardViewGray} alt={''}/>
                        </div>
                    </div>}
                </div>}


                {!!filters?.length && <div className={styles.status}>
                    <div className={styles.status_header} onClick={onToggleStatus}>
                        Status
                        <img src={arrow} alt={''} className={cn(styles.arrow, isStatusOpen && styles.arrow_isOpen)}/>
                    </div>

                    {(isStatusOpen && !!filters?.length) && <div className={styles.status_filters}>
                        {filters?.map((item: any, key: number) => <div className={styles.filter_row} key={key}>
                                <Checkbox
                                    setIsChecked={() => null}
                                    isChecked={item?.status}
                                    title={item?.title}
                                    onClickCallback={() => onToggleStatusFilters(item?.id)}
                                />
                                <span className={styles.amount}>{item?.amount}</span>
                            </div>
                        )}
                    </div>}
                </div>}

                <div className={cn(styles.collection, !filters?.length && styles.collection_margin)}>
                    <div className={styles.collection_header} onClick={onToggleCollection}>
                        Collection
                        <img src={arrow} alt={''}
                             className={cn(styles.arrow, isCollectionOpen && styles.arrow_isOpen)}/>
                    </div>
                    {isCollectionOpen && <div className={styles.search}>
                        <img src={searchImg} alt={''}/>
                        <input placeholder={'Search'} value={collectionsQuery} onChange={onChangeQuery}/>
                    </div>}
                    {isCollectionOpen && <div className={styles.collection_list}>
                        {filteredCollections?.map((item: any, key) =>
                            <div
                                key={key}
                                className={styles.collection_list_item}
                                onClick={() => onSelectCollection(item?.contractAddress?.toLowerCase())}
                            >
                                <div className={styles.collection_list_item_name}>
                                    <div className={styles.collection_list_item_check}>
                                        {selectedCollection === item?.contractAddress?.toLowerCase() &&
                                        <img src={check} alt={''}/>}
                                    </div>
                                    <img src={item?.logoImage} alt={''} className={styles.collection_list_item_image}/>
                                    {item?.name?.length > 15
                                        ? item?.name?.slice(0, 15) + '...'
                                        : item?.name}
                                </div>
                                <span className={styles.collection_list_item_amount}>{item?.totalTokensCount}</span>
                            </div>)}
                    </div>}
                </div>
            </div>

            <div className={styles.buttons}>
                <Button
                    title={'Clear filters'}
                    isBlackWhite
                    onClick={onClearFilters}
                />
                <Button
                    title={`Apply ${!!filtersAmount ? filtersAmount : ''} filters`}
                    isYellow
                    onClick={onApplyFilters}
                />
            </div>
        </div>
    );
};

export default FiltersMobile;

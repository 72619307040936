// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_switch__Ug9ib {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.styles_switch__Ug9ib input {
  opacity: 0;
  width: 0;
  height: 0;
}

.styles_slider__DSZGU {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #4E4E4E;
  border-radius: 60px;
  transition: 0.4s;
  width: 44px;
  height: 24px;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.styles_slider__DSZGU:before {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  left: 5px;
  bottom: 4px;
  transition: 0.4s;
  background: #FFFFFF;
  border-radius: 100%;
}

.styles_switch__Ug9ib input:checked + .styles_slider__DSZGU {
  background: rgba(247, 156, 0, 0.05);
  border: 1px solid rgba(247, 156, 0, 0.25);
  border-radius: 1230px;
}

.styles_switch__Ug9ib input:checked + .styles_slider__DSZGU:before {
  background: #F69C00;
  transform: translateX(18px);
}`, "",{"version":3,"sources":["webpack://./src/components/Switch/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAGE;EACE,UAAA;EACA,QAAA;EACA,SAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EAEA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,6BAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EAEA,gBAAA;EACA,mBAAA;EACA,mBAAA;AADF;;AAKE;EAEE,mCAAA;EACA,yCAAA;EACA,qBAAA;AAHJ;;AAQE;EACE,mBAAA;EAGA,2BAAA;AALJ","sourcesContent":[".switch {\n  position: relative;\n  display: inline-block;\n  width: 44px;\n  height: 24px;\n}\n\n.switch {\n  input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n  }\n}\n\n.slider {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: #4E4E4E;\n  border-radius: 60px;\n  -webkit-transition: .4s;\n  transition: .4s;\n  width: 44px;\n  height: 24px;\n  box-sizing: border-box;\n  border: 1px solid transparent;\n}\n\n.slider:before {\n  position: absolute;\n  content: \"\";\n  width: 14px;\n  height: 14px;\n  left: 5px;\n  bottom: 4px;\n  -webkit-transition: .4s;\n  transition: .4s;\n  background: #FFFFFF;\n  border-radius: 100%;\n}\n\n.switch {\n  input:checked + .slider {\n\n    background: rgba(247, 156, 0, 0.05);\n    border: 1px solid rgba(247, 156, 0, 0.25);\n    border-radius: 1230px;\n  }\n}\n\n.switch {\n  input:checked + .slider:before {\n    background: #F69C00;\n    -webkit-transform: translateX(18px);\n    -ms-transform: translateX(18px);\n    transform: translateX(18px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `styles_switch__Ug9ib`,
	"slider": `styles_slider__DSZGU`
};
export default ___CSS_LOADER_EXPORT___;

import moment from "moment";

export const convertDateToUnix = (date: any, time: any) => {
    const dateString = `${moment(date)
        .format('MMMM Do YYYY, hh:mm a')
        ?.split(', ')[0]}, ${time}`
    const dateFormat = moment(dateString, "MMMM Do YYYY, h:mm a").toDate();
    return moment(dateFormat).unix()
}

export function getArrayOfDates(range: number, date: any) {
    let start = 0;
    const startDate = new Date(date);
    startDate.setDate(startDate.getDate() - range);

    let result = [moment(new Date(startDate)).format('DD MMM YYYY')];

    while (start < range) {
        startDate.setDate(startDate.getDate() + 1);
        result = [...result, moment(new Date(startDate)).format('DD MMM YYYY')];
        start++;
    }

    return result;
}

export function getNumberOfDays(start: string | number | Date, end: string | number | Date) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
}

export default function formatNumber(num: number, precision = 0) {
    const map = [
        {suffix: 'T', threshold: 1e12},
        {suffix: 'B', threshold: 1e9},
        {suffix: 'M', threshold: 1e6},
        {suffix: 'K', threshold: 1e3},
        {suffix: '', threshold: 1},
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
        const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
        return formatted;
    }

    return num;
}

// export const convertIPFSUrl = (url: string) => {
//     const parts = url.split('/');
//     const cid = parts[2];
//     const path = parts.slice(3).join('/');
//
//     return `https://${cid}.ipfs.dweb.link/${path}`;
// };

// export const convertIPFSUrl = (url: string) => {
//     const cid = url.replace(/^ipfs:\/\//, '')
//     return `https://dweb.link/ipfs/${cid}`
// };

export const convertIPFSUrl = (nftData: any) => {
    if (nftData?.contractAddress === '0xcf6e0891cb36065212676f5f328e7e40a3eff68a' || nftData?.contractAddress === '0x4e24db7019ea9d0df8b72b10da149eebaa7d0e78') {
        const cid = (nftData?.metadata?.image || nftData?.imageDOLink)?.replace(/^ipfs:\/\//, '');
        return `https://dweb.link/ipfs/${cid}`;
    }
    return nftData?.imageDOLink || nftData?.metadata?.image;
};

export const playAudioCallback = (event: any) => {
    try {
        const audioList: any = document.querySelectorAll('audio');
        for (let audio of audioList) {
            if (audio?.id === event?.target?.id) continue;
            audio.pause();
        }
    } catch (err) {
        console.warn('Error: playAudioCallback')
    }
}

export const playVideoCallback = (event: any) => {
    try {
        const videoList: any = document.querySelectorAll('video');
        for (let video of videoList) {
            if (video?.id === event?.target?.id) continue;
            video.pause();
        }
    } catch (err) {
        console.warn('Error: playVideoCallback')
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__cLVM3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 14px 50px;
  background: #3E3E3E;
  border-radius: 16px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #FFA201;
  transition: all 0.3s ease;
  border: 2px solid #3E3E3E;
  box-sizing: border-box;
}

.styles_button_yellow__7b4aL {
  background: #FFA201;
  color: #FFFFFF;
  transition: all 0.3s ease;
  border: 2px solid #FFA201;
  box-sizing: border-box;
}

.styles_button_disabled__q\\+N1i {
  opacity: 0.5;
  cursor: not-allowed;
  transition: all 0.3s ease;
}

.styles_button_isBlackWhite__4PzaZ {
  background: transparent;
  color: #FFFFFF;
  transition: all 0.3s ease;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
}

.styles_button_isFullWidth__bMVSe {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,yBAAA;AACF;;AAEA;EACE,uBAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;AACF","sourcesContent":[".button{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n  padding: 14px 50px;\n  background: #3E3E3E;\n  border-radius: 16px;\n  cursor: pointer;\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 27px;\n  color: #FFA201;\n  transition: all .3s ease;\n  border: 2px solid #3E3E3E;\n  box-sizing: border-box;\n}\n\n.button_yellow{\n  background: #FFA201;\n  color: #FFFFFF;\n  transition: all .3s ease;\n  border: 2px solid #FFA201;\n  box-sizing: border-box;\n}\n\n.button_disabled{\n  opacity: .5;\n  cursor: not-allowed;\n  transition: all .3s ease;\n}\n\n.button_isBlackWhite{\n  background: transparent;\n  color: #FFFFFF;\n  transition: all .3s ease;\n  border: 2px solid #FFFFFF;\n  box-sizing: border-box;\n}\n\n.button_isFullWidth{\n  max-width: 100%;\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__cLVM3`,
	"button_yellow": `styles_button_yellow__7b4aL`,
	"button_disabled": `styles_button_disabled__q+N1i`,
	"button_isBlackWhite": `styles_button_isBlackWhite__4PzaZ`,
	"button_isFullWidth": `styles_button_isFullWidth__bMVSe`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import {useNavigate} from "react-router-dom";
import profileImg from "../../assets/images/symbols/profile.svg";
import {localStorageGet, localStorageSet} from "../../utils/localStorage";
import cross from "../../assets/images/symbols/cross.svg";
import {convertIPFSUrl} from "../../utils";

type Props = {
    isOpenSearch: boolean,
    searchRes: any,
    onCloseSearch: any,
    isLoadingSearch: boolean,
    setSearchRes: any,
    setIsLoadingSearch: any,
    searchQuery: any,
}

const MobileSearch = ({
                          isOpenSearch,
                          searchRes,
                          onCloseSearch,
                          isLoadingSearch,
                          setSearchRes,
                          setIsLoadingSearch,
                          searchQuery
                      }: Props) => {
    const navigate = useNavigate()
    const [recent, setRecent] = useState<any>([])

    const onClickNFT = (item: any) => {
        const recentFromLS = localStorageGet('SEARCH_RECENT', [])
        const newLSData = recentFromLS?.filter((nft: any) => {
            if (nft?.contractAddress?.toLowerCase() === item?.contractAddress?.toLowerCase()
                && +nft?.tokenID === +item?.tokenID)
                return null
            else return nft
        })
        localStorageSet('SEARCH_RECENT', [{...item, isNFT: true}, ...newLSData?.slice(0, 2)])
        setRecent([{...item, isNFT: true}, ...newLSData?.slice(0, 2)])

        onCloseSearch()
        navigate(`/collection/${item?.contractAddress}/${item?.tokenID}`)
        setSearchRes([])
        setIsLoadingSearch(true)
    }

    const onClickCollection = (item: any) => {
        const recentFromLS = localStorageGet('SEARCH_RECENT', [])
        const newLSData = recentFromLS?.filter((collection: any) => {
            if (item?.contractAddress?.toLowerCase() === collection?.contractAddress?.toLowerCase() && collection?.isCollection)
                return null
            else return collection
        })

        localStorageSet('SEARCH_RECENT', [{...item, isCollection: true}, ...newLSData?.slice(0, 2)])
        setRecent([{...item, isCollection: true}, ...newLSData?.slice(0, 2)])

        const contractAddress = item?.externalLink
            ? item?.externalLink?.includes('/')
                ? item?.contractAddress
                : item?.externalLink
            : item?.contractAddress

        onCloseSearch()
        navigate(`/collection/${contractAddress}`)
        setSearchRes([])
        setIsLoadingSearch(true)
    }

    const onClickUser = (item: any) => {
        const recentFromLS = localStorageGet('SEARCH_RECENT', [])
        const newLSData = recentFromLS?.filter((user: any) =>
            user?.address?.toLowerCase() !== item?.address?.toLowerCase())
        localStorageSet('SEARCH_RECENT', [{...item, isUser: true}, ...newLSData?.slice(0, 2)])
        setRecent([{...item, isUser: true}, ...newLSData?.slice(0, 2)])

        onCloseSearch()
        navigate(`/${item?.address}/collected`)
        setSearchRes([])
        setIsLoadingSearch(true)
    }

    const onDeleteNFT = (e: any, item: any) => {
        e?.stopPropagation()
        const recentFromLS = localStorageGet('SEARCH_RECENT', [])
        const newLSData = recentFromLS?.filter((nft: any) => {
            if (nft?.contractAddress?.toLowerCase() === item?.contractAddress?.toLowerCase()
                && +nft?.tokenID === +item?.tokenID)
                return null
            else return nft
        })
        localStorageSet('SEARCH_RECENT', newLSData)
        setRecent(newLSData)
    }

    const onDeleteCollection = (e: any, item: any) => {
        e?.stopPropagation()
        const recentFromLS = localStorageGet('SEARCH_RECENT', [])
        const newLSData = recentFromLS?.filter((collection: any) => {
            if (item?.contractAddress?.toLowerCase() === collection?.contractAddress?.toLowerCase() && collection?.isCollection)
                return null
            else return collection
        })

        localStorageSet('SEARCH_RECENT', newLSData)
        setRecent(newLSData)

    }

    const onDeleteUser = (e: any, item: any) => {
        e?.stopPropagation()
        const recentFromLS = localStorageGet('SEARCH_RECENT', [])
        const newLSData = recentFromLS?.filter((user: any) =>
            user?.address?.toLowerCase() !== item?.address?.toLowerCase())
        localStorageSet('SEARCH_RECENT', newLSData)
        setRecent(newLSData)
    }

    useEffect(() => {
        const recentFromLS = localStorageGet('SEARCH_RECENT', [])
        setRecent(recentFromLS)
    }, [])

    return (
        <div className={cn(styles.mobileSearch, isOpenSearch && styles.mobileSearch_isOpenMenu)}>

            {!!recent?.length && !searchQuery?.length && <div className={styles.list}>
                <p className={styles.list_title}>Recent</p>
                {recent?.map((item: any, key: number) => {
                    if (item?.isNFT)
                        return <div
                            key={key}
                            className={styles.recent_list_item}
                            onClick={() => onClickNFT(item)}
                        >
                            <div className={styles.wrapper}>
                                <img src={convertIPFSUrl(item)} alt={''} width={50} height={50}/>
                                <div>
                                    <p className={styles.id}>#{item?.tokenID} {item?.metadata?.name ? '- ' + item?.metadata?.name : ''}</p>
                                    <p className={styles.name}>{item?.name}</p>
                                </div>
                            </div>
                            <div className={styles.cross} onClick={(e) => onDeleteNFT(e, item)}>
                                <img src={cross} alt={''}/>
                            </div>
                        </div>
                    else if (item?.isCollection)
                        return <div
                            key={key}
                            className={styles.recent_list_item}
                            onClick={() => onClickCollection(item)}
                        >
                            <div className={styles.wrapper}>
                                <img src={item?.logoImage} alt={''} width={50} height={50}/>
                                <p className={styles.name}>{item?.name}</p>
                            </div>
                            <div className={styles.cross} onClick={(e) => onDeleteCollection(e, item)}>
                                <img src={cross} alt={''}/>
                            </div>
                        </div>
                    else if (item?.isUser)
                        return <div
                            key={key}
                            className={styles.recent_list_item}
                            onClick={() => onClickUser(item)}
                        >
                            <div className={styles.wrapper}>
                                <img src={item?.avatar || profileImg} alt={''} width={50} height={50}/>
                                <p className={styles.name}>
                                    {item?.userName || item?.address?.slice(0, 5) + '...' + item?.address?.slice(-5)}
                                </p>
                            </div>
                            <div className={styles.cross} onClick={(e) => onDeleteUser(e, item)}>
                                <img src={cross} alt={''}/>
                            </div>
                        </div>
                })}
            </div>}


            {(!!searchRes?.collections?.length || !!searchRes?.nft?.length || !!searchRes?.users?.length)
            && <>
                {!!searchRes?.nft?.length && <div className={styles.list}>
                    <p className={styles.list_title}>NFTs</p>

                    {searchRes?.nft?.map((item: any, key: number) =>
                        <div
                            key={key}
                            className={styles.list_item}
                            onClick={() => onClickNFT(item)}
                        >
                            <img src={convertIPFSUrl(item)} alt={''} width={50} height={50}/>
                            <div>
                                <p className={styles.id}>#{item?.tokenID} {item?.metadata?.name ? '- ' + item?.metadata?.name : ''}</p>
                                <p className={styles.name}>{item?.name}</p>
                            </div>
                        </div>)}

                </div>}

                {!!searchRes?.collections?.length && <div className={styles.list}>
                    <p className={cn(styles.list_title, styles.list_title_margin)}>Collections</p>

                    {searchRes?.collections?.map((item: any, key: number) =>
                        <div
                            key={key}
                            className={styles.list_item}
                            onClick={() => onClickCollection(item)}
                        >
                            <img src={item?.logoImage} alt={''} width={50} height={50}/>
                            <p className={styles.name}>{item?.name}</p>
                        </div>)}

                </div>}

                {!!searchRes?.users?.length && <div className={styles.list}>
                    <p className={cn(styles.list_title, styles.list_title_margin)}>Users</p>

                    {searchRes?.users?.map((item: any, key: number) =>
                        <div
                            key={key}
                            className={styles.list_item}
                            onClick={() => onClickUser(item)}
                        >
                            <img src={item?.avatar || profileImg} alt={''} width={50} height={50}/>
                            <p className={styles.name}>
                                {item?.userName || item?.address?.slice(0, 5) + '...' + item?.address?.slice(-5)}
                            </p>
                        </div>)}

                </div>}

            </>}

            {(!searchRes?.collections?.length && !searchRes?.nft?.length && !searchRes?.users?.length && !isLoadingSearch)
            && <p className={styles.noData}>No result</p>}

        </div>
    );
};

export default MobileSearch;

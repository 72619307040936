import {useDispatch} from "react-redux";
import * as loaderActions from '../../store/actions/loader'

export default function useHandleLoader() {
    const dispatch: any = useDispatch()

    const loaderWrapper = async (callback: any, opacityLevel = 3, withImage = false) => {
        if (!callback) return;

        dispatch(loaderActions.setLoader({isActive: true, opacityLevel, withImage}))
        return callback().finally(() =>  dispatch(loaderActions.setLoader({isActive: false})));
    }

    return {
        loaderWrapper
    }
}

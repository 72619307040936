// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__hHVgE {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.35px;
  color: #FFFFFF;
  margin: 5px 0 30px;
}

.styles_button__kFeRR {
  width: 100%;
  margin-top: 17px;
}
.styles_button__kFeRR div {
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/TransferModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;AAEJ","sourcesContent":[".title {\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 30px;\n  letter-spacing: -0.35px;\n  color: #FFFFFF;\n  margin: 5px 0 30px;\n}\n\n.button{\n  width: 100%;\n  margin-top: 17px;\n  div{\n    border-radius: 8px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__hHVgE`,
	"button": `styles_button__kFeRR`
};
export default ___CSS_LOADER_EXPORT___;

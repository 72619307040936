// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_categoryBlock__ymu\\+- {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 176px;
}
@media (max-width: 640px) {
  .styles_categoryBlock__ymu\\+- {
    margin-bottom: 100px;
  }
}

.styles_category_item__UEcso {
  max-width: 505px;
  width: 100%;
  height: 348px;
  border-radius: 24px;
  cursor: pointer;
}
@media (max-width: 1025px) {
  .styles_category_item__UEcso {
    max-width: 344px;
  }
}
@media (max-width: 640px) {
  .styles_category_item__UEcso {
    max-width: 320px;
    height: 274px;
  }
}

.styles_colored__SQ94w {
  width: 100%;
  height: 272px;
  border-radius: 24px 24px 0 0;
}
@media (max-width: 640px) {
  .styles_colored__SQ94w {
    height: 218px;
  }
}
.styles_colored__SQ94w img {
  height: 272px;
  width: 505px;
  object-fit: cover;
  border-radius: 24px 24px 0px 0px;
}
@media (max-width: 1025px) {
  .styles_colored__SQ94w img {
    max-width: 344px;
  }
}
@media (max-width: 640px) {
  .styles_colored__SQ94w img {
    max-width: 320px;
    height: 218px;
  }
}

.styles_title__dDfmo {
  background: #3E3E3E;
  border-radius: 0 0 24px 24px;
  padding: 24px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.35px;
  color: #FFA201;
}
@media (max-width: 640px) {
  .styles_title__dDfmo {
    font-size: 15px;
    font-style: normal;
    padding: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/templates/MainPage/CategoryBlock/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,eAAA;EACA,oBAAA;AACF;AAAE;EAPF;IAQI,oBAAA;EAGF;AACF;;AAAA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAGF;AADE;EAPF;IAQI,gBAAA;EAIF;AACF;AAFE;EAXF;IAYI,gBAAA;IACA,aAAA;EAKF;AACF;;AAFA;EACE,WAAA;EACA,aAAA;EACA,4BAAA;AAKF;AAJE;EAJF;IAKI,aAAA;EAOF;AACF;AALE;EACE,aAAA;EACA,YAAA;EACA,iBAAA;EACA,gCAAA;AAOJ;AALI;EANF;IAOI,gBAAA;EAQJ;AACF;AAPI;EATF;IAUI,gBAAA;IACA,aAAA;EAUJ;AACF;;AANA;EACE,mBAAA;EACA,4BAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,cAAA;AASF;AARE;EAVF;IAWI,eAAA;IACA,kBAAA;IACA,aAAA;EAWF;AACF","sourcesContent":[".categoryBlock {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 32px;\n  flex-wrap: wrap;\n  margin-bottom: 176px;\n  @media (max-width: 640px) {\n    margin-bottom: 100px;\n  }\n}\n\n.category_item {\n  max-width: 505px;\n  width: 100%;\n  height: 348px;\n  border-radius: 24px;\n  cursor: pointer;\n\n  @media (max-width: 1025px) {\n    max-width: 344px;\n  }\n\n  @media (max-width: 640px) {\n    max-width: 320px;\n    height: 274px;\n  }\n}\n\n.colored {\n  width: 100%;\n  height: 272px;\n  border-radius: 24px 24px 0 0;\n  @media (max-width: 640px) {\n    height: 218px;\n  }\n\n  img {\n    height: 272px;\n    width: 505px;\n    object-fit: cover;\n    border-radius: 24px 24px 0px 0px;\n\n    @media (max-width: 1025px) {\n      max-width: 344px;\n    }\n    @media (max-width: 640px) {\n      max-width: 320px;\n      height: 218px;\n    }\n  }\n}\n\n.title {\n  background: #3E3E3E;\n  border-radius: 0 0 24px 24px;\n  padding: 24px;\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 28px;\n  text-align: center;\n  letter-spacing: -0.35px;\n  color: #FFA201;\n  @media (max-width: 640px) {\n    font-size: 15px;\n    font-style: normal;\n    padding: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryBlock": `styles_categoryBlock__ymu+-`,
	"category_item": `styles_category_item__UEcso`,
	"colored": `styles_colored__SQ94w`,
	"title": `styles_title__dDfmo`
};
export default ___CSS_LOADER_EXPORT___;

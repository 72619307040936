import Base from "./Base";

export default class CustomerApi extends Base {
    async getInfo() {
        return super.get(`customer/info`);
    }

    async getProfile(address: any) {
        return super.get(`customer/profile/${address}`);
    }

    async getNonce(address: string) {
        return super.get(`customer/nonce/${address}`);
    }

    async getName(id: string) {
        return super.get(`customer/name/${id}`);
    }

    async getUserName(address: string) {
        return super.get(`customer/userName/${address}`);
    }

    async editInfo(data: any) {
        return super.put(`customer/info`, data);
    }

    async getUserNftsCreated(walletAddress: string,isNsfwON = 1, showHidden = 1) {
        return super.get(`marketplace/tokenByUser/created/${walletAddress}/${isNsfwON}/${showHidden}`)
    }

    async getUserNftsCollected(walletAddress: string, limit: number, page: number) {
        return super.get(`marketplace/tokenByUser/collected/${walletAddress}?limit=${limit}&page=${page}`)
    }

    async getUserNftsCollectedTab(walletAddress: string, isNsfwON = 1, showHidden = 1) {
        return super.get(`marketplace/tokenByUser/collectedTab/${walletAddress}/${isNsfwON}/${showHidden}`)
    }

    async getUserCollections(address: string) {
        return super.get(`collection/byUser/${address}`)
    }

    async checkUniqueUrl(url: string) {
        return super.post(`customer/checkUniqueUrl`, {url})
    }
}

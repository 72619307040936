import check from '../assets/images/symbols/checked.svg'
import cross from '../assets/images/symbols/cross.svg'

export const TABLET = 1025
export const MOBILE = 640

export const EVENT_TYPE_TRANSFER = 1

export const EVENT_TYPE_SALE = 2
export const EVENT_TYPE_LISTING = 3
export const EVENT_TYPE_LISTING_EDITED = 4
export const EVENT_TYPE_LISTING_CANCELED = 5

export const EVENT_TYPE_OFFER = 6
export const EVENT_TYPE_OFFER_ACCEPTED = 7
export const EVENT_TYPE_OFFER_CANCELED = 8
export const EVENT_TYPE_OFFER_EDITED = 9


export const EVENT_TYPES: any = {
    [EVENT_TYPE_TRANSFER]: 'Transfer',
    [EVENT_TYPE_SALE]: 'Sale',
    [EVENT_TYPE_LISTING]: 'List',
    [EVENT_TYPE_LISTING_EDITED]: 'List edited',
    [EVENT_TYPE_LISTING_CANCELED]: 'List canceled',
    [EVENT_TYPE_OFFER]: 'Offer',
    [EVENT_TYPE_OFFER_ACCEPTED]: 'Offer accepted',
    [EVENT_TYPE_OFFER_CANCELED]: 'Offer canceled',
    [EVENT_TYPE_OFFER_EDITED]: 'Offer edited',
}

export const EVENT_TYPES_IMAGES: any = {
    [EVENT_TYPE_TRANSFER]: check,
    [EVENT_TYPE_SALE]: check,
    [EVENT_TYPE_LISTING]: check,
    [EVENT_TYPE_LISTING_EDITED]: check,
    [EVENT_TYPE_LISTING_CANCELED]: cross,
    [EVENT_TYPE_OFFER]: check,
    [EVENT_TYPE_OFFER_ACCEPTED]: check,
    [EVENT_TYPE_OFFER_CANCELED]: cross,
    [EVENT_TYPE_OFFER_EDITED]: check,
}

export const EVENT_TYPES_COLORS: any = {
    [EVENT_TYPE_TRANSFER]: '#99B83C',
    [EVENT_TYPE_SALE]: '#99B83C',
    [EVENT_TYPE_LISTING]: '#99B83C',
    [EVENT_TYPE_LISTING_EDITED]: '#99B83C',
    [EVENT_TYPE_LISTING_CANCELED]: '#E81A00',
    [EVENT_TYPE_OFFER]: '#99B83C',
    [EVENT_TYPE_OFFER_ACCEPTED]: '#99B83C',
    [EVENT_TYPE_OFFER_CANCELED]: '#E81A00',
    [EVENT_TYPE_OFFER_EDITED]: '#99B83C',
}

export const METADATA_CATEGORY_LEVEL = 'LEVEL'
export const METADATA_CATEGORY_PROPERTY = 'PROPERTY'
export const METADATA_CATEGORY_STATS = 'STATS'

// export const NETWORK_VERSION = '80001'
export const CHAIN_ID = "0x6d"
export const CHAIN_NAME = "Shibarium"
export const CHAIN_RPC_URL = 'https://www.shibrpc.com'
export const BLOCK_EXPLORER_URL = 'https://shibariumscan.io'
export const NATIVE_CURRENCY = 'BONE'

export const SHIB_ADDRESS = '0x495eea66b0f8b636d441dc6a98d8f5c3d455c4c0'
export const LEASH_ADDRESS = '0x65218a41fb92637254b4f8c97448d3df343a3064'
export const WBONE_ADDRESS = '0xC76F4c819D820369Fb2d7C1531aB3Bb18e6fE8d8'

export const WHITELIST_DROPS_COLLECTIONS = [
    '0x267404a991d46d3a15f6ffbe27a2bc44d4b5267a',
    '0x153b19c4beaaf9fbc92832455c82702298bf2466',
    '0x4a70cd5b4c3464fe23f47fd99d880de7583a2061',
    '0xcae9f328c20b3171d30b1fe1231f462f61f7aab0',
    '0x544612b9c27e3c110c38fb9b8488258ee7a57554',
    '0x5acb5930fefe8098fa6c7f76cc5ddd55216c057a',
    '0x0f3e392700a26d5ae0cd639d0ea21adce9d74928',
    '0xe103768ff7315b5313a6334643eb6399871a197c',
    '0xe20f25a5eac567301eee7268cfdd152427b57d54'
]

import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import TabHeader from "../TabHeader/TabHeader";
import cn from "classnames";
import FiltersBlock from "../FiltersBlock/FiltersBlock";
import {
    ACTIVITY_FILTER_LISTINGS, ACTIVITY_FILTER_OFFERS,
    ACTIVITY_FILTER_SALES, ACTIVITY_FILTER_TRANSFERS,
    ACTIVITY_TABLE_HEADERS,
} from "../../../constants/profile";
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import crossIcon from "../../../assets/images/symbols/cross.svg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {
    EVENT_TYPE_LISTING,
    EVENT_TYPE_LISTING_CANCELED,
    EVENT_TYPE_LISTING_EDITED,
    EVENT_TYPE_OFFER,
    EVENT_TYPE_OFFER_ACCEPTED,
    EVENT_TYPE_OFFER_CANCELED,
    EVENT_TYPE_OFFER_EDITED,
    EVENT_TYPE_SALE, EVENT_TYPE_TRANSFER,
    TABLET
} from "../../../constants";
import FiltersMobile from "../../../components/FiltersMobile/FiltersMobile";
import ActivityTabItem from '../ActivityTabItem/ActivityTabItem';
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import ReactPaginate from "react-paginate";
import CryptoApi from "../../../utils/api/CryptoApi";

const ActivityTab = ({
                         currentUser, isOpenedFilters,
                         setIsOpenedFilters
                     }: { currentUser: any, isOpenedFilters: boolean, setIsOpenedFilters: any }) => {

    const handleActivity = useHandleActivity()
    const handleLoader = useHandleLoader()
    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const [isCardView, setIsCardView] = useState(true)
    const [selectedFilters, setSelectedFilters] = useState<any>([])
    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false)
    const [filtersAmount, setFiltersAmount] = useState(0)
    const [activitiesData, setActivitiesData] = useState([])
    const [activitiesDataFiltered, setActivitiesDataFiltered] = useState([])
    const [activitiesToShow, setActivitiesToShow] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [forcePage, setForcePage] = useState(0)
    const [selectedCollectionAddress, setSelectedCollectionAddress] = useState(null)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)

    const [filters, setFilters] = useState([
        {title: 'Sales', status: false, id: ACTIVITY_FILTER_SALES, amount: 0},
        {title: 'Listings', status: false, id: ACTIVITY_FILTER_LISTINGS, amount: 0},
        {title: 'Offers', status: false, id: ACTIVITY_FILTER_OFFERS, amount: 0},
        {title: 'Transfers', status: false, id: ACTIVITY_FILTER_TRANSFERS, amount: 0},
    ])

    const itemsPerPage = 10
    const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0;

    const onDeleteFilter = (id: number) => setFilters && setFilters([...filters?.map((item: any) => item?.id === id ? {
        ...item,
        status: false
    } : item)])

    const onClearFilters = () => setFilters && setFilters(filters?.map((item: any) => ({...item, status: false})))

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % totalCount;
        setForcePage(event.selected)
        setActivitiesToShow(activitiesDataFiltered?.slice(event.selected * itemsPerPage, (event.selected + 1) * itemsPerPage))
    }

    const wrapperRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setSelectedFilters(filters?.filter((item: any) => !!item?.status))
        if (selectedCollectionAddress) {
            setFiltersAmount(filters?.filter((item: any) => item?.status)?.length + 1)
        } else setFiltersAmount(filters?.filter((item: any) => item?.status)?.length)
    }, [filters, selectedCollectionAddress])

    useEffect(() => {
        if (!!currentUser) handleLoader.loaderWrapper(() =>
            handleActivity.getActivitiesByUser(currentUser?.address))
            .then(res => {
                setActivitiesData(res?.data?.reverse())
                setActivitiesDataFiltered(res?.data?.reverse())
                setTotalCount(res?.data?.length)
                setActivitiesToShow(res?.data?.reverse()?.slice(0, itemsPerPage))
                setFilters([
                    {title: 'Sales', status: false, id: ACTIVITY_FILTER_SALES, amount: res?.salesCount},
                    {title: 'Listings', status: false, id: ACTIVITY_FILTER_LISTINGS, amount: res?.listingsCount},
                    {title: 'Offers', status: false, id: ACTIVITY_FILTER_OFFERS, amount: res?.offersCount},
                    {title: 'Transfers', status: false, id: ACTIVITY_FILTER_TRANSFERS, amount: res?.transfersCount},
                ])
            })

        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
    }, [currentUser])

    useEffect(() => {
        let newData: any = activitiesData

        if (selectedFilters?.length) {
            newData = []

            if (!!selectedFilters?.filter((item: any) => item?.id === ACTIVITY_FILTER_SALES)?.length) {
                newData = [
                    ...newData,
                    ...activitiesData?.filter((item: any) =>
                        item?.eventType === EVENT_TYPE_SALE)
                ]
            }

            if (!!selectedFilters?.filter((item: any) => item?.id === ACTIVITY_FILTER_LISTINGS)?.length) {
                newData = [
                    ...newData,
                    ...activitiesData?.filter((item: any) =>
                        item?.eventType === EVENT_TYPE_LISTING
                        || item?.eventType === EVENT_TYPE_LISTING_CANCELED
                        || item?.eventType === EVENT_TYPE_LISTING_EDITED)
                ]
            }

            if (!!selectedFilters?.filter((item: any) => item?.id === ACTIVITY_FILTER_OFFERS)?.length) {
                newData = [
                    ...newData,
                    ...activitiesData?.filter((item: any) =>
                        item?.eventType === EVENT_TYPE_OFFER
                        || item?.eventType === EVENT_TYPE_OFFER_CANCELED
                        || item?.eventType === EVENT_TYPE_OFFER_ACCEPTED
                        || item?.eventType === EVENT_TYPE_OFFER_EDITED)
                ]
            }

            if (!!selectedFilters?.filter((item: any) => item?.id === ACTIVITY_FILTER_TRANSFERS)?.length) {
                newData = [
                    ...newData,
                    ...activitiesData?.filter((item: any) =>
                        item?.eventType === EVENT_TYPE_TRANSFER)
                ]
            }

        }

        if (!!selectedCollectionAddress) {
            newData = newData?.filter((item: any) => item?.contractAddress?.toLowerCase() === selectedCollectionAddress)
        }


        setActivitiesDataFiltered(newData)
        setTotalCount(newData?.length)
        setActivitiesToShow(newData?.slice(0, itemsPerPage))
    }, [selectedFilters?.length, selectedCollectionAddress])

    return (
        <div className={styles.activityTab}>
            <TabHeader
                isOpenedFilters={isOpenedFilters}
                setIsOpenedFilters={setIsOpenedFilters}
                wrapperRef={wrapperRef}
                isCardView={isCardView}
                setIsCardView={setIsCardView}
                isActivityTab
                filters={filters}
                setFilters={setFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                isOpenedMobileFilters={isOpenedMobileFilters}
                filtersAmount={filtersAmount}
            />

            <div className={styles.wrapper} ref={wrapperRef}>

                {!isTablet && <FiltersBlock
                    isOpenedFilters={isOpenedFilters}
                    isActivityTab
                    setFilters={setFilters}
                    filters={filters}
                    nftsList={activitiesData}
                    setSelectedCollection={setSelectedCollectionAddress}
                    selectedCollection={selectedCollectionAddress}
                />}

                {isTablet && <div className={styles.list}>
                    {!!activitiesDataFiltered?.length
                        ? activitiesToShow?.map((item, key) =>
                            <ActivityTabItem maticToUsd={maticToUsd} key={key} item={item}/>)
                        : <p className={styles.empty}>No recent activities found</p>
                    }

                    {!!activitiesDataFiltered?.length && <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        pageCount={pageCount}
                        forcePage={forcePage}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pagination_page}
                        activeClassName={styles.pagination_active}
                        previousClassName={styles.pagination_previous}
                        nextClassName={styles.pagination_next}
                        disabledClassName={styles.pagination_disabled}
                    />}
                </div>}

                {!isTablet &&
                <div className={cn(styles.nftList_wrapper, isOpenedFilters && styles.nftList_wrapper_filters)}>
                    {!!selectedFilters?.length && <div className={styles.selectedFilters}>
                        {selectedFilters?.map((item: any, key: number) => <div
                            key={key}
                            className={styles.selectedFilters_item}
                            onClick={() => onDeleteFilter(item?.id)}
                        >
                            {item?.title}
                            <img src={crossIcon} alt={''}/>
                        </div>)}
                        <div className={styles.selectedFilters_clear} onClick={onClearFilters}>Clear filters</div>
                    </div>}

                    <div className={styles.NfTsTable}>
                        {!!activitiesDataFiltered?.length
                            ? <NfTsTable maticToUsd={maticToUsd} data={activitiesToShow}
                                         headers={ACTIVITY_TABLE_HEADERS}/>
                            : <p className={styles.empty}>No recent activities found</p>
                        }

                        {!!activitiesDataFiltered?.length && <ReactPaginate
                            breakLabel="..."
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={3}
                            pageCount={pageCount}
                            forcePage={forcePage}
                            previousLabel="<"
                            nextLabel=">"
                            renderOnZeroPageCount={null}
                            containerClassName={styles.pagination}
                            pageClassName={styles.pagination_page}
                            activeClassName={styles.pagination_active}
                            previousClassName={styles.pagination_previous}
                            nextClassName={styles.pagination_next}
                            disabledClassName={styles.pagination_disabled}
                        />}
                    </div>
                </div>}
            </div>

            {isTablet && <FiltersMobile
                withoutView
                isOpenedMobileFilters={isOpenedMobileFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                filters={filters}
                setFilters={setFilters}
                setSelectedCollection={setSelectedCollectionAddress}
                selectedCollection={selectedCollectionAddress}
                filtersAmount={filtersAmount}
                nftsList={activitiesData}
            />}

        </div>
    );
};

export default ActivityTab;

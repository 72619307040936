import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import MainLayout from "../../layouts/MainLayout/MainLayout";
import userImg from '../../assets/images/profile_photo.svg'
import twitterImg from '../../assets/images/symbols/twitter.svg'
import crossedEye from '../../assets/images/symbols/crossed_eye.svg'
import eye from '../../assets/images/symbols/eye.svg'
import dots from '../../assets/images/symbols/dots.svg'
import editImg from '../../assets/images/symbols/edit.svg'
import shareImg from '../../assets/images/symbols/link.svg'
import Button from "../../components/Button/Button";
import {
    ACTIVITY_TAB,
    COLLECTED_TAB,
    CREATED_TAB, FAVOURITES_TAB,
    LISTINGS_TAB,
    OFFERS_TAB,
    PROFILE_TABS
} from "../../constants/profile";
import cn from "classnames";
import CollectedTab from "./CollectedTab/CollectedTab";
import CreatedTab from "./CreatedTab/CreatedTab";
import ActivityTab from "./ActivityTab/ActivityTab";
import ListingsTab from "./ListingsTab/ListingsTab";
import OffersTab from "./OffersTab/OffersTab";
import FavouritesTab from "./FavouritesTab/FavouritesTab";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../constants";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import {useSelector} from "react-redux";
import useHandleModal from "../../hooks/dom/useHandleModal";
import EditProfileModal from "../../components/Modals/EditProfileModal/EditProfileModal";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import CustomerApi from "../../utils/api/CustomerApi";
import {pathList} from "../../routes/path";
import {toast} from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";

const ProfilePage = () => {
        const customer = useSelector((state: any) => state.customer.data)

        const [isOpenedFilters, setIsOpenedFilters] = useState(false)
        const [isCardView, setIsCardView] = useState(true)
        const [isSmallView, setIsSmallView] = useState(false)
        const [isCrossedEye, setIsCrossedEye] = useState(true)
        const [isTabletMenuOpen, setIsTabletMenuOpen] = useState(false)
        const [activeTab, setActiveTab] = useState(COLLECTED_TAB)
        const [isOwner, setIsOwner] = useState(false)
        const [currentUser, setCurrentUser] = useState<any>({})
        const [isCopied, setIsCopied] = useState(false)
        const [isCopiedAddress, setIsCopiedAddress] = useState(false)
        const [isNsfwON, setIsNsfwON] = useState(false)

        const windowDimensions = useWindowDimensions()
        const menuRef = useRef<HTMLInputElement>(null)
        const dotsRef = useRef<HTMLInputElement>(null)
        const copyRef: any = useRef<HTMLInputElement>(null)
        const addressRef: any = useRef<HTMLInputElement>(null)
        const handleEditModal = useHandleModal()
        const location = useLocation()
        const params = useParams()
        const navigate = useNavigate()

        const isTablet = windowDimensions?.width <= TABLET
        const isMobile = windowDimensions?.width <= MOBILE

        const onClickTab = (value: string) => navigate('/' + (currentUser?.userName || currentUser?.address) + value)
        const onToggleMenu = () => setIsTabletMenuOpen(!isTabletMenuOpen)
        const onCloseMenu = () => setIsTabletMenuOpen(false)
        const onOpenEditModal = () => handleEditModal.open()
        // const onToggleNSFW = () => setIsNsfwON(!isNsfwON)
        const onClickEye = () => setIsCrossedEye(!isCrossedEye)
        const onClickMainPage = () => navigate(pathList.mainPage.path)

        const onCopy = () => {
            if (isCopied) return

            navigator.clipboard.writeText(copyRef?.current?.value).then(() => {
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 3000)
            })
        }

        const onCopyAddress = () => {
            if (isCopiedAddress) return

            navigator.clipboard.writeText(addressRef?.current?.value).then(() => {
                setIsCopiedAddress(true)
                toast(<ToastMsg text={'Copied to clipboard!'}/>)
                setTimeout(() => setIsCopiedAddress(false), 3000)
            })
        }

        useOutsideClick(menuRef, onCloseMenu, dotsRef)

        useEffect(() => {
            const locationArr = location?.pathname?.split('/')
            setActiveTab('/' + locationArr[2])
        }, [location?.pathname])

        useEffect(() => {
            setIsOwner(params?.address?.toLowerCase() === customer?.address?.toLowerCase()
                || params?.address?.toLowerCase() === customer?.userName?.toLowerCase())

            if (params?.address?.toLowerCase() === customer?.address?.toLowerCase()
                || params?.address?.toLowerCase() === customer?.userName?.toLowerCase())
                setCurrentUser(customer)
            else new CustomerApi()
                .getProfile(params?.address)
                .then((res) => setCurrentUser(res?.data))

        }, [params?.address, customer])

        useEffect(() => {
            setIsOpenedFilters(!isTablet)
            setIsSmallView(isMobile)
            setIsCardView(true)
        }, [isTablet, isMobile])

        return (
            <MainLayout isFullWidth>
                {!currentUser
                    ? <FullPageLoader isLoaderActive={true}/>
                    : currentUser?.isBlocked
                        ? <div className={styles.removed}>
                            <p>This user was removed from the marketplace by admin</p>
                            <Button title={'Go to Main Page'} onClick={onClickMainPage} isYellow/>
                        </div>
                        : <div className={styles.profilePage}>

                            <div className={styles.header}>
                                <div className={styles.header_profile}>
                                    <img src={currentUser?.avatar || userImg} alt={''} width={186} height={186}
                                         className={styles.header_profile_avatar}/>
                                    {currentUser?._id && <div>
                                        <div className={styles.header_name}>
                                            {currentUser?.userName || ''}
                                            {!!currentUser?.twitter && <a
                                                href={currentUser?.twitter?.includes('https://')
                                                    ? currentUser?.twitter
                                                    : `https://${currentUser?.twitter}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={twitterImg} alt={''} width={20.92} height={17}/>
                                            </a>}
                                        </div>
                                        <p className={styles.header_address} onClick={onCopyAddress}>
                                            <input
                                                value={currentUser?.address}//done
                                                ref={addressRef}
                                                hidden
                                                onChange={() => {
                                                }}/>
                                            {currentUser?.address?.slice(0, 6) + '...' + currentUser?.address?.slice(-4)}
                                        </p>
                                    </div>}
                                </div>

                                {isTablet
                                    ? <div className={styles.menu_wrapper}>
                                        <div className={styles.dots} ref={dotsRef} onClick={onToggleMenu}>
                                            <img src={dots} alt={''} width={21.33} height={5.33}/>
                                        </div>

                                        {isTabletMenuOpen && <div ref={menuRef} className={styles.menu}>
                                            {isOwner && <div className={styles.menu_item} onClick={onOpenEditModal}>
                                                <div><img src={editImg} alt={''}/></div>
                                                Edit profile
                                            </div>}
                                            {isOwner && <div className={styles.menu_item} onClick={onClickEye}>
                                                <div><img src={isCrossedEye ? crossedEye : eye} alt={''}/></div>
                                                {isCrossedEye ? 'Hide' : 'Show'}
                                            </div>}
                                            <div className={styles.menu_item} onClick={onCopy}>
                                                <div><img src={shareImg} alt={''}/></div>
                                                {isCopied ? 'Copied' : 'Copy Link'}
                                                <input
                                                    value={`${process.env.REACT_APP_LINK}/${currentUser?.userName || currentUser?.address}/collected`}//done
                                                    ref={copyRef}
                                                    hidden
                                                    onChange={() => {
                                                    }}/>
                                            </div>
                                        </div>}
                                    </div>
                                    : <div className={styles.header_buttons}>
                                        {isOwner && <div className={styles.header_eye} onClick={onClickEye}>
                                            <img src={isCrossedEye ? crossedEye : eye} alt={''} width={27.98}
                                                 height={21.96}/>
                                        </div>}
                                        <div className={styles.copyBtn}>
                                            <Button
                                                title={isCopied ? 'Copied' : 'Copy Link'}
                                                isBlackWhite
                                                onClick={onCopy}
                                                image={shareImg}
                                            />
                                            <input
                                                value={`${process.env.REACT_APP_LINK}/${currentUser?.userName || currentUser?.address}/collected`}//done
                                                ref={copyRef}
                                                hidden
                                                onChange={() => {
                                                }}/>
                                        </div>
                                        {isOwner && <Button title={'Edit profile'} isBlackWhite onClick={onOpenEditModal}/>}
                                    </div>}
                            </div>

                            <div className={styles.tabs}>
                                {PROFILE_TABS?.map((item, key) => <span
                                    key={key}
                                    onClick={() => onClickTab(item?.value)}
                                    className={cn(styles.tabs_item, activeTab === item?.value && styles.tabs_item_active)}
                                >
                        {item?.title}
                    </span>)}
                            </div>

                            <div className={styles.content}>
                                {activeTab === COLLECTED_TAB && <CollectedTab
                                    isNsfwON={isNsfwON}
                                    showHidden={isCrossedEye}
                                    currentUser={currentUser}
                                    isOwner={isOwner}
                                    isOpenedFilters={isOpenedFilters}
                                    setIsOpenedFilters={setIsOpenedFilters}
                                    isCardView={isCardView}
                                    setIsCardView={setIsCardView}
                                    isSmallView={isSmallView}
                                    setIsSmallView={setIsSmallView}
                                />}

                                {activeTab === CREATED_TAB &&
                                <CreatedTab
                                    isNsfwON={isNsfwON}
                                    showHidden={isCrossedEye}
                                    isOwner={isOwner}
                                    currentUser={currentUser}
                                    isOpenedFilters={isOpenedFilters}
                                    setIsOpenedFilters={setIsOpenedFilters}
                                    isCardView={isCardView}
                                    setIsCardView={setIsCardView}
                                    isSmallView={isSmallView}
                                    setIsSmallView={setIsSmallView}
                                />}

                                {activeTab === ACTIVITY_TAB && <ActivityTab
                                    currentUser={currentUser}
                                    isOpenedFilters={isOpenedFilters}
                                    setIsOpenedFilters={setIsOpenedFilters}
                                />}
                                {activeTab === LISTINGS_TAB && <ListingsTab
                                    isOwner={isOwner}
                                    currentUser={currentUser}
                                    isOpenedFilters={isOpenedFilters}
                                    setIsOpenedFilters={setIsOpenedFilters}
                                />}
                                {activeTab === OFFERS_TAB && <OffersTab
                                    isOwner={isOwner}
                                    currentUser={currentUser}
                                    isOpenedFilters={isOpenedFilters}
                                    setIsOpenedFilters={setIsOpenedFilters}
                                />}

                                {activeTab === FAVOURITES_TAB && <FavouritesTab
                                    isNsfwON={isNsfwON}
                                    currentUser={currentUser}
                                    showHidden={isCrossedEye}
                                    isOpenedFilters={isOpenedFilters}
                                    setIsOpenedFilters={setIsOpenedFilters}
                                    isCardView={isCardView}
                                    setIsCardView={setIsCardView}
                                    isSmallView={isSmallView}
                                    setIsSmallView={setIsSmallView}
                                />}
                            </div>

                            {handleEditModal.isActive &&
                                <EditProfileModal
                                    isOpen={handleEditModal.isActive}
                                    onClose={handleEditModal.close}/>}

                        </div>
                }
            </MainLayout>
        );
    }
;

export default ProfilePage;

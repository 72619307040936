// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiperExplore .swiper {
    width: 100%;
}

.swiperExplore .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1025px) {
    .swiperExplore .swiper-slide {
        width: 40%;
    }
}

@media (max-width: 970px) {
    .swiperExplore .swiper-slide {
        width: 50%;
    }
}

@media (max-width: 760px) {
    .swiperExplore .swiper-slide {
        width: 60%;
    }
}

@media (max-width: 640px) {
    .swiperExplore .swiper-slide {
        width: 40%;
    }
}

@media (max-width: 470px) {
    .swiperExplore .swiper-slide {
        width: 50%;
    }
}

@media (max-width: 390px) {
    .swiperExplore .swiper-slide {
        width: 60%;
    }
}

@media (max-width: 330px) {
    .swiperExplore .swiper-slide {
        width: 70%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/templates/ExplorePage/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".swiperExplore .swiper {\n    width: 100%;\n}\n\n.swiperExplore .swiper-slide {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n@media (max-width: 1025px) {\n    .swiperExplore .swiper-slide {\n        width: 40%;\n    }\n}\n\n@media (max-width: 970px) {\n    .swiperExplore .swiper-slide {\n        width: 50%;\n    }\n}\n\n@media (max-width: 760px) {\n    .swiperExplore .swiper-slide {\n        width: 60%;\n    }\n}\n\n@media (max-width: 640px) {\n    .swiperExplore .swiper-slide {\n        width: 40%;\n    }\n}\n\n@media (max-width: 470px) {\n    .swiperExplore .swiper-slide {\n        width: 50%;\n    }\n}\n\n@media (max-width: 390px) {\n    .swiperExplore .swiper-slide {\n        width: 60%;\n    }\n}\n\n@media (max-width: 330px) {\n    .swiperExplore .swiper-slide {\n        width: 70%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const pathList = {
    login: {
        path: '/login'
    },
    mainPage: {
        path: '/'
    },
    explore: {
        path: '/explore'
    },
    exploreDefinedTab: {
        path: '/explore/:category'
    },
    create: {
        path: '/create'
    },
    createCollection: {
        path: '/createCollection'
    },
    createDropsCollection: {
        path: '/createDropsCollection'
    },
    myCollections: {
        path: '/collections/:address'
    },
    stats: {
        path: '/stats'
    },
    rewards: {
        path: '/rewards'
    },

    profileCollected: {
        path: '/:address/collected'
    },
    profileCreated: {
        path: '/:address/created'
    },
    profileActivity: {
        path: '/:address/activity'
    },
    profileListings: {
        path: '/:address/listings'
    },
    profileOffers: {
        path: '/:address/offers'
    },
    profileFavorites: {
        path: '/:address/favorites'
    },


    collection: {
        path: '/collection/:contractAddress'
    },
    nft: {
        path: '/collection/:contractAddress/:tokenId'
    },
    listNFT: {
        path: '/collection/:contractAddress/:tokenId/list'
    },

    terms: {
        path: '/termsOfService'
    },
    privacyPolicy: {
        path: '/privacy'
    },
    guides: {
        path: '/guides'
    },
    launchpad: {
        path: '/drops'
    }
}

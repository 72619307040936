// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_priceGraphic__Kb\\+F3 {
  width: 100%;
  background: #242424;
  border-radius: 12px;
  box-sizing: border-box;
}

.styles_title__tgimK {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.35px;
  color: #D9D9D9;
  padding: 24px 32px;
  box-sizing: border-box;
  border-bottom: 1px solid #2D2D2D;
  margin: 0;
}
@media (max-width: 640px) {
  .styles_title__tgimK {
    font-size: 15px;
    line-height: 20px;
  }
}

.styles_chart__6eHu5 {
  width: 100%;
  margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/templates/SingleNFTPage/PriceGraphic/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,sBAAA;EACA,gCAAA;EACA,SAAA;AACF;AACE;EAZF;IAaI,eAAA;IACA,iBAAA;EAEF;AACF;;AACA;EACE,WAAA;EACA,cAAA;AAEF","sourcesContent":[".priceGraphic {\n  width: 100%;\n  background: #242424;\n  border-radius: 12px;\n  box-sizing: border-box;\n}\n\n.title{\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 25px;\n  letter-spacing: -0.35px;\n  color: #D9D9D9;\n  padding: 24px 32px;\n  box-sizing: border-box;\n  border-bottom: 1px solid #2D2D2D;\n  margin: 0;\n\n  @media (max-width: 640px) {\n    font-size: 15px;\n    line-height: 20px;\n  }\n}\n\n.chart{\n  width: 100%;\n  margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priceGraphic": `styles_priceGraphic__Kb+F3`,
	"title": `styles_title__tgimK`,
	"chart": `styles_chart__6eHu5`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import arrow from "../../../assets/images/symbols/arrow.svg";
import {useSelector} from "react-redux";

const UnlockableContentBlock = ({nftData}: { nftData: any}) => {
        const [isOpen, setIsOpen] = useState(true)
        const customer = useSelector((state: any) => state.customer.data)

        const onToggle = () => setIsOpen(!isOpen)

        return (
            <div className={styles.attributesBlock}>
                <div className={cn(styles.title, isOpen && styles.title_open)} onClick={onToggle}>
                    Unlockable Content
                    <img src={arrow} alt={''}/>
                </div>

                {isOpen &&  <p className={styles.noData}>
                    { nftData?.owner?.toLowerCase() === customer?.address?.toLowerCase()
                        ? nftData?.metadata?.unlockableContent
                        : 'Unlockable content is available only for owner of the NFT'
                    }
                </p>}
            </div>
        );
    }
;

export default UnlockableContentBlock;

import React, {useRef, useState} from 'react';
import styles from './styles.module.scss'
import badge from '../../../assets/images/symbols/verified-badge_orange.svg'//done
import dots from '../../../assets/images/symbols/dots.svg'
import editImg from '../../../assets/images/symbols/edit.svg'
import cross from '../../../assets/images/symbols/cross.svg'
import shiba from '../../../assets/images/symbols/shiba.svg'
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import formatNumber from "../../../utils";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../../constants";
import {formatIPFSUrl} from "../../../utils/blockchain";

type Props = {
    item: any,
    onEditListing: any,
    onCancelListing: any,
    isOwner: any
}

const ListingsTabItem = ({item, onEditListing, onCancelListing, isOwner}: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    const navigate = useNavigate()
    const dropdownRef = useRef<HTMLInputElement>(null)
    const dotsRef = useRef<HTMLInputElement>(null)
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width <= MOBILE

    const onToggleOpen = () => setIsOpen(!isOpen)
    const onCloseOpen = () => setIsOpen(false)
    const onClickNFT = () => navigate(`/collection/${item?.contractAddress}/${item?.tokenID}`)


    useOutsideClick(dropdownRef, onCloseOpen, dotsRef)

    return (
        <div className={styles.listingsTabItem}>

            <div className={styles.leftBlock} onClick={onClickNFT}>
                {item?.imageDOLink && !item?.imageDOLink?.includes('undefined')
                && <img
                    src={formatIPFSUrl(item?.imageDOLink)}
                    alt={''}
                    className={styles.image}
                />}

                {item?.videoDOLink && !item?.videoDOLink?.includes('undefined')
                && <video
                    src={item?.videoDOLink}
                    controls={false}
                    autoPlay={false}
                />}

                <div>
                    <p className={styles.id}>
                        {(isMobile && item?.nftName?.length > 10 || item?.metadata?.name?.length > 10)
                            ? (item?.nftName || item?.metadata?.name)?.slice(0, 10) + '...'
                            : (item?.metadata?.name || item?.nftName || '#' + item?.tokenID)
                        }
                    </p>
                    <p className={styles.name}>
                        {(isMobile && item?.name?.length > 10)
                            ? item?.name?.slice(0, 10) + '...'
                            : item?.name}
                        {item?.isCollectionVerified && <img src={badge} alt={''}/>}
                    </p>
                </div>
            </div>

            <div className={styles.rightBlock}>
                <div>
                    <p className={styles.price}>
                        {formatNumber(+item?.listing?.price?.toFixed(2)) || '-'}
                        <img src={shiba} alt={''}/>
                        {/*{AVAILABLE_CURRENCY?.find((currency: any) => currency?.address?.toLowerCase() === item?.listing?.tokenAddress?.toLowerCase())?.label}*/}
                    </p>
                    <p className={styles.expires}>Expires {moment.unix(item?.listing?.endDate).format('hh:mm a, D MMM YYYY')}</p>
                </div>
                {isOwner && <div className={styles.dots} onClick={onToggleOpen} ref={dotsRef}>
                    <img src={dots} alt={''}/>
                </div>}

                {isOpen && isOwner && <div ref={dropdownRef} className={styles.dropdown}>
                    <div className={styles.dropdown_item} onClick={() => onEditListing(item)}>
                        <div><img src={editImg} alt={''}/></div>
                        Edit listing
                    </div>
                    <div className={styles.dropdown_item} onClick={() => onCancelListing(item)}>
                        <div><img src={cross} alt={''}/></div>
                        Unlist
                    </div>
                </div>}
            </div>

        </div>
    );
};

export default ListingsTabItem;

import {combineReducers} from "redux";
import customer from "./customer";
import loader from "./loader";
import listingModal from "./listingSuccessModal";

export default combineReducers({
    customer,
    loader,
    listingModal
})

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_chart__6DAtr {
  margin: 50px 0;
  width: 100%;
}
@media (max-width: 1025px) {
  .styles_chart__6DAtr {
    margin: 50px auto;
  }
}
@media (max-width: 640px) {
  .styles_chart__6DAtr {
    margin: 23px auto 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/templates/SingleCollectionPage/GraphicsBlock/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;AACE;EAJF;IAKI,iBAAA;EAEF;AACF;AAAE;EARF;IASI,sBAAA;EAGF;AACF","sourcesContent":[".chart {\n  margin: 50px 0;\n  width: 100%;\n\n  @media (max-width: 1025px) {\n    margin: 50px auto;\n  }\n\n  @media (max-width: 640px) {\n    margin: 23px auto 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `styles_chart__6DAtr`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import userImg from '../../../assets/images/profile_photo.svg'
import editImg from '../../../assets/images/symbols/edit.svg'
import useHandleCustomer from "../../../hooks/customer/useHandleCustomer";
import {useSelector} from "react-redux";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import FileApi from "../../../utils/api/FileApi";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import CustomerApi from "../../../utils/api/CustomerApi";
import {useLocation, useNavigate} from "react-router-dom";
import cn from "classnames";
import moment from "moment";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";

type Props = {
    isOpen: boolean,
    onClose: any,
}

const EditProfileModal = ({isOpen, onClose}: Props) => {
    const customer = useSelector((state: any) => state.customer.data)

    const [userName, setUserName] = useState('')
    const [twitter, setTwitter] = useState('')
    const [avatar, setAvatar] = useState('')
    const [avatarFile, setAvatarFile] = useState(null)
    const [isValidUserName, setIsValidUserName] = useState(false)
    const [timerID, setTimerID] = useState<any>(0)
    const [isUpdateAvailable, setIsUpdateAvailable] = useState<any>(true)

    const fileRef = useRef<HTMLInputElement>(null)
    const handleCustomer = useHandleCustomer()
    const handleLoader = useHandleLoader()
    const navigate = useNavigate()
    const location = useLocation()
    const windowDimensions = useWindowDimensions()

    const uploadImage = (event: any) => {
        if (!event?.target?.files[0]) return
        if (event?.target?.files[0]?.size > 100000000) return toast(<ToastMsg
            text={'You can not upload file over 100 MB'} isError/>)
        const reader: any = new FileReader();
        reader.readAsDataURL(event?.target?.files[0]);
        reader.onload = function () {
            if (event?.target?.files[0]?.type?.includes('image')) {
                setAvatarFile(event?.target?.files[0])
                setAvatar(reader.result)
            } else toast(<ToastMsg text={'You can upload only image'} isError/>)
        };
    }

    const onChangeUserName = (e: any) => {
        if (e?.target?.value?.length > 18) return toast(<ToastMsg text={'User name is too long'} isError/>)
        if (e?.target?.value?.includes('/')
            || e?.target?.value?.includes(':')
            || e?.target?.value?.includes('&')
            || e?.target?.value?.includes('$')
            || e?.target?.value?.includes('+')
            || e?.target?.value?.includes(' ')
            || e?.target?.value?.includes('=')) return toast(<ToastMsg
            text={'User name can not contain spaces and symbols / : & = $ +'} isError/>)

        setUserName(e?.target?.value)
    }

    const checkUserName = () => {
        if (userName?.length) {
            new CustomerApi()
                .checkUniqueUrl(userName?.trim()?.toLowerCase())
                .then((res) => setIsValidUserName(!res?.data || userName?.trim() === customer?.userName?.toLowerCase()))
        }
    }

    const onChangeTwitter = (e: any) => setTwitter(e?.target?.value)

    const onEditProfile = async (isEditedUserName: boolean) => {
        if (!!avatarFile) {
            const formData = new FormData();
            formData.append("file", avatarFile);

            return new FileApi().saveFileDO(formData).then(async (res: any) => {
                await handleCustomer
                    .editInfo({
                        twitter,
                        avatar: res?.data,
                        userName,
                        lastUpdatedUserName: isEditedUserName ? new Date() : customer?.lastUpdatedUserName
                    })
                    .finally(() => {
                        onClose()
                        setAvatarFile(null)
                    })
            })
        } else return handleCustomer
            .editInfo({
                twitter,
                avatar,
                userName,
                lastUpdatedUserName: isEditedUserName ? new Date() : customer?.lastUpdatedUserName
            })
            .finally(() => {
                onClose()
                setAvatarFile(null)
            })
    }

    const onSave = async () => {
        await handleLoader.loaderWrapper(async () => {

            if (userName !== customer?.userName) {
                await new CustomerApi().checkUniqueUrl(userName).then(async (res) => {
                    if (res?.data?.length) {
                        return toast(<ToastMsg text={'This user name already exists.'} isError/>)
                    } else {
                        await onEditProfile(true)
                            .then(() => {
                                const newLink = '/' + (userName || customer?.address)
                                    + '/' + location?.pathname?.split('/')[2]

                                navigate(newLink)
                            })
                    }
                })
            } else {
                await onEditProfile(false)
            }
        })
    }

    useEffect(() => {
        setAvatar(customer?.avatar)
        setTwitter(customer?.twitter)
        setUserName(customer?.userName || '')
        setIsUpdateAvailable(!customer?.lastUpdatedUserName?.length || (moment(customer?.lastUpdatedUserName).unix() + (3600 * 24 * 30)) < moment().unix())
    }, [customer])

    useEffect(() => {
        clearTimeout(timerID)
        const id = setTimeout(checkUserName, 1000)
        setTimerID(id)
    }, [userName])

    return (
        <ModalLayout
            maxWidth={windowDimensions?.width < 540 ? windowDimensions?.width - 40 + 'px' : '500px'}
            isOpen={isOpen}
            onClose={onClose}
            maxHeight={'800px'}
            withCrossIcon
        >
            <p className={styles.title}>Edit profile information</p>

            <div className={styles.list}>

                <div className={styles.photo} onClick={() => fileRef?.current?.click()}>
                    <input
                        type={'file'}
                        hidden
                        ref={fileRef}
                        onChange={uploadImage}
                        accept="image/*"
                    />
                    <img src={avatar || userImg} alt={''} className={styles.photo_img}/>

                    <div className={styles.deleteImg}>
                        {avatar && <img src={editImg} alt={''} width={40}/>}
                    </div>
                </div>

                <p className={styles.subtitle}>Username</p>
                <Input
                    value={userName}
                    onChange={onChangeUserName}
                    placeholder={'Username'}
                    disabled={!isUpdateAvailable}
                />
                {!isUpdateAvailable &&
                <p className={styles.validation}>
                    You can update username once every 30 days
                </p>}
                {userName?.trim()?.toLowerCase() !== customer?.userName?.toLowerCase() && !!userName?.trim()?.length
                && <p className={cn(styles.validation, isValidUserName && styles.validation_isValid)}>
                    {isValidUserName
                        ? 'This user name is available'
                        : 'This user name already exists'}
                </p>}

                <p className={styles.subtitle}>Twitter</p>
                <Input value={twitter} onChange={onChangeTwitter} placeholder={'Twitter'}/>
            </div>

            <Button title={'Save'} isYellow onClick={onSave}/>
        </ModalLayout>
    );
};

export default EditProfileModal;

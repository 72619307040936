import React, {useState} from 'react';
import arrow from '../../../assets/images/symbols/arrow_right.svg'
import badge from '../../../assets/images/symbols/verified-badge_orange.svg'//done
import shiba from '../../../assets/images/symbols/shiba.svg'
import styles from './styles.module.scss'
import cn from "classnames";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {EVENT_TYPES, MOBILE} from "../../../constants";
import moment from "moment";
import openSvg from "../../../assets/images/symbols/open.svg";
import {useNavigate} from "react-router-dom";
import formatNumber from "../../../utils";
import {formatIPFSUrl} from "../../../utils/blockchain";

type Props = {
    item: any,
    maticToUsd: number
}

const ActivityTabItem = ({item, maticToUsd}: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    const navigate = useNavigate()
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width <= MOBILE

    const onToggleOpen = () => setIsOpen(!isOpen)
    const onClickOwner = (address: string) => address ? navigate(`/${address}/collected`) : null
    const onClickNFT = (contractAddress: string, id: number) => navigate(`/collection/${contractAddress}/${id}`)

    return (
        <div className={styles.activityTabItem}>

            <div className={styles.main_row}>
                <div className={styles.activityTabItem_left}>
                    {!isMobile &&
                    <div onClick={onToggleOpen} className={cn(styles.arrow, isOpen && styles.arrow_isOpen)}>
                        <img src={arrow} alt={''}/>
                    </div>}

                    <div className={styles.activityTabItem_info}>

                        {item?.imageDOLink && !item?.imageDOLink?.includes('undefined')
                        && <img
                            src={formatIPFSUrl(item?.imageDOLink)}
                            alt={''}
                            className={styles.collection_image}
                            onClick={() => onClickNFT(item?.contractAddress, item?.tokenID)}
                        />}

                        {item?.videoDOLink && !item?.videoDOLink?.includes('undefined')
                        && <video
                            src={item?.videoDOLink}
                            controls={false}
                            autoPlay={false}
                            onClick={() => onClickNFT(item?.contractAddress, item?.tokenID)}
                        />}

                        <div>
                            <p className={styles.collection_id}
                               onClick={() => onClickNFT(item?.contractAddress, item?.tokenID)}>
                                {(isMobile && item?.nftName?.length > 10)
                                    ? item?.nftName?.slice(0, 10) + '...'
                                    : (item?.nftName || '#' + item?.tokenID)}
                            </p>
                            <p className={styles.collection_name}
                               onClick={() => onClickNFT(item?.contractAddress, item?.tokenID)}>
                                {(isMobile && item?.name?.length > 10)
                                    ? item?.name?.slice(0, 10) + '...'
                                    : item?.name}
                                {item?.isCollectionVerified && <img src={badge} alt={''}/>}
                            </p>
                            {isMobile &&
                            <p className={cn(styles.more, isOpen && styles.more_isOpen)} onClick={onToggleOpen}>
                                {isOpen ? 'Less' : 'More'}
                                <img src={arrow} alt={''} width={4}/>
                            </p>}
                        </div>
                    </div>
                </div>

                <div className={styles.activityTabItem_right}>
                    <p className={styles.event}>{EVENT_TYPES[item?.eventType]}</p>
                    <p className={styles.price}>
                        {formatNumber(+((+item?.price || 0)?.toFixed(2)))}
                        <img src={shiba} alt={''}/>
                        {/*{item?.currency}*/}
                    </p>
                    <p className={styles.time}>{moment.unix(item?.transactionTime).format('hh:mm a, D MMM YYYY')}
                        <a target="_blank" rel="noopener noreferrer"
                           href={`https://www.shibariumscan.io/tx/${item?.transactionHash}`}>
                            <img src={openSvg} alt={''}/>
                        </a></p>
                </div>
            </div>

            {isOpen && <div className={styles.bottom_row}>
                <div className={styles.bottom_row_item}>
                    <span className={styles.bottom_row_item_header}>USD price</span>
                    <span
                        className={styles.bottom_row_item_text}>$ {formatNumber(+((+item?.price || 0) * maticToUsd)?.toFixed(2))}</span>
                </div>

                <div className={styles.bottom_row_item}>
                    <span className={styles.bottom_row_item_header}>From</span>
                    <span className={styles.bottom_row_item_from} onClick={() => onClickOwner(item?.from)}>{
                        item?.from?.length > 10
                            ? item?.from?.slice(0, 10) + '...'
                            : item?.from
                    }</span>
                </div>

                {!!item?.to?.length && <div className={styles.bottom_row_item}>
                    <span className={styles.bottom_row_item_header}>To</span>
                    <span className={styles.bottom_row_item_from} onClick={() => onClickOwner(item?.to)}>{
                        item?.to?.length > 10
                            ? item?.to?.slice(0, 10) + '...'
                            : item?.to
                    }</span>
                </div>}

            </div>}

        </div>
    );
};

export default ActivityTabItem;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_launchpadPage__rdvUy {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 150px;
}

.styles_title__x9P39 {
  color: #FFF;
  text-align: center;
  font-family: Cerebri Sans, sans-serif;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.35px;
  margin: 62px auto 72px;
}
@media (max-width: 640px) {
  .styles_title__x9P39 {
    font-size: 32px;
    margin: 40px auto 32px;
  }
}

.styles_soon__dq9lS {
  color: #F79C00;
  text-align: center;
  font-family: Cerebri Sans, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.35px;
  margin: 62px auto 72px;
}
@media (max-width: 640px) {
  .styles_soon__dq9lS {
    font-size: 32px;
    margin: 40px auto 32px;
  }
}

.styles_list__-9kgv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
}

.styles_timer__6KQD4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  flex-direction: column;
  gap: 30px;
}`, "",{"version":3,"sources":["webpack://./src/templates/LaunchpadPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sBAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACF;AAAE;EAVF;IAWI,eAAA;IACA,sBAAA;EAGF;AACF;;AAAA;EACE,cAAA;EACA,kBAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAGF;AAFE;EAVF;IAWI,eAAA;IACA,sBAAA;EAKF;AACF;;AAFA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,eAAA;AAKF;;AAFA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,cAAA;EACA,sBAAA;EACA,SAAA;AAKF","sourcesContent":[".launchpadPage {\n  width: 100%;\n  box-sizing: border-box;\n  padding-bottom: 150px;\n}\n\n.title {\n  color: #FFF;\n  text-align: center;\n  font-family: Cerebri Sans, sans-serif;\n  font-size: 62px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: -0.35px;\n  margin: 62px auto 72px;\n  @media (max-width: 640px) {\n    font-size: 32px;\n    margin: 40px auto 32px;\n  }\n}\n\n.soon{\n  color: #F79C00;\n  text-align: center;\n  font-family: Cerebri Sans, sans-serif;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: -0.35px;\n  margin: 62px auto 72px;\n  @media (max-width: 640px) {\n    font-size: 32px;\n    margin: 40px auto 32px;\n  }\n}\n\n.list {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 32px;\n  flex-wrap: wrap;\n}\n\n.timer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: fit-content;\n  margin: 0 auto;\n  flex-direction: column;\n  gap: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"launchpadPage": `styles_launchpadPage__rdvUy`,
	"title": `styles_title__x9P39`,
	"soon": `styles_soon__dq9lS`,
	"list": `styles_list__-9kgv`,
	"timer": `styles_timer__6KQD4`
};
export default ___CSS_LOADER_EXPORT___;

import Base from "./Base";

export default class CollectionApi extends Base {
    async getTokenList(contractAddress: string | undefined, isNsfwON = 1) {
        return super.get(`marketplace/token/${contractAddress}/${isNsfwON}`);
    }

    async getTokenListWithFilters(page: number, filters: any) {
        return super.post(`marketplace/token/list/${page}`, filters);
    }

    async getTokenListCounter(contractAddress: string) {
        return super.get(`marketplace/token/list/counter/${contractAddress}`);
    }

    async getTokenListByFilters(contractAddress: string | undefined, attributes: any, isNSFW: boolean) {
        return super.post(`marketplace/byAttributes/${contractAddress}`, {attributes, isNSFW});
    }

    async getTokenListListedCount(contractAddress: string) {
        return super.get(`marketplace/token/listed/count/${contractAddress}`);
    }

    async getTokenCountByCollection(contractAddress: string) {
        return super.get(`marketplace/token/count/${contractAddress}`);
    }

    async getOneToken(contractAddress: string | undefined, tokenID: string | undefined) {
        return super.get(`marketplace/token/${contractAddress}/${tokenID}`);
    }

    async hideToken(contractAddress: string | undefined, tokenID: string | undefined, isHidden: boolean) {
        return super.post(`marketplace/token/hide/${contractAddress}/${tokenID}`, {isHidden});
    }

    async createToken(collection: any,
                      tokenID: number,
                      imageIPFSLink: string,
                      owner: string,
                      metadata: any,
                      metadataObj: any,
                      imageDOLink: string,
                      fromDrops = false,
                      audioIPFSLink: string,
                      audioDOLink: string,
                      videoIPFSLink: string,
                      videoDOLink: string,
    ) {
        return super.post(`marketplace/token/${collection?.contractAddress}/${tokenID}`, {
            collectionName: collection?.name,
            imageIPFSLink,
            owner,
            metadata,
            metadataObj,
            imageDOLink,
            fromDrops,
            audioIPFSLink,
            audioDOLink,
            videoIPFSLink,
            videoDOLink,
        });
    }

    async createTokenList(collection: any,
                          tokenID: number,
                          imageIPFSLink: string,
                          owner: string,
                          metadata: any,
                          metadataObj: any,
                          amount: number,
                          imageDOLink: string,
                          audioIPFSLink: string,
                          audioDOLink: string,
                          videoIPFSLink: string,
                          videoDOLink: string,
    ) {
        return super.post(`marketplace/token-list/${collection?.contractAddress}/${tokenID}`, {
            collectionName: collection?.name,
            imageIPFSLink,
            owner,
            metadata,
            metadataObj,
            amount,
            imageDOLink,
            audioIPFSLink,
            audioDOLink,
            videoIPFSLink,
            videoDOLink,
        });
    }

    async createTokenListFromDrops(contractAddress: string,
                                   tokenID: number,
                                   owner: string,
                                   amount: number,
                                   collectionName: string,
                                   nftData: any,
                                   generatedMetadataLinkArr: any) {
        return super.post(`marketplace/createTokenListFromDrops`, {
            contractAddress,
            tokenID,
            owner,
            amount,
            collectionName,
            nftData,
            generatedMetadataLinkArr
        });
    }

    async checkToken(contractAddress: string, tokenID: number) {
        return super.post(`marketplace/token/check/${contractAddress}/${tokenID}`);
    }

    async getCollectionStatistics(contractAddress: string) {
        return super.get(`marketplace/collection/info/${contractAddress}`);
    }

    async getContract() {
        if (!super.getToken()) return null;
        return super.get(`collection/contract`);
    }

    async getContractForDrops() {
        if (!super.getToken()) return null;
        return super.get(`collection/contractForDrops`);
    }

    async createContract(data: any) {
        if (!super.getToken()) return null;
        return super.post("collection", data);
    }

    async getCollectionByAddress(contractAddress?: string) {
        return super.get(`collection/getOne/${contractAddress}`);
    }

    async getCollectionFees(contractAddress?: string) {
        return super.get(`collection/getFee/${contractAddress}`);
    }

    async getCollectionsDropdown() {
        return super.get(`collection/dropdown`);
    }

    async checkWalletCollections(data: any) {
        return super.post(`collection/checkWallet`, data);
    }

    async getAllCollections() {
        return super.get(`collection`);
    }

    async getAllDropsCollections() {
        return super.get(`collection/drops`);
    }

    async getCollectionsByCategory(category: string) {
        return super.get(`collection/category/${category}`);
    }

    async getLikedCollectionsByCategory(category: string) {
        return super.get(`collection/likedCollections/${category}`);
    }

    async setLikes(contractAddress: string) {
        return super.post(`collection/setLike`, {contractAddress});
    }

    async getLikedCollections() {
        return super.get(`collection/likedCollections`);
    }

    async getCollectionsByAddresses(addresses: any) {
        return super.post(`collection/byAddresses`, {addresses});
    }

    async search(searchQuery: string) {
        return super.get(`marketplace/search/${searchQuery}`)
    }

    async checkCollection(contractAddress: string) {
        return super.post(`collection/checkCollection`, {contractAddress});
    }

    async getCollectionUrlsList() {
        return super.get(`collection/collectionUrlsList`);
    }

    async checkUniqueUrl(url: string) {
        return super.post(`collection/checkUniqueUrl`, {url});
    }

    async editCollection(data: any) {
        return super.put(`collection/edit`, data);
    }

    async getSponsoredCollection() {
        return super.get(`sponsoredCollection`)
    }

    async getSpotlightCollections() {
        return super.get(`collection/spotlightCollection`)
    }

    async getCollectionRoyalty(contractAddress: string) {
        return super.get(`collection/collectionRoyalty/${contractAddress}`)
    }
}

import {useEffect} from "react";
import PAWZAAR_COLLECTION from '../../abi/PawzaarCollection.json'
import LAUNCHPAD_COLLECTION from '../../abi/LaunchpadCollection.json'
import LISTING from '../../abi/Listing.json'
import OFFERS from '../../abi/Offers.json'
import OFFERS_OLD from '../../abi/OffersOld.json'
import {
    useWeb3React,
} from "@web3-react/core";
import ERC20_ABI from "../../abi/ERC20_ABI.json";
import ERC721_ABI from "../../abi/ERC721_ABI.json";

const ethers = require("ethers")
// @ts-ignore
const ethereum = window?.ethereum;

export default function useHandleContracts() {
    let provider = ethereum ? new ethers.providers.Web3Provider(ethereum) : null;

    const context = useWeb3React();
    const {library} = context;

    useEffect(() => {
        if (library) provider = library.provider;// eslint-disable-line
    }, [library])// eslint-disable-line react-hooks/exhaustive-deps

    const getSigner = () => {
        if (!library) return ethereum ? new ethers.providers.Web3Provider(ethereum).getSigner() : null;
        return library.getSigner();
    };

    const signerForRoyalty = new ethers.Wallet(process.env.REACT_APP_ROYALTY_PRIVATE_KEY, provider);
    const signer = getSigner();

    const contractListing = ethereum ? new ethers.Contract(process.env.REACT_APP_LISTING_ADDRESS, LISTING.abi, provider) : null;
    const contractListingWithSigner = ethereum ? contractListing.connect(signer) : null;
    const contractListingWithSignerRoyalty = ethereum ? contractListing.connect(signerForRoyalty) : null;

    const contractOffersOLD = ethereum ? new ethers.Contract(process.env.REACT_APP_OFFERS_ADDRESS_OLD, OFFERS_OLD.abi, provider) : null;
    const contractOffers = ethereum ? new ethers.Contract(process.env.REACT_APP_OFFERS_ADDRESS, OFFERS.abi, provider) : null;
    const contractOffersWithSignerOLD = ethereum ? contractOffersOLD.connect(signer) : null;
    const contractOffersWithSigner = ethereum ? contractOffers.connect(signer) : null;
    const contractOffersWithSignerRoyalty = ethereum ? contractOffers.connect(signerForRoyalty) : null;

    const contractNFTCollectionWithSigner = (contractAddress: string) => {
        const contract = new ethers.Contract(contractAddress, PAWZAAR_COLLECTION.abi, provider);
        return contract.connect(signer);
    }

    const contractDropsCollectionWithSigner = (contractAddress: string) => {
        const contract = new ethers.Contract(contractAddress, LAUNCHPAD_COLLECTION.abi, provider);
        return contract.connect(signer);
    }

    const contractERC20WithSigner = (contractAddress: string) => {
        const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider);
        return contract.connect(signer);
    }

    const contractERC20WithSignerRoyalty = (contractAddress: string) => {
        const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider);
        return contract.connect(signerForRoyalty);
    }

    const contractERC721WithSigner = (contractAddress: string | undefined) => {
        const contract = new ethers.Contract(contractAddress, ERC721_ABI, provider);
        return contract.connect(signer);
    }

    const getBlockTimestamp = async (blockNumber: number) => {
        if (!provider) return
        return (await provider.getBlock(blockNumber)).timestamp;
    }

    return {
        contractNFTCollectionWithSigner, contractDropsCollectionWithSigner,
        contractListing, contractListingWithSigner, contractListingWithSignerRoyalty,
        contractOffers, contractOffersWithSigner, contractOffersWithSignerRoyalty,
        contractERC20WithSigner, contractERC20WithSignerRoyalty,
        contractERC721WithSigner,
        getSigner, getBlockTimestamp,
        contractOffersOLD,
        contractOffersWithSignerOLD
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_profileDropdown__s6Tyo {
  display: flex;
  width: 313px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  background: #242424;
  position: absolute;
  top: 60px;
  right: -28px;
  z-index: 22;
}

.styles_row__wejey {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileDropdown/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;AACF","sourcesContent":[".profileDropdown {\n  display: flex;\n  width: 313px;\n  padding: 16px 24px;\n  box-sizing: border-box;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 25px;\n  background: #242424;\n  position: absolute;\n  top: 60px;\n  right: -28px;\n  z-index: 22;\n}\n\n.row {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 24px;\n  width: 100%;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileDropdown": `styles_profileDropdown__s6Tyo`,
	"row": `styles_row__wejey`
};
export default ___CSS_LOADER_EXPORT___;

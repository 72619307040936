import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import TabHeader from "../TabHeader/TabHeader";
import {
    FILTER_BUY_NOW,
    FILTER_HAS_OFFER,
    LISTINGS_TABLE_HEADERS, SORTING_DATA,
} from "../../../constants/profile";
import FiltersBlock from "../FiltersBlock/FiltersBlock";
import cn from "classnames";
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import crossIcon from "../../../assets/images/symbols/cross.svg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {EVENT_TYPE_LISTING_CANCELED, TABLET} from "../../../constants";
import FiltersMobile from "../../../components/FiltersMobile/FiltersMobile";
import SortingMobile from "../../../components/SortingMobile/SortingMobile";
import ListingsTabItem from "../ListingsTabItem/ListingsTabItem";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import {useNavigate} from "react-router-dom";
import {AVAILABLE_CURRENCY} from "../../../constants/listingPage";
import moment from "moment";
import {toast} from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import useHandleMarketplace from "../../../hooks/blockchain/useHandleMarketplace";
import useHandleToken from "../../../hooks/blockchain/useHandleToken";
import ReactPaginate from "react-paginate";
import CryptoApi from "../../../utils/api/CryptoApi";
import {sortNFTList} from "../../../utils/sortingNFT";
import CollectionApi from "../../../utils/api/CollectionApi";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import TransactionPendingModal from "../../../components/Modals/TransactionPendingModal/TransactionPendingModal";
import TransactionSuccessModal from "../../../components/Modals/TransactionSuccessModal/TransactionSuccessModal";
import TransactionFailedModal from "../../../components/Modals/TransactionFailedModal/TransactionFailedModal";
import useHandleWeb3 from "../../../hooks/web3/useHandleWeb3";
import {useSelector} from "react-redux";

const ListingsTab = ({
                         currentUser, isOwner, isOpenedFilters,
                         setIsOpenedFilters
                     }: { currentUser: any, isOpenedFilters: boolean, setIsOpenedFilters: any, isOwner: boolean }) => {
    const customer = useSelector((state: any) => state.customer.data)

    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false)
    const [isOpenedMobileSorting, setIsOpenedMobileSorting] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<any>([])
    const [filtersAmount, setFiltersAmount] = useState(0)
    const [selectedSorting, setSelectedSorting] = useState<any>(SORTING_DATA[0])
    const [listingsList, setListingsList] = useState([])
    const [listingsListFiltered, setListingsListFiltered] = useState([])
    const [listingsToShow, setListingsToShow] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [forcePage, setForcePage] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedCollectionAddress, setSelectedCollectionAddress] = useState(null)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)
    const [cancelTxID, setCancelTxID] = useState<any>('')
    const [cancelGasFee, setCancelGasFee] = useState<any>(0)

    const [currentNFT, setCurrentNFT] = useState<any>(null)

    const itemsPerPage = 10
    const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0;

    const wrapperRef = useRef<HTMLInputElement>(null)
    const handleLoader = useHandleLoader()
    const handleActivity = useHandleActivity()
    const navigate = useNavigate()
    const handleMarketplace = useHandleMarketplace()
    const handleToken = useHandleToken()
    const handleWeb3 = useHandleWeb3()

    const handlePendingCancelListingModal = useHandleModal()

    const [filters, setFilters] = useState([
        {title: 'Buy Now', status: false, id: FILTER_BUY_NOW, amount: 0},
        {title: 'Has Offer', status: false, id: FILTER_HAS_OFFER, amount: 0},
    ])


    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % totalCount;
        setForcePage(event.selected)
        setListingsToShow(listingsListFiltered?.slice(event.selected * itemsPerPage, (event.selected + 1) * itemsPerPage))
    }

    const onDeleteFilter = (id: number) => setFilters && setFilters([...filters?.map((item: any) => item?.id === id ? {
        ...item,
        status: false
    } : item)])

    const onClearFilters = () => setFilters && setFilters(filters?.map((item: any) => ({...item, status: false})))

    const onCancelListing = async (nft: any) => {
        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to cancel listing'} isError/>)

        setCurrentNFT(nft)

        handleMarketplace
            .cancelListing(nft?.tokenID, nft?.contractAddress)
            .then(async (res: any) => {
                setCancelTxID(res?.hash)
                handlePendingCancelListingModal.open()
                const transaction = await res?.wait();
                if (transaction?.status) {

                    await handleActivity.createActivity({
                        eventType: EVENT_TYPE_LISTING_CANCELED,
                        tokenID: nft?.tokenID,
                        contractAddress: nft?.contractAddress?.toLowerCase(),
                        name: nft?.name,
                        nftName: nft?.metadata?.name,
                        isCollectionVerified: nft?.isCollectionVerified,
                        imageDOLink: nft?.imageDOLink,//done
                        audioDOLink: nft?.audioDOLink,
                        videoDOLink: nft?.videoDOLink,
                        price: nft?.listing?.price,
                        currency: AVAILABLE_CURRENCY?.find((item: any) =>
                            item?.address?.toLowerCase() === nft?.listing?.tokenAddress?.toLowerCase())?.label,
                        from: currentUser?.address?.toLowerCase(),
                        startDate: nft?.listing?.startDate,
                        endDate: nft?.listing?.endDate,
                        transactionTime: moment().unix(),
                        transactionHash: transaction?.transactionHash
                    })

                    await handleToken.checkToken(nft?.contractAddress, nft?.tokenID)
                    await handleActivity
                        .getListingsList(currentUser?.address)
                        .then((res) => {
                            setListingsList(res)
                            setListingsListFiltered(res)
                            setTotalCount(res?.length)
                            setListingsToShow(res?.slice(0, itemsPerPage))
                        })
                    await new CollectionApi().checkCollection(nft?.contractAddress?.toLowerCase())
                    handlePendingCancelListingModal.close()
                    toast(<ToastMsg text={'Listing is successfully canceled'}/>)
                } else {
                    handlePendingCancelListingModal.close()
                    toast(<ToastMsg text={'Failed to cancel listing'} isError/>)
                }
            })
            .catch((err: any) => {
                if (err?.code !== 'ACTION_REJECTED') {
                    toast(<ToastMsg text={'Failed to cancel listing'} isError/>)
                }
                return handlePendingCancelListingModal.close()
            })
    }

    const onEditListing = (nft: any) => navigate(`/collection/${nft?.contractAddress}/${nft?.tokenID}/list`)

    useEffect(() => {
        setSelectedFilters(filters?.filter((item: any) => !!item?.status))
        if (selectedCollectionAddress) {
            setFiltersAmount(filters?.filter((item: any) => item?.status)?.length + 1)
        } else setFiltersAmount(filters?.filter((item: any) => item?.status)?.length)
    }, [filters, selectedCollectionAddress])

    useEffect(() => {
        if (!!currentUser) {
            handleLoader.loaderWrapper(() => handleActivity
                .getListingsList(currentUser?.address)
                .then((res) => {
                    const today = moment().unix()

                    setListingsList(res)
                    setListingsListFiltered(res)
                    setTotalCount(res?.length)
                    setListingsToShow(res?.slice(0, itemsPerPage))
                    setFilters([
                        {
                            title: 'Buy Now',
                            status: false,
                            id: FILTER_BUY_NOW,
                            amount: res?.filter((item: any) => item?.listing?.startDate <= today)?.length
                        },
                        {
                            title: 'Has Offer', status: false, id: FILTER_HAS_OFFER, amount: res?.filter((item: any) =>
                                !!item?.offers?.filter((offer: any) =>
                                    offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                                )?.length)?.length
                        },
                    ])
                }))
        }

        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
    }, [currentUser])

    useEffect(() => {
        const today = moment().unix()

        let newListingsList = listingsList

        if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_BUY_NOW)?.length && !!selectedFilters?.filter((item: any) => item?.id === FILTER_HAS_OFFER)?.length) {
            newListingsList = listingsList
                ?.filter((item: any) =>
                    !!item?.offers?.filter((offer: any) =>
                        offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                    )?.length)
                ?.filter((item: any) => item?.listing?.startDate <= today)
        } else if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_BUY_NOW)?.length) {
            newListingsList = listingsList?.filter((item: any) => item?.listing?.startDate <= today)
        } else if (!!selectedFilters?.filter((item: any) => item?.id === FILTER_HAS_OFFER)?.length) {
            newListingsList = listingsList?.filter((item: any) =>
                !!item?.offers?.filter((offer: any) =>
                    offer?.price > 0 && offer?.endDate > today && offer?.startDate <= today
                )?.length)
        }

        if (!!searchQuery?.length) {
            newListingsList = newListingsList?.filter((item: any) => item?.metadata?.name?.toLowerCase()?.startsWith(searchQuery?.toLowerCase()?.trim()))
        }

        if (!!selectedCollectionAddress) {
            newListingsList = newListingsList?.filter((item: any) => item?.contractAddress?.toLowerCase() === selectedCollectionAddress)
        }

        newListingsList = sortNFTList(newListingsList, selectedSorting?.id)

        setListingsListFiltered(newListingsList)
        setTotalCount(newListingsList?.length)
        setListingsToShow(newListingsList?.slice(0, itemsPerPage))
    }, [selectedFilters?.length, listingsList, searchQuery, selectedCollectionAddress, selectedSorting])

    return (
        <div className={styles.listingsTab}>
            <TabHeader
                isOpenedFilters={isOpenedFilters}
                setIsOpenedFilters={setIsOpenedFilters}
                wrapperRef={wrapperRef}
                filters={filters}
                setFilters={setFilters}
                withoutCardView
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                isOpenedMobileFilters={isOpenedMobileFilters}
                filtersAmount={filtersAmount}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                isOpenedMobileSorting={isOpenedMobileSorting}
                totalCount={listingsListFiltered?.length}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />

            <div className={styles.wrapper} ref={wrapperRef}>
                {!isTablet && <FiltersBlock
                    isOpenedFilters={isOpenedFilters}
                    filters={filters}
                    setFilters={setFilters}
                    nftsList={listingsList}
                    setSelectedCollection={setSelectedCollectionAddress}
                    selectedCollection={selectedCollectionAddress}
                />}

                {isTablet && <div className={styles.list}>
                    {!!listingsListFiltered?.length
                        ? listingsToShow?.map((item, key) =>
                            <ListingsTabItem
                                isOwner={isOwner}
                                key={key}
                                item={item}
                                onEditListing={onEditListing}
                                onCancelListing={onCancelListing}
                            />)
                        : <p className={styles.noData}>No listed items yet</p>}
                    {!!listingsListFiltered?.length && <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        pageCount={pageCount}
                        forcePage={forcePage}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pagination_page}
                        activeClassName={styles.pagination_active}
                        previousClassName={styles.pagination_previous}
                        nextClassName={styles.pagination_next}
                        disabledClassName={styles.pagination_disabled}
                    />}
                </div>}

                {!isTablet &&
                <div className={cn(styles.nftList_wrapper, isOpenedFilters && styles.nftList_wrapper_filters)}>
                    {!!selectedFilters?.length && <div className={styles.selectedFilters}>
                        {selectedFilters?.map((item: any, key: number) => <div
                            key={key}
                            className={styles.selectedFilters_item}
                            onClick={() => onDeleteFilter(item?.id)}
                        >
                            {item?.title}
                            <img src={crossIcon} alt={''}/>
                        </div>)}
                        <div className={styles.selectedFilters_clear} onClick={onClearFilters}>Clear filters</div>
                    </div>}

                    <div className={cn(styles.NfTsTable, isOpenedFilters && styles.NfTsTable_filters)}>
                        {!!listingsListFiltered?.length
                            ? <NfTsTable
                                maticToUsd={maticToUsd}
                                data={listingsToShow}
                                headers={LISTINGS_TABLE_HEADERS}
                                withListingButtons={isOwner}
                                onCancelListing={onCancelListing}
                                onEditListing={onEditListing}
                            />
                            : <p className={styles.noData}>No listed items yet</p>}

                        {!!listingsListFiltered?.length && <ReactPaginate
                            breakLabel="..."
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={3}
                            pageCount={pageCount}
                            forcePage={forcePage}
                            previousLabel="<"
                            nextLabel=">"
                            renderOnZeroPageCount={null}
                            containerClassName={styles.pagination}
                            pageClassName={styles.pagination_page}
                            activeClassName={styles.pagination_active}
                            previousClassName={styles.pagination_previous}
                            nextClassName={styles.pagination_next}
                            disabledClassName={styles.pagination_disabled}
                        />}
                    </div>
                </div>}
            </div>

            {isTablet && <FiltersMobile
                isOpenedMobileFilters={isOpenedMobileFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                filters={filters}
                setFilters={setFilters}
                setSelectedCollection={setSelectedCollectionAddress}
                selectedCollection={selectedCollectionAddress}
                filtersAmount={filtersAmount}
                withoutView
                nftsList={listingsList}
            />}

            {isTablet && <SortingMobile
                isOpenedMobileSorting={isOpenedMobileSorting}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />}

            {handlePendingCancelListingModal.isActive && <TransactionPendingModal
                isOpen={handlePendingCancelListingModal.isActive}
                transactionID={cancelTxID}
                title={'Your transaction is processing...'}
                text={`Cancel listing of`}
                yellowText={`${currentNFT?.metadata?.name || '#' + currentNFT?.tokenID}`}
            />}

        </div>
    );
};

export default ListingsTab;

import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import FiltersRow from "../FiltersRow/FiltersRow";
import {sortInAlphabeticalOrder} from "../../../utils/sortingNFT";

type Props = {
    isOpenedFilters: boolean,
    setFilters?: (a: any) => void,
    filters?: any,
    isActivityTab?: boolean,
    collectionData?: any
}

const FiltersBlock = ({
                          isOpenedFilters,
                          setFilters,
                          filters,
                          isActivityTab,
                          collectionData
                      }: Props) => {
    const onToggleActivityFilters = (title: number, parent: string) =>
        setFilters && setFilters([...filters?.map((item: any) =>
            (item?.title === title && item?.parent === parent)
                ? {...item, status: !item?.status}
                : item)])

    return (
        <div
            className={cn(styles.filtersBlock, isOpenedFilters && styles.filtersBlock_open)}>

            {isActivityTab && <FiltersRow
                filterParent={'Event'}
                onToggleActivityFilters={onToggleActivityFilters}
                filters={filters}
                isActivityTab
            />}

            {!isActivityTab && <FiltersRow
                filterParent={'Status'}
                onToggleActivityFilters={onToggleActivityFilters}
                filters={filters?.filter((filter: any) => filter?.parent === 'Status')}
                isActivityTab
            />}

            {!isActivityTab && collectionData?.attributes
            && sortInAlphabeticalOrder(Object.keys(collectionData?.attributes)?.filter((item: string) => !!item?.trim()?.length && item !== 'undefined'))
                ?.map((item: any, key: number) =>
                    <FiltersRow
                        key={key}
                        filterParent={item}
                        onToggleActivityFilters={onToggleActivityFilters}
                        filters={filters?.filter((filter: any) => filter?.parent === item)}
                    />)
            }
        </div>
    );
};

export default FiltersBlock;

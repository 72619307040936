import React, {useEffect, useState} from 'react';
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import Input from "../../Input/Input";
import styles from './styles.module.scss'
import Button from "../../Button/Button";
import deleteImg from '../../../assets/images/symbols/delete.svg'
import cn from "classnames";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";

type Props = {
    isOpen: boolean,
    onClose: any,
    setCreateData: any,
    createData: any
}

const AddStatsModal = ({isOpen, onClose, createData, setCreateData}: Props) => {
    const [stats, setStats] = useState([{name: '', type: '', maxStats: 1000, id: new Date().getTime()}])
    const [isSaveDisabled, setIsSaveDisabled] = useState(true)
    const windowDimensions = useWindowDimensions()

    const onChangeType = (e: any, id: number) => setStats(
        stats?.map((item: any) => item?.id === id
            ? {...item, type: e?.target?.value}
            : item))

    const onChangeValue = (e: any, id: number) => {
        if (e?.target?.value < 0) return
        setStats(
            stats?.map((item: any) => item?.id === id
                ? {...item, name: e?.target?.value}
                : item))
    }

    const onChangeMaxStats = (e: any, id: number) => {
        if (e?.target?.value < 0) return
        setStats(
            stats?.map((item: any) => item?.id === id
                ? {...item, maxStats: e?.target?.value}
                : item))
    }

    const onAddStats = () => {
        if (isSaveDisabled) return
        if (stats?.length > 9) return toast(<ToastMsg text={'You can add only 10 stats'} isError/>)

        setStats([...stats, {name: '', type: '', maxStats: 1000, id: new Date().getTime()}])
    }
    const onDelete = (id: number) => {
        if (stats?.length === 1) setStats([{name: '', type: '', maxStats: 1000, id: new Date().getTime()}])
        else setStats(stats?.filter((item: any) => item?.id !== id))
    }

    const onSave = () => {
        if (isSaveDisabled) return
        if (!!stats?.filter(item => +item?.maxStats < +item?.name)?.length)
            return toast(<ToastMsg text={'NFT stats value must be smaller than NFT max stats value'} isError/>)
        onClose()
        setCreateData({
            ...createData,
            stats: stats?.filter((item) => item?.name?.length && +item?.maxStats && item?.type?.length)
        })
    }

    const onCloseModal = () => {
        onClose()
        setStats([{name: '', type: '', maxStats: 1000, id: new Date().getTime()}])
    }

    useEffect(() => {
        setIsSaveDisabled(!!stats?.filter((item: any) => !item?.name?.length || !item?.maxStats || !item?.type?.length)?.length)
    }, [stats])

    useEffect(() => {
        setStats(createData?.stats?.length
            ? createData?.stats
            : [{name: '', type: '', maxStats: 1000, id: new Date().getTime()}])
    }, [createData?.stats, isOpen])

    return (
        <ModalLayout
            onClose={onCloseModal}
            isOpen={isOpen}
            maxWidth={windowDimensions?.width < 820 ? windowDimensions?.width - 40 + 'px' : '784px'}
            withCrossIcon
            maxHeight={'90vh'}
        >
            <>
                <p className={styles.title}>Add stats</p>

                <div className={styles.list}>
                    {stats?.map((property: any, key: number) => <div className={styles.property} key={key}>
                        <div className={styles.block}>
                            <p>Type</p>
                            <Input
                                value={property?.type}
                                onChange={(e: any) => onChangeType(e, property?.id)}
                                placeholder={'Luck'}
                            />
                        </div>

                        <div className={styles.block}>
                            <p>Value</p>
                            <div className={styles.block_inputs}>
                                <Input
                                    value={property?.name}
                                    onChange={(e: any) => onChangeValue(e, property?.id)}
                                    placeholder={'45'}
                                    type={'number'}
                                    min={0}
                                />
                                <Input
                                    value={property?.maxStats}
                                    onChange={(e: any) => onChangeMaxStats(e, property?.id)}
                                    placeholder={'1000'}
                                    type={'number'}
                                    min={0}
                                />
                            </div>
                        </div>

                        <div
                            className={cn(styles.delete, styles.delete_visible)}
                            onClick={() => onDelete(property?.id)}>
                            <img src={deleteImg} alt={''}/>
                        </div>
                    </div>)}
                </div>


                <div className={styles.add}>
                    <Button
                        title={'Add stats'}
                        disabled={isSaveDisabled || stats?.length === 10}
                        isBlackWhite
                        onClick={onAddStats}
                    />
                </div>

                <Button title={'Save'} disabled={isSaveDisabled} isYellow onClick={onSave}/>

            </>
        </ModalLayout>
    );
};

export default AddStatsModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__wUbIP {
  width: 100%;
  position: relative;
}

.styles_cross__mN0UR {
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/layouts/ModalLayout/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AACF","sourcesContent":[".wrapper{\n  width: 100%;\n  position: relative;\n}\n\n.cross{\n  position: absolute;\n  top: 13px;\n  right: 13px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__wUbIP`,
	"cross": `styles_cross__mN0UR`
};
export default ___CSS_LOADER_EXPORT___;

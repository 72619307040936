export const OFFERS_TABLE_HEADERS = [
    {title: 'Price', withCheckBox: false},
    {title: 'From', withCheckBox: false},
    {title: 'Expires', withCheckBox: false},
    {title: '', withCheckBox: false},
]

export const ACTIVITY_TABLE_HEADERS = [
    {title: 'Event', withCheckBox: false},
    {title: 'Price', withCheckBox: false},
    {title: 'From', withCheckBox: false},
    {title: 'To', withCheckBox: false},
    {title: 'Time', withCheckBox: false},
]

import React, {useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import arrow from "../../../assets/images/symbols/arrow.svg";
// import {useSelector} from "react-redux";
import {METADATA_CATEGORY_LEVEL, METADATA_CATEGORY_PROPERTY, METADATA_CATEGORY_STATS} from "../../../constants";
import {sortByTraitInAlphabeticalOrder} from "../../../utils/sortingNFT";

const AttributesBlock = ({
                             nftData,
                             totalAttributes,
                             totalTokensCount
                         }: { nftData: any, totalAttributes: any, totalTokensCount: number }) => {
        const [isOpen, setIsOpen] = useState(true)
        // const customer = useSelector((state: any) => state.customer.data)

        const attributes = nftData?.metadata?.attributes
            ?.filter((item: any) => !!item?.trait_type?.length)
            ?.map((item: any) => {
                const totalCount = totalAttributes[item?.trait_type] ? totalAttributes[item?.trait_type][item?.value] : 1
                const percentage = +(totalCount / totalTokensCount * 100)?.toFixed(2)
                return {...item, percentage}
            })

        const properties = attributes?.filter((item: any) => item?.category ? item?.category === METADATA_CATEGORY_PROPERTY : item)
        const levels = attributes?.filter((item: any) => item?.category === METADATA_CATEGORY_LEVEL)
        const stats = attributes?.filter((item: any) => item?.category === METADATA_CATEGORY_STATS)
        // const hasUnlockable = (!!nftData.metadata.unlockableContent && nftData?.owner?.toLowerCase() !== customer?.address?.toLowerCase())

        const onToggle = () => setIsOpen(!isOpen)

        const levelWidth: any = (level: any, maxLevel: number) => {
            return level / (+maxLevel || 1000) * 100 + '%'
        }

        return (
            <>{(!!attributes?.length) &&
            <div className={styles.attributesBlock}>
                <div className={cn(styles.title, isOpen && styles.title_open)} onClick={onToggle}>
                    Attributes
                    <img src={arrow} alt={''}/>
                </div>

                {isOpen && <>
                    {(!!attributes?.length)
                        ? <div className={styles.content_wrapper}>

                            {(!!properties?.length) && <>
                                <p className={styles.subtitle}>Properties</p>
                                <div className={styles.content}>
                                    {sortByTraitInAlphabeticalOrder(properties)?.map((item: any, key: number) =>
                                        <div key={key} className={styles.attribute}>
                                            <p className={styles.attribute_name}>{item?.trait_type}</p>
                                            <p className={styles.attribute_value}>{item?.value}</p>
                                            <p className={styles.attribute_percentage}>{item?.percentage}% have this
                                                trait</p>
                                        </div>)}

                                    {/*{hasUnlockable*/}
                                    {/*&& <div className={styles.attribute}>*/}
                                    {/*    <p className={styles.attribute_name}>Unlockable Content</p>*/}
                                    {/*</div>}*/}
                                </div>
                            </>}

                            {!!stats?.length && <>
                                <p className={styles.subtitle}>Stats</p>
                                <div className={styles.content}>
                                    {sortByTraitInAlphabeticalOrder(stats)?.map((item: any, key: number) =>
                                        <div className={styles.stats} key={key}>
                                            <p>{item?.trait_type}</p>
                                            <span>{item?.value} of {item?.max_value || '1000'}</span>
                                        </div>)}
                                </div>
                            </>}

                            {!!levels?.length && <>
                                <p className={styles.subtitle}>Levels</p>
                                <div className={styles.content}>
                                    {sortByTraitInAlphabeticalOrder(levels)?.map((item: any, key: number) =>
                                        <div className={styles.power} key={key}>
                                            <p>{item?.trait_type}</p>
                                            <div className={styles.range_wrapper}>
                                                <input
                                                    type={"range"}
                                                    min="1"
                                                    max={item?.max_value?.toString() || '1000'}
                                                    step={'1'}
                                                    value={item?.value}
                                                    onChange={(e) => null}
                                                    style={{backgroundSize: `${levelWidth(item?.value, item?.max_value)} 100%`}}
                                                />
                                                <span>{item?.value} of {item?.max_value || '1000'}</span>
                                            </div>
                                        </div>)}
                                </div>
                            </>}
                        </div>
                        : <p className={styles.noData}>This NFT has no attributes</p>}
                </>}
            </div>}</>
        );
    }
;

export default AttributesBlock;

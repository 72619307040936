import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from './reducers';
import thunk from 'redux-thunk';

const customMiddleware = () => {
    return applyMiddleware(thunk);
}

export function configureStore(){
    return createStore(
        rootReducer,
        composeWithDevTools(customMiddleware())
    )
}

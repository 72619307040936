import React, {useRef, useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../../constants";

type Props = {
    isOpen: boolean,
    onClose?: any,
    title?: string,
    text?: string,
    transactionID?: string,
    yellowText?: string
}

const TransactionPendingModal = ({isOpen, title, text, transactionID, yellowText}: Props) => {
    const [isCopied, setIsCopied] = useState(false)
    const textRef: any = useRef<HTMLInputElement>(null)
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width < MOBILE

    const onCopyLink = () => {
        if (isCopied) return

        navigator.clipboard.writeText(textRef?.current?.value).then(() => {
            setIsCopied(true)
            toast(<ToastMsg text={'Copied to clipboard!'}/>)
            setTimeout(() => setIsCopied(false), 3000)
        })
    }

    return (
        <ModalLayout
            isOpen={isOpen}
            maxWidth={isMobile ? windowDimensions?.width - 40 + 'px' : '572px'}
            onClose={() => null}
            maxHeight={'418px'}
        >
            <div>
                <div className={styles.loader_wrapper}>
                    <span className={styles.loader}/>
                </div>
                <p className={styles.title}>{title}</p>
                <p className={styles.text}>{text}
                    {yellowText && <span> {yellowText} </span>}
                    is being recorded on the blockchain. Confirmation should be finalized soon.</p>

                <p className={styles.id}>Transaction ID</p>
                <p className={styles.value} onClick={onCopyLink}>
                    <input
                        value={transactionID}
                        ref={textRef}
                        hidden
                        onChange={() => {
                        }}
                    />
                    {transactionID?.slice(0, 12) + '...' + transactionID?.slice(-3)}
                </p>

            </div>
        </ModalLayout>
    );
};

export default TransactionPendingModal;

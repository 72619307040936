import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import cross from "../../assets/images/symbols/cross.svg";
import check from "../../assets/images/symbols/check_yellow.svg";
import Button from "../Button/Button";
import {SORTING_DATA} from "../../constants/profile";

type Props = {
    isOpenedMobileSorting: boolean,
    setIsOpenedMobileSorting: any,
    selectedSorting: any,
    setSelectedSorting: any,
    filters?: any
}

const SortingStatsMobile = ({
                           isOpenedMobileSorting,
                           setIsOpenedMobileSorting,
                           selectedSorting,
                           setSelectedSorting,
                           filters = SORTING_DATA
                       }: Props) => {
    const onCloseSorting = () => setIsOpenedMobileSorting(false)
    const onApplySorting = () => setIsOpenedMobileSorting(false)
    const onClickSorting = (sorting: any) => setSelectedSorting(sorting?.id)

    return (
        <div className={cn(styles.sortingMobile, isOpenedMobileSorting && styles.sortingMobile_isOpenedMobileSorting)}>

            <div className={styles.title}>
                Sort by
                <div className={styles.cross} onClick={onCloseSorting}>
                    <img src={cross} alt={''} width={18.67} height={18.67}/>
                </div>
            </div>

            <div className={styles.content}>
                {filters?.map((item: any, key: number) =>
                    <div key={key} onClick={() => onClickSorting(item)} className={styles.sorting_item}>
                        <div className={styles.sorting_item_check}>
                            {selectedSorting === item?.id && <img src={check} alt={''}/>}
                        </div>
                        {item?.title}
                    </div>)}
            </div>

            <div className={styles.buttons}>
                <Button
                    title={`Apply`}
                    isYellow
                    isFullWidth
                    onClick={onApplySorting}
                />
            </div>

        </div>
    );
};

export default SortingStatsMobile;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_guidesSideBar__Q5Vnl {
  background: #F69C00;
  width: 350px;
  height: calc(100vh - 92px);
  border-radius: 0 12px 12px 0;
  overflow-y: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
}
@media (max-width: 800px) {
  .styles_guidesSideBar__Q5Vnl {
    height: -moz-fit-content;
    height: fit-content;
    max-height: calc(100vh - 92px - 30px);
    position: absolute;
    top: 30px;
    left: 0;
    padding-bottom: 0;
  }
}

.styles_list__TwBNJ {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.styles_list__TwBNJ p {
  margin: 0;
  padding: 10px 10px 10px 40px;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.styles_active__f3zBn {
  background: #3E3E3E;
  transition: all 0.3s ease;
}

.styles_title__At6r2 {
  text-align: center;
  color: #FFF;
  font-family: Cerebri Sans, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.35px;
  padding: 30px 0;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}
@media (max-width: 800px) {
  .styles_title__At6r2 {
    padding: 15px 0 15px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    cursor: pointer;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/GuidesSideBar/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;EACA,0BAAA;EACA,4BAAA;EACA,gBAAA;EACA,oBAAA;EACA,sBAAA;AACF;AACE;EATF;IAUI,wBAAA;IAAA,mBAAA;IACA,qCAAA;IACA,kBAAA;IACA,SAAA;IACA,OAAA;IACA,iBAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,sBAAA;AAEF;AAAE;EACE,SAAA;EACA,4BAAA;EACA,yBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;AAEJ;;AAEA;EACE,mBAAA;EACA,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EAdF;IAeI,yBAAA;IACA,aAAA;IACA,mBAAA;IACA,2BAAA;IACA,SAAA;IACA,eAAA;EAEF;AACF","sourcesContent":[".guidesSideBar {\n  background: #F69C00;\n  width: 350px;\n  height: calc(100vh - 92px);\n  border-radius: 0 12px 12px 0;\n  overflow-y: auto;\n  padding-bottom: 20px;\n  box-sizing: border-box;\n\n  @media (max-width: 800px) {\n    height: fit-content;\n    max-height: calc(100vh - 92px - 30px);\n    position: absolute;\n    top: 30px;\n    left: 0;\n    padding-bottom: 0;\n  }\n}\n\n.list {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-direction: column;\n\n  p {\n    margin: 0;\n    padding: 10px 10px 10px 40px;\n    transition: all .3s ease;\n    cursor: pointer;\n    width: 100%;\n    box-sizing: border-box;\n  }\n}\n\n.active {\n  background: #3E3E3E;\n  transition: all .3s ease;\n}\n\n.title {\n  text-align: center;\n  color: #FFF;\n  font-family: Cerebri Sans, sans-serif;\n  font-size: 30px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: -0.35px;\n  padding: 30px 0;\n  width: 100%;\n  box-sizing: border-box;\n  margin: 0;\n\n  @media (max-width: 800px) {\n    padding: 15px 0 15px 30px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 30px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guidesSideBar": `styles_guidesSideBar__Q5Vnl`,
	"list": `styles_list__TwBNJ`,
	"active": `styles_active__f3zBn`,
	"title": `styles_title__At6r2`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useRef, useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import linkImg from '../../../assets/images/symbols/link.svg'
import Button from "../../Button/Button";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../../constants";
import {formatIPFSUrl} from "../../../utils/blockchain";
import {playAudioCallback, playVideoCallback} from "../../../utils";

type Props = {
    nftData: any,
    title?: string,
    text?: string,
    text2?: string,
    transactionID?: string,
    yellowText?: string,
    onClose: any,
    isOpen: boolean,
    buttonText?: string,
    onClickButton?: any
}

const TransactionSuccessModal = ({
                                     nftData,
                                     onClose,
                                     isOpen,
                                     title,
                                     text,
                                     text2,
                                     transactionID,
                                     yellowText,
                                     buttonText = 'View item',
                                     onClickButton = null
                                 }: Props) => {
    const navigate = useNavigate()
    const [isCopied, setIsCopied] = useState(false)
    const textRef: any = useRef<HTMLInputElement>(null)
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width < MOBILE

    const onClickViewNFT = () => {
        onClose()
        onClickButton
            ? onClickButton()
            : navigate(`/collection/${nftData?.contractAddress}/${nftData?.tokenID}`)
    }

    const onCopyLink = () => {
        if (isCopied) return

        navigator.clipboard.writeText(textRef?.current?.value).then(() => {
            setIsCopied(true)
            toast(<ToastMsg text={'Copied to clipboard!'}/>)
            setTimeout(() => setIsCopied(false), 3000)
        })
    }

    return (
        <ModalLayout
            onClose={onClose}
            maxWidth={isMobile ? windowDimensions?.width - 40 + 'px' : '572px'}
            isOpen={isOpen}
            maxHeight={'650px'}
            withCrossIcon
        >
            <div>
                <div className={styles.img}>
                    {(nftData?.imageDOLink && !nftData?.imageDOLink?.includes('undefined'))
                    && <img
                        src={formatIPFSUrl(nftData?.imageDOLink)}
                        alt={''}/>}

                    {(nftData?.videoDOLink && !nftData?.videoDOLink?.includes('undefined'))
                    && <video //done
                        onPlay={playVideoCallback}
                        id={`modal-${nftData?.videoDOLink}`}
                        className={'nft_video'}
                        controls
                        src={nftData?.videoDOLink}
                    />}

                    {(nftData?.audioDOLink && !nftData?.audioDOLink?.includes('undefined'))
                    && <audio //done
                        id={`modal-${nftData?.audioDOLink}`}
                        onPlay={playAudioCallback}
                        className={'nft_audio'}
                        controls
                        src={nftData?.audioDOLink}
                    />}
                </div>

                <p className={styles.title}>{title}</p>
                <p className={styles.text}>{text}
                    {yellowText && <span> {yellowText} </span>}
                    {text2}
                </p>

                <p className={styles.id}>Transaction ID</p>
                <p className={styles.value}>{transactionID?.slice(0, 12) + '...' + transactionID?.slice(-3)}</p>

                <p className={styles.id} style={{marginTop: '16px'}}>Share</p>
                <div className={styles.socials}>
                    <div onClick={onCopyLink}>
                        <input
                            value={`https://www.shibariumscan.io/tx/${transactionID}`}
                            ref={textRef}
                            hidden
                            onChange={() => {
                            }}
                        />
                        <img src={linkImg} alt={''}/>
                    </div>
                </div>

                <div className={styles.button}>
                    <Button title={buttonText} isYellow onClick={onClickViewNFT}/>
                </div>
            </div>
        </ModalLayout>
    );
};

export default TransactionSuccessModal;

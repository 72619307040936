import React from 'react';
import styles from './styles.module.scss'
import TopCollectionsItem from "../TopCollectionsItem/TopCollectionsItem";
import Button from "../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../../routes/path";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {TABLET} from "../../../constants";

const TopCollections = ({collectionsData}: { collectionsData: any }) => {
    const navigate = useNavigate()
    const windowDimensions = useWindowDimensions()

    const isTablet = windowDimensions?.width <= TABLET

    const onClickViewAll = () => navigate(pathList.stats.path)

    return (
        <div className={styles.topCollections}>
            <div className={styles.listWrapper}>
                {isTablet
                    ? collectionsData
                        ?.filter((item: any) => item?.volumeAllTime === 0 || !!item?.volumeAllTime)
                        ?.sort((a: any, b: any) => +b?.volumeAllTime - +a?.volumeAllTime)
                        ?.slice(0, 10)
                        ?.map((item: any, key: number) =>
                            <TopCollectionsItem collection={item} key={key} number={key + 1}/>)
                    : <>
                        <div className={styles.list}>
                            {collectionsData
                                ?.filter((item: any) => item?.volumeAllTime === 0 || !!item?.volumeAllTime)
                                ?.sort((a: any, b: any) => +b?.volumeAllTime - +a?.volumeAllTime)
                                ?.slice(0, 6)
                                ?.map((item: any, key: number) =>
                                    <TopCollectionsItem collection={item} key={key} number={key + 1}/>)}
                        </div>

                        {collectionsData?.length > 6 && <div className={styles.list}>
                            {collectionsData
                                ?.filter((item: any) => item?.volumeAllTime === 0 || !!item?.volumeAllTime)
                                ?.sort((a: any, b: any) => +b?.volumeAllTime - +a?.volumeAllTime)
                                ?.slice(6, 12)
                                ?.map((item: any, key: number) =>
                                    <TopCollectionsItem collection={item} key={key} number={key + 7}/>)}
                        </div>}

                        {collectionsData?.length > 12 && <div className={styles.list}>
                            {collectionsData
                                ?.filter((item: any) => item?.volumeAllTime === 0 || !!item?.volumeAllTime)
                                ?.sort((a: any, b: any) => +b?.volumeAllTime - +a?.volumeAllTime)
                                ?.slice(12, 18)
                                ?.map((item: any, key: number) =>
                                    <TopCollectionsItem collection={item} key={key} number={key + 13}/>)}
                        </div>}
                    </>}
            </div>

            <div className={styles.button}>
                <Button title={'View all'} onClick={onClickViewAll}/>
            </div>
        </div>
    );
};

export default TopCollections;

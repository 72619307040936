import React from "react";
import styles from "./styles.module.scss";

type Props = {
    isLoaderActive: boolean,
    opacityLevel?: number,
    withImage?: boolean
}

export default function FullPageLoader({isLoaderActive, opacityLevel = 3, withImage = false}: Props) {
    if (!isLoaderActive) return null;
    return (
        <div
            className={`${styles.loaderWrap} ${
                styles[`opacityLevel_${opacityLevel}`]
            }`}
        >
            {withImage && <span className={styles.loader}/>}
        </div>
    );
}

import React, {useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import arrow from "../../../assets/images/symbols/arrow.svg";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const DetailsBlock = ({nftData, collectionData}: { nftData: any, collectionData: any }) => {
    const [isOpen, setIsOpen] = useState(true)

    const navigate = useNavigate()

    const onToggle = () => setIsOpen(!isOpen)
    const onClickContract = () => {
        const link = collectionData?.externalLink
            ? collectionData?.externalLink?.includes('/')
                ? nftData?.contractAddress
                : collectionData?.externalLink
            : nftData?.contractAddress
        navigate(`/collection/${link}`)
    }

    return (
        <div className={styles.detailsBlock}>
            <div className={cn(styles.title, isOpen && styles.title_open)} onClick={onToggle}>
                Details
                <img src={arrow} alt={''}/>
            </div>

            {isOpen && <div className={styles.content}>

                <div className={styles.content_row}>
                    <span className={styles.content_title}>Contact address</span>
                    <span className={cn(styles.content_value_orange, styles.pointer)} onClick={onClickContract}>
                        {nftData?.contractAddress?.slice(0, 8) + '...' + nftData?.contractAddress?.slice(-3)}
                    </span>
                </div>

                <div className={styles.content_row}>
                    <span className={styles.content_title}>Token ID</span>
                    <span className={styles.content_value_orange}>{nftData?.tokenID}</span>
                </div>

                <div className={styles.content_row}>
                    <span className={styles.content_title}>Token Standard</span>
                    <span className={styles.content_value}>ERC-721</span>
                </div>

                <div className={styles.content_row}>
                    <span className={styles.content_title}>Chain</span>
                    <span className={styles.content_value}>Shibarium</span>
                </div>

                <div className={styles.content_row}>
                    <span className={styles.content_title}>Last updated</span>
                    <span
                        className={styles.content_value}>{moment(nftData?.createdAt).format('Do MMM YYYY')}</span>
                </div>

                <div className={styles.content_row}>
                    <span className={styles.content_title}>Creator earnings</span>
                    <span className={styles.content_value}>{collectionData?.royaltyPercentage}%</span>
                </div>


            </div>}
        </div>
    );
};

export default DetailsBlock;

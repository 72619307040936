import {useEffect, useState} from 'react';

const useCountdown = (targetDate: string) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

export const getReturnValues = (countDown: any) => {
    // calculate time left
    const daysValue = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hoursValue = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesValue = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const secondsValue = Math.floor((countDown % (1000 * 60)) / 1000);

    // const hours = hoursValue < 10 ? `0${hoursValue}` : hoursValue
    const hours = hoursValue
    // const minutes = minutesValue < 10 ? `0${minutesValue}` : minutesValue
    const minutes = minutesValue
    // const seconds = secondsValue < 10 ? `0${secondsValue}` : secondsValue
    const seconds = secondsValue
    // const days = daysValue < 10 ? `0${daysValue}` : daysValue
    const days = daysValue

    return [hours, minutes, seconds, hoursValue, minutesValue, secondsValue, days, daysValue];
};

export {useCountdown};

import React from 'react';
import styles from './styles.module.scss'
import verifiedBadge from '../../../assets/images/symbols/verified-badge_orange.svg'//done
import etherImg from '../../../assets/images/symbols/shiba.svg'
import {useNavigate} from "react-router-dom";
import formatNumber from "../../../utils";
import Tooltip from "../../../components/Tooltip/Tooltip";
import cn from "classnames";

type Props = {
    collection: any,
    number: number
};

const TopCollectionsItem = ({collection, number}: Props) => {
    const navigate = useNavigate()
    const onClickCollection = () => navigate(`/collection/${collection?.externalLink
        ? collection?.externalLink?.includes('/')
            ? collection?.contractAddress
            : collection?.externalLink
        : collection?.contractAddress}`)

    return (
        <div className={styles.topCollectionsItem} onClick={onClickCollection}>
            <div className={styles.infoBlock}>
                <p className={styles.number}>{number}</p>

                <div className={styles.collectionData}>
                    <div className={styles.image}>
                        <img className={styles.logoImage}
                             src={collection?.logoImage}
                             width={56}
                             height={56}
                             alt={''}
                        />
                        {collection?.isVerified && <img src={verifiedBadge}
                                                        alt={''}
                                                        width={20}
                                                        height={20}
                                                        className={styles.image_isVerified}/>}
                    </div>

                    <div>
                        <p className={styles.name}>{collection?.name?.length > 22 ? collection?.name?.slice(0, 22) + '...' : collection?.name}</p>
                        <Tooltip
                            text={(+collection?.volumeAllTime)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                            <p className={styles.amount}>
                                <img src={etherImg} alt={''}/>
                                {formatNumber(+collection?.volumeAllTime?.toFixed(2))}
                            </p>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <p className={cn(styles.percentage, collection?.volume24HPercent < 0 && styles.percentage_red)}>{+collection?.volume24HPercent?.toFixed(2) || 0} %</p>
        </div>
    );
};

export default TopCollectionsItem;

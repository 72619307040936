import React from 'react';
import styles from './styles.module.scss'
import shiba from '../../assets/images/symbols/shiba.svg'
import cn from "classnames";

const Tooltip = ({
                     children,
                     text,
                     withImage = true,
                     isStart = false,
                     isEnd = false,
                     isVisibleOnMobile = false
                 }: { children: any, text: string, isStart?: boolean, isEnd?: boolean, withImage?: boolean, isVisibleOnMobile?: boolean }) => {
    const isWindows = window.navigator.userAgent.search('Windows') > -1
    return (
        <div className={cn(styles.wrapper, isVisibleOnMobile && styles.isVisibleOnMobile)}>
            <div className={cn(
                styles.tooltip,
                isStart && styles.tooltip_start,
                isEnd && styles.tooltip_end)}>
                <span className={isWindows ? styles.text : ''}>{text}</span>
                {withImage && <img src={shiba} alt={''}/>}
            </div>
            {children}
        </div>
    );
};

export default Tooltip;

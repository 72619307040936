// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_dateInput__xQlqb {
  width: 100%;
  position: relative;
}

.styles_default__S-331 {
  position: absolute;
  top: -26px;
  right: 0;
  text-decoration: underline;
  cursor: pointer;
}

.styles_inputWrapper__06g2- {
  width: 100%;
  cursor: pointer;
  position: relative;
}

.styles_date_note__LPgDQ {
  position: absolute;
  top: 14px;
  left: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #949494;
}
.styles_date_note__LPgDQ span {
  color: white;
}

.styles_calendar__QDzkt {
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 2;
  background: #3E3E3E;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
}

.styles_time_wrapper__m9lR\\+ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin: 16px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/MakeOfferModal/DateInput/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,0BAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,UAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,cAAA;AAAF","sourcesContent":[".dateInput {\n  width: 100%;\n  position: relative;\n}\n\n.default{\n  position: absolute;\n  top: -26px;\n  right: 0;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.inputWrapper {\n  width: 100%;\n  cursor: pointer;\n  position: relative;\n}\n\n.date_note {\n  position: absolute;\n  top: 14px;\n  left: 18px;\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 24px;\n  color: #949494;\n\n  span {\n    color: white;\n  }\n}\n\n.calendar{\n  position: absolute;\n  top: -50px;\n  right: 0;\n  z-index: 2;\n  background: #3E3E3E;\n  border-radius: 8px;\n  padding: 16px;\n  box-sizing: border-box;\n}\n\n.time_wrapper{\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 16px;\n  margin: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateInput": `styles_dateInput__xQlqb`,
	"default": `styles_default__S-331`,
	"inputWrapper": `styles_inputWrapper__06g2-`,
	"date_note": `styles_date_note__LPgDQ`,
	"calendar": `styles_calendar__QDzkt`,
	"time_wrapper": `styles_time_wrapper__m9lR+`
};
export default ___CSS_LOADER_EXPORT___;

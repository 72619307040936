import React from 'react';
import styles from './styles.module.scss'
import deleteImg from '../../../assets/images/symbols/delete.svg'

type Props = {
    property: any,
    onDelete?: any
}

const PropertyRow = ({property, onDelete}: Props) => {

    return (
        <div className={styles.propertyRow}>
            <div className={styles.property}>
                <p className={styles.property_type}>{property?.type}</p>
                <p className={styles.property_name}>{property?.name}</p>
            </div>

            <div className={styles.delete} onClick={() => onDelete(property?.id)}>
                <img src={deleteImg} alt={''}/>
            </div>
        </div>
    );
};

export default PropertyRow;

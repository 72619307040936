import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import TabHeader from "../TabHeader/TabHeader";
import FiltersBlock from "../FiltersBlock/FiltersBlock";
import cn from "classnames";
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import {
    OFFERS_TABLE_HEADERS
} from "../../../constants/profile";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {EVENT_TYPE_OFFER_CANCELED, TABLET} from "../../../constants";
import FiltersMobile from "../../../components/FiltersMobile/FiltersMobile";
import SortingMobile from "../../../components/SortingMobile/SortingMobile";
import OffersTabItem from "../OffersTabItem/OffersTabItem";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import moment from "moment";
import useHandleMarketplace from "../../../hooks/blockchain/useHandleMarketplace";
import MakeOfferModal from "../../../components/Modals/MakeOfferModal/MakeOfferModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import AcceptOfferModal from "../../../components/Modals/AcceptOfferModal/AcceptOfferModal";
import ReactPaginate from "react-paginate";
import CryptoApi from "../../../utils/api/CryptoApi";
import CollectionApi from "../../../utils/api/CollectionApi";
import TransactionPendingModal from "../../../components/Modals/TransactionPendingModal/TransactionPendingModal";
import TransactionSuccessModal from "../../../components/Modals/TransactionSuccessModal/TransactionSuccessModal";
import TransactionFailedModal from "../../../components/Modals/TransactionFailedModal/TransactionFailedModal";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import {AVAILABLE_CURRENCY} from "../../../constants/listingPage";
import useHandleWeb3 from "../../../hooks/web3/useHandleWeb3";

const OffersTab = ({
                       isOwner, currentUser, isOpenedFilters,
                       setIsOpenedFilters
                   }: { currentUser: any, isOpenedFilters: boolean, setIsOpenedFilters: any, isOwner: boolean }) => {
    const customer = useSelector((state: any) => state.customer.data)

    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false)
    const [isOpenedMobileSorting, setIsOpenedMobileSorting] = useState(false)
    const [isOffersReceived, setIsOffersReceived] = useState(true)
    const [filtersAmount, setFiltersAmount] = useState(0)
    const [selectedSorting, setSelectedSorting] = useState(1)
    const [offersData, setOffersData] = useState([])
    const [filteredOffersData, setFilteredOffersData] = useState([])
    const [currentOffer, setCurrentOffer] = useState<any>(null)
    const [offersToShow, setOffersToShow] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [forcePage, setForcePage] = useState(0)
    const [selectedCollectionAddress, setSelectedCollectionAddress] = useState(null)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)

    const [offerTxID, setOfferTxID] = useState<any>('')
    const [cancelTxID, setCancelTxID] = useState<any>('')
    const [acceptTxID, setAcceptTxID] = useState<any>('')
    const [approveTxID, setApproveTxID] = useState<any>('')
    const [approveShibOfferTxID, setApproveShibOfferTxID] = useState<any>('')

    const itemsPerPage = 10
    const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0;

    const wrapperRef = useRef<HTMLInputElement>(null)
    const handleActivity = useHandleActivity()
    const handleLoader = useHandleLoader()
    const handleWeb3 = useHandleWeb3()
    const handleMarketplace = useHandleMarketplace()
    const handleEditOfferModal = useHandleModal()
    const handleAcceptOfferModal = useHandleModal()

    const handlePendingOfferModal = useHandleModal()
    const handlePendingCancelModal = useHandleModal()
    const handlePendingAcceptModal = useHandleModal()
    const handlePendingApproveModal = useHandleModal()
    const handlePendingApproveShibOfferModal = useHandleModal()

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % totalCount;
        setForcePage(event.selected)
        setOffersToShow(filteredOffersData?.slice(event.selected * itemsPerPage, (event.selected + 1) * itemsPerPage))
    }

    const updateDataCallback = async () => {
        isOffersReceived
            ? await handleActivity.getOffersReceived(currentUser?.address)
                .then((res) => {
                    setOffersData(res)
                    setFilteredOffersData(res)
                    setTotalCount(res?.length)
                    setOffersToShow(res?.slice(0, itemsPerPage))
                })
            : await handleActivity.getOffersMade(currentUser?.address)
                .then((res) => {
                    setOffersData(res)
                    setFilteredOffersData(res)
                    setTotalCount(res?.length)
                    setOffersToShow(res?.slice(0, itemsPerPage))
                })
    }

    const onCancelOffer = async (offer: any) => {
        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to cancel offer'} isError/>)

        setCurrentOffer(offer)

        handleMarketplace
            .onCancelOffer(offer?.tokenID, offer?.offerID, offer?.contractAddress)
            .then(async (res: any) => {
                setCancelTxID(res?.hash)
                handlePendingCancelModal.open()
                const transaction = await res?.wait();

                if (transaction?.status) {
                    await handleActivity.createActivity({
                        eventType: EVENT_TYPE_OFFER_CANCELED,
                        tokenID: offer?.tokenID,
                        contractAddress: offer?.contractAddress?.toLowerCase(),
                        name: offer?.name,
                        nftName: offer?.nftName,
                        isCollectionVerified: offer?.isCollectionVerified,
                        imageDOLink: offer?.imageDOLink,//done
                        audioDOLink: offer?.audioDOLink,
                        videoDOLink: offer?.videoDOLink,
                        price: offer?.price,
                        currency: offer?.currency,
                        from: offer?.from?.toLowerCase(),
                        to: offer?.to?.toLowerCase(),
                        startDate: offer?.startDate,
                        endDate: offer?.endDate,
                        transactionTime: moment().unix(),
                        transactionHash: transaction?.transactionHash,
                        offerID: offer?.offerID
                    })

                    await updateDataCallback()
                    await new CollectionApi().checkCollection(offer?.contractAddress?.toLowerCase())
                    handlePendingCancelModal.close()
                    toast(<ToastMsg text={'You have successfully canceled your offer'}/>)
                } else {
                    handlePendingCancelModal.close()
                    toast(<ToastMsg text={'Failed to cancel offer. Try again'} isError/>)
                }
            })
            .catch((err: any) => {
                if (err?.code !== 'ACTION_REJECTED') {
                    toast(<ToastMsg text={'Failed to cancel offer. Try again'} isError/>)
                }
                return handlePendingCancelModal.close()
            })
    }

    const onEditOffer = (offer: any) => {
        setCurrentOffer(offer)
        handleEditOfferModal.open()
    }

    const onAcceptOffer = (offer: any) => {
        setCurrentOffer(offer)
        handleAcceptOfferModal.open()
    }

    useEffect(() => {
        setFiltersAmount(selectedCollectionAddress ? 1 : 0)
    }, [selectedCollectionAddress])

    useEffect(() => {
        if (currentUser) {
            isOffersReceived
                ? handleLoader.loaderWrapper(() =>
                    handleActivity.getOffersReceived(currentUser?.address)
                        .then((res) => {
                            setOffersData(res)
                            setFilteredOffersData(res)
                            setTotalCount(res?.length)
                            setOffersToShow(res?.slice(0, itemsPerPage))
                        }))
                : handleLoader.loaderWrapper(() =>
                    handleActivity.getOffersMade(currentUser?.address)
                        .then((res) => {
                            setOffersData(res)
                            setFilteredOffersData(res)
                            setTotalCount(res?.length)
                            setOffersToShow(res?.slice(0, itemsPerPage))
                        }))
        }

        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
    }, [currentUser, isOffersReceived])

    useEffect(() => {
        if (!!selectedCollectionAddress) {
            const res = offersData
                ?.filter((item: any) => item?.contractAddress?.toLowerCase() === selectedCollectionAddress)

            setFilteredOffersData(res)
            setTotalCount(res?.length)
            setOffersToShow(res?.slice(0, itemsPerPage))

        } else {
            setFilteredOffersData(offersData)
            setTotalCount(offersData?.length)
            setOffersToShow(offersData?.slice(0, itemsPerPage))
        }
    }, [selectedCollectionAddress])

    return (
        <div className={styles.offersTab}>

            <TabHeader
                isOpenedFilters={isOpenedFilters}
                setIsOpenedFilters={setIsOpenedFilters}
                wrapperRef={wrapperRef}
                isOffersTab
                isOffersReceived={isOffersReceived}
                setIsOffersReceived={setIsOffersReceived}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                isOpenedMobileFilters={isOpenedMobileFilters}
                filtersAmount={filtersAmount}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                isOpenedMobileSorting={isOpenedMobileSorting}
                totalCount={filteredOffersData?.length}
            />

            <div className={styles.wrapper} ref={wrapperRef}>
                {!isTablet && <FiltersBlock
                    isOpenedFilters={isOpenedFilters}
                    isOffersTab
                    nftsList={offersData}
                    setSelectedCollection={setSelectedCollectionAddress}
                    selectedCollection={selectedCollectionAddress}
                />}

                {isTablet && <div className={styles.list}>
                    {!!filteredOffersData?.length
                        ? offersToShow?.map((item: any, key: number) =>
                            <OffersTabItem
                                key={key}
                                item={item}
                                isOffersReceived={isOffersReceived}
                                onCancelOffer={onCancelOffer}
                                onEditOffer={onEditOffer}
                                onAcceptOffer={onAcceptOffer}
                                isOwner={isOwner}
                            />)
                        : <p className={styles.noData}>{
                            isOffersReceived
                                ? 'No received offers yet'
                                : 'No made offers yet'
                        }</p>
                    }
                    {!!filteredOffersData?.length && <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        pageCount={pageCount}
                        forcePage={forcePage}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pagination_page}
                        activeClassName={styles.pagination_active}
                        previousClassName={styles.pagination_previous}
                        nextClassName={styles.pagination_next}
                        disabledClassName={styles.pagination_disabled}
                    />}
                </div>}

                {!isTablet && <div className={cn(styles.NfTsTable, isOpenedFilters && styles.NfTsTable_filters)}>
                    {!!filteredOffersData?.length
                        ? <NfTsTable
                            maticToUsd={maticToUsd}
                            data={offersToShow}
                            headers={OFFERS_TABLE_HEADERS}
                            withOffersButtons={isOwner}
                            isOffersReceived={isOffersReceived}
                            onCancelOffer={onCancelOffer}
                            onEditOffer={onEditOffer}
                            onAcceptOffer={onAcceptOffer}
                        />
                        :
                        <p className={styles.noData}>{isOffersReceived ? 'No received offers yet' : 'No made offers yet'}</p>
                    }

                    {!!filteredOffersData?.length && <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        pageCount={pageCount}
                        forcePage={forcePage}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pagination_page}
                        activeClassName={styles.pagination_active}
                        previousClassName={styles.pagination_previous}
                        nextClassName={styles.pagination_next}
                        disabledClassName={styles.pagination_disabled}
                    />}
                </div>}
            </div>


            {isTablet && <FiltersMobile
                isOpenedMobileFilters={isOpenedMobileFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                setSelectedCollection={setSelectedCollectionAddress}
                selectedCollection={selectedCollectionAddress}
                filtersAmount={filtersAmount}
                withoutView
                nftsList={offersData}
            />}

            {isTablet && <SortingMobile
                isOpenedMobileSorting={isOpenedMobileSorting}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />}

            {handleEditOfferModal.isActive && <MakeOfferModal
                isOpen={handleEditOfferModal.isActive}
                onClose={handleEditOfferModal.close}
                isEdit
                currentOffer={currentOffer}
                isFromProfile
                updateDataCallback={updateDataCallback}
                handlePendingOfferModal={handlePendingOfferModal}
                setOfferTxID={setOfferTxID}
                setApproveShibOfferTxID={setApproveShibOfferTxID}
                handlePendingApproveShibOfferModal={handlePendingApproveShibOfferModal}
            />}

            {handleAcceptOfferModal.isActive && <AcceptOfferModal
                isOpen={handleAcceptOfferModal.isActive}
                onClose={handleAcceptOfferModal.close}
                isFromProfile
                currentOffer={currentOffer}
                updateDataCallback={updateDataCallback}
                handlePendingAcceptModal={handlePendingAcceptModal}
                handlePendingApproveModal={handlePendingApproveModal}
                setAcceptTxID={setAcceptTxID}
                setApproveTxID={setApproveTxID}
            />}

            {handlePendingApproveShibOfferModal.isActive && <TransactionPendingModal
                isOpen={handlePendingApproveShibOfferModal.isActive}
                transactionID={approveShibOfferTxID}
                title={'Your transaction is processing...'}
                text={`Your approval of`}
                yellowText={`SHIB`}
            />}

            {handlePendingOfferModal.isActive && <TransactionPendingModal
                isOpen={handlePendingOfferModal.isActive}
                transactionID={offerTxID}
                title={'Your transaction is processing...'}
                text={`Your offer for`}
                yellowText={currentOffer?.nftName || '#' + currentOffer?.tokenID}
            />}

            {handlePendingCancelModal.isActive && <TransactionPendingModal
                isOpen={handlePendingCancelModal.isActive}
                transactionID={cancelTxID}
                title={'Your transaction is processing...'}
                text={`Cancel your offer for`}
                yellowText={currentOffer?.nftName || '#' + currentOffer?.tokenID}
            />}

            {handlePendingApproveModal.isActive && <TransactionPendingModal
                isOpen={handlePendingApproveModal.isActive}
                title={'Your transaction is processing...'}
                text={`Your approval of`}
                transactionID={approveTxID}
                yellowText={currentOffer?.nftName || '#' + currentOffer?.tokenID}
            />}

            {handlePendingAcceptModal.isActive && <TransactionPendingModal
                isOpen={handlePendingAcceptModal.isActive}
                title={'Your transaction is processing...'}
                text={`Your accepted offer for`}
                transactionID={acceptTxID}
                yellowText={currentOffer?.nftName || '#' + currentOffer?.tokenID}
            />}
        </div>
    );
};

export default OffersTab;

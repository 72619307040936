import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import MainLayout from "../../layouts/MainLayout/MainLayout";
import NftInfo from "./NFTInfo/NFTInfo";
import PriceGraphic from "./PriceGraphic/PriceGraphic";
import ActivityBlock from "./ActivityBlock/ActivityBlock";
import {useNavigate, useParams} from "react-router-dom";
import useHandleToken from "../../hooks/blockchain/useHandleToken";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import CollectionApi from "../../utils/api/CollectionApi";
import {useSelector} from "react-redux";
import ViewApi from "../../utils/api/ViewApi";
import CryptoApi from "../../utils/api/CryptoApi";
import {pathList} from "../../routes/path";
import Button from "../../components/Button/Button";

const SingleNftPage = () => {
    const customer = useSelector((state: any) => state.customer.data)

    const [nftData, setNftData] = useState<any>(null)
    const [collectionData, setCollectionData] = useState<any>(null)
    const [viewsData, setViewsData] = useState<any>(0)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)

    const params: any = useParams()
    const handleToken = useHandleToken()
    const navigate = useNavigate()

    const onClickMainPage = () => navigate(pathList.mainPage.path)

    const getViews = async () => {
        await new ViewApi()
            .getViews(params?.contractAddress, params?.tokenId)
            .then((res) => setViewsData(res?.data || 0))
    }

    useEffect(() => {
        handleToken
            .checkToken(params?.contractAddress, params?.tokenId)
            .then((res: any) => setNftData(res))

        new CollectionApi()
            .getCollectionByAddress(params?.contractAddress)
            .then((res: any) => setCollectionData(res?.data))

        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
    }, [params?.contractAddress, params?.tokenId])

    useEffect(() => {
        if (customer?.address) {
            new ViewApi()
                .setView(customer?.address, params?.contractAddress, params?.tokenId)
                .then(async () => await getViews())
            // @ts-ignore
        } else if (window.ethereum?.selectedAddress) {
            new ViewApi()
                // @ts-ignore
                .setView(window.ethereum?.selectedAddress, params?.contractAddress, params?.tokenId)
                .then(async () => await getViews())
        }
        // @ts-ignore
    }, [params?.contractAddress, params?.tokenId, customer?.address, window?.ethereum?.selectedAddress])

    return (
        <MainLayout>
            {!nftData?.tokenID
                ? <FullPageLoader isLoaderActive={!nftData?.tokenID}/>
                : nftData?.isBlocked
                    ? <div className={styles.removed}>
                        <p>This NFT was removed from the marketplace by admin</p>
                        <Button title={'Go to Main Page'} onClick={onClickMainPage} isYellow/>
                    </div>
                    : <div className={styles.singleNftPage}>
                        <NftInfo viewsData={viewsData} nftData={nftData} setNftData={setNftData}
                                 collectionData={collectionData} maticToUsd={maticToUsd}/>
                        <PriceGraphic
                            createdAt={nftData?.timeCollectionCreated}
                            priceData={nftData?.averagePrice}
                            collectionVolume={nftData?.collectionVolume}
                        />
                        <ActivityBlock nftData={nftData} maticToUsd={maticToUsd}/>
                    </div>
            }
        </MainLayout>
    );
};

export default SingleNftPage;

import React from 'react';
import {CATEGORY_DATA} from "../../../constants/mainPage";
import styles from './styles.module.scss'
import {useNavigate} from "react-router-dom";
import {
    CATEGORY_ALL,
    CATEGORY_ART,
    CATEGORY_COMMUNITY,
    CATEGORY_GAMING,
    CATEGORY_MEMBERSHIPS, CATEGORY_MUSIC, CATEGORY_PHOTO
} from "../../../constants/myCollectionsPage";


const CategoryBlock = ({collectionsData}: { collectionsData: any }) => {

    const navigate = useNavigate()

    const onClickCategory = (category: string) => {
        if (category === CATEGORY_ALL) navigate(`/explore`)
        else navigate(`/explore/${category}`)
    }

    return (
        <div className={styles.categoryBlock}>
            {/*<div className={styles.category_item} onClick={() => onClickCategory(CATEGORY_ALL)}>*/}
            {/*    <div style={{background: '#5D32E9'}} className={styles.colored}>*/}
            {/*        {!!collectionsData && <img src={collectionsData[0]?.logoImage} alt={''}/>}*/}
            {/*    </div>*/}
            {/*    <div className={styles.title}>All</div>*/}
            {/*</div>*/}

            <div className={styles.category_item} onClick={() => onClickCategory(CATEGORY_ART)}>
                <div style={{background: '#2BCDE4'}} className={styles.colored}>
                    <img
                        src={collectionsData?.find((item: any) => item?.category === CATEGORY_ART)?.logoImage}
                        alt={''}/>
                </div>
                <div className={styles.title}>Art</div>
            </div>

            <div className={styles.category_item} onClick={() => onClickCategory(CATEGORY_COMMUNITY)}>
                <div style={{background: '#34C57A'}} className={styles.colored}>
                    <img
                        src={collectionsData?.find((item: any) => item?.category === CATEGORY_COMMUNITY)?.logoImage}
                        alt={''}/>
                </div>
                <div className={styles.title}>Community</div>
            </div>

            <div className={styles.category_item} onClick={() => onClickCategory(CATEGORY_GAMING)}>
                <div style={{background: '#EB5757'}} className={styles.colored}>
                    <img
                        src={collectionsData?.find((item: any) => item?.category === CATEGORY_GAMING)?.logoImage}
                        alt={''}/>
                </div>
                <div className={styles.title}>Gaming</div>
            </div>

            <div className={styles.category_item} onClick={() => onClickCategory(CATEGORY_MEMBERSHIPS)}>
                <div style={{background: '#2081E2'}} className={styles.colored}>
                    <img
                        src={collectionsData?.find((item: any) => item?.category === CATEGORY_MEMBERSHIPS)?.logoImage}
                        alt={''}/>
                </div>
                <div className={styles.title}>Memberships</div>
            </div>

            <div className={styles.category_item} onClick={() => onClickCategory(CATEGORY_MUSIC)}>
                <div style={{background: '#34C57A'}} className={styles.colored}>
                    <img
                        src={collectionsData?.find((item: any) => item?.category === CATEGORY_MUSIC)?.logoImage}
                        alt={''}/>
                </div>
                <div className={styles.title}>Music</div>
            </div>

            <div className={styles.category_item} onClick={() => onClickCategory(CATEGORY_PHOTO)}>
                <div style={{background: '#EB5757'}} className={styles.colored}>
                    <img
                        src={collectionsData?.find((item: any) => item?.category === CATEGORY_PHOTO)?.logoImage}
                        alt={''}/>
                </div>
                <div className={styles.title}>Photography</div>
            </div>

            {// @ts-ignore
                <a name='learn' className={styles.learn}/>}
        </div>
    );
};

export default CategoryBlock;

import React, {useEffect} from 'react';
import './App.css';
import Routers from "./routes";
import {Provider} from 'react-redux'
import {configureStore} from './store'

function App() {
    const store = configureStore();

    return (
        <div className="App">
            <Provider store={store}>
                <Routers/>
            </Provider>
        </div>
    );
}

export default App;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import Button from "../../../components/Button/Button";
import transferSvg from '../../../assets/images/symbols/transfer.svg'
import shiba from '../../../assets/images/symbols/shiba.svg'
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {EVENT_TYPE_LISTING_CANCELED, MOBILE} from "../../../constants";
import {AVAILABLE_CURRENCY} from "../../../constants/listingPage";
import Timer from "../../../components/Timer/Timer";
import moment from "moment";
import useHandleMarketplace from "../../../hooks/blockchain/useHandleMarketplace";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import BuyModal from "../../../components/Modals/BuyModal/BuyModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useHandleToken from "../../../hooks/blockchain/useHandleToken";
import TransferModal from "../../../components/Modals/TransferModal/TransferModal";
import {useSelector} from "react-redux";
import MakeOfferModal from "../../../components/Modals/MakeOfferModal/MakeOfferModal";
import {useCountdown} from "../../../hooks/useCountdown";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import CollectionApi from "../../../utils/api/CollectionApi";
import useHandleAuth from "../../../hooks/auth/useHandleAuth";
import CryptoApi from "../../../utils/api/CryptoApi";
import TransactionPendingModal from "../../../components/Modals/TransactionPendingModal/TransactionPendingModal";
import TransactionSuccessModal from "../../../components/Modals/TransactionSuccessModal/TransactionSuccessModal";
import TransactionFailedModal from "../../../components/Modals/TransactionFailedModal/TransactionFailedModal";
import {toast} from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import useHandleWeb3 from "../../../hooks/web3/useHandleWeb3";

type Props = {
    isOwner: boolean,
    nftData: any,
    setNftData: any,
    setIsNewOfferPlaced: any,
    isNewOfferPlaced: boolean,
    handleSignUpModal: any
}

const ActionsBlock = ({
                          isOwner,
                          nftData,
                          setNftData,
                          setIsNewOfferPlaced,
                          isNewOfferPlaced,
                          handleSignUpModal
                      }: Props) => {
        const customer = useSelector((state: any) => state.customer.data)
        const [isListed, setIsListed] = useState(false)
        const [isMadeOffer, setIsMadeOffer] = useState(false)
        const [isListingDelayed, setIsListingDelayed] = useState(false)
        const [currentOffer, setCurrentOffer] = useState<any>(null)
        const [maticToUsd, setMaticToUsd] = useState<any>(0)

        const [cancelTxID, setCancelTxID] = useState<any>('')

        const [transferTxID, setTransferTxID] = useState<any>('')

        const [approveShibTxID, setApproveShibTxID] = useState<any>('')
        const [approveShibOfferTxID, setApproveShibOfferTxID] = useState<any>('')
        const [purchaseTxID, setPurchaseTxID] = useState<any>('')
        const [purchaseGasFee, setPurchaseGasFee] = useState<any>(0)

        const [offerTxID, setOfferTxID] = useState<any>('')

        const windowDimensions = useWindowDimensions()
        const navigate = useNavigate()
        const handleMarketplace = useHandleMarketplace()
        const handleAuth = useHandleAuth()
        const handleLoader = useHandleLoader()
        const handleBuyModal = useHandleModal()
        const handleTransferModal = useHandleModal()
        const handleMakeOfferModal = useHandleModal()
        const handleEditOfferModal = useHandleModal()
        const handleToken = useHandleToken()
        const handleActivity = useHandleActivity()
        const handleWeb3 = useHandleWeb3()

        const handlePendingCancelListingModal = useHandleModal()
        const handlePendingTransferModal = useHandleModal()

        const handlePendingApproveShibModal = useHandleModal()
        const handlePendingApproveShibOfferModal = useHandleModal()
        const handlePendingPurchaseModal = useHandleModal()
        const handleSuccessPurchaseModal = useHandleModal()
        const handleFailPurchaseModal = useHandleModal()

        const handlePendingOfferModal = useHandleModal()

        const [, , , hoursValue, minutesValue, secondsValue] = useCountdown(moment.unix(nftData?.listing?.startDate).format())

        const isMobile = windowDimensions?.width <= MOBILE

        const onClickList = () => navigate(`/collection/${nftData?.contractAddress}/${nftData?.tokenID}/list`)
        const onClickBuy = () => {
            if (!customer?.address)
                return handleAuth.connectWallet(handleSignUpModal.open)
            handleBuyModal.open()
        }

        const onClickTransfer = () => handleTransferModal.open()
        const onClickMakeOffer = () => {
            if (!customer?.address)
                return handleAuth.connectWallet(handleSignUpModal.open)
            handleMakeOfferModal.open()
        }

        const onClickEditOffer = () => {
            if (!customer?.address)
                return handleAuth.connectWallet(handleSignUpModal.open)
            handleEditOfferModal.open()
        }

        const onCancelListing = async () => {
            const balanceBONE = await handleWeb3
                .getBalance(customer?.address)
                .then((res: any) => res / 10 ** 18)
            if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to cancel listing'} isError/>)

            handleMarketplace
                .cancelListing(nftData?.tokenID, nftData?.contractAddress)
                .then(async (res: any) => {
                    setCancelTxID(res?.hash)
                    handlePendingCancelListingModal.open()
                    const transaction = await res?.wait();

                    if (transaction?.status) {

                        await handleActivity.createActivity({
                            eventType: EVENT_TYPE_LISTING_CANCELED,
                            tokenID: nftData?.tokenID,
                            contractAddress: nftData?.contractAddress?.toLowerCase(),
                            name: nftData?.name,
                            nftName: nftData?.metadata?.name,
                            isCollectionVerified: nftData?.isCollectionVerified,
                            imageDOLink: nftData?.imageDOLink,//done
                            audioDOLink: nftData?.audioDOLink,
                            videoDOLink: nftData?.videoDOLink,
                            price: nftData?.listing?.price,
                            currency: AVAILABLE_CURRENCY?.find((item: any) =>
                                item?.address?.toLowerCase() === nftData?.listing?.tokenAddress?.toLowerCase())?.label,
                            from: customer?.address?.toLowerCase(),
                            startDate: nftData?.listing?.startDate,
                            endDate: nftData?.listing?.endDate,
                            transactionTime: moment().unix(),
                            transactionHash: transaction?.transactionHash
                        })

                        await handleToken
                            .checkToken(nftData?.contractAddress, nftData?.tokenID)
                            .then(res => setNftData(res))

                        await new CollectionApi().checkCollection(nftData?.contractAddress?.toLowerCase())
                        handlePendingCancelListingModal.close()
                        toast(<ToastMsg text={'Listing is successfully canceled'}/>)
                    } else {
                        handlePendingCancelListingModal.close()
                        toast(<ToastMsg text={'Failed to cancel listing'} isError/>)
                    }
                })
                .catch((err: any) => {
                    if (err?.code !== 'ACTION_REJECTED') {
                        toast(<ToastMsg text={'Failed to cancel listing'} isError/>)
                    }
                    return handlePendingCancelListingModal.close()
                })
        }

        useEffect(() => {
            const offers = nftData?.offers
                ?.filter((item: any) => item?.buyer?.toLowerCase() === customer?.address?.toLowerCase())
                ?.filter((item: any) => (item?.price > 0 && item?.endDate >= moment().unix()))

            setIsListed(!!nftData?.listing?.price && nftData?.listing?.endDate > moment().unix())
            setIsMadeOffer(!!offers?.length)
            setCurrentOffer(offers[offers?.length - 1])
        }, [nftData?.listing?.price, nftData?.listing?.endDate, nftData?.offers, customer?.address])

        useEffect(() => {
            if (nftData?.listing?.startDate > moment().unix()) {
                setIsListingDelayed(true)
            } else setIsListingDelayed(false)
        }, [hoursValue, minutesValue, secondsValue])

        useEffect(() => {
            new CryptoApi()
                .getUSDtoMATIC()
                .then((res) => setMaticToUsd(res))
        }, [])

        return (
            <div className={styles.actionsBlock}>

                {(!isOwner && isListed && !isListingDelayed) && <>
                    {isMobile && <p className={styles.sale}>Sale ends:
                        <Timer targetDate={moment.unix(nftData?.listing?.endDate).format()} setIsListed={setIsListed}/>
                    </p>}

                    <div className={styles.wrapper}>
                        <span className={styles.title}>Purchase price</span>
                        {!isMobile && <p className={styles.sale}>Sale ends:
                            <Timer targetDate={moment.unix(nftData?.listing?.endDate).format()} setIsListed={setIsListed}/>
                        </p>}
                    </div>

                    <div className={styles.value_wrapper}>
                        <div className={styles.value}>
                            {(+nftData?.listing?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}
                            {/*{AVAILABLE_CURRENCY.find(item => item?.address === nftData?.listing?.tokenAddress)?.label}*/}
                            <img alt={''} src={shiba} width={24} height={24}/>
                        </div>
                        <p className={styles.dollar_amount}>$ {((+nftData?.listing?.price || 0) * maticToUsd)?.toLocaleString("en-US", {maximumFractionDigits: 2})}</p>
                    </div>

                    <div className={styles.buttons}>
                        <Button title={'Buy now'} isYellow onClick={onClickBuy}/>
                        <Button title={isMadeOffer ? 'Edit offer' : 'Make offer'} isBlackWhite
                                onClick={isMadeOffer ? onClickEditOffer : onClickMakeOffer}/>
                    </div>
                </>}

                {(!isOwner && (!isListed || isListingDelayed)) && <>
                    <p className={styles.text}>Not listed</p>
                    <div className={styles.buttons}>
                        <Button title={isMadeOffer ? 'Edit offer' : 'Make offer'} isYellow
                                onClick={isMadeOffer ? onClickEditOffer : onClickMakeOffer}/>
                    </div>
                </>}

                {(isOwner && !isListed) && <>
                    <p className={styles.text}>Not listed</p>
                    <div className={styles.buttons}>
                        <Button title={'List now'} isYellow onClick={onClickList}/>
                        <Button title={'Transfer'} isBlackWhite image={transferSvg} onClick={onClickTransfer}/>
                    </div>
                </>}

                {(isOwner && isListed && !isListingDelayed) && <>
                    {isMobile && <p className={styles.sale}>
                        Sale ends:
                        <Timer targetDate={moment.unix(nftData?.listing?.endDate).format()} setIsListed={setIsListed}/>
                    </p>}
                    <div className={styles.wrapper}>
                        <span className={styles.title}>Purchase price</span>
                        {!isMobile && <p className={styles.sale}>Sale ends:
                            <Timer targetDate={moment.unix(nftData?.listing?.endDate).format()} setIsListed={setIsListed}/>
                        </p>}
                    </div>

                    <div className={styles.value_wrapper}>
                        <div className={styles.value}>
                            {(+nftData?.listing?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}
                            {/*{AVAILABLE_CURRENCY.find(item => item?.address === nftData?.listing?.tokenAddress)?.label}*/}
                            <img alt={''} src={shiba} width={24} height={24}/>
                        </div>
                        <p className={styles.dollar_amount}>$ {((+nftData?.listing?.price || 0) * maticToUsd)?.toLocaleString("en-US", {maximumFractionDigits: 2})}</p>
                    </div>

                    <div className={styles.buttons}>
                        <Button title={'Edit listing'} isBlackWhite onClick={onClickList}/>
                        <Button title={'Unlist'} isBlackWhite onClick={onCancelListing}/>
                    </div>
                </>}

                {(isOwner && isListed && isListingDelayed) && <>
                    {isMobile && <p className={styles.sale}>
                        Sale starts: <span>{moment.unix(nftData?.listing?.startDate).format('hh:mm a, D MMM YYYY')}</span>
                    </p>}
                    <div className={styles.wrapper}>
                        <span className={styles.title}>Purchase price</span>
                        {!isMobile && <p className={styles.sale}>
                            Sale
                            starts: <span>{moment.unix(nftData?.listing?.startDate).format('hh:mm a, D MMM YYYY')}</span>
                        </p>}
                    </div>

                    <div className={styles.value_wrapper}>
                        <div className={styles.value}>
                            {(+nftData?.listing?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}
                            {/*{AVAILABLE_CURRENCY.find(item => item?.address === nftData?.listing?.tokenAddress)?.label}*/}
                            <img alt={''} src={shiba} width={24} height={24}/>
                        </div>
                        <p className={styles.dollar_amount}>$ {((+nftData?.listing?.price || 0) * maticToUsd)?.toLocaleString("en-US", {maximumFractionDigits: 2})}</p>
                    </div>

                    <div className={styles.buttons}>
                        <Button title={'Edit listing'} isBlackWhite onClick={onClickList}/>
                        <Button title={'Unlist'} isBlackWhite onClick={onCancelListing}/>
                    </div>
                </>}

                {handleBuyModal.isActive && <BuyModal
                    nftData={nftData}
                    isOpen={handleBuyModal.isActive}
                    onClose={handleBuyModal.close}
                    setNftData={setNftData}
                    handlePendingApproveShibModal={handlePendingApproveShibModal}
                    handlePendingPurchaseModal={handlePendingPurchaseModal}
                    handleSuccessPurchaseModal={handleSuccessPurchaseModal}
                    handleFailPurchaseModal={handleFailPurchaseModal}
                    setPurchaseTxID={setPurchaseTxID}
                    setApproveShibTxID={setApproveShibTxID}
                />}

                {handleTransferModal.isActive && <TransferModal
                    isOpen={handleTransferModal.isActive}
                    onClose={handleTransferModal.close}
                    nftData={nftData}
                    setNftData={setNftData}
                    handlePendingTransferModal={handlePendingTransferModal}
                    setTransferTxID={setTransferTxID}
                />}

                {handleMakeOfferModal.isActive && <MakeOfferModal
                    isOpen={handleMakeOfferModal.isActive}
                    onClose={handleMakeOfferModal.close}
                    nftData={nftData}
                    setNftData={setNftData}
                    setIsNewOfferPlaced={setIsNewOfferPlaced}
                    isNewOfferPlaced={isNewOfferPlaced}
                    handlePendingOfferModal={handlePendingOfferModal}
                    setOfferTxID={setOfferTxID}
                    setApproveShibOfferTxID={setApproveShibOfferTxID}
                    handlePendingApproveShibOfferModal={handlePendingApproveShibOfferModal}
                />}

                {handleEditOfferModal.isActive && <MakeOfferModal
                    isOpen={handleEditOfferModal.isActive}
                    onClose={handleEditOfferModal.close}
                    nftData={nftData}
                    setIsNewOfferPlaced={setIsNewOfferPlaced}
                    isEdit
                    currentOffer={currentOffer}
                    isNewOfferPlaced={isNewOfferPlaced}
                    handlePendingOfferModal={handlePendingOfferModal}
                    setOfferTxID={setOfferTxID}
                    setApproveShibOfferTxID={setApproveShibOfferTxID}
                    handlePendingApproveShibOfferModal={handlePendingApproveShibOfferModal}
                />}

                {handlePendingCancelListingModal.isActive && <TransactionPendingModal
                    isOpen={handlePendingCancelListingModal.isActive}
                    transactionID={cancelTxID}
                    title={'Your transaction is processing...'}
                    text={`Cancel listing of`}
                    yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
                />}

                {handlePendingTransferModal.isActive && <TransactionPendingModal
                    isOpen={handlePendingTransferModal.isActive}
                    transactionID={transferTxID}
                    title={'Your transaction is processing...'}
                    text={`Transfer of`}
                    yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
                />}

                {handlePendingPurchaseModal.isActive && <TransactionPendingModal
                    isOpen={handlePendingPurchaseModal.isActive}
                    transactionID={purchaseTxID}
                    title={'Your transaction is processing...'}
                    text={`Your purchase of`}
                    yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
                />}

                {handlePendingApproveShibModal.isActive && <TransactionPendingModal
                    isOpen={handlePendingApproveShibModal.isActive}
                    transactionID={approveShibTxID}
                    title={'Your transaction is processing...'}
                    text={`Your approval of`}
                    yellowText={`${nftData?.listing?.price} SHIB`}
                />}

                {handlePendingApproveShibOfferModal.isActive && <TransactionPendingModal
                    isOpen={handlePendingApproveShibOfferModal.isActive}
                    transactionID={approveShibOfferTxID}
                    title={'Your transaction is processing...'}
                    text={`Your approval of`}
                    yellowText={`SHIB`}
                />}

                {handleSuccessPurchaseModal.isActive && <TransactionSuccessModal
                    nftData={nftData}
                    onClose={handleSuccessPurchaseModal.close}
                    isOpen={handleSuccessPurchaseModal.isActive}
                    yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
                    text={'You have successfully purchased'}
                    title={'Your purchase is complete!'}
                    transactionID={purchaseTxID}
                    text2={'and it has been sent to your wallet.'}
                    buttonText={'View purchase'}
                    onClickButton={() => navigate(`/${customer?.userName || customer?.address}/collected`)}
                />}

                {handleFailPurchaseModal.isActive && <TransactionFailedModal
                    nftData={nftData}
                    onClose={handleFailPurchaseModal.close}
                    isOpen={handleFailPurchaseModal.isActive}
                    price={nftData?.listing?.price}
                    gasFee={purchaseGasFee}
                    transactionID={purchaseTxID}
                    text={'Purchases can be unsuccessful due to network problems or because the item was sold to someone else before you.'}
                    title={'Your purchase failed'}
                />}


                {handlePendingOfferModal.isActive && <TransactionPendingModal
                    isOpen={handlePendingOfferModal.isActive}
                    transactionID={offerTxID}
                    title={'Your transaction is processing...'}
                    text={`Your offer for`}
                    yellowText={`${nftData?.metadata?.name || '#' + nftData?.tokenID}`}
                />}

            </div>
        );
    }
;

export default ActionsBlock;

import guideImg1 from '../assets/images/guides/_SHIB Burns_ Platform Fees_ and Creator Fees.jpeg'
import guideImg2 from '../assets/images/guides/Buying_ Selling_ Offers_ and Transferring NFTS.jpeg'
import guideImg3 from '../assets/images/guides/Creating Collections and NFTS.jpeg'
import guideImg4 from '../assets/images/guides/Discovering NFT Collections.jpeg'
import guideImg5 from '../assets/images/guides/Getting Started Guide.jpeg'

export const GUIDE_SLIDER_DATA = [
    {image: guideImg1, text: 'SHIB Burns Fees and Royalties'},
    {image: guideImg2, text: 'Buying Selling Offers and Transferring NFTS'},
    {image: guideImg3, text: 'Creating Collections and NFTS'},
    {image: guideImg4, text: 'Discovering NFT Collections'},
    {image: guideImg5, text: 'Getting Started Guide'},
]

export const CATEGORY_DATA = [
    {title: 'All', color: '#5D32E9'},
    {title: 'Art', color: '#2BCDE4'},
    {title: 'Community', color: '#34C57A'},
    {title: 'Gaming', color: '#EB5757'},
    {title: 'Memberships', color: '#2081E2'},
    {title: 'More', color: '#2081E2'},
]

import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";

type Props = {
    isChecked: boolean,
    onChange: any
}

const Switch = ({isChecked, onChange}: Props) => {
    return (
        <label className={styles.switch}>
            <input type="checkbox" checked={isChecked} onChange={onChange}/>
            <span className={cn(styles.slider, styles.round)}/>
        </label>
    );
};

export default Switch;

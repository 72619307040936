// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_arrow__H6X8r {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 48px !important;
  height: 48px !important;
  background: #3E3E3E !important;
  border: 1px solid #4E4E4E !important;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.24) !important;
  border-radius: 1230px !important;
  cursor: pointer;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/SliderArrows/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,uBAAA;EACA,8BAAA;EACA,oCAAA;EACA,uDAAA;EACA,gCAAA;EACA,eAAA;EACA,UAAA;AACF","sourcesContent":[".arrow{\n  display: flex !important;\n  align-items: center;\n  justify-content: center;\n  width: 48px !important;\n  height: 48px !important;\n  background: #3E3E3E !important;\n  border: 1px solid #4E4E4E !important;\n  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.24) !important;\n  border-radius: 1230px !important;\n  cursor: pointer;\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": `styles_arrow__H6X8r`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from 'react';
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import Input from "../../Input/Input";
import styles from './styles.module.scss'
import Button from "../../Button/Button";
import deleteImg from '../../../assets/images/symbols/delete.svg'
import cn from "classnames";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";

type Props = {
    isOpen: boolean,
    onClose: any,
    setCreateData: any,
    createData: any
}

const AddLevelsModal = ({isOpen, onClose, createData, setCreateData}: Props) => {
    const [levels, setLevels] = useState([{type: '', level: 1, maxLevel: 1000, id: 0}])
    const [isSaveDisabled, setIsSaveDisabled] = useState(true)
    const windowDimensions = useWindowDimensions()

    const onChangeType = (e: any, id: number) => setLevels(
        levels?.map((item: any) => item?.id === id
            ? {...item, type: e?.target?.value}
            : item))

    const onChangeName = (e: any, id: number) => {
        if (+e?.target?.value < 0) return

        setLevels(
            levels?.map((item: any) => item?.id === id
                ? {...item, level: e?.target?.value}
                : item))
    }

    const onChangeMaxLevel = (e: any, id: number) => {
        if (+e?.target?.value < 0) return

        setLevels(
            levels?.map((item: any) => item?.id === id
                ? {...item, maxLevel: e?.target?.value}
                : item))
    }

    const onAddProperty = () => {
        if (isSaveDisabled) return
        if (levels?.length > 9) return toast(<ToastMsg text={'You can add only 10 levels'} isError/>)

        setLevels([...levels, {type: '', level: 1, maxLevel: 1000, id: new Date().getTime()}])
    }
    const onDelete = (id: number) => {
        if (levels?.length === 1) setLevels([{type: '', level: 1, maxLevel: 1000, id: new Date().getTime()}])
        else setLevels(levels?.filter((item: any) => item?.id !== id))
    }

    const onSave = () => {
        if (isSaveDisabled) return
        if (!!levels?.filter(item => +item?.maxLevel < +item?.level)?.length)
            return toast(<ToastMsg text={'NFT level must be smaller than NFT max level'} isError/>)
        onClose()
        setCreateData({
            ...createData,
            levels: levels.filter((item) => +item?.level && +item?.maxLevel && item?.type?.length)
        })
    }

    const onCloseModal = () => {
        onClose()
        setLevels([{level: 1, type: '', maxLevel: 1000, id: new Date().getTime()}])
    }

    useEffect(() => {
        setIsSaveDisabled(!!levels?.filter((item: any) => +!item?.level || +!item?.maxLevel || !item?.type?.length)?.length)
    }, [levels])

    useEffect(() => {
        setLevels(createData?.levels?.length
            ? createData?.levels
            : [{level: 1, type: '', maxLevel: 1000, id: new Date().getTime()}])
    }, [createData?.levels, isOpen])

    return (
        <ModalLayout
            onClose={onCloseModal}
            isOpen={isOpen}
            maxWidth={windowDimensions?.width < 820 ? windowDimensions?.width - 40 + 'px' : '784px'}
            withCrossIcon
            maxHeight={'90vh'}
        >
            <>
                <p className={styles.title}>Add levels</p>

                <div className={styles.list}>
                    {levels?.map((property: any, key: number) => <div className={styles.property} key={key}>
                        <div className={styles.block}>
                            <p>Type</p>
                            <Input
                                value={property?.type}
                                onChange={(e: any) => onChangeType(e, property?.id)}
                                placeholder={'Background'}
                            />
                        </div>

                        <div className={styles.block}>
                            <p>Level</p>
                            <div className={styles.block_inputs}>
                                <Input
                                    value={property?.level}
                                    onChange={(e: any) => onChangeName(e, property?.id)}
                                    placeholder={'1'}
                                    type={'number'}
                                    min={0}
                                />
                                of
                                <Input
                                    value={property?.maxLevel}
                                    onChange={(e: any) => onChangeMaxLevel(e, property?.id)}
                                    placeholder={'1000'}
                                    type={'number'}
                                    min={0}
                                />
                            </div>
                        </div>

                        <div
                            className={cn(styles.delete, styles.delete_visible)}
                            onClick={() => onDelete(property?.id)}>
                            <img src={deleteImg} alt={''}/>
                        </div>
                    </div>)}
                </div>


                <div className={styles.add}>
                    <Button
                        title={'Add level'}
                        disabled={isSaveDisabled || levels?.length === 10}
                        isBlackWhite
                        onClick={onAddProperty}
                    />
                </div>

                <Button title={'Save'} disabled={isSaveDisabled} isYellow onClick={onSave}/>

            </>
        </ModalLayout>
    );
};

export default AddLevelsModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_topCollections__PjMxb {
  width: 100%;
}

.styles_listWrapper__uG6jg {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
  width: 100%;
  margin-bottom: 60px;
  flex-wrap: wrap;
}
@media (max-width: 1025px) {
  .styles_listWrapper__uG6jg {
    flex-direction: column;
    gap: 0;
  }
}
@media (max-width: 640px) {
  .styles_listWrapper__uG6jg {
    margin-bottom: 40px;
  }
}

.styles_list__phSbQ {
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.styles_button__C8qGz {
  margin: 0 auto 176px;
  width: -moz-fit-content;
  width: fit-content;
}
@media (max-width: 640px) {
  .styles_button__C8qGz {
    margin: 0 auto 100px;
  }
  .styles_button__C8qGz div {
    width: 163px;
    height: 40px;
    font-size: 15px;
    line-height: 20px;
    border-radius: 12px;
    margin: 0 auto 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/templates/MainPage/TopCollections/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EATF;IAUI,sBAAA;IACA,MAAA;EAEF;AACF;AAAE;EAdF;IAeI,mBAAA;EAGF;AACF;;AAAA;EACE,WAAA;EACA,gBAAA;EACA,sBAAA;AAGF;;AAAA;EACE,oBAAA;EACA,uBAAA;EAAA,kBAAA;AAGF;AADE;EAJF;IAKI,oBAAA;EAIF;EAHE;IACE,YAAA;IACA,YAAA;IACA,eAAA;IACA,iBAAA;IACA,mBAAA;IACA,oBAAA;EAKJ;AACF","sourcesContent":[".topCollections {\n  width: 100%;\n}\n\n.listWrapper {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  gap: 40px;\n  width: 100%;\n  margin-bottom: 60px;\n  flex-wrap: wrap;\n\n  @media (max-width: 1025px) {\n    flex-direction: column;\n    gap: 0;\n  }\n\n  @media (max-width: 640px) {\n    margin-bottom: 40px;\n  }\n}\n\n.list {\n  width: 100%;\n  max-width: 500px;\n  box-sizing: border-box;\n}\n\n.button {\n  margin: 0 auto 176px;\n  width: fit-content;\n\n  @media (max-width: 640px) {\n    margin: 0 auto 100px;\n    div {\n      width: 163px;\n      height: 40px;\n      font-size: 15px;\n      line-height: 20px;\n      border-radius: 12px;\n      margin: 0 auto 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topCollections": `styles_topCollections__PjMxb`,
	"listWrapper": `styles_listWrapper__uG6jg`,
	"list": `styles_list__phSbQ`,
	"button": `styles_button__C8qGz`
};
export default ___CSS_LOADER_EXPORT___;

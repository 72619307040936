import CollectionApi from "../../utils/api/CollectionApi";
import useHandleMarketplace from "./useHandleMarketplace";
import useHandleActivity from "./useHandleActivity";
import {STATS_TIME_ALL_TIME} from "../../constants/statsPage";

export default function useHandleToken() {
    const handleMarketplace = useHandleMarketplace()
    const handleActivity = useHandleActivity()

    const getSingleTokenInfo = async (contractAddress: any, tokenId: any) => {
        return await new CollectionApi()
            .getOneToken(contractAddress, tokenId)
            .then(async (res) => {
                const listingInfoResponse = await handleMarketplace.checkListing(tokenId, contractAddress)
                const ownerOf = await handleMarketplace.checkOwnerOf(tokenId, contractAddress)
                return {...res?.data, owner: ownerOf, listing: listingInfoResponse}
            })
    }

    const checkToken = async (contractAddress: any, tokenId: any) => {
        const {averagePriceData} = await handleActivity.getAveragePriceByToken(contractAddress, tokenId)
        const volume = await handleActivity.getCollectionVolume(contractAddress, STATS_TIME_ALL_TIME)
        const {allActivityData} = await handleActivity.getActivitiesByToken(contractAddress, tokenId)

        const tokenData = await new CollectionApi()
            .checkToken(contractAddress, tokenId)
            .then((res) => res?.data)

        return ({
            ...tokenData,
            activity: allActivityData?.reverse(),
            collectionVolume: volume,
            averagePrice: averagePriceData
        })
    }

    return {
        getSingleTokenInfo, checkToken
    }
}

import React from 'react';
import styles from '../styles.module.scss'
import arrowPrev from '../../../assets/images/symbols/arrow_left.svg'
import cn from "classnames";

const PrevArrow = (props: any) => {
    const {className, style, onClick, left} = props;
    return (
        <div className={cn(className, styles.arrow)}
             style={{...style, left: left}}
             onClick={onClick}>
            <img src={arrowPrev} alt={''} width={7.5} height={13.5}/>
        </div>
    );
};

export default PrevArrow;

import React from 'react';
import styles from './styles.module.scss'
import walletImg from '../../../assets/images/symbols/wallet.svg'
import createCollectionImg from "../../../assets/images/symbols/create_collection.svg";
import addNFTsImg from "../../../assets/images/symbols/add_nfts.svg";
import useCollectionImg from "../../../assets/images/symbols/use_collection.svg";

const CreateSellNfTs = () => {
    return (
        <div className={styles.createSellNfTs}>
            <div className={styles.list_item}>
                <img width={77.84} height={59} src={walletImg} alt={''}/>
                <p className={styles.title}>Set up wallet</p>
                <p className={styles.text}>Once you’ve set up your wallet, connect it to <span>Pawzaar</span> by clicking the wallet or profile icon in the top right corner. <span>Fund your wallet</span> with BONE to make transactions.</p>
            </div>

            <div className={styles.list_item}>
                <img src={createCollectionImg} alt={''}/>
                <p className={styles.title}>Create collection</p>
                <p className={styles.text}>Click <span>My Collections</span> and set up your collection. Add social links, a description, profile & banner images, and set a secondary royalty fee.</p>
            </div>

            <div className={styles.list_item}>
                <img src={addNFTsImg} alt={''}/>
                <p className={styles.title}>Add nFTs</p>
                <p className={styles.text}><span>Upload</span> your work (image, audio, video or 3D art), add a title and description and <span>customize</span> your NFTs with properties, stats and unlockable content.</p>
            </div>

            <div className={styles.list_item}>
                <img src={useCollectionImg} alt={''}/>
                <p className={styles.title}>Use collection</p>
                <p className={styles.text}>Finally, <span>list</span> any NFT you create for sale for a duration you set. You <span>choose the price</span> you want to sell your NFTs for and we help you sell them!</p>
            </div>
        </div>
    );
};

export default CreateSellNfTs;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import Chart from 'react-apexcharts'
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../../constants";
import moment from "moment";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import {
    STATS_TIME_24H,
    STATS_TIME_30D,
    STATS_TIME_7D,
    STATS_TIME_90D,
    STATS_TIME_ALL_TIME
} from "../../../constants/statsPage";

const GraphicsBlock = ({
                           activeTime,
                           contractAddress,
                           createdAt
                       }: { activeTime: number, contractAddress: string, createdAt: string }) => {
    const [priceData, setPriceData] = useState<any>([])
    const [numOfSalesPerDay, setNumOfSalesPerDay] = useState<any>([])
    const [collectionVolume, setCollectionVolume] = useState<any>([])

    const handleActivity = useHandleActivity()
    const handleLoader = useHandleLoader()
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width <= MOBILE

    const minTime: any = {
        [STATS_TIME_24H]: moment().subtract(24, 'hours').unix() * 1000,
        [STATS_TIME_7D]: moment().subtract(7, 'days').unix() * 1000,
        [STATS_TIME_30D]: moment().subtract(30, 'days').unix() * 1000,
        [STATS_TIME_90D]: moment().subtract(90, 'days').unix() * 1000,
        [STATS_TIME_ALL_TIME]: moment(createdAt).unix() * 1000,
    }

    const options = {
        chart: {
            id: 'area-datetime',
            zoom: {
                enabled: false,
            },
            height: 404,
            type: 'line',
            foreColor: '#757575',
            toolbar: {
                show: false
            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            type: 'datetime',
            min: minTime[activeTime],
            tickAmount: 6,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: [{
            title: {
                text: 'Volume (SHIB)',
                style: {
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: '18px',
                    fontWeight: '700',
                }
            },

        }, {
            opposite: true,
            title: {
                text: 'Average Price (SHIB)',
                style: {
                    fontFamily: "'Open Sans', sans-serif",
                    lineHeight: '25px',
                    fontSize: '18px',
                    fontWeight: '700',
                }
            }
        },],
        tooltip: {
            theme: 'dark',
            x: {
                format: 'dd MMM yyyy, HH:mm'
            },
            y: {
                formatter: function (val: any) {
                    return +val
                }
            }
        },
        selection: {
            xaxis: {
                min: minTime[activeTime],
                max: moment().unix() * 1000
            },
        },
        colors: ['#2D2D2D', '#F69C00', 'rgba(255,255,255,0)'],
        grid: {
            borderColor: '#2D2D2D',
        },
        legend: {
            show: false
        },
    };

    useEffect(() => {
        handleLoader.loaderWrapper(async () => {
            await handleActivity
                .getCollectionVolume(contractAddress?.toLowerCase(), activeTime)
                .then((res) => setCollectionVolume(res))
            await handleActivity
                .getAveragePriceByCollection(contractAddress?.toLowerCase(), activeTime)
                .then((res) => {
                    setPriceData(res?.averagePriceData)
                    setNumOfSalesPerDay(res?.numOfSalesPerDay)
                })
        })
    }, [contractAddress, activeTime])

    return (
        <div className={styles.chart}>
            <Chart
                // @ts-ignore
                options={options}
                series={[{
                    name: 'Volume (SHIB)',
                    type: 'column',
                    data: collectionVolume
                }, {
                    name: 'Average Price (SHIB)',
                    type: 'line',
                    data: priceData
                }, {
                    name: 'Num. sales: ',
                    type: 'line',
                    data: numOfSalesPerDay
                }]}
                width={'100%'}
                height={isMobile ? 234 : 404}
            />
        </div>
    );
};

export default GraphicsBlock;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__-rd1X {
  width: 270px;
  position: relative;
}

.styles_categories__dq07g {
  width: 270px;
  height: 38px;
  background: #3E3E3E;
  border-radius: 1230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 24px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.styles_categories_arrow__zPsog {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.styles_categories_arrow_open__71fjc {
  transform: rotate(0);
  transition: all 0.3s ease;
}

.styles_dropdown__2swOi {
  width: 100%;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background: #3E3E3E;
  border-radius: 12px;
}

.styles_dropdown_item__quI0T {
  padding: 20px 20px;
  width: 100%;
  transition: all 0.3s ease;
  box-sizing: border-box;
  cursor: pointer;
}
.styles_dropdown_item__quI0T:hover {
  background: rgba(0, 0, 0, 0.2);
}
.styles_dropdown_item__quI0T:first-child {
  border-radius: 12px 12px 0 0;
}
.styles_dropdown_item__quI0T:last-child {
  border-radius: 0 0 12px 12px;
}`, "",{"version":3,"sources":["webpack://./src/templates/StatsPage/CategoriesDropdown/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE,oBAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,sBAAA;EACA,eAAA;AACF;AACE;EACE,8BAAA;AACJ;AAEE;EACE,4BAAA;AAAJ;AAGE;EACE,4BAAA;AADJ","sourcesContent":[".wrapper {\n  width: 270px;\n  position: relative;\n}\n\n.categories {\n  width: 270px;\n  height: 38px;\n  background: #3E3E3E;\n  border-radius: 1230px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 7px 24px;\n  box-sizing: border-box;\n  cursor: pointer;\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 24px;\n}\n\n.categories_arrow {\n  transform: rotate(180deg);\n  transition: all .3s ease;\n}\n\n.categories_arrow_open {\n  transform: rotate(0);\n  transition: all .3s ease;\n}\n\n.dropdown {\n  width: 100%;\n  position: absolute;\n  top: 45px;\n  left: 0;\n  right: 0;\n  background: #3E3E3E;\n  border-radius: 12px;\n}\n\n.dropdown_item {\n  padding: 20px 20px;\n  width: 100%;\n  transition: all .3s ease;\n  box-sizing: border-box;\n  cursor: pointer;\n\n  &:hover {\n    background: rgba(0, 0, 0, 0.2);\n  }\n\n  &:first-child {\n    border-radius: 12px 12px 0 0;\n  }\n\n  &:last-child {\n    border-radius: 0 0 12px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__-rd1X`,
	"categories": `styles_categories__dq07g`,
	"categories_arrow": `styles_categories_arrow__zPsog`,
	"categories_arrow_open": `styles_categories_arrow_open__71fjc`,
	"dropdown": `styles_dropdown__2swOi`,
	"dropdown_item": `styles_dropdown_item__quI0T`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import walletImg1 from '../../assets/images/wallet 1.svg'
import walletImg2 from '../../assets/images/walllet 2.svg'
import walletImg3 from '../../assets/images/wallet 3.svg'
import Button from "../Button/Button";
import useHandleActivity from "../../hooks/blockchain/useHandleActivity";
import {useSelector} from "react-redux";
import {EVENT_TYPES} from "../../constants";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useHandleWeb3 from "../../hooks/web3/useHandleWeb3";
import CryptoApi from "../../utils/api/CryptoApi";
import CustomerApi from "../../utils/api/CustomerApi";
import {hexToNumber} from "../../utils/blockchain";

const WalletDropdown = ({
                            dropdownRef,
                            isOpenWallet,
                            onCloseWallet
                        }: { dropdownRef: any, isOpenWallet: any, onCloseWallet: any }) => {
    const customer = useSelector((state: any) => state.customer.data)
    const [activities, setActivities] = useState([])
    const [balance, setBalance] = useState(0)
    const [balanceSHIB, setBalanceSHIB] = useState(0)
    const [balanceLEASH, setBalanceLEASH] = useState(0)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)
    const [shibToUsd, setShibToUsd] = useState<any>(0)
    const [leashToUsd, setLeashToUsd] = useState<any>(0)
    const [isLoading, setIsLoading] = useState<any>(false)

    const handleActivity = useHandleActivity()
    const handleWeb3 = useHandleWeb3()
    const navigate = useNavigate()

    const onClickReadMore = () => {
        navigate(`/${customer?.userName || customer?.address}/activity`)
        onCloseWallet()
    }

    const onClickUser = (address: string) => {
        navigate(`/${address}/collected`)
        onCloseWallet()
    }

    const firstLoadInfo = async () => {
        await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => setBalance(res / 10 ** 18))

        await handleWeb3
            .getBalanceOfShib()
            .then((res: any) => setBalanceSHIB(hexToNumber(res?._hex) / 10 ** 18))

        await handleWeb3
            .getBalanceOfLeash()
            .then((res: any) => setBalanceLEASH(hexToNumber(res?._hex) / 10 ** 18))

        await handleActivity
            .getActivitiesByUserForWallet(customer?.address)
            .then((res) => {
                const data = res?.slice(0, 2)
                const addresses: any = Array.from(new Set([data[0]?.from, data[0]?.to, data[1]?.from, data[1]?.to]?.filter((item) => !!item?.length)))

                if (addresses?.length) {
                    Promise
                        .all(addresses?.map((item: any) => new CustomerApi()
                            .getUserName(item?.toLowerCase())))
                        .then((names: any) => {
                            const namesData = names?.map((item: any) => item?.data)

                            setActivities(data?.map((item: any) => {
                                return {
                                    ...item,
                                    from: namesData
                                        ?.find((add: any) => add?.address?.toLowerCase() === item?.from?.toLowerCase())
                                        ?.userName || item?.from,
                                    to: namesData
                                        ?.find((add: any) => add?.address?.toLowerCase() === item?.to?.toLowerCase())
                                        ?.userName || item?.to,
                                }
                            }))
                        })
                } else setActivities(data)
            })

        await new CryptoApi()
            .getUSDtoBONE()
            .then((res) => setMaticToUsd(res))

        await new CryptoApi()
            .getUSDtoSHIB()
            .then((res) => setShibToUsd(res))

        await new CryptoApi()
            .getUSDtoLEASH()
            .then((res) => setLeashToUsd(res))
    }

    useEffect(() => {
        if (customer?.address && isOpenWallet) {
            setIsLoading(true)
            firstLoadInfo().then(() => setIsLoading(false))
        }
    }, [customer, isOpenWallet])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={dropdownRef} className={cn(styles.wallet, isOpenWallet && styles.wallet_open)}>

            {isLoading
                ? <p className={styles.total}>Loading your balance info...</p>
                : <>
                    <p className={styles.total}>Total</p>
                    <p className={styles.total_value}>$
                        {+((maticToUsd * +balance) + (leashToUsd * +balanceLEASH) + (shibToUsd * +balanceSHIB))?.toFixed(4)}
                    </p>

                    <div className={styles.currency_list}>
                        <div className={styles.currency}>
                            <img src={walletImg1} alt={''} className={styles.currency_image}/>
                            <div>
                                <p className={styles.currency_value}>{balanceSHIB?.toLocaleString("en-US", {maximumFractionDigits: 4})} SHIB</p>
                                <p className={styles.currency_value_usd}>${+(shibToUsd * +balanceSHIB)?.toFixed(4)}</p>
                            </div>
                        </div>

                        <div className={styles.currency}>
                            <img src={walletImg2} alt={''} className={styles.currency_image}/>
                            <div>
                                <p className={styles.currency_value}>{+(+balance)?.toFixed(4)} BONE</p>
                                <p className={styles.currency_value_usd}>${+(maticToUsd * +balance)?.toFixed(4)}</p>
                            </div>
                        </div>

                        <div className={styles.currency}>
                            <img src={walletImg3} alt={''} className={styles.currency_image}/>
                            <div>
                                <p className={styles.currency_value}>{balanceLEASH?.toLocaleString("en-US", {maximumFractionDigits: 4})} LEASH</p>
                                <p className={styles.currency_value_usd}>${+(leashToUsd * +balanceLEASH)?.toFixed(4)}</p>
                            </div>
                        </div>

                        {/*<div className={styles.currency}>*/}
                        {/*    <img src={walletImg4} alt={''} className={styles.currency_image}/>*/}
                        {/*    <div>*/}
                        {/*        <p className={styles.currency_value}>100,000,000 PAW</p>*/}
                        {/*        <p className={styles.currency_value_usd}>$12,123</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <a
                        href={'https://faucet.shibarium.technology/'}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.faucet}
                        onClick={onCloseWallet}
                    >
                        <Button title={'Add funds'} isYellow isFullWidth/>
                    </a>

                    {!!activities?.length && <>

                        <p className={styles.history}>Transaction History</p>
                        <div>
                            <table cellSpacing={0} className={styles.table}>
                                <thead>
                                <tr>
                                    <th className={styles.table_header}>Event</th>
                                    <th className={styles.table_header}>From</th>
                                    <th className={styles.table_header}>To</th>
                                    <th className={styles.table_header}>Time</th>
                                </tr>
                                </thead>
                                <tbody>

                                {activities?.map((item: any, key) => <tr key={key}>
                                    <td className={styles.table_row}>
                                        <div className={styles.eventType}>{EVENT_TYPES[item?.eventType]}</div>
                                    </td>
                                    <td className={styles.table_row}>
                            <span onClick={() => onClickUser(item?.from)}
                                  className={cn(styles.table_row_text, styles.pointer)}>
                                {item?.from?.length > 7
                                    ? item?.from?.slice(0, 4) + '...' + item?.from?.slice(-2)
                                    : (item?.from || '-')}
                            </span>
                                    </td>
                                    <td className={styles.table_row}>
                            <span onClick={() => onClickUser(item?.to)}
                                  className={cn(styles.table_row_text, styles.pointer)}>
                                {item?.to?.length > 7
                                    ? item?.to?.slice(0, 4) + '...' + item?.to?.slice(-2)
                                    : (item?.to || '-')}
                            </span>
                                    </td>
                                    <td className={styles.table_row}>
                            <span
                                className={styles.table_row_text}>{moment.unix(item?.transactionTime).format('MMM D')}</span>
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>
                        <p className={styles.read_more} onClick={onClickReadMore}>Read more</p>
                    </>}
                </>}
        </div>
    );
};

export default WalletDropdown;

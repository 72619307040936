import React, {useState} from 'react';
import styles from './styles.module.scss'
import {GUIDE_SLIDER_DATA} from "../../../constants/mainPage";
import Slider from "react-slick";
import Button from "../../../components/Button/Button";
import NextArrow from "../../../components/SliderArrows/NexArrow/NextArrow";
import PrevArrow from "../../../components/SliderArrows/PrevArrow/PrevArrow";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../../routes/path";

const GuideBlock = () => {
    const navigate = useNavigate()
    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const onClickView = () => navigate(pathList.guides.path)

    const settings = {
        dots: true,
        infinite: true,
        arrows: !isTablet,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        nextArrow: <NextArrow right={'-6px'}/>,
        prevArrow: <PrevArrow left={'-6px'}/>,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: TABLET,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    cssEase: "linear",
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                }
            },
            {
                breakpoint: MOBILE,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    cssEase: "linear",
                    centerMode: true,
                    arrows: false,
                    nextArrow: <></>,
                    prevArrow: <></>,
                }
            },
        ]
    };

    return (
        <div>
            <Slider {...settings}>
                {GUIDE_SLIDER_DATA?.map((item, key) =>
                    <div key={key} className={styles.slider_item}>
                        <img src={item?.image} alt={''} className={styles.slider_item_image}/>
                        <p className={styles.slider_item_text}>{item?.text}</p>
                    </div>)}
            </Slider>

            <div className={styles.button} onClick={onClickView}>
                <Button title={'View all'}/>
            </div>
        </div>
    );
};

export default GuideBlock;

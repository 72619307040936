import useHandleContracts from "./useHandleContracts";
import {hexDateToUnixNumber, hexToNumber} from "../../utils/blockchain";
import {useSelector} from "react-redux";
import {ethers} from "ethers";
import CollectionApi from "../../utils/api/CollectionApi";
import useHandleWeb3 from "../web3/useHandleWeb3";
import {SHIB_ADDRESS} from "../../constants";

export default function useHandleMarketplace() {
    const customer = useSelector((state: any) => state.customer.data)

    const handleContracts = useHandleContracts()
    const handleWeb3 = useHandleWeb3()

    const mint = (walletAddress: string, ipfsLink: string, contractAddress: string) => {
        return handleContracts
            .contractNFTCollectionWithSigner(contractAddress)
            // @ts-ignore
            .mint(walletAddress, ipfsLink)
            .then((tx: any) => tx.wait())
    }

    const mintDrops = (walletAddress: string, ipfsLink: string, contractAddress: string, price: number) => {
        return handleContracts
            .contractDropsCollectionWithSigner(contractAddress)
            // @ts-ignore
            .mint(walletAddress, ipfsLink, (price * 10 ** 18)?.toString())
            .then((tx: any) => tx.wait())
    }

    const bulkMint = (walletAddress: string, ipfsLink: string, amount: number, contractAddress: string) => {
        return handleContracts
            .contractNFTCollectionWithSigner(contractAddress)
            // @ts-ignore
            .bulkMint(walletAddress, ipfsLink, amount)
            .then((tx: any) => tx.wait())
    }

    const bulkMintDrops = (walletAddress: string, ipfsLinks: string[], amount: number, contractAddress: string, price: number) => {
        return handleContracts
            .contractDropsCollectionWithSigner(contractAddress)
            // @ts-ignore
            .bulkMint(walletAddress, ipfsLinks, amount, (price * 10 ** 18)?.toString())
            .then((tx: any) => tx.wait())
    }

    const checkOwnerOf = (tokenID: any, contractAddress: string) => {
        return handleContracts
            .contractERC721WithSigner(contractAddress)
            ?.ownerOf(tokenID)
            .catch(() => null);
    }

    const transfer = (address: string, tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractERC721WithSigner(contractAddress)
            .transferFrom(customer?.address, address, tokenID)
    }

    const getListingCommission = async () => {
        return handleContracts.contractListingWithSigner
            .commissionPer()
            .then((tx: any) => hexToNumber(tx?._hex))
            .catch(() => null);
    }

    const approveListing = (tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractERC721WithSigner(contractAddress).approve(process.env.REACT_APP_LISTING_ADDRESS, tokenID)
    }

    const createListing = (startDate: number, endDate: number, price: string, tokenID: number, isToken: boolean, tokenAddress: string, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS, burnAmount: number, royaltyPercent: number) => {
        return handleContracts.contractListingWithSigner
            .addListing(price, tokenID, contractAddress, startDate, endDate, isToken, tokenAddress, burnAmount, royaltyPercent)
    }

    const purchaseListing = (tokenID: number, price: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractListingWithSigner
            .purchase(contractAddress, tokenID, {value: (price * (10 ** 18)).toString()})
    }

    const purchaseListingWithRoyalty = (tokenID: number, price: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractListingWithSigner
            .purchaseWithRoyalty(contractAddress, tokenID, {value: (price * (10 ** 18)).toString()})
    }

    const purchaseListingToken = (tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractListingWithSigner
            .purchaseToken(contractAddress, tokenID)
    }

    const purchaseListingTokenWithRoyalty = (tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractListingWithSigner
            .purchaseTokenWithRoyalty(contractAddress, tokenID)
    }

    const cancelListing = (tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractListingWithSigner
            .cancelListing(tokenID, contractAddress)
    }

    const checkListing = (tokenID: any, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractListing
            .listings(contractAddress, tokenID)
            .then((tx: any) => {
                return {
                    owner: tx?.owner,
                    price: hexToNumber(tx?.price?._hex) / 10 ** 18,
                    startDate: hexToNumber(tx?.startDate?._hex),
                    endDate: hexToNumber(tx?.endDate?._hex),
                    isToken: tx?.isToken,
                    tokenAddress: tx?.tokenAddress
                }
            })
            .catch(() => null);
    }

    const changeListing = (price: string, tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS, startDate: number, endDate: number, isToken: boolean, tokenAddress: string, burnAmount: number, royaltyPercent: number) => {
        return handleContracts.contractListingWithSigner
            .changeListing(price, tokenID, contractAddress, startDate, endDate, isToken, tokenAddress, burnAmount, royaltyPercent)
    }

    const onMakeOffer = (price: string, owner: string, tokenID: number, startDate: number, endDate: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS, royaltyPercent: number) => {
        return handleContracts.contractOffersWithSigner
            .makeOffer(price, owner, contractAddress, tokenID, startDate, endDate, royaltyPercent, {value: price})
    }

    const onMakeOfferWithToken = (price: string, owner: string, tokenID: number, startDate: number, endDate: number, isToken: boolean, tokenAddress: string, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS, royaltyPercent: number) => {
        return handleContracts.contractOffersWithSigner
            .makeOfferToken(price, owner, contractAddress, tokenID, startDate, endDate, isToken, tokenAddress, royaltyPercent)
    }

    const onEditOffer = (price: string, owner: string, nftAddress: string, tokenID: number, startDate: number, endDate: number, offerID: number, royaltyPercent: number) => {
        return handleContracts.contractOffersWithSigner
            .editOffer(price, owner, nftAddress, tokenID, startDate, endDate, offerID, royaltyPercent, {value: price})
    }

    const onEditOfferWithToken = (price: string, owner: string, nftAddress: string, tokenID: number, startDate: number, endDate: number, isToken: boolean, tokenAddress: string, offerID: number, royaltyPercent: number) => {
        return handleContracts.contractOffersWithSigner
            .editOfferToken(price, owner, nftAddress, tokenID, startDate, endDate, isToken, tokenAddress, offerID, royaltyPercent)
    }

    const onCancelOffer = (tokenID: number, offerID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractOffersWithSigner
            .cancelOffer(tokenID, contractAddress, offerID)
    }

    const onCancelOfferOLD = (tokenID: number, offerID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractOffersWithSignerOLD
            .cancelOffer(tokenID, contractAddress, offerID)
    }

    const onAcceptOffer = (tokenID: number, offerID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS, burnAmount: number, withRoyalty: boolean) => {
        return handleContracts.contractOffersWithSigner
            .acceptOffer(contractAddress, tokenID, offerID, 0, withRoyalty, process.env.REACT_APP_LISTING_ADDRESS)
    }

    const transferRoyaltyOffers = (royaltyAmount: string, royaltyReceiver: string) => {
        return handleContracts.contractOffersWithSignerRoyalty
            .transferRoyalty(royaltyAmount, royaltyReceiver)
            .then((tx: any) => tx.wait())
    }

    const transferRoyaltyListings = (royaltyAmount: string, royaltyReceiver: string) => {
        return handleContracts.contractListingWithSignerRoyalty
            .transferRoyalty(royaltyAmount, royaltyReceiver)
            .then((tx: any) => tx.wait())
            .catch(() => null)
    }

    const checkOffer = (tokenID: number, offerID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractOffers
            .offers(contractAddress, tokenID, offerID)
            .then((tx: any) => {
                return {
                    price: +ethers.utils.formatEther(hexToNumber(tx?.price?._hex)?.toString()),
                    owner: tx?.owner,
                    from: tx?.buyer,
                    id: hexToNumber(tx?.id?._hex),
                    startDate: hexToNumber(tx?.startDate?._hex),
                    endDate: hexToNumber(tx?.endDate?._hex),
                    isToken: tx?.isToken,
                    tokenAddress: tx?.tokenAddress,
                }
            })
            .catch(() => null)
    }

    const checkOfferOld = (tokenID: number, offerID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractOffersOLD
            .offers(contractAddress, tokenID, offerID)
            .then((tx: any) => {
                return {
                    price: hexToNumber(tx?.price?._hex) / 10 ** 18,
                    // price: hexDateToUnixNumber(tx?.price?._hex),
                    owner: tx?.owner,
                    from: tx?.buyer,
                    id: hexToNumber(tx?.id?._hex),
                    startDate: hexToNumber(tx?.startDate?._hex),
                    endDate: hexToNumber(tx?.endDate?._hex),
                    isToken: tx?.isToken,
                    tokenAddress: tx?.tokenAddress,
                }
            })
            .catch(() => null)
    }

    const getOffersLength = (tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        if (handleContracts.contractListing)
            return handleContracts.contractOffers
                .offersCount(tokenID, contractAddress)
                .then((tx: any) => {
                    return hexToNumber(tx?._hex);
                })
                .catch(() => null);
        else return null
    }

    const getOffersLengthOLD = (tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        if (handleContracts.contractListing)
            return handleContracts.contractOffersOLD
                .offersCount(tokenID, contractAddress)
                .then((tx: any) => {
                    return hexToNumber(tx?._hex);
                })
                .catch(() => null);
        else return null
    }

    const approveOffer = (tokenID: number, contractAddress = process.env.REACT_APP_PAWZAAR_COLLECTION_ADDRESS) => {
        return handleContracts.contractERC721WithSigner(contractAddress)
            .approve(process.env.REACT_APP_OFFERS_ADDRESS, tokenID)
    }

    const approveShib = (contractAddress: string, amount: number) => {
        return handleContracts.contractERC20WithSigner(SHIB_ADDRESS)
            .approve(contractAddress, BigInt(amount * 10 ** 18).toString())
    }

    const approveShibRoyalty = (contractAddress: any, amount: string) => {
        return handleContracts.contractERC20WithSignerRoyalty(SHIB_ADDRESS)
            .approve(contractAddress, amount)
            .then((tx: any) => tx.wait())
    }

    const allowanceShib = (userAddress: string) => {
        return handleContracts.contractERC20WithSigner(SHIB_ADDRESS)
            .allowance(userAddress, process.env.REACT_APP_LISTING_ADDRESS)
    }

    const deployContract = async (name: string,
                                  symbol: string,
                                  royaltyPercentage: number,
                                  burnAmount: number
    ) => {
        // @ts-ignore
        const web3 = window?.web3;
        const contractFile = await new CollectionApi()
            .getContract()
            .then((res) => (res?.status ? res.data : null))
            .catch(() => null);
        if (!contractFile?.abi || !contractFile?.bytecode) return;

        const account = await handleWeb3.getAccount();
        if (!account) return;

        const nonce = await handleWeb3.getNonce(account);
        if (!nonce) return;

        const gasPrice = await handleWeb3.getGasPrice();
        if (!gasPrice) return;

        const contract = new web3.eth.Contract(JSON.parse(contractFile?.abi));
        const deployData = {
            data: contractFile?.bytecode,
            arguments: [name, symbol, royaltyPercentage, burnAmount],
        };

        const status = await handleWeb3.add({
            nonce,
            action: 1,
        });
        if (!status) return;

        const sendData: any = {from: account, nonce, gasPrice};

        let txHash = null

        const contractData = await contract
            .deploy(deployData)
            .send(sendData)
            .on("transactionHash", (hash: any) => {
                txHash = hash
            })
            .catch(() => null)

        return {transactionHash: txHash, nonce, contractAddress: contractData?._address}
    };

    const deployContractForDrops = async (name: string,
                                          symbol: string,
                                          maxAmount: number,
                                          mintPrice: number
    ) => {
        // @ts-ignore
        const web3 = window?.web3;
        const contractFile = await new CollectionApi()
            .getContractForDrops()
            .then((res) => (res?.status ? res.data : null))
            .catch(() => null);
        if (!contractFile?.abi || !contractFile?.bytecode) return;

        const account = await handleWeb3.getAccount();
        if (!account) return;

        const nonce = await handleWeb3.getNonce(account);
        if (!nonce) return;

        const gasPrice = await handleWeb3.getGasPrice();
        if (!gasPrice) return;

        const contract = new web3.eth.Contract(JSON.parse(contractFile?.abi));
        const deployData = {
            data: contractFile?.bytecode,
            arguments: [name, symbol, mintPrice, maxAmount],
        };

        const status = await handleWeb3.add({
            nonce,
            action: 1,
        });
        if (!status) return;

        const sendData: any = {from: account, nonce, gasPrice};

        let txHash = null

        const contractData = await contract
            .deploy(deployData)
            .send(sendData)
            .on("transactionHash", (hash: any) => {
                txHash = hash
            })
            .catch(() => null)

        return {transactionHash: txHash, nonce, contractAddress: contractData?._address}
    };

    return {
        mint,
        mintDrops,
        bulkMint,
        bulkMintDrops,
        transfer,
        transferRoyaltyOffers,
        transferRoyaltyListings,

        getListingCommission,
        approveListing,
        createListing,
        purchaseListing,
        purchaseListingWithRoyalty,
        purchaseListingToken,
        purchaseListingTokenWithRoyalty,
        cancelListing,
        checkListing,
        changeListing,
        checkOwnerOf,

        onMakeOffer,
        onMakeOfferWithToken,
        onCancelOffer,
        onAcceptOffer,
        checkOffer,
        getOffersLength,
        approveOffer,
        onEditOffer,
        onEditOfferWithToken,
        approveShib,
        approveShibRoyalty,
        allowanceShib,

        deployContract,
        deployContractForDrops,

        onCancelOfferOLD,
        getOffersLengthOLD,
        checkOfferOld
    }
}

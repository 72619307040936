import React, {useRef, useState} from 'react';
import styles from './styles.module.scss'
import Input from "../../../components/Input/Input";
import arrow from '../../../assets/images/symbols/arrow.svg'
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import {AVAILABLE_CATEGORIES} from "../../../constants/myCollectionsPage";

type Props = {
    setCreateData: any,
    createData: any
}

const CategoryDropdown = ({createData, setCreateData}: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    const dropdownRef = useRef<HTMLInputElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const onToggle = () => setIsOpen(!isOpen)
    const onClose = () => setIsOpen(false)

    const onSetCategory = (address: any) => {
        setCreateData({...createData, category: address})
        onClose()
    }

    useOutsideClick(dropdownRef, onClose, inputRef)

    return (
        <div className={styles.collection}>
            <Input
                inputRef={inputRef}
                value={createData?.category?.length
                    ? AVAILABLE_CATEGORIES?.find(item => item?.value === createData?.category)?.name
                    : ''}
                onChange={null}
                placeholder={'Choose category'}
                onlyWithArrow
                image={arrow}
                onClickWrapper={onToggle}
                withoutCaret
                isOpen={isOpen}
            />

            {isOpen && <div className={styles.dropdown} ref={dropdownRef}>
                {AVAILABLE_CATEGORIES?.map((item, key) =>
                    <div key={key} className={styles.dropdown_item} onClick={() => onSetCategory(item?.value)}>
                        {item?.name}
                    </div>)}
            </div>}
        </div>
    );
};

export default CategoryDropdown;

import Base from "./Base";

export default class TransactionApi extends Base {
  async add(data: any) {
    if (!super.getToken()) return null;
    return super.post(`transaction`, data);
  }

  async checkNonce(nonce: any, provider: any) {
    if (!super.getToken()) return null;
    return super.get(`transaction/check/${nonce}/${provider}`);
  }
}

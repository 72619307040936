import nftImage from '../assets/images/nft_image.png'

export const COLLECTED_TAB = '/collected'
export const CREATED_TAB = '/created'
export const ACTIVITY_TAB = '/activity'
export const LISTINGS_TAB = '/listings'
export const OFFERS_TAB = '/offers'
export const FAVOURITES_TAB = '/favorites'

export const PROFILE_TABS = [
    {title: 'Collected', value: COLLECTED_TAB},
    {title: 'Created', value: CREATED_TAB},
    {title: 'Activity', value: ACTIVITY_TAB},
    {title: 'Listings', value: LISTINGS_TAB},
    {title: 'Offers', value: OFFERS_TAB},
    {title: 'Favorites', value: FAVOURITES_TAB},
]

export const NFT_TABLE_HEADERS = [
    {title: 'NFT', withCheckBox: true},
    {title: 'Floor price', withCheckBox: false},
    {title: 'Best offer', withCheckBox: false},
    {title: 'Listing price', withCheckBox: false},
    {title: 'Cost', withCheckBox: false},
    {title: 'Difference', withCheckBox: false},
    {title: '', withCheckBox: false},
]

export const ACTIVITY_TABLE_HEADERS = [
    {title: 'Event', withCheckBox: false},
    {title: 'NFT', withCheckBox: false},
    {title: 'Price', withCheckBox: false},
    {title: 'From', withCheckBox: false},
    {title: 'To', withCheckBox: false},
    {title: 'Time', withCheckBox: false},
]

export const LISTINGS_TABLE_HEADERS = [
    {title: 'NFT', withCheckBox: false},
    {title: 'Price', withCheckBox: false},
    {title: 'Expires', withCheckBox: false},
    {title: '', withCheckBox: false},
]

export const OFFERS_TABLE_HEADERS = [
    {title: 'NFT', withCheckBox: false},
    {title: 'Price', withCheckBox: false},
    {title: 'From', withCheckBox: false},
    {title: 'Expires', withCheckBox: false},
    {title: '', withCheckBox: false},
]

export const ACTIVITY_TABLE_DATA = [
    {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    }, {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    }, {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    }, {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    }, {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    }, {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    }, {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    }, {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    }, {
        event: 'List',
        id: '1337',
        collectionName: 'Bored Ape Yacht Club',
        isVerified: true,
        image: nftImage,
        price: '12,098.56',
        from: 'usernameistoolong_cryptooooooo',
        to: 'nft_enthusiast',
        time: '2 min ago'
    },
]

export const ACTIVITY_FILTER_SALES = 1
export const ACTIVITY_FILTER_LISTINGS = 2
export const ACTIVITY_FILTER_OFFERS = 3
export const ACTIVITY_FILTER_TRANSFERS = 4
export const FILTER_BUY_NOW = 5
export const FILTER_HAS_OFFER = 6
export const FILTER_NOT_LISTED = 7

export const SORTING_RECENTLY_LISTED = 1
export const SORTING_RECENTLY_SOLD = 2
export const SORTING_MOST_VIEWED = 3
export const SORTING_MOST_FAVORITED = 4
export const SORTING_RECENTLY_OBTAINED = 5
export const SORTING_PRICE_INCREASE = 6
export const SORTING_PRICE_DECREASE = 7
export const SORTING_FLOOR_DECREASE = 8
export const SORTING_FLOOR_INCREASE = 9
export const SORTING_BEST_OFFER = 10
export const SORTING_OLDEST = 11
export const SORTING_RECENTLY_ADDED = 12

export const SORTING_DATA = [
    {title: 'Recently added', id: SORTING_RECENTLY_ADDED},
    {title: 'Price low to high', id: SORTING_PRICE_INCREASE},
    {title: 'Price high to low', id: SORTING_PRICE_DECREASE},
    {title: 'Floor high to low', id: SORTING_FLOOR_DECREASE},
    {title: 'Floor low to high', id: SORTING_FLOOR_INCREASE},
    {title: 'Best offer', id: SORTING_BEST_OFFER},
    {title: 'Most viewed', id: SORTING_MOST_VIEWED},
    {title: 'Most favorited', id: SORTING_MOST_FAVORITED},
]

export const SORTING_DATA_COLLECTION_PAGE = [
    {title: 'Price low to high', id: SORTING_PRICE_INCREASE},
    {title: 'Price high to low', id: SORTING_PRICE_DECREASE},
    // {title: 'Floor high to low', id: SORTING_FLOOR_DECREASE},
    // {title: 'Floor low to high', id: SORTING_FLOOR_INCREASE},
    {title: 'Most viewed', id: SORTING_MOST_VIEWED},
    {title: 'Most favorited', id: SORTING_MOST_FAVORITED},
]

import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import moment from "moment";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import shiba from '../../../../assets/images/symbols/shiba.svg'
import formatNumber from "../../../../utils";
import Tooltip from "../../../../components/Tooltip/Tooltip";

type Props = {
    headers: { title: string, withCheckBox: boolean }[],
    data: any,
    withLightBorder?: boolean,
    onEdit: any,
    onCancelOffer: any,
    onAcceptOffer: any,
    nftData: any,
    maticToUsd: number
}

const OffersTable = ({
                         headers,
                         data,
                         withLightBorder,
                         onEdit,
                         onCancelOffer,
                         onAcceptOffer,
                         nftData,
                         maticToUsd
                     }: Props) => {
    const customer = useSelector((state: any) => state.customer.data)
    const navigate = useNavigate()

    const onClickUser = (address: string) => navigate(`/${address}/collected`)

    return (
        <table cellSpacing={0} className={styles.table}>
            <thead>
            <tr className={withLightBorder ? styles.header : ''}>
                {headers?.map((item, key) =>
                    <th key={key}
                        className={cn(styles.table_header, withLightBorder && styles.table_header_withLightBorder)}>
                        {item?.title}
                    </th>)}
            </tr>
            </thead>

            <tbody>
            {data?.map((item: any, key: number) => <tr key={key}>

                <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                    <Tooltip text={(+item?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                        <p className={styles.symbol}>
                            {formatNumber(+(+item?.price)?.toFixed(2))}
                            <img src={shiba} alt={''}/>
                            {/*{AVAILABLE_CURRENCY?.find(currency => currency?.address === item?.tokenAddress)?.label}*/}
                        </p>
                        <p className={styles.text}>$ {+(+item?.price * maticToUsd)?.toFixed(2)}</p>
                    </Tooltip>
                </td>

                <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder, styles.from)}>
                    <p className={cn(styles.text_yellow, styles.from)}
                       onClick={() => onClickUser(item?.from?.length ? item?.from : item?.buyer)}
                    >{
                        item?.from ?
                            item?.from?.length > 23
                                ? item?.from?.slice(0, 23) + '...'
                                : item?.from
                            : item?.buyer?.length > 23
                            ? item?.buyer?.slice(0, 23) + '...'
                            : item?.buyer
                    }
                    </p>
                </td>

                <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                    <p className={styles.text}>{moment.unix(item?.endDate).format('hh:mm a, D MMM YYYY')}</p>
                </td>


                <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                    {(item?.from?.toLowerCase() || item?.buyer?.toLowerCase()) === customer?.address?.toLowerCase()
                    && <div className={styles.listingButtons}>
                        <span onClick={() => onEdit(item)}>Edit </span>
                        <span onClick={() => onCancelOffer(item)}>Cancel</span>
                    </div>}


                    {nftData?.owner?.toLowerCase() === customer?.address?.toLowerCase()
                    && !(item?.from?.toLowerCase() === nftData?.owner?.toLowerCase()
                        && item?.from?.toLowerCase() === customer?.address?.toLowerCase())
                    && <div className={styles.listingButtons}>
                        <span onClick={() => onAcceptOffer(item)}>Accept</span>
                    </div>}
                </td>

            </tr>)}
            </tbody>
        </table>
    );
};

export default OffersTable;

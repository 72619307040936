import Base from "./Base";

export default class LikeApi extends Base {
    async set(data: any) {
        if (!super.getToken()) return null;
        return super.post('like', data);
    }

    async getAll(address: string) {
        return super.get(`like/${address}`);
    }

    async getCountByTokens(data: any) {
        return super.post("like/tokens", data);
    }

    async getCountByToken(data: any) {
        return super.post("like/token", data);
    }
}

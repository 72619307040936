import React from 'react';
import styles from './styles.module.scss'
import propertiesImg from '../../../assets/images/symbols/properties.svg'
import plusImg from '../../../assets/images/symbols/plus.svg'
import AddPropertiesModal from "../../../components/Modals/AddPropertiesModal/AddPropertiesModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import PropertyRow from "../PropertyRow/PropertyRow";

type Props = {
    setCreateData: any,
    createData: any
}

const PropertiesBlock = ({createData, setCreateData}: Props) => {
    const handlePropertiesModal = useHandleModal()

    const onOpenModal = () => handlePropertiesModal.open()

    const onDelete = (id: number) => setCreateData({
        ...createData,
        properties: createData?.properties?.filter((item: any) => item?.id !== id)
    })

    return (
        <div className={styles.propertiesBlock}>
            <div className={styles.block_top}>
                <div className={styles.wrapper}>
                    <img src={propertiesImg} alt={''}/>
                    <div>
                        <p className={styles.title}>Properties<span>(Optional)</span></p>
                        <p className={styles.text}>Textual traits that show up as rectangles</p>
                    </div>
                </div>


                <div className={styles.plus} onClick={onOpenModal}>
                    <img src={plusImg} alt={''}/>
                </div>
            </div>

            {createData?.properties?.some((item: any) => item?.name?.length && item?.type?.length)
            && <div className={styles.list}>
                {createData?.properties?.map((item: any, key: number) =>
                    <PropertyRow property={item} key={key} onDelete={onDelete}/>)}
            </div>}

            {handlePropertiesModal.isActive && <AddPropertiesModal
                isOpen={handlePropertiesModal.isActive}
                onClose={handlePropertiesModal.close}
                createData={createData}
                setCreateData={setCreateData}
            />}
        </div>
    );
};

export default PropertiesBlock;

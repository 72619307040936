import ActivitiesApi from "../../utils/api/ActivitiesApi";
import moment from "moment";
import {useSelector} from "react-redux";
import useHandleMarketplace from "./useHandleMarketplace";
import useHandleCustomer from "../customer/useHandleCustomer";

export default function useHandleActivity() {
    const customer = useSelector((state: any) => state.customer.data)
    const handleMarketplace = useHandleMarketplace()
    const handleCustomer = useHandleCustomer()

    const createActivity = async (data: any) => {
        return await new ActivitiesApi()
            .createActivity(data)
            .catch((err) => console.log('createActivity err --> ', err))
    }

    const getActivitiesByUser = async (walletAddress: string) => {
        return await new ActivitiesApi()
            .getActivitiesByUser(walletAddress)
            .then((res) => {
                return res?.data
            })
            .catch((err) => console.log('getActivitiesByUser err --> ', err))
    }

    const getActivitiesByUserForWallet = async (walletAddress: string) => {
        return await new ActivitiesApi()
            .getActivitiesByUserForWallet(walletAddress)
            .then((res) => {
                return res?.data?.data?.reverse()
            })
            .catch((err) => console.log('getActivitiesByUserForWallet err --> ', err))
    }

    const getAveragePriceByToken = async (contractAddress: string, tokenID: number) => {
        return await new ActivitiesApi()
            .getAveragePriceByToken(contractAddress, tokenID)
            .then((res) => {
                return res?.data
            })
            .catch((err) => console.log('getActivitiesByToken err --> ', err))
    }

    const getAveragePriceByCollection = async (contractAddress: string, activeTime: number) => {
        return await new ActivitiesApi()
            .getAveragePriceByCollection(contractAddress, activeTime)
            .then((res) => {
                return res?.data
            })
            .catch((err) => console.log('getAveragePriceByCollection err --> ', err))
    }

    const getActivitiesByToken = async (contractAddress: string, tokenID: number) => {
        return await new ActivitiesApi()
            .getActivitiesByToken(contractAddress, tokenID)
            .then((res) => {
                return res?.data
            })
            .catch((err) => console.log('getActivitiesByToken err --> ', err))
    }

    const getCollectionVolume = async (contractAddress: string, activeTime: number) => {
        return await new ActivitiesApi()
            .getCollectionVolume(contractAddress, activeTime)
            .then((res) => {
                return res?.data?.data
            })
            .catch((err) => console.log('getCollectionVolume err --> ', err))
    }

    const getOffersReceived = async (walletAddress: string) => {
        return await new ActivitiesApi()
            .getOffersReceived(walletAddress)
            .then(async (res) => {
                const receivedOffersData = res?.data?.data
                    ?.filter((item: any) => (item?.price > 0
                        && item?.endDate >= moment().unix()
                        && item?.startDate <= moment().unix()
                    ))

                let receivedOffers: any = []
                for (let i = 0; i < receivedOffersData?.length; i++) {
                    const ownerOf = await handleMarketplace
                        .checkOwnerOf(receivedOffersData[i]?.tokenID, receivedOffersData[i]?.contractAddress)
                    if (ownerOf?.toLowerCase() === customer?.address?.toLowerCase())
                        receivedOffers = [...receivedOffers, receivedOffersData[i]]
                }

                return receivedOffers
            })
            .catch((err) => console.log('getOffersReceived err --> ', err))
    }

    const getOffersMade = async (walletAddress: string) => {
        return await new ActivitiesApi()
            .getOffersMade(walletAddress)
            .then(async (res) => {
                const madeOffersData = res?.data?.data
                    ?.filter((item: any) => (item?.price > 0
                        && item?.endDate >= moment().unix()
                    ))

                // let madeOffers: any = []
                // for (let i = 0; i < madeOffersData?.length; i++) {
                // const ownerOf = await handleMarketplace
                //     .checkOwnerOf(madeOffersData[i]?.tokenID, madeOffersData[i]?.contractAddress)

                // if (!(madeOffersData[i]?.from?.toLowerCase() === ownerOf?.toLowerCase() && madeOffersData[i]?.from?.toLowerCase() === customer?.address?.toLowerCase()))
                //     madeOffers = [...madeOffers, madeOffersData[i]]
                // }

                return madeOffersData
            })
            .catch((err) => console.log('getOffersMade err --> ', err))
    }

    const editOfferEvent = async (offerID: number, eventType: number) => {
        return await new ActivitiesApi()
            .editOfferEvent(offerID, eventType)
            .then(res => res?.data)
            .catch((err) => console.log('editOfferEvent err --> ', err))
    }

    const getListingsList = async (address: string) => {
        return await handleCustomer
            .getUserNFTsCollected(address, 100, 1)
            .then((res: any) =>
                res?.data?.filter((item: any) => item?.listing?.price > 0 && item?.listing?.endDate >= moment().unix())
            )
    }

    return {
        createActivity,
        getActivitiesByUser,
        getActivitiesByUserForWallet,
        getAveragePriceByToken,
        getActivitiesByToken,
        getOffersReceived,
        getOffersMade,
        editOfferEvent,
        getListingsList,
        getCollectionVolume,
        getAveragePriceByCollection
    }
}

export const SIDEBAR_PRIVACY = [
    {id: 1, text: 'Types of Information We Collect'},
    {id: 2, text: 'Use of Your Information'},
    {id: 3, text: 'Disclosure of Your Information'},
    {id: 4, text: 'Third-Party Websites'},
    {id: 5, text: 'Third-Party Wallets'},
    {id: 6, text: 'Your Choices Regarding Information'},
    {id: 7, text: 'Data Access and Control'},
    {id: 8, text: 'Data Retention'},
    {id: 9, text: 'Security'},
    {id: 10, text: 'Minors'},
    {id: 11, text: 'Users Outside of the United States'},
    {id: 12, text: 'For users in EEA, UK and Switzerland'},
    {id: 13, text: 'Changes to This Privacy Policy'},
    {id: 14, text: 'California Privacy Rights Disclosure'},
    {id: 15, text: 'Questions'},
]

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slider_item__LbjZU {
  width: 370px !important;
  margin: 0 20px;
  box-sizing: border-box;
  background: #3E3E3E;
  border-radius: 24px;
}

.styles_slider_item_image__Wr0tE {
  width: 100%;
  object-fit: cover;
  border-radius: 24px 24px 0 0;
}

.styles_slider_item_text__DtgAz {
  border-radius: 0 0 24px 24px;
  padding: 24px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.35px;
  width: 100%;
  margin: 0;
}

.styles_button__tDiuI {
  width: -moz-fit-content;
  width: fit-content;
  margin: 175px auto;
}
@media (max-width: 640px) {
  .styles_button__tDiuI {
    margin: 92px auto 100px;
    width: 163px;
    font-size: 15px;
    line-height: 20px;
    border-radius: 12px;
    white-space: nowrap;
  }
  .styles_button__tDiuI div {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    line-height: normal;
  }
}`, "",{"version":3,"sources":["webpack://./src/templates/MainPage/GuideBlock/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,cAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,4BAAA;AACF;;AAEA;EACE,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,WAAA;EACA,SAAA;AACF;;AAEA;EACE,uBAAA;EAAA,kBAAA;EACA,kBAAA;AACF;AACE;EAJF;IAKI,uBAAA;IACA,YAAA;IACA,eAAA;IACA,iBAAA;IACA,mBAAA;IACA,mBAAA;EAEF;EADE;IACE,iBAAA;IACA,oBAAA;IACA,eAAA;IACA,mBAAA;EAGJ;AACF","sourcesContent":[".slider_item {\n  width: 370px !important;\n  margin: 0 20px;\n  box-sizing: border-box;\n  background: #3E3E3E;\n  border-radius: 24px;\n}\n\n.slider_item_image {\n  width: 100%;\n  object-fit: cover;\n  border-radius: 24px 24px 0 0;\n}\n\n.slider_item_text{\n  border-radius: 0 0 24px 24px;\n  padding: 24px;\n  box-sizing: border-box;\n  font-weight: 700;\n  font-size: 15px;\n  line-height: 24px;\n  letter-spacing: -0.35px;\n  width: 100%;\n  margin: 0;\n}\n\n.button{\n  width: fit-content;\n  margin: 175px auto;\n\n  @media (max-width: 640px) {\n    margin: 92px auto 100px;\n    width: 163px;\n    font-size: 15px;\n    line-height: 20px;\n    border-radius: 12px;\n    white-space: nowrap;\n    div{\n      padding-top: 10px;\n      padding-bottom: 10px;\n      font-size: 15px;\n      line-height: normal;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider_item": `styles_slider_item__LbjZU`,
	"slider_item_image": `styles_slider_item_image__Wr0tE`,
	"slider_item_text": `styles_slider_item_text__DtgAz`,
	"button": `styles_button__tDiuI`
};
export default ___CSS_LOADER_EXPORT___;

import * as LOADER from '../types/loader'

const initialState = {
    isActive: false,
    opacityLevel: 3,
    withImage: false
}

export default function loader (state = initialState, action: any) {
    switch (action.type) {
        case LOADER.SET_LOADER: {
            return {
                ...state,
                isActive: action?.data?.isActive,
                opacityLevel: action?.data?.opacityLevel,
                withImage: action?.data?.withImage,
            }
        }
        default:
            return state
    }
}

import React from 'react';
import styles from './styles.module.scss'
import deleteImg from '../../../assets/images/symbols/delete.svg'

type Props = {
    property: any,
    onDelete?: any
}

const StatsRow = ({property, onDelete}: Props) => {

    return (
        <div className={styles.propertyRow}>
            <div className={styles.stats} >
                <p>{property?.type}</p>
                <span>{property?.name} of {'1000'}</span>
            </div>

            <div className={styles.delete} onClick={() => onDelete(property?.id)}>
                <img src={deleteImg} alt={''}/>
            </div>
        </div>
    );
};

export default StatsRow;

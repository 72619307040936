// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_priceInput__De62Y {
  width: 100%;
  position: relative;
}

.styles_text__w2fcD {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #949494;
  margin: 16px 0 0;
}
.styles_text__w2fcD span {
  color: #FFFFFF;
}

.styles_dropdown__vLb72 {
  position: absolute;
  top: 57px;
  right: 0;
  width: 100%;
  background: #242424;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 2;
}

.styles_dropdown_item__vrVTr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}
.styles_dropdown_item__vrVTr:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.styles_dropdown_item__vrVTr:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.styles_dropdown_item__vrVTr:hover {
  background: #3E3E3E;
  transition: all 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/templates/ListNFTPage/PriceInput/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AACF;AACE;EACE,cAAA;AACJ;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,sBAAA;EACA,sBAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;AAAF;AAEE;EACE,2BAAA;EACA,4BAAA;AAAJ;AAGE;EACE,8BAAA;EACA,+BAAA;AADJ;AAIE;EACE,mBAAA;EACA,yBAAA;AAFJ","sourcesContent":[".priceInput{\n  width: 100%;\n  position: relative;\n}\n\n.text{\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 24px;\n  color: #949494;\n  margin: 16px 0 0;\n\n  span{\n    color: #FFFFFF;\n  }\n}\n\n.dropdown{\n  position: absolute;\n  top: 57px;\n  right: 0;\n  width: 100%;\n  background: #242424;\n  border-radius: 8px;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-direction: column;\n  box-sizing: border-box;\n  z-index: 2;\n}\n\n.dropdown_item {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 16px;\n  width: 100%;\n  cursor: pointer;\n  padding: 20px;\n  box-sizing: border-box;\n  transition: all .3s ease;\n\n  &:first-child{\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n  }\n\n  &:last-child{\n    border-bottom-left-radius: 8px;\n    border-bottom-right-radius: 8px;\n  }\n\n  &:hover{\n    background: #3E3E3E;\n    transition: all .3s ease;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priceInput": `styles_priceInput__De62Y`,
	"text": `styles_text__w2fcD`,
	"dropdown": `styles_dropdown__vLb72`,
	"dropdown_item": `styles_dropdown_item__vrVTr`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import arrow from '../../../assets/images/symbols/arrow.svg'
import cn from "classnames";
import CustomerApi from "../../../utils/api/CustomerApi";
import {setSocialsImage} from "../../../utils/socials";
import {useNavigate} from "react-router-dom";

const AboutCollection = ({collectionData, nftData}: { collectionData: any, nftData: any }) => {
    const [isOpen, setIsOpen] = useState(true)
    const [creatorAddress, setCreatorAddress] = useState('')

    const navigate = useNavigate()

    const onToggle = () => setIsOpen(!isOpen)
    const onClickOwner = () => navigate(`/${creatorAddress}/collected`)

    useEffect(() => {
        new CustomerApi().getName(collectionData?.creator).then((res: any) => {
            setCreatorAddress(res?.data?.userName || res?.data?.address)
        })
    }, [])

    return (
        <div className={styles.aboutCollection}>
            <div className={cn(styles.title, isOpen && styles.title_open)} onClick={onToggle}>
                Description
                <img src={arrow} alt={''}/>
            </div>

            {isOpen && <div className={styles.content}>
                <p className={styles.author} onClick={onClickOwner}>
                    By <span>{
                    creatorAddress?.length > 25 ? creatorAddress?.slice(0, 25) + '...' : creatorAddress
                }</span>
                </p>
                <p className={styles.desc}>{nftData?.metadata?.description}</p>

                {!!collectionData?.socials?.length &&
                <div className={styles.socials}>
                    {[...collectionData?.socials]?.map((item: any, key: number) =>
                        <a
                            key={key}
                            href={item?.link?.includes('https://') ? item?.link : 'https://' + item?.link}
                            target="_blank"
                            rel="noreferrer noopener"
                            className={styles.socials_item}>
                            <img alt={''} src={setSocialsImage(item?.link)}/>
                        </a>)}
                </div>}
            </div>}
        </div>
    );
};

export default AboutCollection;

import React, {useEffect, useState} from 'react';
import cn from "classnames";
import styles from "../FiltersBlock/styles.module.scss";
import arrow from "../../../assets/images/symbols/arrow.svg";
import Checkbox from "../../../components/Checkbox/Checkbox";
import {sortBuTitleInAlphabeticalOrder} from "../../../utils/sortingNFT";

type Props = {
    onToggleActivityFilters: any,
    filters: any,
    filterParent: string,
    isActivityTab?: boolean
}

const FiltersRow = ({onToggleActivityFilters, filters, filterParent, isActivityTab}: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    const onToggleFilter = () => setIsOpen(!isOpen)

    useEffect(() => {
        setIsOpen(filterParent === 'Status')
    }, [filterParent])

    return (
        <div className={cn(styles.status_block,
            isActivityTab && styles.status_block_isActivityTab,
            isOpen && styles.status_block__opened)}>
            <div className={styles.title} onClick={onToggleFilter}>{filterParent}
                <img src={arrow} alt={''}
                     className={cn(styles.title_arrow, isOpen && styles.title_arrow_opened)}/>
            </div>
            <div className={cn(styles.status_list, isOpen && styles.status_list_opened)}>
                {!!filters?.length && <>{
                    sortBuTitleInAlphabeticalOrder(filters)
                        ?.map((item: any, key: number) =>
                            <div className={styles.filter_row} key={key}>
                                <Checkbox
                                    setIsChecked={() => null}
                                    isChecked={item?.status}
                                    title={item?.title}
                                    onClickCallback={() => onToggleActivityFilters(item?.title, item?.parent)}
                                    isFullWidth
                                />
                                <span className={styles.amount}>{item?.amount}</span>
                            </div>
                        )}
                </>}
            </div>
        </div>

    );
};

export default FiltersRow;

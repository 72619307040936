export const CATEGORY_ALL = 'CATEGORY_ALL'
export const CATEGORY_ART = 'CATEGORY_ART'
export const CATEGORY_COMMUNITY = 'CATEGORY_COMMUNITY'
export const CATEGORY_GAMING = 'CATEGORY_GAMING'
export const CATEGORY_MEMBERSHIPS = 'CATEGORY_MEMBERSHIPS'
export const CATEGORY_MUSIC = 'CATEGORY_MUSIC'
export const CATEGORY_PHOTO = 'CATEGORY_PHOTO'

export const AVAILABLE_CATEGORIES = [
    {name: 'Art', value: CATEGORY_ART},
    {name: 'Community', value: CATEGORY_COMMUNITY},
    {name: 'Gaming', value: CATEGORY_GAMING},
    {name: 'Memberships', value: CATEGORY_MEMBERSHIPS},
    {name: 'Music', value: CATEGORY_MUSIC},
    {name: 'Photography', value: CATEGORY_PHOTO},
]

export const CATEGORY_ALL_DATA = {name: 'All categories', value: CATEGORY_ALL}

export const AVAILABLE_PAYMENT_TOKENS = [
    {id: 'SHIB', chain: 'Shibarium', address: ''},
    // {id: 'WETH', chain: 'Ethereum', address: ''},
    // {id: 'WETH', chain: 'Ethereum', address: ''},
]

export const customStyles = {
	content: {
		background: '#242424',
		borderRadius: '16px',
		border: 'none',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '24px 32px',
		boxSizing: 'border-box',
		overflowX: 'hidden',
		width: '100%',
		height: 'fit-content'
	},
	overlay: {
		background: 'rgba(0, 0, 0, 0.8)',
		zIndex: 11111111
	}
}

import React from 'react';
import styles from './styles.module.scss'
import Chart from "react-apexcharts";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE} from "../../../constants";
import moment from "moment";

const PriceGraphic = ({
                          priceData,
                          collectionVolume,
                          createdAt
                      }: { priceData: any, collectionVolume: any, createdAt: string }) => {
    const windowDimensions = useWindowDimensions()
    const isMobile = windowDimensions?.width <= MOBILE

    const options = {
        chart: {
            id: 'area-datetime',
            zoom: {
                enabled: false,
            },
            height: 462,
            type: 'line',
            foreColor: '#757575',
            toolbar: {
                show: false
            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            type: 'datetime',
            min: moment(createdAt).unix() * 1000,
            tickAmount: 6,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: [{
            title: {
                text: 'Volume (SHIB)',
                style: {
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: '18px',
                    fontWeight: '700',
                }
            },
        }, {
            opposite: true,
            title: {
                text: 'Average Price (SHIB)',
                style: {
                    fontFamily: "'Open Sans', sans-serif",
                    lineHeight: '25px',
                    fontSize: '18px',
                    fontWeight: '700',
                }
            }
        },],
        tooltip: {
            theme: 'dark',
            x: {
                format: 'dd MMM yyyy, HH:mm'
            },
            y: {
                formatter: function (val: any) {
                    return +val
                }
            }
        },
        selection: {
            xaxis: {
                min: moment(createdAt).unix() * 1000,
                max: moment().unix() * 1000
            },
        },
        colors: ['#2D2D2D', '#F69C00'],
        grid: {
            borderColor: '#2D2D2D',
        },
        legend: {
            show: false
        },
    };

    return (
        <div className={styles.priceGraphic} id={'chart'}>
            <p className={styles.title}>Price history</p>

            <div className={styles.chart}>
                <Chart
                    // @ts-ignore
                    options={options}
                    series={[{
                        name: 'Volume (SHIB)',
                        type: 'column',
                        data: collectionVolume
                    }, {
                        name: 'Average Price (SHIB)',
                        type: 'line',
                        data: priceData
                    }]}
                    width={'100%'}
                    height={isMobile ? 212 : 462}
                />
            </div>
        </div>
    );
};

export default PriceGraphic;

import * as LOADER from '../types/loader'

export function setLoader (data: any) {
    return function (dispatch: any) {
        dispatch({
            type: LOADER.SET_LOADER,
            data
        })
    }
}

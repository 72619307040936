import React, {useEffect, useState} from 'react';
import styles from "../../../components/NFTsTable/styles.module.scss";
import verifiedBadge from "../../../assets/images/symbols/verified-badge_orange.svg";//done
import cn from "classnames";
import starGray from '../../../assets/images/symbols/star_gray.svg'
import starOrange from '../../../assets/images/symbols/star_orange.svg'
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {TABLET} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {
    STATS_TIME_1H,
    STATS_TIME_24H,
    STATS_TIME_30D,
    STATS_TIME_7D,
    STATS_TIME_ALL_TIME
} from "../../../constants/statsPage";
import CollectionApi from "../../../utils/api/CollectionApi";
import {useSelector} from "react-redux";
import useHandleAuth from "../../../hooks/auth/useHandleAuth";
import formatNumber from "../../../utils";
import Tooltip from "../../../components/Tooltip/Tooltip";

type Props = {
    collection?: any,
    activeSubTab: any,
    rank: number,
    handleSignUpModal: any
}

const StatsTableRow = ({collection, activeSubTab, rank, handleSignUpModal}: Props) => {
        const customer = useSelector((state: any) => state.customer.data)

        const [isFavourite, setIsFavourite] = useState(false)
        const [sales, setSales] = useState(0)
        const [volume, setVolume] = useState(0)

        const navigate = useNavigate()
        const handleAuth = useHandleAuth()
        const windowDimensions = useWindowDimensions()
        const isTablet = windowDimensions?.width <= TABLET

        const onClickStar = async () => {
            if (!customer?._id)
                return handleAuth.connectWallet(handleSignUpModal.open)

            setIsFavourite(!isFavourite)
            await new CollectionApi().setLikes(collection?.contractAddress)
        }

        const onClickRow = () => navigate(`/collection/${collection?.externalLink
            ? collection?.externalLink?.includes('/')
                ? collection?.contractAddress
                : collection?.externalLink
            : collection?.contractAddress}`)//done

        useEffect(() => {
            if (activeSubTab === STATS_TIME_1H) {
                setSales(collection?.sales1H)
                setVolume(collection?.volume1H)
            } else if (activeSubTab === STATS_TIME_24H) {
                setSales(collection?.sales24H)
                setVolume(collection?.volume24H)
            } else if (activeSubTab === STATS_TIME_7D) {
                setSales(collection?.sales7D)
                setVolume(collection?.volume7D)
            } else if (activeSubTab === STATS_TIME_30D) {
                setSales(collection?.sales30D)
                setVolume(collection?.volume30D)
            } else if (activeSubTab === STATS_TIME_ALL_TIME) {
                setSales(collection?.salesAllTime)
                setVolume(collection?.volumeAllTime)
            }

            setIsFavourite(collection?.likes?.includes(customer?._id))
        }, [activeSubTab, collection?.contractAddress, customer?._id])

        return (
            <tr className={styles.row}>

                <td className={styles.table_data}>
                    <p className={styles.rank}>{rank}</p>
                </td>

                <td className={cn(styles.table_data, styles.table_data_name)} onClick={onClickRow}>
                    <div className={styles.nftData}>
                        <div className={styles.nftData_wrapper}>
                            <img src={collection?.logoImage} alt={''} width={72} height={72} className={styles.nftImage}/>
                            <div>
                                <p className={cn(styles.name, styles.name_bold)}>
                                    {collection?.name}
                                    {collection?.isVerified && <img src={verifiedBadge} alt={''}/>}
                                </p>
                            </div>
                        </div>
                    </div>
                </td>

                <td className={styles.table_data}>
                    <Tooltip text={volume?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                        <p className={styles.volume}>{formatNumber(+(+volume)?.toFixed(2))} SHIB</p>
                    </Tooltip>
                </td>

                {!isTablet && <td className={styles.table_data}>
                    <Tooltip
                        text={(collection?.currentListingsFloorPrice || 0)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                        <p className={styles.volume}>{formatNumber(+(+collection?.currentListingsFloorPrice)?.toFixed(2) || 0)} SHIB</p>
                    </Tooltip>
                </td>}

                {!isTablet && <td className={styles.table_data}>
                    <p className={cn(styles.text_yellow, styles.text_right)}>{sales}</p>
                </td>}

                {!isTablet && <td className={styles.table_data}>
                    <p className={cn(styles.text_yellow, styles.text_right)}>{collection?.uniqueOwners || 0}</p>
                </td>}

                {!isTablet && <td className={styles.table_data}>
                    <p className={cn(styles.text_right, styles.volume)}>{+((collection?.itemsListed * 100 / collection?.totalTokensCount) || 0)?.toFixed(2)?.toString() || 0}%</p>
                    <p className={cn(styles.text_right, styles.text)}>{collection?.itemsListed} of {collection?.totalTokensCount}</p>
                </td>}

                <td className={styles.table_data}>
                    <div className={styles.star} onClick={onClickStar}>
                        <img src={isFavourite ? starOrange : starGray} alt={''}/>
                    </div>
                </td>

            </tr>
        );
    }
;

export default StatsTableRow;

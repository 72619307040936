import React, {useEffect, useRef, useState} from 'react';
import styles from "../styles.module.scss";
import TabHeader from "../../ProfilePage/TabHeader/TabHeader";
import cn from "classnames";
import {
    ACTIVITY_FILTER_LISTINGS,
    ACTIVITY_FILTER_OFFERS,
    ACTIVITY_FILTER_SALES, ACTIVITY_FILTER_TRANSFERS,
    ACTIVITY_TABLE_HEADERS
} from "../../../constants/profile";
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import {NFTS_EVENT_FILTERS} from "../../../constants/singleCollection";
import FiltersBlock from "../FiltersBlock/FiltersBlock";
import GraphicsBlock from "../GraphicsBlock/GraphicsBlock";
import crossIcon from "../../../assets/images/symbols/cross.svg";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {
    EVENT_TYPE_LISTING,
    EVENT_TYPE_LISTING_CANCELED,
    EVENT_TYPE_LISTING_EDITED,
    EVENT_TYPE_OFFER,
    EVENT_TYPE_OFFER_ACCEPTED,
    EVENT_TYPE_OFFER_CANCELED,
    EVENT_TYPE_OFFER_EDITED,
    EVENT_TYPE_SALE, EVENT_TYPE_TRANSFER,
    TABLET
} from "../../../constants";
import SingleCollectionFiltersMobile
    from "../../../components/SingleCollectionFiltersMobile/SingleCollectionFiltersMobile";
import SortingMobile from "../../../components/SortingMobile/SortingMobile";
import ActivityTabItem from "../../ProfilePage/ActivityTabItem/ActivityTabItem";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import ActivitiesApi from "../../../utils/api/ActivitiesApi";
import ReactPaginate from "react-paginate";
import CryptoApi from "../../../utils/api/CryptoApi";
import {useSelector} from "react-redux";
import {STATS_TIME_ALL_TIME} from "../../../constants/statsPage";

const ActivityTab = ({
                         contractAddress,
                         collectionData,
                         isOpenedFilters,
                         setIsOpenedFilters,
                         isMoreOpen
                     }: {
    contractAddress: string, collectionData: any, isOpenedFilters: boolean,
    setIsOpenedFilters: any, isMoreOpen: boolean
}) => {
    const customer = useSelector((state: any) => state.customer.data)
    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET

    const [filters, setFilters] = useState(NFTS_EVENT_FILTERS)
    const [selectedFilters, setSelectedFilters] = useState<any>([])
    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false)
    const [isOpenedMobileSorting, setIsOpenedMobileSorting] = useState(false)
    const [selectedCollections, setSelectedCollections] = useState<any>([])
    const [filtersAmount, setFiltersAmount] = useState(0)
    const [selectedSorting, setSelectedSorting] = useState(1)
    const [activityData, setActivityData] = useState([])
    const [activitiesDataFiltered, setActivitiesDataFiltered] = useState([])
    const [activitiesToShow, setActivitiesToShow] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [forcePage, setForcePage] = useState(0)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)
    const [activeTime, setActiveTime] = useState(STATS_TIME_ALL_TIME)

    const itemsPerPage = 10
    const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0;

    const wrapperRef = useRef<HTMLInputElement>(null)
    const handleLoader = useHandleLoader()

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % totalCount;
        setForcePage(event.selected)
        setActivitiesToShow(activitiesDataFiltered?.slice(event.selected * itemsPerPage, (event.selected + 1) * itemsPerPage))
    }

    const onDeleteFilter = (title: string) => setFilters && setFilters([...filters?.map((item: any) =>
        item?.title === title
            ? {
                ...item,
                status: false
            }
            : item)])

    const onClearFilters = () => setFilters && setFilters(filters?.map((item: any) => ({...item, status: false})))

    useEffect(() => {
        setSelectedFilters(filters?.filter((item: any) => !!item?.status))
        setFiltersAmount(filters?.filter((item: any) => item?.status)?.length + selectedCollections?.length)
    }, [filters, selectedCollections])

    useEffect(() => {
        handleLoader.loaderWrapper(async () => await new ActivitiesApi()
            .getActivitiesByContract(contractAddress, activeTime)
            .then((res: any) => {
                setActivityData(res?.data?.activities?.reverse())
                setActivitiesDataFiltered(res?.data?.activities?.reverse())
                setTotalCount(res?.data?.activities?.length)
                setActivitiesToShow(res?.data?.activities?.reverse()?.slice(0, itemsPerPage))
                setFilters([
                    {
                        ...filters?.filter((item: any) => item?.type === ACTIVITY_FILTER_SALES)[0],
                        amount: res?.data?.salesCount
                    },
                    {
                        ...filters?.filter((item: any) => item?.type === ACTIVITY_FILTER_LISTINGS)[0],
                        amount: res?.data?.listingsCount
                    },
                    {
                        ...filters?.filter((item: any) => item?.type === ACTIVITY_FILTER_OFFERS)[0],
                        amount: res?.data?.offersCount
                    },
                    {
                        ...filters?.filter((item: any) => item?.type === ACTIVITY_FILTER_TRANSFERS)[0],
                        amount: res?.data?.transfersCount
                    },
                ])
            })
            .catch(() => null)
        )
    }, [contractAddress, activeTime])

    useEffect(() => {
        let newData: any = activityData

        if (selectedFilters?.length) {
            newData = []

            if (!!selectedFilters?.filter((item: any) => item?.type === ACTIVITY_FILTER_SALES)?.length) {
                newData = [
                    ...newData,
                    ...activityData?.filter((item: any) =>
                        item?.eventType === EVENT_TYPE_SALE)
                ]
            }

            if (!!selectedFilters?.filter((item: any) => item?.type === ACTIVITY_FILTER_LISTINGS)?.length) {
                newData = [
                    ...newData,
                    ...activityData?.filter((item: any) =>
                        item?.eventType === EVENT_TYPE_LISTING
                        || item?.eventType === EVENT_TYPE_LISTING_CANCELED
                        || item?.eventType === EVENT_TYPE_LISTING_EDITED)
                ]
            }

            if (!!selectedFilters?.filter((item: any) => item?.type === ACTIVITY_FILTER_OFFERS)?.length) {
                newData = [
                    ...newData,
                    ...activityData?.filter((item: any) =>
                        item?.eventType === EVENT_TYPE_OFFER
                        || item?.eventType === EVENT_TYPE_OFFER_CANCELED
                        || item?.eventType === EVENT_TYPE_OFFER_ACCEPTED
                        || item?.eventType === EVENT_TYPE_OFFER_EDITED)
                ]
            }

            if (!!selectedFilters?.filter((item: any) => item?.type === ACTIVITY_FILTER_TRANSFERS)?.length) {
                newData = [
                    ...newData,
                    ...activityData?.filter((item: any) =>
                        item?.eventType === EVENT_TYPE_TRANSFER)
                ]
            }

        }

        setActivitiesDataFiltered(newData)
        setTotalCount(newData?.length)
        setActivitiesToShow(newData?.slice(0, itemsPerPage))
    }, [selectedFilters])

    useEffect(() => {
        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
            .catch(() => null)
    }, [])

    return (
        <div className={styles.tab_wrapper}>
            <TabHeader
                isOpenedFilters={isOpenedFilters}
                setIsOpenedFilters={setIsOpenedFilters}
                wrapperRef={wrapperRef}
                withoutCardView
                filters={filters}
                setFilters={setFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                isOpenedMobileFilters={isOpenedMobileFilters}
                filtersAmount={filtersAmount}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                isOpenedMobileSorting={isOpenedMobileSorting}
                isOwnerOfCollection={collectionData?.creator === customer?._id}
                isActivityTab
                isActivityCollectionTab
                activeTime={activeTime}
                setActiveTime={setActiveTime}
                isMoreOpen={isMoreOpen}
            />

            <div className={styles.block_wrapper} ref={wrapperRef}>
                <FiltersBlock
                    isOpenedFilters={isOpenedFilters}
                    filters={filters}
                    setFilters={setFilters}
                    isActivityTab
                />

                <div className={cn(
                    styles.nftList_wrapper,
                    isOpenedFilters && styles.nftList_wrapper_filters,
                    styles.nftList_wrapper_margin,
                )}>
                    {(!!selectedFilters?.length && !isTablet) && <div className={styles.selectedFilters}>
                        {selectedFilters?.map((item: any, key: number) => <div
                            key={key}
                            className={styles.selectedFilters_item}
                            onClick={() => onDeleteFilter(item?.title)}
                        >
                            {item?.title}
                            <img src={crossIcon} alt={''}/>
                        </div>)}
                        <div className={styles.selectedFilters_clear} onClick={onClearFilters}>Clear filters</div>
                    </div>}

                    <div className={cn(styles.NfTsTable, isOpenedFilters && styles.NfTsTable_filters)}>
                        <GraphicsBlock
                            activeTime={activeTime}
                            contractAddress={contractAddress}
                            createdAt={collectionData?.createdAt}
                        />
                        {!!activitiesDataFiltered?.length
                            ? <>{isTablet
                                ? <div className={styles.list}>
                                    {activitiesToShow?.map((item, key) =>
                                        <ActivityTabItem maticToUsd={maticToUsd} key={key} item={item}/>)}
                                </div>
                                : <NfTsTable maticToUsd={maticToUsd} data={activitiesToShow}
                                             headers={ACTIVITY_TABLE_HEADERS}/>}
                            </>
                            : <p className={styles.noData}>No recent activity yet</p>}


                        {!!activitiesDataFiltered?.length && <ReactPaginate
                            breakLabel="..."
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={3}
                            pageCount={pageCount}
                            forcePage={forcePage}
                            previousLabel="<"
                            nextLabel=">"
                            renderOnZeroPageCount={null}
                            containerClassName={styles.pagination}
                            pageClassName={styles.pagination_page}
                            activeClassName={styles.pagination_active}
                            previousClassName={styles.pagination_previous}
                            nextClassName={styles.pagination_next}
                            disabledClassName={styles.pagination_disabled}
                        />}

                    </div>
                </div>
            </div>

            {isTablet && <SingleCollectionFiltersMobile
                isOpenedMobileFilters={isOpenedMobileFilters}
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                filters={filters}
                setFilters={setFilters}
                setSelectedCollections={setSelectedCollections}
                selectedCollections={selectedCollections}
                filtersAmount={filtersAmount}
                isActivityTab
                withoutView
                activeTime={activeTime}
                setActiveTime={setActiveTime}
            />}

            {isTablet && <SortingMobile
                isOpenedMobileSorting={isOpenedMobileSorting}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />}
        </div>
    );
};

export default ActivityTab;

import React, {useEffect, useRef, useState} from 'react';
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from './styles.module.scss'
import Input from "../../components/Input/Input";
import TextArea from "../../components/TextArea/TextArea";
import Button from "../../components/Button/Button";
import uploadImg from '../../assets/images/nft_img_upload.svg'
import PropertiesBlock from "./PropertiesBlock/PropertiesBlock";
import LevelsBlock from "./LevelsBlock/LevelsBlock";
import StatsBlock from "./StatsBlock/StatsBlock";
import UnlockableContent from "./UnlockableContent/UnlockableContent";
import FileApi from "../../utils/api/FileApi";
import {useDispatch, useSelector} from "react-redux";
import {getItemMetadata, hexToNumber} from "../../utils/blockchain";
import useHandleMarketplace from "../../hooks/blockchain/useHandleMarketplace";
import CollectionApi from "../../utils/api/CollectionApi";
import {toast} from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import * as loaderActions from '../../store/actions/loader'
import CollectionDropdown from "./CollectionDropdown/CollectionDropdown";
import useHandleWeb3 from "../../hooks/web3/useHandleWeb3";

const initState = {
    name: '',
    image: uploadImg,
    audio: null,
    video: null,
    description: '',
    supply: '1',
    collection: '',
    isUnlockableContent: {status: false, text: ''},
    isNsfWcontent: false,
    levels: [{type: '', level: 1, maxLevel: 1000, id: new Date().getTime()}],
    socials: [],
    properties: [{name: '', type: '', id: new Date().getTime()}],
    stats: [{name: '', type: '', maxStats: 1000, id: new Date().getTime()}],
    imageFile: null,
    audioFile: null,
    videoFile: null
}

const CreatePage = () => {
    const customer = useSelector((state: any) => state.customer.data)
    const [createData, setCreateData] = useState<any>(initState)
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)

    const fileRef = useRef<HTMLInputElement>(null)
    const audioImageRef = useRef<HTMLInputElement>(null)
    const audioRef = useRef<HTMLInputElement>(null)
    const videoRef = useRef<HTMLInputElement>(null)
    const handleMarketplace = useHandleMarketplace()
    const dispatch = useDispatch<any>()
    const handleWeb3 = useHandleWeb3()

    const onChangeName = (e: any) => {
        if (e?.target?.value?.length > 32) return toast(<ToastMsg text={'Name is too long'} isError/>)
        setCreateData({...createData, name: e?.target?.value})
    }

    const onChangeDesc = (e: any) => {
        if (e?.target?.value?.length > 500) return toast(<ToastMsg text={'Description is too long'} isError/>)
        setCreateData({...createData, description: e?.target?.value})
    }

    const onUploadImageForAudio = (event: any) => {
        if (!event?.target?.files[0]) return
        if (event?.target?.files[0]?.size > 100000000) return toast(<ToastMsg
            text={'You can not upload file over 100 MB'} isError/>)

        const reader = new FileReader();
        reader?.readAsDataURL(event?.target?.files[0]);

        if (!event?.target?.files[0]?.type?.includes('image')) return toast(<ToastMsg
            text={'You can upload only image file'} isError/>)

        reader.onload = function () {
            setCreateData({
                ...createData,
                imageForAudio: reader.result,
                imageForAudioFile: event?.target?.files[0]
            })
        }
    }

    const onUploadImage = (event: any) => {
        if (!event?.target?.files[0]) return
        if (event?.target?.files[0]?.size > 100000000) return toast(<ToastMsg
            text={'You can not upload file over 100 MB'} isError/>)

        const reader = new FileReader();
        reader?.readAsDataURL(event?.target?.files[0]);

        if (!event?.target?.files[0]?.type?.includes('image')
            && !event?.target?.files[0]?.type?.includes('audio')
            && !event?.target?.files[0]?.type?.includes('video')
        ) return toast(<ToastMsg
            text={'You can upload only image file'} isError/>)

        reader.onload = function () {
            // @ts-ignore
            if (event?.target?.files[0]?.type?.includes('image')) {
                setCreateData({
                    ...createData,
                    image: reader.result,
                    imageFile: event?.target?.files[0],
                    audio: null,
                    audioFile: null,
                    video: null,
                    videoFile: null
                })
            } else if (event?.target?.files[0]?.type?.includes('audio')) {
                setCreateData({
                    ...createData,
                    audio: reader.result,
                    audioFile: event?.target?.files[0],
                    video: null,
                    videoFile: null,
                    image: null,
                    imageFile: null
                })
            } else if (event?.target?.files[0]?.type?.includes('video')) {
                setCreateData({
                    ...createData,
                    video: reader.result,
                    videoFile: event?.target?.files[0],
                    image: null,
                    imageFile: null,
                    audio: null,
                    audioFile: null,
                })
            }

        };
    }

    const onChangeSupply = (e: any) => {
        if (e?.target?.value < 0) return
        if (e?.target?.value > 100000) return toast(<ToastMsg text={'Supply can not be over 100,000'} isError/>)

        setCreateData({...createData, supply: e?.target?.value})
    }

    const onMintNft = async () => {
        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to mint NFT'} isError/>)

        dispatch(loaderActions.setLoader({isActive: true, opacityLevel: 3, withImage: true}))

        console.log('createData --> ', createData)

        const link = await Promise.all([
            new FileApi()
                .generateIPFSLink(createData?.imageFile || createData?.audioFile || createData?.videoFile)
                .then((res) => {
                    console.log('generateIPFSLink --> ', res);
                    return res?.status ? res?.data?.link : null
                })
                .catch((err) => {
                    console.log('generateIPFSLink err --> ', err);
                    return null
                }),
            new FileApi()
                .generateDOLink(createData?.imageFile || createData?.audioFile || createData?.videoFile)
                .then((res) => {
                    console.log('generateDOLink --> ', res);
                    return res?.status ? res?.data : null
                })
                .catch((err) => {
                    console.log('generateDOLink err --> ', err);
                    return null
                }),
        ]);

        console.log('link --> ', link);

        if (link?.length < 2 || !link[0]?.length || !link[1]?.length) {
            dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
            return toast(<ToastMsg text={'Failed to upload image to IPFS. Try again'} isError/>)
        }

        let audioImg: any = null
        if (createData?.audio) {
            audioImg = await Promise.all([
                new FileApi().generateIPFSLink(createData?.imageForAudioFile)
                    .then((res) => {
                        console.log('generateIPFSLink audio --> ', res);
                        return res?.status ? res?.data?.link : null
                    })
                    .catch((err) => {
                        console.log('generateIPFSLink audio err --> ', err);
                        return null
                    }),
                new FileApi()
                    .generateDOLink(createData?.imageForAudioFile)
                    .then((res) => {
                        console.log('generateDOLink audio --> ', res);
                        return res?.status ? res?.data : null
                    })
                    .catch((err) => {
                        console.log('generateDOLink audio err --> ', err);
                        return null
                    }),
            ]);

            console.log('audioImg --> ', audioImg);

            if (audioImg?.length < 2 || !audioImg[0]?.length || !audioImg[1]?.length) {
                dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
                return toast(<ToastMsg text={'Failed to upload image to IPFS. Try again'} isError/>)
            }
        }

        const tokenMetadata = getItemMetadata(createData, link[0], audioImg ? audioImg[0] : null)

        const generatedMetadataLink = await new FileApi()
            .generateMetadataLink(tokenMetadata)
            .then((res) => (res?.status ? res?.data?.hash : null))
            .catch(() => null);

        if (!generatedMetadataLink) {
            dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
            return toast(<ToastMsg text={'Failed to upload metadata to IPFS. Try again'} isError/>)
        }

        const tokenID = await handleMarketplace
            .mint(customer?.address, generatedMetadataLink, createData.collection?.contractAddress)//done
            .then((res: any) => {
                return hexToNumber(res?.events[0]?.args[2]?._hex)
            })
            .catch(() => {
                dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
                toast(<ToastMsg text={'Failed to create NFT. Try again'} isError/>)
                return null
            });

        if (!tokenID) {
            dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
            return toast(<ToastMsg text={'Failed to mint NFT. Try again'} isError/>)
        }

        await new CollectionApi()
            // @ts-ignore
            .createToken(createData?.collection,
                tokenID,
                createData?.image ? link[0] : createData?.audio ? audioImg[0] : null,
                customer?.address,
                generatedMetadataLink,
                tokenMetadata,
                createData?.image ? link[1] : createData?.audio ? audioImg[1] : null,
                false,
                createData?.audio ? link[0] : null,
                createData?.audio ? link[1] : null,
                createData?.video ? link[0] : null,
                createData?.video ? link[1] : null,
            )
            .then((res) => {
                if (res?.status) {
                    new CollectionApi().checkCollection(createData.collection?.contractAddress?.toLowerCase())
                    setCreateData(initState)
                    toast(<ToastMsg text={'NFT is successfully created!'}/>)
                } else toast(<ToastMsg text={'Failed to create NFT. Try again'} isError/>)
            })
            .catch(() => null)
            .finally(() => dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3})));

    }

    const onBulkMintNft = async () => {
        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to mint NFT'} isError/>)

        dispatch(loaderActions.setLoader({isActive: true, opacityLevel: 3, withImage: true}))

        console.log('createData --> ', createData);

        const link = await Promise.all([
            new FileApi().generateIPFSLink(createData?.imageFile || createData?.audioFile || createData?.videoFile)
                .then((res) => {
                    console.log('generateIPFSLink --> ', res);
                    return res?.status ? res?.data?.link : null
                })
                .catch((err) => {
                    console.log('generateIPFSLink err --> ', err);
                    return null
                }),
            new FileApi()
                .generateDOLink(createData?.imageFile || createData?.audioFile || createData?.videoFile)
                .then((res) => {
                    console.log('generateDOLink -->', res);
                    return res?.status ? res?.data : null
                })
                .catch((err) => {
                    console.log('generateDOLink err --> ', err);
                    return null
                }),
        ]);

        console.log('link --> ', link);

        if (link?.length < 2 || !link[0]?.length || !link[1]?.length) {
            dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
            return toast(<ToastMsg text={'Failed to upload image to IPFS. Try again'} isError/>)
        }

        let audioImg: any = null
        if (createData?.audio) {
            audioImg = await Promise.all([
                new FileApi().generateIPFSLink(createData?.imageForAudioFile)
                    .then((res) => {
                        console.log('generateIPFSLink audio --> ', res);
                        return res?.status ? res?.data?.link : null
                    })
                    .catch((err) => {
                        console.log('generateIPFSLink audio err --> ', err);
                        return null
                    }),
                new FileApi()
                    .generateDOLink(createData?.imageForAudioFile)
                    .then((res) => {
                        console.log('generateDOLink audio --> ', res);
                        return res?.status ? res?.data : null
                    })
                    .catch((err) => {
                        console.log('generateDOLink audio err --> ', err);
                        return null
                    }),
            ]);

            console.log('audioImg --> ', audioImg);

            if (audioImg?.length < 2 || !audioImg[0]?.length || !audioImg[1]?.length) {
                dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
                return toast(<ToastMsg text={'Failed to upload image to IPFS. Try again'} isError/>)
            }
        }

        const tokenMetadata = getItemMetadata(createData, link[0], audioImg ? audioImg[0] : null)

        const generatedMetadataLink = await new FileApi()
            .generateMetadataLink(tokenMetadata)
            .then((res) => (res?.status ? res?.data?.hash : null))
            .catch(() => null);

        if (!generatedMetadataLink) {
            dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
            return toast(<ToastMsg text={'Failed to upload metadata to IPFS. Try again'} isError/>)
        }

        const {startTokenID, endTokenID} = await handleMarketplace
            .bulkMint(customer?.address, generatedMetadataLink, +createData?.supply, createData.collection?.contractAddress)
            .then((res: any) => {
                return {
                    startTokenID: hexToNumber(res?.events[0]?.args[2]?._hex),
                    endTokenID: hexToNumber(res?.events[1]?.args[2]?._hex)
                }
            })
            .catch(() => {
                dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
                toast(<ToastMsg text={'Failed to create NFT. Try again'} isError/>)
                return null
            });

        if (!startTokenID) {
            dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3}))
            return toast(<ToastMsg text={'Failed to mint NFT. Try again'} isError/>)
        }

        await new CollectionApi()
            // @ts-ignore
            .createTokenList(
                createData?.collection,
                startTokenID,
                createData?.image ? link[0] : createData?.audio ? audioImg[0] : null,
                customer?.address,
                generatedMetadataLink,
                tokenMetadata,
                +createData?.supply,
                createData?.image ? link[1] : createData?.audio ? audioImg[1] : null,
                createData?.audio ? link[0] : null,
                createData?.audio ? link[1] : null,
                createData?.video ? link[0] : null,
                createData?.video ? link[1] : null,
            )
            .then((res) => {
                if (res?.status) {
                    toast(<ToastMsg text={`${+createData?.supply} NFTs is successfully created!`}/>)
                    new CollectionApi().checkCollection(createData.collection?.contractAddress?.toLowerCase())
                    setCreateData(initState)
                } else toast(<ToastMsg text={`Failed to create ${+createData?.supply} NFTs. Try again`} isError/>)
            })
            .catch(() => null)
            .finally(() => dispatch(loaderActions.setLoader({isActive: false, opacityLevel: 3})));
    }

    const onSubmit = async () => {
        if (!customer?.address) return toast(<ToastMsg text={'You should authorize to create NFT'} isError/>)
        if (+createData?.supply <= 0) return toast(<ToastMsg text={'Supply of NFT should be at least 1'} isError/>)
        if (!createData?.collection?.contractAddress) return toast(<ToastMsg
            text={'You should create collection before creating NFT'} isError/>)
        if (isDisabledSubmit) return toast(<ToastMsg
            text={'Fields "name", "image" and "collections" should not be empty'} isError/>)
        if (createData?.audio && !createData?.imageForAudio) return toast(<ToastMsg
            text={'Audio NFT should have image file'} isError/>)

        if (+createData?.supply > 1) {
            await onBulkMintNft().catch(() => null)
        } else {
            await onMintNft().catch(() => null)
        }
    }

    useEffect(() => {
        if (createData?.name && (createData?.image || createData?.audio || createData?.video) && +createData?.supply > 0 && !!createData?.collection?.contractAddress) {
            setIsDisabledSubmit(false)
        } else setIsDisabledSubmit(true)
    }, [createData?.name, createData?.image, createData?.supply, createData?.collection])

    return (
        <MainLayout>
            <div className={styles.createPage}>

                <p className={styles.title}>Create item</p>

                <div className={styles.subtitle}>Image, Video, Audio, or 3D Model <p>File types supported: JPG, PNG,
                    GIF,
                    SVG, WEBP, MP4, MP3, WAV, OGG. Max size: 100 MB</p>
                </div>
                <div className={styles.nft_img} onClick={() => fileRef?.current?.click()}>
                    <input
                        type={'file'}
                        hidden
                        ref={fileRef}
                        onChange={onUploadImage}
                        accept="image/*, video/MP4, audio/MP3, audio/WAV, audio/OGG"
                    />
                    <img src={createData.image} alt={''}/>
                    {createData?.audio &&
                    <>
                        <p>{createData?.audioFile?.name}</p>
                        <audio
                            // @ts-ignore
                            ref={audioRef}
                            autoPlay
                            controls
                            src={createData.audio}
                        />
                    </>}
                    {createData?.video &&
                    // @ts-ignore
                    <video ref={videoRef} autoPlay src={createData.video}/>}
                </div>

                {createData?.audio && <>
                    <p className={styles.subtitle}>Upload image for audio <p>File types supported: JPG, PNG, GIF,
                        SVG. Max size: 100 MB</p></p>
                    <div className={styles.nft_img} onClick={() => audioImageRef?.current?.click()}>
                        <input
                            type={'file'}
                            hidden
                            ref={audioImageRef}
                            onChange={onUploadImageForAudio}
                            accept="image/*"
                        />
                        <img src={createData?.imageForAudio || uploadImg} alt={''}/>
                    </div>
                </>}


                <p className={styles.subtitle}>Name</p>
                <Input value={createData?.name} onChange={onChangeName} placeholder={'Item name'}/>

                <p className={styles.subtitle}>Description<span>(Optional)</span></p>
                <TextArea value={createData?.description} onChange={onChangeDesc} placeholder={'Item description'}/>

                <p className={styles.subtitle}>Supply</p>
                <div className={styles.supply}>
                    <Input
                        value={createData?.supply}
                        onChange={onChangeSupply}
                        placeholder={'Supply'}
                        type={'number'}
                        min={1}
                        withoutDecimals
                    />
                </div>

                <p className={styles.subtitle}>Collection</p>
                <p className={styles.chose_collection}>Choose collection this item will appear in</p>
                <CollectionDropdown createData={createData} setCreateData={setCreateData}/>
                <PropertiesBlock createData={createData} setCreateData={setCreateData}/>
                <LevelsBlock createData={createData} setCreateData={setCreateData}/>
                <StatsBlock createData={createData} setCreateData={setCreateData}/>
                <UnlockableContent createData={createData} setCreateData={setCreateData}/>

                <Button title={'Create item'} disabled={isDisabledSubmit} isYellow onClick={onSubmit}/>

            </div>
        </MainLayout>
    );
};

export default CreatePage;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_propertyRow__Acsy9 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.styles_delete__Z3spd {
  width: 52px;
  height: 52px;
  border: 1px solid #3E3E3E;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.styles_stats__\\+LWEr {
  max-width: 424px;
  background: #242424;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styles_stats__\\+LWEr p {
  margin: 5px 0;
  font-size: 20px;
}
@media (max-width: 1025px) {
  .styles_stats__\\+LWEr {
    max-width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/templates/CreatePage/StatsRow/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;EACA,0CAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,eAAA;AACJ;AAEE;EAjBF;IAkBI,cAAA;EACF;AACF","sourcesContent":[".propertyRow {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 30px;\n}\n\n.delete {\n  width: 52px;\n  height: 52px;\n  border: 1px solid #3E3E3E;\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.stats {\n  max-width: 424px;\n  background: #242424;\n  border: 1px solid rgba(255, 255, 255, .2);\n  border-radius: 12px;\n  width: 100%;\n  padding: 12px 16px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  p {\n    margin: 5px 0;\n    font-size: 20px;\n  }\n\n  @media (max-width: 1025px) {\n    max-width: 90%;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"propertyRow": `styles_propertyRow__Acsy9`,
	"delete": `styles_delete__Z3spd`,
	"stats": `styles_stats__+LWEr`
};
export default ___CSS_LOADER_EXPORT___;

import CustomerApi from "../../utils/api/CustomerApi";
import {useDispatch} from "react-redux";
import * as customerActions from '../../store/actions/customer'
import {toast} from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";

export default function useHandleCustomer() {
    const dispatch: any = useDispatch()

    const fetchInfo = async () => {
        return await new CustomerApi()
            .getInfo()
            .then((res) => {
                if (res?.status) dispatch(customerActions.setCustomer(res?.data));
                return res?.data || null;
            })
            .catch(() => null)
    };

    const getNonce = (address: string) => {
        return new CustomerApi()
            .getNonce(address)
            .then(res => res?.status ? res?.data : null)
            .catch(() => null)
    }

    const editInfo = async (data: any) => {
        return new CustomerApi()
            .editInfo(data)
            .then((res) => {
                if (res?.status) {
                    fetchInfo()
                    // @ts-ignore
                    toast.success(<ToastMsg text={'Profile successfully edited!'}/>)
                }
                return res?.data || null;
            })
            .catch(() => null)
    };

    const getUserNFTsCreated = async (walletAddress: string, isNsfwON: number, showHidden: number) => {
        return new CustomerApi()
            .getUserNftsCreated(walletAddress, isNsfwON, showHidden)
            .then(res => {
                if (res?.status) {
                    return res?.data
                }
            })
            .catch(() => null)
    }

    const getUserNFTsCollected = async (walletAddress: string, limit: number, page: number) => {
        return new CustomerApi()
            .getUserNftsCollected(walletAddress, limit, page).then(res => {
                if (res?.status) {
                    return res?.data
                }
            })
            .catch(() => null)
    }

    const getUserNFTsCollectedTab = async (walletAddress: string, isNsfwON: number, showHidden: number) => {
        return await new CustomerApi()
            .getUserNftsCollectedTab(walletAddress, isNsfwON, showHidden)
            .then(res => {
                if (res?.status) {
                    return res?.data
                }
            })
            .catch(() => null)
    }

    return {
        getNonce,
        fetchInfo,
        editInfo,
        getUserNFTsCreated,
        getUserNFTsCollected,
        getUserNFTsCollectedTab
    }
}

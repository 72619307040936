import * as LISTING from '../types/listingSuccessModal'

const initialState = {
    isOpen: false,
}

export default function listingModal(state = initialState, action: any) {
    switch (action.type) {
        case LISTING.SET_LISTING: {
            return {
                isOpen: action?.data?.isOpen
            }
        }
        default:
            return state
    }
}

import LikeApi from "../../utils/api/LikeApi";

export default function useHandleLike() {
    const getCountByTokens = (tokenList: any) => {
        return new LikeApi()
            .getCountByTokens({tokenList})
            .then((res) => (res?.status ? res?.data : null))
            .catch(() => null);
    };

    const getCountByToken = (contractAddress: string, tokenID: number) => {
        return new LikeApi()
            .getCountByToken({contractAddress, tokenID})
            .then((res) => (res?.status ? res?.data : null))
            .catch(() => null);
    };

    const set = (contractAddress: string, tokenID: number) => {
        return new LikeApi()
            .set({contractAddress, tokenID})
            .then((res) => (res?.status ? res?.data : null))
            .catch((err: any) => {
                console.log('set like --> ', err)
            });
    };

    const getAll = (address: string) => {
        return new LikeApi()
            .getAll(address)
            .then((res) => (res?.status ? res?.data?.tokenList : null))
            .catch(() => null);
    };

    return {
        set,
        getCountByToken,
        getCountByTokens,
        getAll
    };
}

import React, {useEffect, useState} from 'react';
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from './styles.module.scss'
import useHandleLoader from "../../hooks/loader/useHandleLoader";
import CollectionApi from "../../utils/api/CollectionApi";
import CollectionCard from "./CollectionCard/CollectionCard";
import LaunchpadModal from "../../components/Modals/LaunchpadModal/LaunchpadModal";
import useHandleModal from "../../hooks/dom/useHandleModal";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../routes/path";
import moment from "moment";
import Timer from "../../components/Timer/Timer";

const LaunchpadPage = () => {
    const customer = useSelector((state: any) => state.customer.data)

    const [collections, setCollections] = useState([])
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [onTimer, setOnTimer] = useState(true)

    const handleLoader = useHandleLoader()
    const handleModal = useHandleModal()
    const navigate = useNavigate()

    // useEffect(() => {
    //     if (customer?.address) {
    //         if (!customer?.hasAccessToDrops)
    //             return navigate(pathList.mainPage.path)
    //     }
    // }, [customer])

    useEffect(() => {
        handleLoader.loaderWrapper(() => new CollectionApi()
            .getAllDropsCollections()
            .then((res) => setCollections(res?.data)))
            .catch(() => null)

        // new DropsApi().setContractAddress('0x08e3baee6e29e19667b5c45c9ba59b4ade7b20d4')
    }, [])

    return (
        <MainLayout>
            <div className={styles.launchpadPage}>
                <p className={styles.title}>Drops</p>

                <p className={styles.soon}>COLLECTIONS COMING SOON</p>


                {/*{onTimer && (customer?.address?.toLowerCase() !== '0x267404a991d46d3a15f6ffbe27a2bc44d4b5267a') && (moment().unix() < 1698163200)*/}
                {/*    ? <div className={styles.timer}>*/}
                {/*        <p>Will be available in</p>*/}
                {/*        <Timer isBig targetDate={moment.unix(1698163200).format()} setIsListed={setOnTimer}/>*/}
                {/*    </div>*/}
                {/*    : <div className={styles.list}>*/}
                {/*        {collections?.map((item, key) =>*/}
                {/*            <CollectionCard*/}
                {/*                setSelectedCollection={setSelectedCollection}*/}
                {/*                key={key}*/}
                {/*                collection={item}*/}
                {/*                onOpenModal={handleModal.open}*/}
                {/*            />)}*/}
                {/*    </div>*/}
                {/*}*/}


                {/*{handleModal.isActive && <LaunchpadModal*/}
                {/*    isOpen={handleModal.isActive}*/}
                {/*    onClose={handleModal.close}*/}
                {/*    collection={selectedCollection}*/}
                {/*/>}*/}
            </div>
        </MainLayout>
    );
};

export default LaunchpadPage;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import PriceInput from "./PriceInput/PriceInput";
import {addDays} from "date-fns";
import moment from "moment";
import {AVAILABLE_CURRENCY} from "../../../constants/listingPage";
import DateInput from "./DateInput/DateInput";
import Button from "../../Button/Button";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useHandleMarketplace from "../../../hooks/blockchain/useHandleMarketplace";
import {convertDateToUnix, playAudioCallback, playVideoCallback} from "../../../utils";
import {useSelector} from "react-redux";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import {EVENT_TYPE_OFFER, EVENT_TYPE_OFFER_CANCELED, EVENT_TYPE_OFFER_EDITED, SHIB_ADDRESS} from "../../../constants";
import {formatIPFSUrl, hexToNumber} from "../../../utils/blockchain";
import useHandleToken from "../../../hooks/blockchain/useHandleToken";
import CollectionApi from "../../../utils/api/CollectionApi";
import badge from '../../../assets/images/symbols/verified-badge_orange.svg'
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import useHandleWeb3 from "../../../hooks/web3/useHandleWeb3";

type Props = {
    isOpen: boolean,
    onClose: any,
    nftData?: any,
    setIsNewOfferPlaced?: any,
    isEdit?: boolean,
    currentOffer?: any,
    isNewOfferPlaced?: boolean,
    isFromProfile?: boolean,
    updateDataCallback?: any,
    setNftData?: any,
    handlePendingOfferModal?: any,
    setOfferTxID: any,
    setApproveShibOfferTxID: any,
    handlePendingApproveShibOfferModal: any
}

const MakeOfferModal = ({
                            onClose,
                            isOpen,
                            nftData,
                            setIsNewOfferPlaced,
                            isEdit,
                            currentOffer,
                            isNewOfferPlaced,
                            isFromProfile,
                            updateDataCallback,
                            setNftData,
                            handlePendingOfferModal,
                            setOfferTxID,
                            setApproveShibOfferTxID,
                            handlePendingApproveShibOfferModal
                        }: Props) => {
    const customer = useSelector((state: any) => state.customer.data)
    const today = new Date();
    const [offerData, setOfferData] = useState<any>({
        from: today,
        to: addDays(today, 30),
        startTime: moment(today).format('HH:mm'),
        endTime: moment(addDays(today, 30)).format('HH:mm'),
        price: {currency: AVAILABLE_CURRENCY[0], value: ''}
    })
    const [royaltyAmount, setRoyaltyAmount] = useState(0)

    const handleMarketplace = useHandleMarketplace()
    const handleActivity = useHandleActivity()
    const handleToken = useHandleToken()
    const windowDimensions = useWindowDimensions()
    const handleWeb3 = useHandleWeb3()

    const onCloseModal = () => {
        onClose()
        setOfferData({
            from: today,
            to: addDays(today, 30),
            startTime: moment(today).format('HH:mm'),
            endTime: moment(addDays(today, 30)).format('HH:mm'),
            price: {currency: AVAILABLE_CURRENCY[0], value: ''}
        })
    }

    const onMakeOffer = async () => {
        if (+offerData?.price?.value < 0.01) return toast(<ToastMsg text={'Price should be filled'} isError/>)

        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to make this offer'} isError/>)

        const balanceSHIB = await handleWeb3
            .getBalanceOfShib()
            .then((res: any) => hexToNumber(res?._hex) / 10 ** 18)
        if (balanceSHIB < +offerData?.price?.value)
            return toast(<ToastMsg text={'You have not enough SHIB to make this offer'} isError/>)

        /* global BigInt */
        const value = BigInt(+offerData?.price?.value * 10 ** 18).toString();
        const startDateOfferUnix = convertDateToUnix(offerData?.from, offerData?.startTime)
        const endDateOfferUnix = convertDateToUnix(offerData?.to, offerData?.endTime)

        handleMarketplace
            // @ts-ignore
            .approveShib(process.env.REACT_APP_OFFERS_ADDRESS, +offerData?.price?.value)
            .then(async (resApprove: any) => {
                setApproveShibOfferTxID(resApprove?.hash)
                onClose()
                handlePendingApproveShibOfferModal.open()
                const transaction = await resApprove?.wait();

                if (transaction?.status) {
                    handlePendingApproveShibOfferModal.close()

                    handleMarketplace
                        .onMakeOfferWithToken(value,
                            nftData?.owner,
                            nftData?.tokenID,
                            startDateOfferUnix,
                            endDateOfferUnix,
                            true,
                            SHIB_ADDRESS,
                            nftData?.contractAddress,
                            royaltyAmount)
                        .then(async (res: any) => {
                            setOfferTxID(res?.hash)
                            onClose()
                            handlePendingOfferModal.open()
                            const transaction = await res?.wait();

                            if (transaction?.status) {
                                const offerID = hexToNumber(transaction?.events[2]?.args?.offerID?._hex)

                                await handleActivity.createActivity({
                                    eventType: EVENT_TYPE_OFFER,
                                    tokenID: nftData?.tokenID,
                                    contractAddress: nftData?.contractAddress?.toLowerCase(),
                                    name: nftData?.name,
                                    nftName: nftData?.metadata?.name,
                                    isCollectionVerified: nftData?.isCollectionVerified,
                                    imageDOLink: nftData?.imageDOLink,//done
                                    audioDOLink: nftData?.audioDOLink,
                                    videoDOLink: nftData?.videoDOLink,
                                    price: offerData?.price?.value,
                                    currency: offerData?.price?.currency?.label,
                                    from: customer?.address?.toLowerCase(),
                                    to: nftData?.owner?.toLowerCase(),
                                    startDate: startDateOfferUnix,
                                    endDate: endDateOfferUnix,
                                    transactionTime: moment().unix(),
                                    transactionHash: transaction?.transactionHash,
                                    offerID: offerID
                                })

                                setIsNewOfferPlaced(!isNewOfferPlaced)
                                await handleToken
                                    .checkToken(nftData?.contractAddress, nftData?.tokenID)
                                    .then(res => setNftData && setNftData(res))
                                await new CollectionApi().checkCollection(nftData?.contractAddress?.toLowerCase())
                                onCloseModal()
                                handlePendingOfferModal.close()
                                toast(<ToastMsg
                                    text={`You have successfully made offer to ${nftData?.metadata?.name}`}/>)
                            } else {
                                onCloseModal()
                                handlePendingOfferModal.close()
                                toast(<ToastMsg text={'Failed to make offer. Try again'} isError/>)
                            }
                        })
                        .catch((err: any) => {
                            console.log('err 2 --> ', err);
                            if (err?.data?.message?.includes('insufficient funds'))
                                toast(<ToastMsg text={'You have no funds to make this offer '} isError/>)
                            else if (err?.code !== 'ACTION_REJECTED') {
                                onCloseModal()
                                handlePendingOfferModal.close()
                                toast(<ToastMsg text={'Failed to make offer. Try again'} isError/>)
                            }
                            return null
                        })
                }
            })
            .catch((err: any) => {
                console.log('err --> ', err);
                if (err?.data?.message?.includes('insufficient funds'))
                    toast(<ToastMsg text={'You have no funds to make this offer '} isError/>)
                else if (err?.code !== 'ACTION_REJECTED') {
                    onCloseModal()
                    handlePendingOfferModal.close()
                    handlePendingApproveShibOfferModal.close()
                    toast(<ToastMsg text={'Failed to make offer. Try again'} isError/>)
                }
                return null
            })
            .finally(onCloseModal)
    }

    const onEditOffer = async () => {
        if (+offerData?.price?.value < 0.01) return toast(<ToastMsg text={'Price should be filled'} isError/>)

        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to make this offer'} isError/>)

        const balanceSHIB = await handleWeb3
            .getBalanceOfShib()
            .then((res: any) => hexToNumber(res?._hex) / 10 ** 18)
        if (balanceSHIB < +offerData?.price?.value)
            return toast(<ToastMsg text={'You have not enough SHIB to make this offer'} isError/>)

        /* global BigInt */
        const value = BigInt(+offerData?.price?.value * 10 ** 18).toString();
        const startDateOfferUnix = convertDateToUnix(offerData?.from, offerData?.startTime)
        const endDateOfferUnix = convertDateToUnix(offerData?.to, offerData?.endTime)


        handleMarketplace
            // @ts-ignore
            .approveShib(process.env.REACT_APP_OFFERS_ADDRESS, +offerData?.price?.value)
            .then(async (resApprove: any) => {
                setApproveShibOfferTxID(resApprove?.hash)
                onClose()
                handlePendingApproveShibOfferModal.open()
                const transaction = await resApprove?.wait();

                if (transaction?.status) {
                    handlePendingApproveShibOfferModal.close()
                    handleMarketplace
                        .onEditOfferWithToken(
                            value,
                            isFromProfile ? currentOffer?.to : nftData?.owner,
                            isFromProfile ? currentOffer?.contractAddress : nftData?.contractAddress,
                            isFromProfile ? currentOffer?.tokenID : nftData?.tokenID,
                            startDateOfferUnix,
                            endDateOfferUnix,
                            true,
                            SHIB_ADDRESS,
                            isFromProfile ? currentOffer?.offerID : currentOffer?.id,
                            royaltyAmount)
                        .then(async (res: any) => {
                                setOfferTxID(res?.hash)
                                onClose()
                                handlePendingOfferModal.open()
                                const transaction = await res?.wait();
                                if (transaction?.status) {

                                    await handleActivity.createActivity({
                                        eventType: EVENT_TYPE_OFFER_CANCELED,
                                        tokenID: isFromProfile ? currentOffer?.tokenID : nftData?.tokenID,
                                        contractAddress: isFromProfile ? currentOffer?.contractAddress?.toLowerCase() : nftData?.contractAddress?.toLowerCase(),
                                        name: isFromProfile ? currentOffer?.name : nftData?.name,
                                        nftName: isFromProfile ? currentOffer?.nftName : nftData?.metadata?.name,
                                        isCollectionVerified: isFromProfile ? currentOffer?.isCollectionVerified : nftData?.isCollectionVerified,
                                        imageDOLink: isFromProfile ? currentOffer?.imageDOLink : nftData?.imageDOLink,//done
                                        audioDOLink: isFromProfile ? currentOffer?.audioDOLink : nftData?.audioDOLink,
                                        videoDOLink: isFromProfile ? currentOffer?.videoDOLink : nftData?.videoDOLink,
                                        price: currentOffer?.price,
                                        currency: isFromProfile ? currentOffer?.currency : AVAILABLE_CURRENCY?.find((item: any) =>
                                            item?.address?.toLowerCase() === currentOffer?.tokenAddress?.toLowerCase())?.label,
                                        from: customer?.address?.toLowerCase(),
                                        to: isFromProfile ? currentOffer?.to : currentOffer?.owner?.toLowerCase(),
                                        startDate: currentOffer?.startDate,
                                        endDate: currentOffer?.endDate,
                                        transactionTime: moment().unix(),
                                        transactionHash: transaction?.transactionHash,
                                        offerID: isFromProfile ? currentOffer?.offerID : currentOffer?.id
                                    })

                                    const offerID = hexToNumber(transaction?.events[3]?.args?.offerID?._hex)

                                    await handleActivity.createActivity({
                                        eventType: EVENT_TYPE_OFFER_EDITED,
                                        tokenID: isFromProfile ? currentOffer?.tokenID : nftData?.tokenID,
                                        contractAddress: isFromProfile ? currentOffer?.contractAddress?.toLowerCase() : nftData?.contractAddress?.toLowerCase(),
                                        name: isFromProfile ? currentOffer?.name : nftData?.name,
                                        nftName: isFromProfile ? currentOffer?.nftName : nftData?.metadata?.name,
                                        isCollectionVerified: isFromProfile ? currentOffer?.isCollectionVerified : nftData?.isCollectionVerified,
                                        imageDOLink: isFromProfile ? currentOffer?.imageDOLink : nftData?.imageDOLink,//done
                                        audioDOLink: isFromProfile ? currentOffer?.audioDOLink : nftData?.audioDOLink,
                                        videoDOLink: isFromProfile ? currentOffer?.videoDOLink : nftData?.videoDOLink,
                                        price: offerData?.price?.value,
                                        currency: offerData?.price?.currency?.label,
                                        from: customer?.address?.toLowerCase(),
                                        to: isFromProfile ? currentOffer?.to : nftData?.owner?.toLowerCase(),
                                        startDate: startDateOfferUnix,
                                        endDate: endDateOfferUnix,
                                        transactionTime: moment().unix(),
                                        transactionHash: transaction?.transactionHash,
                                        offerID: offerID
                                    })

                                    await handleToken
                                        .checkToken(nftData?.contractAddress, nftData?.tokenID)
                                        .then(res => setNftData && setNftData(res))

                                    await new CollectionApi().checkCollection(currentOffer?.contractAddress?.toLowerCase() || nftData?.contractAddress?.toLowerCase())

                                    setIsNewOfferPlaced && setIsNewOfferPlaced(!isNewOfferPlaced)
                                    updateDataCallback && await updateDataCallback()

                                    onCloseModal()
                                    handlePendingOfferModal.close()
                                    toast(<ToastMsg text={'You have edited your offer successfully!'}/>)
                                } else {
                                    onCloseModal()
                                    handlePendingOfferModal.close()
                                    toast(<ToastMsg text={'Failed to edit the offer'} isError/>)
                                }
                            }
                        )
                        .catch((err: any) => {
                            if (err?.data?.message?.includes('insufficient funds'))
                                toast(<ToastMsg text={'You have no funds to edit this offer '} isError/>)
                            else if (err?.code !== 'ACTION_REJECTED') {
                                onCloseModal()
                                handlePendingOfferModal.close()
                                toast(<ToastMsg text={'Failed to edit offer. Try again'} isError/>)
                            }
                            return null
                        })
                }
            })
            .catch((err: any) => {
                if (err?.data?.message?.includes('insufficient funds'))
                    toast(<ToastMsg text={'You have no funds to edit this offer '} isError/>)
                else if (err?.code !== 'ACTION_REJECTED') {
                    onCloseModal()
                    handlePendingOfferModal.close()
                    handlePendingApproveShibOfferModal.close()
                    toast(<ToastMsg text={'Failed to edit offer. Try again'} isError/>)
                }
                return null
            })
            .finally(onCloseModal)
    }

    useEffect(() => {
        isEdit && setOfferData({
            from: new Date(moment.unix(currentOffer?.startDate).format()),
            to: new Date(moment.unix(currentOffer?.endDate).format()),
            startTime: moment.unix(currentOffer?.startDate).format('HH:mm'),
            endTime: moment.unix(currentOffer?.endDate).format('HH:mm'),
            price: {
                currency: isFromProfile
                    ? AVAILABLE_CURRENCY?.find(item => item?.label?.toLowerCase() === currentOffer?.currency?.toLowerCase())
                    : AVAILABLE_CURRENCY?.find(item => item?.address?.toLowerCase() === currentOffer?.tokenAddress?.toLowerCase()),
                value: currentOffer?.price
            }
        })
    }, [isEdit, currentOffer, isOpen])

    useEffect(() => {
        new CollectionApi()
            .getCollectionFees(isFromProfile ? currentOffer?.contractAddress?.toLowerCase() : nftData?.contractAddress?.toLowerCase())
            .then((res) => {
                setRoyaltyAmount((res?.data?.isExternalCollection || res?.data?.forDrops) ? +res?.data?.royaltyPercentage : 0)
            })
    }, [])

    return (
        <ModalLayout
            onClose={onCloseModal}
            isOpen={isOpen}
            maxWidth={windowDimensions?.width < 840 ? windowDimensions?.width - 40 + 'px' : '800px'}
            maxHeight={''}
            withCrossIcon
        >
            <p className={styles.title}>{isEdit ? 'Edit offer' : 'Make offer'}</p>

            <div className={styles.wrapper}>

                <div className={styles.image}>
                    {((nftData?.imageDOLink && !nftData?.imageDOLink?.includes('undefined'))
                        || (currentOffer?.imageDOLink && !currentOffer?.imageDOLink?.includes('undefined')))
                    && <img
                        src={isFromProfile ? formatIPFSUrl(currentOffer?.imageDOLink) : formatIPFSUrl(nftData?.imageDOLink)}
                        alt={''}/>}

                    {((nftData?.videoDOLink && !nftData?.videoDOLink?.includes('undefined'))
                        || (currentOffer?.videoDOLink && !currentOffer?.videoDOLink?.includes('undefined')))
                    && <video //done
                        className={'nft_video'}
                        id={`modal-${isFromProfile ? currentOffer?.videoDOLink : nftData?.videoDOLink}`}
                        controls
                        onPlay={playVideoCallback}
                        src={isFromProfile ? currentOffer?.videoDOLink : nftData?.videoDOLink}
                    />}

                    {((nftData?.audioDOLink && !nftData?.audioDOLink?.includes('undefined'))
                        || (currentOffer?.audioDOLink && !currentOffer?.audioDOLink?.includes('undefined')))
                    && <audio //done
                        id={`modal-${isFromProfile ? currentOffer?.audioDOLink : nftData?.audioDOLink}`}
                        onPlay={playAudioCallback}
                        className={'nft_audio'}
                        controls
                        src={isFromProfile ? currentOffer?.audioDOLink : nftData?.audioDOLink}
                    />}
                </div>

                <div className={styles.info}>
                    <p className={styles.id}>{
                        isFromProfile
                            ? (currentOffer?.nftName || 'NFT #' + currentOffer?.tokenID)
                            : (nftData?.metadata?.name || 'NFT #' + nftData?.tokenID)
                    }</p>
                    <p className={styles.collection}>
                        {isFromProfile ? currentOffer?.name : nftData?.name}
                        {(currentOffer?.isCollectionVerified || nftData?.isCollectionVerified) &&
                        <img src={badge} alt={''}/>}
                    </p>

                    <p className={styles.price}>Offered price</p>
                    <PriceInput setOfferData={setOfferData} offerData={offerData}/>

                    <p className={styles.duration}>Set offer duration</p>
                    <DateInput setOfferData={setOfferData} offerData={offerData}/>

                    <div className={styles.button}>
                        <Button
                            title={isEdit ? 'Edit offer' : 'Make offer'}
                            isYellow
                            disabled={+offerData?.price?.value < 0.01}
                            onClick={isEdit ? onEditOffer : onMakeOffer}
                        />
                    </div>
                </div>
            </div>

        </ModalLayout>
    );
};

export default MakeOfferModal;

import React, {useEffect, useState} from 'react';
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from './styles.module.scss'
import {
    EXPLORE_PAGE_TABS,
} from "../../constants/explorePage";
import cn from "classnames";
import Slider from "react-slick";
import verifiedBadge from "../../assets/images/symbols/verified-badge_orange.svg";//done
import NextArrow from "../../components/SliderArrows/NexArrow/NextArrow";
import PrevArrow from "../../components/SliderArrows/PrevArrow/PrevArrow";
import {useNavigate, useParams} from "react-router-dom";
import CollectionApi from "../../utils/api/CollectionApi";
import useHandleLoader from "../../hooks/loader/useHandleLoader";
import {
    CATEGORY_ALL,
} from "../../constants/myCollectionsPage";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {TABLET} from "../../constants";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import {FreeMode, Mousewheel} from 'swiper/modules';
import './styles.css'
import CryptoApi from "../../utils/api/CryptoApi";

const ExplorePage = () => {
    const params = useParams()

    const [activeTab, setActiveTab] = useState(params?.category || CATEGORY_ALL)
    const [sliderData, setSliderData] = useState([])
    const [shibariumCollections, setShibariumCollections] = useState([])
    const [mouseMoved, setMouseMoved] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [maticToUsd, setMaticToUsd] = useState<any>(0)
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()
    const handleLoader = useHandleLoader()
    const windowDimensions = useWindowDimensions()

    const onClickTab = (value: string) => {
        if (value === CATEGORY_ALL) {
            navigate(`/explore`)
        } else navigate(`/explore/${value}`)
        setActiveTab(value)
    }
    const onClickCollection = (contractAddress: string | undefined) => {
        if (!mouseMoved || isTablet)
            navigate(`/collection/${contractAddress}`)//done
    }

    const settings1 = {
        dots: false,
        infinite: sliderData?.length > 3,
        speed: 1000,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToScroll: 3,
        nextArrow: <NextArrow right={'-7px'}/>,
        prevArrow: <PrevArrow left={'-5px'}/>,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 2.8,
                    slidesToScroll: 1,
                    centerMode: sliderData?.length > 3,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 1390,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    centerMode: sliderData?.length > 3,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    centerMode: sliderData?.length > 2,
                    centerPadding: '20px',
                    infinite: sliderData?.length > 2,
                }
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    centerMode: sliderData?.length > 2,
                    centerPadding: '20px',
                    infinite: sliderData?.length > 2,
                }
            },
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: sliderData?.length > 2,
                    centerPadding: '20px',
                    infinite: sliderData?.length > 2,
                }
            },
        ]
    };

    const settings2 = {
        dots: false,
        infinite: shibariumCollections?.length > 3,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <NextArrow right={'-7px'}/>,
        prevArrow: <PrevArrow left={'-5px'}/>,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 2.8,
                    slidesToScroll: 1,
                    centerMode: shibariumCollections?.length > 3,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 1390,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    centerMode: shibariumCollections?.length > 3,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    centerMode: shibariumCollections?.length > 2,
                    centerPadding: '20px',
                    infinite: shibariumCollections?.length > 2,
                }
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    centerMode: shibariumCollections?.length > 2,
                    centerPadding: '20px',
                    infinite: shibariumCollections?.length > 2,
                }
            },
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: shibariumCollections?.length > 2,
                    centerPadding: '20px',
                    infinite: shibariumCollections?.length > 2,
                }
            },
        ]
    };

    useEffect(() => {
        if (activeTab === CATEGORY_ALL) {
            handleLoader.loaderWrapper(async () => await new CollectionApi()
                .getAllCollections()
                .then((res) => {
                    setSliderData(res?.data)
                    // const filteredCollections = res?.data
                    //     ?.filter((item: any) => item?.volumeAllTime && (+item?.volumeAllTime * maticToUsd) >= 500)
                    setShibariumCollections(res?.data)
                })
                .catch(() => null)
                .finally(() => setIsLoading(false))
            )
        } else {
            handleLoader.loaderWrapper(async () => await new CollectionApi()
                .getCollectionsByCategory(activeTab)
                .then((res) => {
                    setSliderData(res?.data)
                    // const filteredCollections = res?.data
                    //     ?.filter((item: any) => item?.volumeAllTime && (+item?.volumeAllTime * maticToUsd) >= 500)
                    setShibariumCollections(res?.data)
                })
                .catch(() => null)
                .finally(() => setIsLoading(false))
            )
        }
    }, [activeTab, maticToUsd])

    useEffect(() => {
        setIsTablet(windowDimensions?.width <= TABLET)
    }, [windowDimensions?.width])

    useEffect(() => {
        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
    }, [])

    return (
        <MainLayout isFullWidth>
            <div className={styles.explorePage}>
                <h1 className={styles.title}>Explore</h1>

                <div className={styles.tabs}>
                    {EXPLORE_PAGE_TABS?.map((item, key) => <div
                        key={key}
                        onClick={() => onClickTab(item?.value)}
                        className={cn(styles.tabs_item, activeTab === item?.value && styles.tabs_item_active)}
                    >
                        {item?.title}
                    </div>)}
                </div>


                {!isTablet && !!sliderData?.length
                && <>
                    <div className={styles.slider}>
                        <Slider {...settings1}>
                            {sliderData
                                ?.sort((a: any, b: any) => b?.volumeAllTime - a?.volumeAllTime)
                                ?.slice(0, 12)
                                ?.map((item: any, key) =>
                                    <div
                                        key={key}
                                        className={styles.slider_item}
                                        onMouseMove={() => setMouseMoved(true)}
                                        onMouseDown={() => setMouseMoved(false)}
                                        onMouseUp={() => onClickCollection(item?.externalLink
                                            ? item?.externalLink?.includes('/')
                                                ? item?.contractAddress
                                                : item?.externalLink
                                            : item?.contractAddress)}>
                                        <img src={item?.featuredImage} alt={''}
                                             className={styles.slider_item_image}/>
                                        <div className={styles.slider_item_name}>
                                            {item?.name}
                                            {item?.isVerified && <img src={verifiedBadge} alt={''}/>}
                                        </div>
                                    </div>)}
                        </Slider>
                    </div>

                    {!!shibariumCollections?.length && <> <p className={styles.blockTitle}>Shibarium Collections</p>

                        <div className={styles.list}>{shibariumCollections
                            ?.sort((a: any, b: any) => b?.volumeAllTime - a?.volumeAllTime)
                            ?.map((item: any, key) => <div
                                key={key}
                                className={styles.slider_item}
                                onMouseMove={() => setMouseMoved(true)}
                                onMouseDown={() => setMouseMoved(false)}
                                onMouseUp={() => onClickCollection(item?.externalLink
                                    ? item?.externalLink?.includes('/')
                                        ? item?.contractAddress
                                        : item?.externalLink
                                    : item?.contractAddress)}>
                                <img src={item?.featuredImage} alt={''}
                                     className={styles.slider_item_image}/>
                                <div className={styles.slider_item_name}>
                                    {item?.name}
                                    {item?.isVerified && <img src={verifiedBadge} alt={''}/>}
                                </div>
                            </div>)}
                        </div>

                        {/*<div className={styles.slider}>*/}
                        {/*    <Slider {...settings2}>*/}
                        {/*        {shibariumCollections*/}
                        {/*            ?.sort((a: any, b: any) => b?.volumeAllTime - a?.volumeAllTime)*/}
                        {/*            ?.slice(0, 12)*/}
                        {/*            ?.map((item: any, key) =>*/}
                        {/*                <div*/}
                        {/*                    key={key}*/}
                        {/*                    className={styles.slider_item}*/}
                        {/*                    onMouseMove={() => setMouseMoved(true)}*/}
                        {/*                    onMouseDown={() => setMouseMoved(false)}*/}
                        {/*                    onMouseUp={() => onClickCollection(item?.externalLink*/}
                        {/*                        ? item?.externalLink?.includes('/')*/}
                        {/*                            ? item?.contractAddress*/}
                        {/*                            : item?.externalLink*/}
                        {/*                        : item?.contractAddress)}>*/}
                        {/*                    <img src={item?.featuredImage} alt={''}*/}
                        {/*                         className={styles.slider_item_image}/>*/}
                        {/*                    <div className={styles.slider_item_name}>*/}
                        {/*                        {item?.name}*/}
                        {/*                        {item?.isVerified && <img src={verifiedBadge} alt={''}/>}*/}
                        {/*                    </div>*/}
                        {/*                </div>)}*/}
                        {/*    </Slider>*/}
                        {/*</div>*/}
                    </>}

                </>
                }


                {isTablet && !!sliderData?.length
                && <>
                    <div className={styles.slider}>
                        <Swiper
                            // @ts-ignore
                            slidesPerView={'auto'}
                            spaceBetween={80}
                            freeMode={true}
                            modules={[FreeMode, Mousewheel]}
                            mousewheel={true}
                            centeredSlides={sliderData?.length === 1}
                            className="swiperExplore"
                        >

                            {sliderData
                                ?.sort((a: any, b: any) => b?.volumeAllTime - a?.volumeAllTime)
                                ?.slice(0, 12)
                                ?.map((item: any, key) =>
                                    <SwiperSlide>
                                        <div
                                            key={key}
                                            className={styles.slider_item}
                                            onMouseMove={() => setMouseMoved(true)}
                                            onMouseDown={() => setMouseMoved(false)}
                                            onMouseUp={() => onClickCollection(item?.externalLink
                                                ? item?.externalLink?.includes('/')
                                                    ? item?.contractAddress
                                                    : item?.externalLink
                                                : item?.contractAddress)}>
                                            <div className={styles.slider_item_wrapper}
                                                 onClick={() => onClickCollection(item?.externalLink
                                                     ? item?.externalLink?.includes('/')
                                                         ? item?.contractAddress
                                                         : item?.externalLink
                                                     : item?.contractAddress)}>
                                                <img src={item?.featuredImage} alt={''}
                                                     className={styles.slider_item_image}/>
                                                <div className={styles.slider_item_name}>
                                                    {item?.name?.length > 20 ? item?.name?.slice(0, 20) + '...' : item?.name}
                                                    {item?.isVerified && <img src={verifiedBadge} alt={''}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>)}
                        </Swiper>
                    </div>

                    {!!shibariumCollections?.length && <><p className={styles.blockTitle}>Shibarium Collections</p>

                        <div className={styles.list}>{shibariumCollections
                            ?.sort((a: any, b: any) => b?.volumeAllTime - a?.volumeAllTime)
                            ?.map((item: any, key) => <div
                                key={key}
                                className={styles.slider_item}
                                onMouseMove={() => setMouseMoved(true)}
                                onMouseDown={() => setMouseMoved(false)}
                                onMouseUp={() => onClickCollection(item?.externalLink
                                    ? item?.externalLink?.includes('/')
                                        ? item?.contractAddress
                                        : item?.externalLink
                                    : item?.contractAddress)}>
                                <img src={item?.featuredImage} alt={''}
                                     className={styles.slider_item_image}/>
                                <div className={styles.slider_item_name}>
                                    {item?.name}
                                    {item?.isVerified && <img src={verifiedBadge} alt={''}/>}
                                </div>
                            </div>)}
                        </div>

                        {/*<div className={styles.slider}>*/}
                        {/*    <Swiper*/}
                        {/*        // @ts-ignore*/}
                        {/*        slidesPerView={'auto'}*/}
                        {/*        spaceBetween={80}*/}
                        {/*        freeMode={true}*/}
                        {/*        modules={[FreeMode, Mousewheel]}*/}
                        {/*        mousewheel={true}*/}
                        {/*        centeredSlides={sliderData?.length === 1}*/}
                        {/*        className="swiperExplore"*/}
                        {/*        preventClicks={false}*/}
                        {/*        preventClicksPropagation={false}*/}
                        {/*    >*/}

                        {/*        {shibariumCollections*/}
                        {/*            ?.sort((a: any, b: any) => b?.volumeAllTime - a?.volumeAllTime)*/}
                        {/*            ?.slice(0, 12)*/}
                        {/*            ?.map((item: any, key) =>*/}
                        {/*                <SwiperSlide>*/}
                        {/*                    <div*/}
                        {/*                        key={key}*/}
                        {/*                        className={styles.slider_item}*/}
                        {/*                        onMouseMove={() => setMouseMoved(true)}*/}
                        {/*                        onMouseDown={() => setMouseMoved(false)}*/}
                        {/*                        onMouseUp={() => onClickCollection(item?.externalLink*/}
                        {/*                            ? item?.externalLink?.includes('/')*/}
                        {/*                                ? item?.contractAddress*/}
                        {/*                                : item?.externalLink*/}
                        {/*                            : item?.contractAddress)}>*/}
                        {/*                        <div className={styles.slider_item_wrapper}*/}
                        {/*                             onClick={() => onClickCollection(item?.externalLink*/}
                        {/*                                 ? item?.externalLink?.includes('/')*/}
                        {/*                                     ? item?.contractAddress*/}
                        {/*                                     : item?.externalLink*/}
                        {/*                                 : item?.contractAddress)}>*/}
                        {/*                            <img src={item?.featuredImage} alt={''}*/}
                        {/*                                 className={styles.slider_item_image}/>*/}
                        {/*                            <div className={styles.slider_item_name}>*/}
                        {/*                                {item?.name?.length > 20 ? item?.name?.slice(0, 20) + '...' : item?.name}*/}
                        {/*                                {item?.isVerified && <img src={verifiedBadge} alt={''}/>}*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </SwiperSlide>)}*/}
                        {/*    </Swiper>*/}
                        {/*</div>*/}
                    </>}

                </>}

                {!sliderData?.length && !isLoading && <p className={styles.noData}>
                    No collections in this category yet
                </p>}

            </div>

        </MainLayout>
    );
};

export default ExplorePage;

import React, {useRef, useState} from 'react';
import Button from "../../../components/Button/Button";
import styles from './styles.module.scss'
import {useNavigate} from "react-router-dom";
import {pathList} from "../../../routes/path";
import img from '../../../assets/images/introduction.svg'
import videoControl from './../../../assets/images/video_control.svg'

const IntroductionBlock = () => {
    const [isPlaying, setIsPlaying] = useState(false)
    const navigate = useNavigate()
    const videoRef: any = useRef<HTMLInputElement>(null)

    const onClickExplore = () => navigate(pathList.explore.path)
    const onClickPlay = () => {
        if (isPlaying) {
            setIsPlaying(false)
            videoRef?.current?.pause()
        } else {
            setIsPlaying(true)
            videoRef?.current?.play()
        }
    }

    return (
        <div className={styles.introductionBlock}>
            <p className={styles.title}>Introduction to Pawzaar</p>
            <p className={styles.text}>The ultimate NFT marketplace for NFT enthusiasts</p>
            <div className={styles.video} onClick={onClickPlay}>
                {!isPlaying && <div className={styles.controls}>
                    <img src={videoControl} alt={''}/>
                </div>}
                <video ref={videoRef}
                       src={'https://pawzaar.sfo3.digitaloceanspaces.com/metadrip-space/InroVideo/SHIBARIUM.mp4'}/>
            </div>
            <div className={styles.button}>
                <Button title={'Explore Marketplace'} isYellow onClick={onClickExplore}/>
            </div>
        </div>
    );
};

export default IntroductionBlock;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_timer__\\+Z-Wo {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  line-height: 25px;
  align-items: center;
  letter-spacing: -0.35px;
  font-weight: 600;
  color: #D9D9D9;
  gap: 7px;
}
@media (max-width: 640px) {
  .style_timer__\\+Z-Wo {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
  }
}

.style_timer_element__y\\+rcx {
  display: flex;
  align-items: center;
}

.style_timer_isBig__0S4-1 {
  font-size: 35px;
}`, "",{"version":3,"sources":["webpack://./src/components/Timer/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,cAAA;EACA,QAAA;AACJ;AAAI;EAVJ;IAWQ,eAAA;IACA,kBAAA;IACA,gBAAA;EAGN;AACF;;AAAA;EACI,aAAA;EACA,mBAAA;AAGJ;;AAAA;EACI,eAAA;AAGJ","sourcesContent":[".timer {\n    display: flex;\n    justify-content: flex-end;\n    font-size: 18px;\n    line-height: 25px;\n    align-items: center;\n    letter-spacing: -0.35px;\n    font-weight: 600;\n    color: #D9D9D9;\n    gap: 7px;\n    @media (max-width: 640px) {\n        font-size: 13px;\n        font-style: normal;\n        font-weight: 700;\n    }\n}\n\n.timer_element{\n    display: flex;\n    align-items: center;\n}\n\n.timer_isBig{\n    font-size: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer": `style_timer__+Z-Wo`,
	"timer_element": `style_timer_element__y+rcx`,
	"timer_isBig": `style_timer_isBig__0S4-1`
};
export default ___CSS_LOADER_EXPORT___;

import {
    SORTING_BEST_OFFER,
    SORTING_FLOOR_DECREASE, SORTING_FLOOR_INCREASE,
    SORTING_MOST_FAVORITED,
    SORTING_MOST_VIEWED, SORTING_OLDEST, SORTING_PRICE_DECREASE, SORTING_PRICE_INCREASE,
    SORTING_RECENTLY_LISTED,
    SORTING_RECENTLY_OBTAINED,
    SORTING_RECENTLY_SOLD
} from "./profile";
import {
    SORT_BY_FLOOR_DECREASE,
    SORT_BY_FLOOR_INCREASE, SORT_BY_LISTED_DECREASE, SORT_BY_LISTED_INCREASE,
    SORT_BY_NAME_DECREASE,
    SORT_BY_NAME_INCREASE,
    SORT_BY_OWNERS_DECREASE,
    SORT_BY_OWNERS_INCREASE,
    SORT_BY_SALES_DECREASE,
    SORT_BY_SALES_INCREASE,
    SORT_BY_VOLUME_DECREASE,
    SORT_BY_VOLUME_INCREASE
} from "../utils/sortingNFT";

export const STATS_TOP_TAB = 1
export const STATS_TOP_WATCHLIST = 2

export const STATS_PAGE_TABS = [
    {title: 'Top', value: STATS_TOP_TAB},
    {title: 'Watchlist', value: STATS_TOP_WATCHLIST}
]

export const STATS_TIME_1H = 1
export const STATS_TIME_24H = 2
export const STATS_TIME_7D = 3
export const STATS_TIME_30D = 4
export const STATS_TIME_90D = 6
export const STATS_TIME_ALL_TIME = 5

export const STATS_TIME_TABS = [
    {title: '1h', value: STATS_TIME_1H},
    {title: '24h', value: STATS_TIME_24H},
    {title: '7d', value: STATS_TIME_7D},
    {title: '30d', value: STATS_TIME_30D},
    {title: 'All time', value: STATS_TIME_ALL_TIME},
]

export const SORTING_DATA_STATS = [
    {title: 'Collection (A-Z)', id: SORT_BY_NAME_INCREASE},
    {title: 'Collection (Z-A)', id: SORT_BY_NAME_DECREASE},

    {title: 'Volume (low-high)', id: SORT_BY_VOLUME_INCREASE},
    {title: 'Volume (high-low)', id: SORT_BY_VOLUME_DECREASE},

    {title: 'Floor (low-high)', id: SORT_BY_FLOOR_INCREASE},
    {title: 'Floor (high-low)', id: SORT_BY_FLOOR_DECREASE},

    {title: 'Sales (low-high)', id: SORT_BY_SALES_INCREASE},
    {title: 'Sales (high-low)', id: SORT_BY_SALES_DECREASE},

    {title: 'Unique owners (low-high)', id: SORT_BY_OWNERS_INCREASE},
    {title: 'Unique owners (high-low)', id: SORT_BY_OWNERS_DECREASE},

    {title: 'Listed items (low-high)', id: SORT_BY_LISTED_INCREASE},
    {title: 'Listed items (high-low)', id: SORT_BY_LISTED_DECREASE},
]

import Base from "./Base";

export default class FileApi extends Base {
  async generateDOLink(data: any) {
    const formData = new FormData();
    formData.append("file", data);

    return super.post("file", formData);
  }

  async saveFileDO(file: any) {
    return super.post('file', file)
  }

  async generateIPFSLink(file: any) {
    const formData = new FormData();
    formData.append("file", file);

    return super.post("file/ipfs", formData);
  }

  async generateMetadataLink(metadata: any) {
      return super.post("file/ipfs-metadata", {metadata});
  }
}

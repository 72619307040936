import React, {useEffect, useState} from 'react';
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import {AVAILABLE_CURRENCY} from "../../../constants/listingPage";
import Button from "../../Button/Button";
import useHandleMarketplace from "../../../hooks/blockchain/useHandleMarketplace";
import useHandleToken from "../../../hooks/blockchain/useHandleToken";
import useHandleActivity from "../../../hooks/blockchain/useHandleActivity";
import {EVENT_TYPE_SALE} from "../../../constants";
import {useSelector} from "react-redux";
import moment from "moment";
import formatNumber, {convertIPFSUrl, playAudioCallback, playVideoCallback} from "../../../utils";
import Tooltip from "../../Tooltip/Tooltip";
import CollectionApi from "../../../utils/api/CollectionApi";
import badge from '../../../assets/images/symbols/verified-badge_orange.svg'
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {formatIPFSUrl, hexToNumber} from "../../../utils/blockchain";
import {toast} from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import useHandleWeb3 from "../../../hooks/web3/useHandleWeb3";

type Props = {
    isOpen: boolean,
    onClose: any,
    nftData: any,
    setNftData: any,
    callback?: any,
    handlePendingPurchaseModal?: any,
    handleSuccessPurchaseModal?: any,
    handleFailPurchaseModal?: any,
    setPurchaseTxID: any,
    handlePendingApproveShibModal: any,
    setApproveShibTxID: any
}

const BuyModal = ({
                      onClose, isOpen, nftData, setNftData, callback = null,
                      handlePendingPurchaseModal,
                      handleSuccessPurchaseModal,
                      handleFailPurchaseModal,
                      setPurchaseTxID,
                      handlePendingApproveShibModal,
                      setApproveShibTxID
                  }: Props) => {
    const customer = useSelector((state: any) => state.customer.data)
    const [isExternalCollection, setIsExternalCollection] = useState(false)
    const [isForDrops, setIsForDrops] = useState(false)
    const [royaltyAmount, setRoyaltyAmount] = useState(0)
    const [royaltyAddress, setRoyaltyAddress] = useState('')

    const handleMarketplace = useHandleMarketplace()
    const handleToken = useHandleToken()
    const handleActivity = useHandleActivity()
    const windowDimensions = useWindowDimensions()
    const handleWeb3 = useHandleWeb3()

    const purchaseCallBack = async (transaction: any) => {
        await handleActivity.createActivity({
            eventType: EVENT_TYPE_SALE,
            tokenID: nftData?.tokenID,
            contractAddress: nftData?.contractAddress?.toLowerCase(),
            name: nftData?.name,
            nftName: nftData?.metadata?.name,
            isCollectionVerified: nftData?.isCollectionVerified,
            imageDOLink: nftData?.imageDOLink,//done
            audioDOLink: nftData?.audioDOLink,
            videoDOLink: nftData?.videoDOLink,
            price: nftData?.listing?.price,
            currency: AVAILABLE_CURRENCY?.find((item: any) =>
                item?.address?.toLowerCase() === nftData?.listing?.tokenAddress?.toLowerCase())?.label,
            from: nftData?.listing?.owner?.toLowerCase(),
            to: customer?.address?.toLowerCase(),
            startDate: nftData?.listing?.startDate,
            endDate: nftData?.listing?.endDate,
            transactionTime: moment().unix(),
            transactionHash: transaction?.transactionHash
        })

        callback && callback()

        await handleToken
            .checkToken(nftData?.contractAddress, nftData?.tokenID)
            .then(res => setNftData(res))
        await new CollectionApi().checkCollection(nftData?.contractAddress?.toLowerCase())

        onClose()
        handlePendingPurchaseModal.close()
        handleSuccessPurchaseModal.open()
    }

    const onClickBuy = async () => {
        const balanceBONE = await handleWeb3
            .getBalance(customer?.address)
            .then((res: any) => res / 10 ** 18)
        if (balanceBONE <= 0) return toast(<ToastMsg text={'You have not enough BONE to buy this NFT'} isError/>)

        const balanceSHIB = await handleWeb3
            .getBalanceOfShib()
            .then((res: any) => hexToNumber(res?._hex) / 10 ** 18)
        if (balanceSHIB < +nftData?.listing?.price)
            return toast(<ToastMsg text={'You have not enough SHIB to buy this NFT'} isError/>)

        return handleMarketplace
            // @ts-ignore
            .approveShib(process.env.REACT_APP_LISTING_ADDRESS, +nftData?.listing?.price)
            .then(async (resApprove: any) => {
                setApproveShibTxID(resApprove?.hash)
                onClose()
                handlePendingApproveShibModal.open()
                const transaction = await resApprove?.wait();

                if (transaction?.status) {
                    try {
                        handlePendingApproveShibModal.close()
                    } catch (err: any) {
                        console.log('catch err --> ', err);
                    }

                    (isExternalCollection || isForDrops)
                        ? handleMarketplace
                            .purchaseListingToken(nftData?.tokenID, nftData?.contractAddress)
                            .then(async (res: any) => {
                                setPurchaseTxID(res?.hash)
                                onClose()
                                handlePendingPurchaseModal.open()
                                const transaction = await res?.wait();

                                if (transaction?.status) {
                                    const royaltyPrice = BigInt((+nftData?.listing?.price * royaltyAmount / 100) * 10 ** 18).toString()

                                    await handleMarketplace
                                        .approveShibRoyalty(process.env.REACT_APP_LISTING_ADDRESS, royaltyPrice)
                                        .catch(() => null)
                                    await handleMarketplace
                                        .transferRoyaltyListings(royaltyPrice, royaltyAddress)
                                        .then((tx: any) => console.log('tx --> ', tx))
                                        .catch(() => null)
                                    await purchaseCallBack(transaction)
                                } else {
                                    onClose()
                                    handlePendingPurchaseModal.close()
                                    handleFailPurchaseModal.open()
                                }
                            })
                            .catch((err: any) => {
                                console.log('err 11 -> ', err);
                                onClose()
                                handlePendingPurchaseModal.close()

                                if (err?.code !== 'ACTION_REJECTED') {
                                    handleFailPurchaseModal.open()
                                }

                                return null
                            })
                            .finally(onClose)
                        : handleMarketplace
                            .purchaseListingTokenWithRoyalty(nftData?.tokenID, nftData?.contractAddress)
                            .then(async (res: any) => {
                                setPurchaseTxID(res?.hash)
                                onClose()
                                handlePendingPurchaseModal.open()
                                const transaction = await res?.wait();

                                if (transaction?.status) {
                                    await purchaseCallBack(transaction)
                                } else {
                                    onClose()
                                    handlePendingPurchaseModal.close()
                                    handleFailPurchaseModal.open()
                                }
                            })
                            .catch((err: any) => {
                                console.log('err  22 -> ', err);
                                onClose()
                                handlePendingPurchaseModal.close()

                                if (err?.code !== 'ACTION_REJECTED') {
                                    handleFailPurchaseModal.open()
                                }

                                return null
                            })
                }

            })
            .catch((err: any) => {
                console.log('err 33 -> ', err);
                onClose()
                handlePendingApproveShibModal.close()

                if (err?.code !== 'ACTION_REJECTED') {
                    handleFailPurchaseModal.open()
                }

                return null
            })
            .finally(onClose)
    }

    useEffect(() => {
        if (isOpen && nftData?.contractAddress) {
            new CollectionApi()
                .getCollectionFees(nftData?.contractAddress)
                .then((res) => {
                    setIsExternalCollection(res?.data?.isExternalCollection)
                    setIsForDrops(res?.data?.forDrops)
                    setRoyaltyAmount(+res?.data?.royaltyPercentage)
                    setRoyaltyAddress(res?.data?.royaltyAddress)
                })
        }
    }, [nftData?.contractAddress, isOpen])

    return (
        <ModalLayout
            onClose={onClose}
            isOpen={isOpen}
            maxWidth={windowDimensions?.width < 840 ? windowDimensions?.width - 40 + 'px' : '800px'}
            maxHeight={''}
            withCrossIcon
        >
            <p className={styles.title}>Buy NFT</p>

            <div className={styles.wrapper}>

                <div className={styles.image}>
                    {(nftData?.imageDOLink && !nftData?.imageDOLink?.includes('undefined'))
                    && <img
                        src={formatIPFSUrl(nftData?.imageDOLink)}
                        alt={''}/>}

                    {(nftData?.videoDOLink && !nftData?.videoDOLink?.includes('undefined'))
                    && <video //done
                        className={'nft_video'}
                        id={`modal-${nftData?.videoDOLink}`}
                        controls
                        onPlay={playVideoCallback}
                        src={nftData?.videoDOLink}
                    />}

                    {(nftData?.audioDOLink && !nftData?.audioDOLink?.includes('undefined'))
                    && <audio //done
                        className={'nft_audio'}
                        id={`modal-${nftData?.audioDOLink}`}
                        controls
                        onPlay={playAudioCallback}
                        src={nftData?.audioDOLink}
                    />}
                </div>

                <div className={styles.info}>
                    <p className={styles.id}>{nftData?.metadata?.name || ('NFT #' + nftData?.tokenID)}</p>
                    <p className={styles.collection}>
                        {nftData?.name}
                        {nftData?.isCollectionVerified && <img src={badge} alt={''}/>}
                    </p>
                </div>
            </div>

            <div className={styles.actions}>
                <p className={styles.price}>
                    Price:
                    <Tooltip text={(+nftData?.listing?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                        {formatNumber(+nftData?.listing?.price?.toFixed(2))} {AVAILABLE_CURRENCY?.find(item => item?.address?.toLowerCase() === nftData?.listing?.tokenAddress?.toLowerCase())?.label}
                    </Tooltip>
                </p>
                <Button title={'Buy now'} isYellow onClick={onClickBuy}/>
            </div>

        </ModalLayout>
    );
};

export default BuyModal;

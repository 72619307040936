import React, {useEffect, useState} from 'react';
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from './styles.module.scss'
import {EXPLORE_TAB_ALL} from "../../constants/explorePage";
import cn from "classnames";
import {
    SORTING_DATA_STATS,
    STATS_PAGE_TABS, STATS_TIME_24H, STATS_TIME_ALL_TIME,
    STATS_TIME_TABS,
    STATS_TOP_TAB, STATS_TOP_WATCHLIST
} from "../../constants/statsPage";
import CategoriesDropdown from "./CategoriesDropdown/CategoriesDropdown";
import StatsTable from "./StatsTable/StatsTable";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../constants";
import sortImg from '../../assets/images/symbols/sorting.svg'
import filtersImg from '../../assets/images/symbols/filters.svg'
import StatsMobileItem from "./StatsMobileItem/StatsMobileItem";
import {CATEGORY_ALL, CATEGORY_ALL_DATA} from "../../constants/myCollectionsPage";
import CollectionApi from "../../utils/api/CollectionApi";
import useHandleLoader from "../../hooks/loader/useHandleLoader";
import {SORT_BY_VOLUME_DECREASE, sortCollections} from "../../utils/sortingNFT";
import StatsFiltersMobile from "./StatsFiltersMobile/StatsFiltersMobile";
import {useSelector} from "react-redux";
import useHandleAuth from "../../hooks/auth/useHandleAuth";
import SignUpModal from "../../components/Modals/SignUpModal/SignUpModal";
import useHandleModal from "../../hooks/dom/useHandleModal";
import SortingStatsMobile from "../../components/SortingStatsMobile/SortingStatsMobile";

const StatsPage = () => {
    const customer = useSelector((state: any) => state.customer.data)

    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET && windowDimensions?.width > MOBILE
    const isMobile = windowDimensions?.width <= MOBILE

    const [activeTab, setActiveTab] = useState(EXPLORE_TAB_ALL)
    const [activeSubTab, setActiveSubTab] = useState(STATS_TIME_ALL_TIME)
    const [isOpenedMobileSorting, setIsOpenedMobileSorting] = useState(false)
    const [isOpenedFiltersMobile, setIsOpenedFiltersMobile] = useState(false)
    const [activeCategory, setActiveCategory] = useState(CATEGORY_ALL_DATA)
    const [collectionsData, setCollectionsData] = useState([])
    const [sortingParam, setSortingParam] = useState(SORT_BY_VOLUME_DECREASE)

    const handleLoader = useHandleLoader()
    const handleAuth = useHandleAuth()
    const handleSignUpModal = useHandleModal()

    const onClickTab = async (value: number) => {
        if (!customer?._id && value === STATS_TOP_WATCHLIST)
            return await handleAuth
                .connectWallet(handleSignUpModal.open, () => setActiveTab(value))

        setActiveTab(value)
    }
    const onClickSubTab = (value: number) => setActiveSubTab(value)
    const onOpenMobileSorting = () => setIsOpenedMobileSorting(true)
    const onOpenMobileFilters = () => setIsOpenedFiltersMobile(true)

    useEffect(() => {

        if (activeTab === STATS_TOP_TAB) {
            if (activeCategory?.value === CATEGORY_ALL) {
                handleLoader.loaderWrapper(async () => await new CollectionApi()
                    .getAllCollections()
                    .then((res) => {
                        setCollectionsData(sortCollections(res?.data, sortingParam, activeSubTab) || [])
                    })
                    .catch(() => null)
                )
            } else {
                handleLoader.loaderWrapper(async () => await new CollectionApi()
                    .getCollectionsByCategory(activeCategory?.value)
                    .then((res) => {
                        setCollectionsData(sortCollections(res?.data, sortingParam, activeSubTab) || [])
                    })
                    .catch(() => null)
                )
            }
        } else if (activeTab === STATS_TOP_WATCHLIST) {
            if (!customer?._id) return setCollectionsData([])

            if (activeCategory?.value === CATEGORY_ALL) {
                handleLoader.loaderWrapper(async () => await new CollectionApi()
                    .getLikedCollections()
                    .then((res) => {
                        setCollectionsData(sortCollections(res, sortingParam, activeSubTab) || [])
                    })
                    .catch(() => null)
                )
            } else {
                handleLoader.loaderWrapper(async () => await new CollectionApi()
                    .getLikedCollectionsByCategory(activeCategory?.value)
                    .then((res) => {
                        setCollectionsData(sortCollections(res, sortingParam, activeSubTab) || [])
                    })
                    .catch(() => null)
                )
            }
        }

    }, [activeCategory, activeTab, sortingParam, activeSubTab])

    return (
        <MainLayout>
            <div className={styles.statsPage}>
                <h1 className={styles.title}>Stats</h1>

                <div className={styles.tabs}>
                    {STATS_PAGE_TABS?.map((item, key) => <div
                        key={key}
                        onClick={() => onClickTab(item?.value)}
                        className={cn(
                            styles.tabs_item,
                            activeTab === item?.value && styles.tabs_item_active
                        )}
                    >
                        {item?.title}
                    </div>)}
                </div>


                <div className={styles.menu}>
                    {!isTablet && !isMobile &&
                    <CategoriesDropdown activeCategory={activeCategory} setActiveCategory={setActiveCategory}/>}
                    {!isMobile && <div className={styles.subtabs}>
                        {STATS_TIME_TABS?.map((item, key) => <div
                            key={key}
                            onClick={() => onClickSubTab(item?.value)}
                            className={cn(styles.subtabs_item, activeSubTab === item?.value && styles.subtabs_item_active)}
                        >
                            {item?.title}
                        </div>)}
                    </div>}
                    {isMobile &&
                    <div onClick={onOpenMobileFilters} className={styles.sortImg}><img src={filtersImg} alt={''}/>
                    </div>}
                    {(isTablet || isMobile) &&
                    <div onClick={onOpenMobileSorting} className={styles.sortImg}><img src={sortImg} alt={''}/></div>}
                </div>

                {(activeTab === STATS_TOP_TAB || customer?._id)
                    ? <>{isMobile
                        ? <div className={styles.list}>
                            {collectionsData?.map((item, key) =>
                                <StatsMobileItem
                                    rank={key + 1}
                                    activeSubTab={activeSubTab}
                                    collection={item}
                                    key={key}
                                    handleSignUpModal={handleSignUpModal}
                                />)}
                        </div>
                        : <StatsTable
                            sortingParam={sortingParam}
                            setSortingParam={setSortingParam}
                            collectionsData={collectionsData}
                            activeSubTab={activeSubTab}
                            handleSignUpModal={handleSignUpModal}
                        />
                    }</>
                    : <p className={styles.noData}>Watchlist is available only for authorized users</p>
                }

                {(isTablet || isMobile) && <SortingStatsMobile
                    isOpenedMobileSorting={isOpenedMobileSorting}
                    setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                    setSelectedSorting={setSortingParam}
                    selectedSorting={sortingParam}
                    filters={SORTING_DATA_STATS}
                />}

                {(isMobile) && <StatsFiltersMobile
                    isOpenedFiltersMobile={isOpenedFiltersMobile}
                    setIsOpenedFiltersMobile={setIsOpenedFiltersMobile}
                    activeSubTab={activeSubTab}
                    onClickSubTab={onClickSubTab}
                    activeCategory={activeCategory}
                    setActiveCategory={setActiveCategory}
                />}

                {handleSignUpModal.isActive && <SignUpModal
                    isOpen={handleSignUpModal.isActive}
                    onClose={handleSignUpModal.close}
                />}
            </div>
        </MainLayout>
    );
};

export default StatsPage;

import {useCountdown} from '../../hooks/useCountdown';
import styles from './style.module.scss';
import {useEffect} from "react";
import cn from "classnames";

const Timer = ({targetDate, setIsListed, isBig}: { targetDate: string, setIsListed: any, isBig?: boolean }) => {
    const [hours, minutes, seconds, hoursValue, minutesValue, secondsValue, days] = useCountdown(targetDate);

    useEffect(() => {
        if (hoursValue === 0 && minutesValue === 0 && secondsValue === 0) {
            setIsListed(false)
        }
    }, [hoursValue, minutesValue, secondsValue])

    return (
        <div className={cn(styles.timer, isBig && styles.timer_isBig)}>
            {days > 0 && <div className={styles.timer_element}>
                <div className="timer__element--count">
                    {days > 0 ? days : '00'}
                </div>
                <div className="timer__element--label">
                    d
                </div>
            </div>}
            <div className={styles.timer_element}>
                <div className="timer__element--count">
                    {hours > 0 ? hours : '00'}
                </div>
                <div className="timer__element--label">
                    h
                </div>
            </div>
            <div className={styles.timer_element}>
                <div className="timer__element--count">
                    {minutes > 0 ? minutes : '00'}
                </div>
                <div className="timer__element--label">
                    m
                </div>
            </div>
            <div className={styles.timer_element}>
                <div className="timer__element--count">
                    {seconds > 0 ? seconds : '00'}
                </div>
                <div className="timer__element--label">
                    s
                </div>
            </div>
        </div>
    );
}

export default Timer;

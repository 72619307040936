import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import cross from "../../../assets/images/symbols/cross.svg";
import check from "../../../assets/images/symbols/check_yellow.svg";
import Button from "../../../components/Button/Button";
import {AVAILABLE_CATEGORIES, CATEGORY_ALL_DATA} from "../../../constants/myCollectionsPage";
import {STATS_TIME_TABS} from "../../../constants/statsPage";

type Props = {
    isOpenedFiltersMobile: any,
    setIsOpenedFiltersMobile: any,
    activeSubTab: any,
    onClickSubTab: any,
    activeCategory: any,
    setActiveCategory: any
}

const StatsFiltersMobile = ({
                                isOpenedFiltersMobile,
                                setIsOpenedFiltersMobile,
                                activeSubTab,
                                onClickSubTab,
                                activeCategory,
                                setActiveCategory
                            }: Props) => {
    const onCloseSorting = () => setIsOpenedFiltersMobile(false)
    const onApplySorting = () => setIsOpenedFiltersMobile(false)

    const onClickCategory = (category: any) => setActiveCategory(category)

    return (
        <div className={cn(styles.sortingMobile, isOpenedFiltersMobile && styles.sortingMobile_isOpenedMobileSorting)}>

            <div className={styles.title}>
                Sort by category
                <div className={styles.cross} onClick={onCloseSorting}>
                    <img src={cross} alt={''} width={18.67} height={18.67}/>
                </div>
            </div>

            <div className={styles.content}>
                {[CATEGORY_ALL_DATA, ...AVAILABLE_CATEGORIES]?.map((item, key) =>
                    <div key={key} className={styles.sorting_item} onClick={() => onClickCategory(item)}>
                        <div className={styles.sorting_item_check}>
                            {activeCategory?.value === item?.value && <img src={check} alt={''}/>}
                        </div>
                        {item?.name}
                    </div>)}

                <div className={styles.subtitle}>
                    Sort by time
                </div>

                {STATS_TIME_TABS?.map((item, key) => <div
                    key={key}
                    onClick={() => onClickSubTab(item?.value)}
                    className={styles.sorting_item}
                >
                    <div className={styles.sorting_item_check}>
                        {activeSubTab === item?.value && <img src={check} alt={''}/>}
                    </div>
                    {item?.title}
                </div>)}
            </div>

            <div className={styles.buttons}>
                <Button
                    title={`Apply`}
                    isYellow
                    isFullWidth
                    onClick={onApplySorting}
                />
            </div>

        </div>
    );
};

export default StatsFiltersMobile;

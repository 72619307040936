import React, {useEffect, useState} from 'react';
import moment from "moment";
import cn from "classnames";
import styles from "../styles.module.scss";
import {EVENT_TYPES} from "../../../constants";
import verifiedBadge from "../../../assets/images/symbols/verified-badge_orange.svg";//done
import Tooltip from "../../Tooltip/Tooltip";
import formatNumber from "../../../utils";
import shiba from "../../../assets/images/symbols/shiba.svg";
import openSvg from "../../../assets/images/symbols/open.svg";
import Button from "../../Button/Button";
import dots from "../../../assets/images/symbols/dots.svg";
import {useNavigate} from "react-router-dom";
import CustomerApi from "../../../utils/api/CustomerApi";
import {formatIPFSUrl} from "../../../utils/blockchain";

type Props = {
    headers: { title: string, withCheckBox: boolean }[],
    withDots?: boolean,
    withNFTCheckbox?: boolean,
    withListingButtons?: boolean,
    withOffersButtons?: boolean,
    isOffersReceived?: boolean,
    withLightBorder?: boolean,
    onClickDots?: any,
    onCancelOffer?: any,
    onEditOffer?: any,
    onAcceptOffer?: any,
    onCancelListing?: any,
    onEditListing?: any,
    maticToUsd: any,
    item: any
}

const NfTsTableRow = ({
                          headers,
                          withDots,
                          withListingButtons,
                          withOffersButtons,
                          isOffersReceived,
                          withLightBorder,
                          onClickDots,
                          onCancelOffer,
                          onEditOffer,
                          onAcceptOffer,
                          onCancelListing,
                          onEditListing,
                          maticToUsd,
                          item
                      }: Props) => {
        const navigate = useNavigate()
        const [from, setFrom] = useState('')
        const [to, setTo] = useState('')

        const hasOffers = !!item?.offers?.filter((offer: any) => offer?.price > 0 && offer?.startDate <= moment().unix())?.length
        const bestOffer = item?.offers
            ?.filter((offer: any) => offer?.price > 0 && offer?.startDate <= moment().unix())
            ?.sort((a: any, b: any) => b?.price - a?.price)[0]

        const isListed = item?.listing?.price > 0 && item?.listing?.endDate > moment().unix() && item?.listing?.startDate <= moment().unix()
        const nftName = item?.metadata?.name || item?.nftName || '#' + item?.tokenID

        const onClickOwner = (address: string) => address ? navigate(`/${address}/collected`) : null
        const onClickNFT = (contractAddress: string, id: number) => navigate(`/collection/${contractAddress}/${id}`)
        const getTableValue = (value: string) => headers?.map(item => item?.title)?.includes(value)

        useEffect(() => {
            if (item?.from) {
                new CustomerApi()
                    .getUserName(item?.from?.toLowerCase())
                    .then((res: any) => {
                        res?.data?.userName ? setFrom(res?.data?.userName) : setFrom(item?.from)
                    })
            }
        }, [item?.from])

        useEffect(() => {
            if (item?.to) {
                new CustomerApi()
                    .getUserName(item?.to?.toLowerCase())
                    .then((res: any) => {
                        res?.data?.userName ? setTo(res?.data?.userName) : setTo(item?.to)
                    })
            }
        }, [item?.to])

        return (<tr>

            {!!getTableValue('Event') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                <p className={styles.empty}>{EVENT_TYPES[item?.eventType]}</p>
            </td>}

            {!!getTableValue('NFT') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                <div className={styles.nftData}
                     onClick={() => onClickNFT(item?.contractAddress, item?.tokenID)}>
                    <div className={styles.nftData_wrapper}>

                        {item?.imageDOLink && !item?.imageDOLink?.includes('undefined')
                        && <img src={formatIPFSUrl(item?.imageDOLink)} alt={''} width={72} height={72}
                                className={styles.nftImage}/>}

                        {item?.videoDOLink && !item?.videoDOLink?.includes('undefined')
                        && <video src={item?.videoDOLink} controls={false} autoPlay={false} width={72} height={72}
                               className={styles.nftImage}/>}

                        <div>
                            <p className={styles.id}>
                                {nftName?.length > 12 ? nftName?.slice(0, 12) + '...' : nftName}
                            </p>
                            <p className={styles.name}>
                                {item?.name?.length > 12 ? item?.name?.slice(0, 12) + '...' : item?.name}
                                {item?.isCollectionVerified && <img src={verifiedBadge} alt={''}/>}
                            </p>
                        </div>
                    </div>
                </div>
            </td>}

            {!!getTableValue('Price') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                {(item?.price || item?.listing?.price)
                    ? <Tooltip
                        text={(+item?.price || +item?.listing?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                        <p className={styles.symbol}>
                            {formatNumber(+(+item?.price)?.toFixed(2)) || formatNumber(+(+item?.listing?.price)?.toFixed(2))}
                            <img src={shiba} alt={''}/>

                            {/*{item?.currency*/}
                            {/*    || AVAILABLE_CURRENCY?.find(currency => currency?.address === item?.tokenAddress)?.label*/}
                            {/*    || AVAILABLE_CURRENCY?.find(currency => currency?.address === item?.listing?.tokenAddress)?.label}*/}
                        </p>
                        <p className={styles.text}>$ {formatNumber(+((+item?.price || +(+item?.listing?.price)) * maticToUsd)?.toFixed(2))}</p>
                    </Tooltip>
                    : <p className={styles.empty}>{withListingButtons ? 'Not listed' : '-'}</p>}
            </td>}

            {!!getTableValue('Floor price') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                <Tooltip text={(+item?.floorPrice)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                    <p className={styles.symbol}>{formatNumber(+(+item?.floorPrice)?.toFixed(2)) || 0}<img
                        src={shiba} alt={''}/></p>
                    <p className={styles.text}>$ {formatNumber(+((+item?.floorPrice || 0) * maticToUsd)?.toFixed(2))}</p>
                </Tooltip>
            </td>}

            {!!getTableValue('Best offer') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                {hasOffers
                    ? <Tooltip text={(+bestOffer?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                        <p className={styles.symbol}>
                            {formatNumber(+(+bestOffer?.price)?.toFixed(2))}
                            <img src={shiba} alt={''}/>
                            {/*{AVAILABLE_CURRENCY?.find((currency: any) => currency?.address === bestOffer?.tokenAddress)?.label}*/}
                        </p>
                        <p className={styles.text}>$ {formatNumber(+(+bestOffer?.price * maticToUsd)?.toFixed(2))}</p>
                    </Tooltip>
                    : <p className={styles.empty}>-</p>}
            </td>}

            {!!getTableValue('Listing price') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                {isListed
                    ? <Tooltip
                        text={(+item?.listing?.price)?.toLocaleString("en-US", {maximumFractionDigits: 2})}>
                        <p className={styles.symbol}>
                            {formatNumber(+(+item?.listing?.price)?.toFixed(2))}
                            <img src={shiba} alt={''}/>
                            {/*{AVAILABLE_CURRENCY?.find((currency: any) => currency?.address === item?.listing?.tokenAddress)?.label}*/}
                        </p>
                        <p className={styles.text}>$ {formatNumber(+((+item?.price || +(+item?.listing?.price)) * maticToUsd)?.toFixed(2))}</p>
                    </Tooltip>
                    : <p className={styles.empty}>Not listed</p>}
            </td>}

            {!!getTableValue('Cost') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                {item?.cost
                    ? <>
                        <p className={styles.symbol}>Ξ 4.89</p>
                        <p className={styles.text}>{item?.cost}</p>
                    </>
                    : <p className={styles.empty}>-</p>}
            </td>}

            {!!getTableValue('Difference') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                {item?.difference
                    ? <>
                        <p className={styles.symbol}>Ξ 4.89</p>
                        <p className={styles.text}>{item?.difference}</p>
                    </>
                    : <p className={styles.empty}>-</p>}
            </td>}


            {!!getTableValue('From') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder, styles.from)}>
                <p className={cn(styles.text_yellow, styles.from)}
                   onClick={() => onClickOwner(from)}>{from?.length > 23
                    ? from?.slice(0, 23) + '...'
                    : from}
                </p>
            </td>}

            {!!getTableValue('To') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                <p className={cn(styles.text_yellow, styles.pointer)} onClick={() => onClickOwner(to)}>{
                    !!to?.length ?
                        (to?.length > 23
                            ? to?.slice(0, 23) + '...'
                            : to)
                        : '-'}
                </p>
            </td>}

            {!!getTableValue('Time') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                <p className={cn(styles.text_yellow, styles.time)}>
                    {moment.unix(item?.transactionTime).format('hh:mm a, D MMM YYYY')}
                    <a target="_blank" rel="noopener noreferrer"
                       href={`https://www.shibariumscan.io/tx/${item?.transactionHash}`}>
                        <img src={openSvg} alt={''}/>
                    </a>
                </p>
            </td>}

            {!!getTableValue('Expires') &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                {(item?.endDate || item?.listing?.endDate)
                    ? <>
                        <p className={styles.text}>{moment.unix(item?.endDate || item?.listing?.endDate).format('hh:mm a, D MMM YYYY')}</p>
                    </>
                    : <p className={styles.empty}>-</p>}
            </td>}

            {withListingButtons &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                <div className={styles.listingButtons}>
                    <Button title={'Edit listing'} isBlackWhite onClick={() => onEditListing(item)}/>
                    <Button title={'Unlist'} isBlackWhite onClick={() => onCancelListing(item)}/>
                </div>
            </td>}

            {withOffersButtons &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                {isOffersReceived
                    ? <div className={styles.listingButtons}>
                        <Button title={'Accept'} isBlackWhite onClick={() => onAcceptOffer(item)}/>
                    </div>
                    : <div className={styles.listingButtons}>
                        <Button title={'Edit offer'} isBlackWhite onClick={() => onEditOffer(item)}/>
                        <Button title={'Cancel'} isBlackWhite onClick={() => onCancelOffer(item)}/>
                    </div>}
            </td>}

            {withDots &&
            <td className={cn(styles.table_data, withLightBorder && styles.table_data_withLightBorder)}>
                <div className={styles.dots} onClick={() => onClickDots ? onClickDots(item) : null}>
                    <img src={dots} alt={''}/>
                </div>
            </td>}

        </tr>)
    }
;

export default NfTsTableRow;

import React from 'react';
import styles from '../styles.module.scss'
import arrowNext from '../../../assets/images/symbols/arrow_right.svg'
import cn from "classnames";

const NextArrow = (props: any) => {
    const {className, style, onClick, right} = props;
    return (
        <div className={cn(className, styles.arrow, style.arrow_next)}
             style={{...style, right: right}}
             onClick={onClick}>
            <img src={arrowNext} alt={''} width={7.5} height={13.5}/>
        </div>
    );
};

export default NextArrow;

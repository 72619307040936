import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import TabHeader from "../TabHeader/TabHeader";
import cn from "classnames";
import {NFT_TABLE_HEADERS, SORTING_DATA} from "../../../constants/profile";
import NftCard from "../../../components/NFTCard/NFTCard";
import NfTsTable from "../../../components/NFTsTable/NFTsTable";
import useWindowDimensions from "../../../hooks/dom/useWidowDimensions";
import {MOBILE, TABLET} from "../../../constants";
import SortingMobile from "../../../components/SortingMobile/SortingMobile";
import useHandleLike from "../../../hooks/like/useHandleLike";
import useHandleLoader from "../../../hooks/loader/useHandleLoader";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";
import CryptoApi from "../../../utils/api/CryptoApi";
import {sortNFTList} from "../../../utils/sortingNFT";

const FavouritesTab = ({
                           currentUser,
                           isNsfwON,
                           showHidden,
                           isOpenedFilters,
                           setIsOpenedFilters, isCardView,
                           setIsCardView,
                           isSmallView,
                           setIsSmallView
                       }: {
    isCardView: boolean,
    setIsCardView: any,
    isSmallView: boolean,
    setIsSmallView: any,
    currentUser: any, isOpenedFilters: boolean, setIsOpenedFilters: any, isNsfwON: boolean, showHidden: any
}) => {
    const windowDimensions = useWindowDimensions()
    const isTablet = windowDimensions?.width <= TABLET
    const isMobile = windowDimensions?.width <= MOBILE

    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false)
    const [isOpenedMobileSorting, setIsOpenedMobileSorting] = useState(false)
    const [selectedCollections, setSelectedCollections] = useState<any>([])
    const [filtersAmount, setFiltersAmount] = useState(0)
    const [selectedSorting, setSelectedSorting] = useState<any>(SORTING_DATA[0])
    const [nftData, setNftData] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [nftListFiltered, setNftListFiltered] = useState([])
    const [nftToShow, setNftToShow] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [forcePage, setForcePage] = useState(0)
    const [maticToUsd, setMaticToUsd] = useState<any>(0)

    const itemsPerPage = 10
    const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0;

    const wrapperRef = useRef<HTMLInputElement>(null)
    const handleLike = useHandleLike()
    const handleLoader = useHandleLoader()
    const navigate = useNavigate()

    const onClickDots = (nft: any) => navigate(`/collection/${nft?.contractAddress}/${nft?.tokenID}`)

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % totalCount;
        setForcePage(event.selected)
        setNftToShow(nftListFiltered?.slice(event.selected * itemsPerPage, (event.selected + 1) * itemsPerPage))
    }

    useEffect(() => {
        setFiltersAmount(selectedCollections?.length)
    }, [selectedCollections])

    useEffect(() => {
        if (!!currentUser) {
            handleLoader.loaderWrapper(() => handleLike
                .getAll(currentUser?.address)
                .then((res) => {
                    const filteredRes = showHidden
                        ? res
                        : res?.filter((item: any) => item?.isHidden === false || item?.isHidden === undefined)
                    setNftData(sortNFTList(filteredRes, selectedSorting?.id))
                    setNftListFiltered(sortNFTList(filteredRes, selectedSorting?.id))
                    setNftToShow(sortNFTList(filteredRes, selectedSorting?.id))
                    setTotalCount(filteredRes?.length)
                }))
        }

        new CryptoApi()
            .getUSDtoMATIC()
            .then((res) => setMaticToUsd(res))
    }, [currentUser?.address, isNsfwON, showHidden])

    useEffect(() => {
        if (!!searchQuery?.length) {
            const res = nftData?.filter((item: any) => item?.metadata?.name?.toLowerCase()?.startsWith(searchQuery?.toLowerCase()?.trim()))
            setNftListFiltered(sortNFTList(res, selectedSorting?.id))
            setNftToShow(sortNFTList(res, selectedSorting?.id))
            setTotalCount(res?.length)
        } else {
            setNftListFiltered(sortNFTList(nftData, selectedSorting?.id))
            setNftToShow(sortNFTList(nftData, selectedSorting?.id))
            setTotalCount(nftData?.length)
        }
    }, [searchQuery, selectedSorting])

    return (
        <div className={styles.favouritesTab}>
            <TabHeader
                isOpenedFilters={isOpenedFilters}
                setIsOpenedFilters={setIsOpenedFilters}
                wrapperRef={wrapperRef}
                isCardView={isCardView}
                setIsCardView={setIsCardView}
                withoutFilters
                setIsOpenedMobileFilters={setIsOpenedMobileFilters}
                isOpenedMobileFilters={isOpenedMobileFilters}
                filtersAmount={filtersAmount}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                isOpenedMobileSorting={isOpenedMobileSorting}
                totalCount={nftData?.length}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />

            <div className={styles.wrapper} ref={wrapperRef}>
                {!!nftListFiltered?.length
                    ? <>{isCardView
                        ? <div className={cn(styles.nftList)}>
                            {nftToShow?.map((item, key) =>
                                <NftCard
                                    key={key}
                                    nft={item}
                                    isSmall={isSmallView}
                                />)}
                        </div>
                        : <div className={cn(styles.NfTsTable)}>
                            <NfTsTable
                                maticToUsd={maticToUsd}
                                data={nftToShow}
                                headers={NFT_TABLE_HEADERS}
                                withDots={true}
                                withNFTCheckbox={true}
                                onClickDots={onClickDots}
                            />
                        </div>
                    }</>
                    : <p className={styles.noData}>No favorite NFTs yet</p>}
            </div>

            {!!nftListFiltered?.length && <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                pageCount={pageCount}
                forcePage={forcePage}
                previousLabel="<"
                nextLabel=">"
                renderOnZeroPageCount={null}
                containerClassName={styles.pagination}
                pageClassName={styles.pagination_page}
                activeClassName={styles.pagination_active}
                previousClassName={styles.pagination_previous}
                nextClassName={styles.pagination_next}
                disabledClassName={styles.pagination_disabled}
            />}

            {/*<FiltersMobile*/}
            {/*    isOpenedMobileFilters={isOpenedMobileFilters}*/}
            {/*    setIsOpenedMobileFilters={setIsOpenedMobileFilters}*/}
            {/*    setSelectedCollections={setSelectedCollections}*/}
            {/*    selectedCollections={selectedCollections}*/}
            {/*    filtersAmount={filtersAmount}*/}
            {/*    setIsSmallView={setIsSmallView}*/}
            {/*    isSmallView={isSmallView}*/}
            {/*/>*/}

            {isTablet && <SortingMobile
                isOpenedMobileSorting={isOpenedMobileSorting}
                setIsOpenedMobileSorting={setIsOpenedMobileSorting}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
            />}
        </div>
    );
};

export default FavouritesTab;

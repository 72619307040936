import React from 'react';
import MainLayout from "../../layouts/MainLayout/MainLayout";

const RewardsPage = () => {
    return (
        <MainLayout>
            <div></div>
        </MainLayout>
    );
};

export default RewardsPage;

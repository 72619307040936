import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import {DayPicker} from "react-day-picker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../../../Input/Input";
import arrow from '../../../../assets/images/symbols/arrow.svg'
import useOutsideClick from "../../../../hooks/dom/useOutsideClick";
import moment from "moment";
import Button from "../../../Button/Button";
import {addDays} from "date-fns";

type Props = {
    setOfferData: any,
    offerData: any
}

const DateInput = ({setOfferData, offerData}: Props) => {
    const today = new Date();

    const [isOpen, setIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [spanValue, setSpanValue] = useState('')

    const inputRef = useRef<HTMLInputElement>(null)
    const calendarRef = useRef<HTMLInputElement>(null)

    const handleDateChange = (date: any) => {
        if (!date?.to)
            return setOfferData({...offerData, from: date?.from, to: date?.from})
        setOfferData({...offerData, ...date})
    }

    const onOpenDatePicker = () => setIsOpen(!isOpen)
    const onCloseDatePicker = () => setIsOpen(false)

    const onChangeStartTime = (e: any) => setOfferData({...offerData, startTime: e?.target?.value})
    const onChangeEndTime = (e: any) => setOfferData({...offerData, endTime: e?.target?.value})

    const onSetDefaultValues = () => {
        setOfferData({
            ...offerData,
            from: today,
            to: addDays(today, 30),
            startTime: moment(today).format('HH:mm'),
            endTime: moment(addDays(today, 30)).format('HH:mm')
        })
    }

    useEffect(() => {
        setInputValue(`${moment(offerData?.to).diff(moment(offerData?.from), 'days')} days`)
        setSpanValue(`${moment(offerData?.from).format('DD MMM YYYY')} - ${moment(offerData?.to).format('DD MMM YYYY')}`)
    }, [offerData?.from, offerData?.to])

    useOutsideClick(calendarRef, onCloseDatePicker, inputRef)

    return (
        <div className={styles.dateInput}>
            <div className={styles.default} onClick={onSetDefaultValues}>Set default duration</div>

            <div className={styles.inputWrapper} ref={inputRef} onClick={onOpenDatePicker}>
                <Input
                    value={''}
                    onChange={null}
                    onlyWithArrow
                    image={arrow}
                    isOpen={isOpen}
                    withoutCaret
                />
                <span className={styles.date_note}><span>{inputValue} </span>{spanValue}</span>
            </div>

            {isOpen && <div ref={calendarRef} className={styles.calendar}>
                <DayPicker
                    disabled={{before: today}}
                    initialFocus={isOpen}
                    mode="range"
                    defaultMonth={today}
                    selected={{from: offerData?.from, to: offerData?.to}}
                    onSelect={handleDateChange}
                />
            </div>}

            <div className={styles.time_wrapper}>
                <Input value={offerData?.startTime} onChange={onChangeStartTime} type={'time'}/>
                -
                <Input value={offerData?.endTime} onChange={onChangeEndTime} type={'time'}/>
            </div>

            {/*<Button title={'Set default'} isBlackWhite onClick={onSetDefaultValues}/>*/}


        </div>
    );
};

export default DateInput;

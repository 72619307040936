// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/symbols/checked.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_checkbox__qoSVh {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
}
.styles_checkbox__qoSVh span {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
}

.styles_checkbox_wrapper__LKlMV {
  width: 24px;
  height: 24px;
  position: relative;
}
.styles_checkbox_wrapper__LKlMV input[type=checkbox] {
  width: 20px;
  height: 20px;
}
.styles_checkbox_wrapper__LKlMV:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 2px solid #757575;
  border-radius: 4px;
  background-color: #1b1b1b;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: all 0.3s ease;
  padding-top: 3px;
}

.styles_checkbox_wrapper_checked__hcEBH:before {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background: #F79C00;
  border: 2px solid #F79C00;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_checkbox_notCentered__Nk1r- {
  justify-content: flex-start;
}

.styles_checkbox_isFullWidth__-zlsL {
  justify-content: flex-start;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Checkbox/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,eAAA;AACF;AACE;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAGA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,yBAAA;EACA,gBAAA;AADJ;;AAME;EACE,gDAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAHJ;;AAOA;EACE,2BAAA;AAJF;;AAOA;EACE,2BAAA;EACA,WAAA;AAJF","sourcesContent":[".checkbox {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n  cursor: pointer;\n\n  span {\n    font-family: 'Open Sans', sans-serif;\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 25px;\n    color: #FFFFFF;\n  }\n}\n\n.checkbox_wrapper {\n  width: 24px;\n  height: 24px;\n  position: relative;\n\n  input[type=\"checkbox\"] {\n    width: 20px;\n    height: 20px;\n  }\n\n  &:before {\n    content: '';\n    width: 24px;\n    height: 24px;\n    border: 2px solid #757575;\n    border-radius: 4px;\n    background-color: #1b1b1b;\n    cursor: pointer;\n    box-sizing: border-box;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 1;\n    transition: all .3s ease;\n    padding-top: 3px;\n  }\n}\n\n.checkbox_wrapper_checked {\n  &:before {\n    content: url(\"../../assets/images/symbols/checked.svg\");\n    background: #F79C00;\n    border: 2px solid #F79C00;\n    transition: all .3s ease;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n\n.checkbox_notCentered {\n  justify-content: flex-start;\n}\n\n.checkbox_isFullWidth {\n  justify-content: flex-start;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `styles_checkbox__qoSVh`,
	"checkbox_wrapper": `styles_checkbox_wrapper__LKlMV`,
	"checkbox_wrapper_checked": `styles_checkbox_wrapper_checked__hcEBH`,
	"checkbox_notCentered": `styles_checkbox_notCentered__Nk1r-`,
	"checkbox_isFullWidth": `styles_checkbox_isFullWidth__-zlsL`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import styles from "../PropertiesBlock/styles.module.scss";
import statsImg from "../../../assets/images/symbols/stats.svg";
import plusImg from "../../../assets/images/symbols/plus.svg";
import AddStatsModal from "../../../components/Modals/AddStatsModal/AddStatsModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import StatsRow from "../StatsRow/PropertyRow";

type Props = {
    setCreateData: any,
    createData: any
}

const StatsBlock = ({createData, setCreateData}: Props) => {
    const handleStatsModal = useHandleModal()

    const onOpenModal = () => handleStatsModal.open()

    const onDelete = (id: number) => setCreateData({
        ...createData,
        stats: createData?.stats?.filter((item: any) => item?.id !== id)
    })

    return (
        <div className={styles.propertiesBlock}>
            <div className={styles.block_top}>
                <div className={styles.wrapper}>
                    <img src={statsImg} alt={''}/>
                    <div>
                        <p className={styles.title}>Stats<span>(Optional)</span></p>
                        <p className={styles.text}>Numerical traits that just show as numbers</p>
                    </div>
                </div>
                <div className={styles.plus} onClick={onOpenModal}>
                    <img src={plusImg} alt={''}/>
                </div>
            </div>

            {createData?.stats?.some((item: any) => item?.name?.length && item?.type?.length)
            && <div className={styles.list}>
                {createData?.stats?.map((item: any, key: number) =>
                    <StatsRow property={item} key={key} onDelete={onDelete}/>)}
            </div>}

            {handleStatsModal.isActive && <AddStatsModal
                isOpen={handleStatsModal.isActive}
                onClose={handleStatsModal.close}
                setCreateData={setCreateData}
                createData={createData}
            />}
        </div>
    );
};

export default StatsBlock;

import React, {useRef, useState} from 'react';
import Input from "../../../Input/Input";
import styles from './styles.module.scss'
import arrow from '../../../../assets/images/symbols/arrow.svg'
import useOutsideClick from "../../../../hooks/dom/useOutsideClick";
import {AVAILABLE_CURRENCY} from "../../../../constants/listingPage";
import {toast} from "react-toastify";
import ToastMsg from "../../../ToastMsg/ToastMsg";

type Props = {
    setOfferData: any,
    offerData: any
}

const PriceInput = ({setOfferData, offerData}: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    const dropdownRef = useRef<HTMLInputElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const onToggle = () => setIsOpen(!isOpen)
    const onClose = () => setIsOpen(false)

    const onChangePriceValue = (e: any) => {
        if (+e?.target?.value > 100000000000)
            return toast(<ToastMsg text={'Price can not be over 100,000,000,000 SHIB'} isError/>)
        if (e?.target?.value < 0) return
        if (e?.target?.value?.split('.')[1]?.length > 2) return toast(<ToastMsg text={'Only hundredth are allowed in price'} isError/>)

        if (e?.target?.value < 0.001) toast(<ToastMsg text={'Minimum offer price is 1 SHIB'} isError/>)
        setOfferData({...offerData, price: {...offerData.price, value: e?.target?.value}})
    }

    const onChangePriceCurrency = (currency: any) => {
        setOfferData({...offerData, price: {...offerData.price, currency}})
        onClose()
    }

    useOutsideClick(dropdownRef, onClose, inputRef)

    return (
        <div className={styles.priceInput}>
            <Input
                inputRef={inputRef}
                value={offerData?.price?.value}
                onChange={onChangePriceValue}
                placeholder={'Offer price'}
                text={offerData?.price?.currency?.label}
                image={arrow}
                withRightBlock
                onClickText={onToggle}
                type={'number'}
                min={0}
                isOpen={isOpen}
            />

            {isOpen && <div className={styles.dropdown} ref={dropdownRef}>
                {AVAILABLE_CURRENCY?.map((item: any, key: number) =>
                    <div key={key} className={styles.dropdown_item} onClick={() => onChangePriceCurrency(item)}>
                        {item?.label}
                    </div>)}
            </div>}
        </div>
    );
};

export default PriceInput;

import {Routes, Route} from "react-router-dom";
import {pathList} from "./path";
import MainPage from "../templates/MainPage/MainPage";
import ExplorePage from "../templates/ExplorePage/ExplorePage";
import RewardsPage from "../templates/RewardsPage/RewardsPage";
import StatsPage from "../templates/StatsPage/StatsPage";
import CreatePage from "../templates/CreatePage/CreatePage";
import ProfilePage from "../templates/ProfilePage/ProfilePage";
import SingleCollectionPage from "../templates/SingleCollectionPage/SingleCollectionPage";
import SingleNftPage from "../templates/SingleNFTPage/SingleNFTPage";
import ListNftPage from "../templates/ListNFTPage/ListNFTPage";
import LoginPage from "../templates/LoginPage/LoginPage";
import CreateCollectionPage from "../templates/CreateCollectionPage/CreateCollectionPage";
import MyCollectionsPage from "../templates/MyCollectionsPage/MyCollectionsPage";
import PrivacyPolicyPage from "../templates/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsPage from "../templates/TermsPage/TermsPage";
import GuidesPage from "../templates/GuidesPage/GuidesPage";
import LaunchpadPage from "../templates/LaunchpadPage/LaunchpadPage";
import CreateDropsCollectionPage from "../templates/CreateDropsCollectionPage/CreateDropsCollectionPage";

export default function Routers() {
    return (
        <Routes>
            {/*<Route path={pathList.login.path} element={<LoginPage/>}/>*/}
            <Route path={pathList.mainPage.path} element={<MainPage/>}/>
            <Route path={pathList.exploreDefinedTab.path} element={<ExplorePage/>}/>
            <Route path={pathList.explore.path} element={<ExplorePage/>}/>
            <Route path={pathList.rewards.path} element={<RewardsPage/>}/>
            <Route path={pathList.stats.path} element={<StatsPage/>}/>
            <Route path={pathList.create.path} element={<CreatePage/>}/>
            <Route path={pathList.createCollection.path} element={<CreateCollectionPage/>}/>
            <Route path={pathList.createDropsCollection.path} element={<CreateDropsCollectionPage/>}/>
            <Route path={pathList.listNFT.path} element={<ListNftPage/>}/>
            <Route path={pathList.nft.path} element={<SingleNftPage/>}/>
            <Route path={pathList.collection.path} element={<SingleCollectionPage/>}/>
            <Route path={pathList.launchpad.path} element={<LaunchpadPage/>}/>

            <Route path={pathList.myCollections.path} element={<MyCollectionsPage/>}/>
            <Route path={pathList.profileCollected.path} element={<ProfilePage/>}/>
            <Route path={pathList.profileCreated.path} element={<ProfilePage/>}/>
            <Route path={pathList.profileActivity.path} element={<ProfilePage/>}/>
            <Route path={pathList.profileListings.path} element={<ProfilePage/>}/>
            <Route path={pathList.profileOffers.path} element={<ProfilePage/>}/>
            <Route path={pathList.profileFavorites.path} element={<ProfilePage/>}/>

            <Route path={pathList.privacyPolicy.path} element={<PrivacyPolicyPage/>}/>
            <Route path={pathList.terms.path} element={<TermsPage/>}/>
            <Route path={pathList.guides.path} element={<GuidesPage/>}/>
        </Routes>
    )
}
